
import * as types from './types'
import { CALL_API } from '../../middlwares/api'




  export const resendAccountVerification = (data)=> {

    return {

        [CALL_API]: {
          endpoint: `/register`,
          method: 'POST',
          request: 'URL',
          payload: {action: 'resendVerification',payload: {verify: {...data}}},
          authencitcated: false,
          types: [types.RESEND_ACCOUNT_VERIFICATION_REQUEST, types.RESEND_ACCOUNT_VERIFICATION_SUCCESS, types.RESEND_ACCOUNT_VERIFICATION_FAILURE]
        }
      }

  }


  