import React from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'

import FavoriteIcon from '@material-ui/icons/Favorite';
import ContractType from './components/contracttype' 
import ExpLevel from './components/explevel'
import Location from './components/location'
import JobTitle from './components/jobtitle'
import Complete from './components/complete'



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Questionnaire extends React.Component{

  constructor(props){
    super();
    this.state = {

      step: 1,
      status: 0,
      location: 'Gauteng'

       
    }
  }

  getCurrentStep(step){

      console.log('THE CURRENT STATE')
      console.log(this.state) 
      const {props} = this 
      const {actions,width} = props 
      console.log(props) 
      console.log(actions)

      switch (step) {
        case 1:
            return (
                

                        <ContractType
                            values={this.state}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                        />
          
                 
                
            );

        case 2:

            return (
               

                        <ExpLevel
                            values={this.state}
                            prevStep={this.prevStep}
                            /*submitted={this.submitted}*/
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                        />
                   
            );

        case 3:

            return (
               

                        <Location
                            values={this.state}
                            prevStep={this.prevStep}
                            /*submitted={this.submitted}*/
                            nextStep={this.nextStep}
                            width={width}
                            handleChange={this.handleChange}
                        />
                   
            );

        case 4:

            return (
                

                        <JobTitle
                            values={this.state}
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            submitted={this.submitted}
                            handleChange={this.handleChange}
                        />
                    
            );


        case 5:

            return (
                

                        <Complete 

                          values={this.state}
                          prevStep={this.prevStep}
                          nextStep={this.nextStep}
                          submitted={this.submitted}
                          actions={actions}
                      
                        
                        />
                  
            );

      }
  }

  nextStep = (validator = null) => {

    const { step } = this.state;
    console.log('THE VALIDATOR')
    console.log(typeof validator)

    if(typeof validator !== 'function'){
  
      this.setState({
            step: step + 1
        });

    }else{

      let validatorResponse = validator() 
      console.log('THE VALIDATOR RESPONSE')
      console.log(validatorResponse)
      if(!validatorResponse){
       
         return false
      }else{
        
        this.setState({
          step: step + 1
      });
      
      }

    }
 
   
  };

  // nextStep = (stepData) => {

  //   const { step, answers = null } = this.state;
  //   const stepKey = stepData.keys()
    
  //   if(!answers){

  //       answers = {[stepKey]: stepData[stepKey]}
  //       this.setState({
  //         step: step + 1,
  //         answers: answers
  //       })

  //   }else{

  //     this.setState({

  //       step: step + 1
        
  //     });

  //   }


   
  // };

  // Go back to prev step
  prevStep = () => {
      const { step } = this.state;
      this.setState({
          step: step - 1
      });
  };

  submitted = () => {
      const { status } = this.state;
      this.setState({
          status: status + 1
      });
  }



handleChange = ({ target: { value, name } },remove = false) =>{

   console.log('STORING A VALUE')
   console.log(name)
   console.log(value)  
   console.log(remove)
   
   if(!remove){

    this.setState({ [name]: value },()=>{

      console.log('NEW STATE AFTER ADD UPDATE')
      console.log(this.state)
    })

   }else{

      //const {state} = this 
      let state = {...this.state} 

      if(state[name]){
        console.log('THE NAME IS IN THE STATE')
        console.log(name)
      }
      delete state[name] 
      console.log('THE UDPATESTATE')
      console.log(state)
      this.setState(state)


   }
  
  }


  render(){

    const { step } = this.state;
    return(
      <div className='questionnaire'>
        
         {this.getCurrentStep(step)}
       
      </div>
    );
  }
}

export default withStyles(styles)(Questionnaire)