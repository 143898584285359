import { yellow } from '@material-ui/core/colors'
import { SettingsOutlined } from '@material-ui/icons'
import React, { Component } from 'react' 







class ActionConfirm extends Component{
    
    constructor(){

        super()
        this.state ={
            showActionMessage: false,
            option: ''
        }

    

        
    }
    componentDidMount(){

        const {props} = this 
        const {setTitle,confirmType} = props
        
        confirmType === 'delete' ?  setTitle('Confirm delete') : setTitle('Choose replace option')
    }

   

    componentWillUnmount(){

        const {actions,userProfile} = this.props
        const {removeMessage} = actions 
        const {message=''} = userProfile 

        if(message){

           
            removeMessage()
        }
    }

    showUpload = (option)=>{

        const {props} = this 
        const {openUpload} = props

        this.setState({...this.state,showActionMessage: true,option: option})
        setTimeout(()=>{

            console.log('THE TIMEOUT IS RUNNING')
            console.log(openUpload)
            openUpload(option)

        },500)

       
    }

    deletecv = (option)=>{

        const {props} = this 
        const {deleteCV} = props

        this.setState({...this.state,showActionMessage: true,option: option})
        setTimeout(()=>{
            deleteCV()

        },500)

       
    }

    
    componentWillUnmount(){
        this.setState({...this.state,showActionMessage: false,option: ''})
    }

   
    render(){


     const {props,state} = this 
     const {confirmType='delete',userProfile} = props 
     const {message=''} = userProfile
    
     const {option,showActionMessage} = state
     let checkStyles = {borderRadius: '5px',marginRight: '5px',textAlign: 'center',display: 'inline-block',border: 'solid maroon 1px',height: '15px',width: '15px'}
     let confirmTextStyles = {display: 'inline-block'}
     let repBg = option === 'only' ? {backgroundColor: '#eed2d2'} : {backgroundColor: 'white'}
     let allBg = option === 'all' ? {backgroundColor: '#eed2d2'} : {backgroundColor: 'white'}
    //  let listStyles = {padding: '5px',width: '300px',marginBottom: '10px',cursor: 'pointer'}
     let iconStyles = {color: 'seagreen',position:'relative',bottom:'2px',display: 'inline-block'}
     let textStyles = {marginBottom: 0}
     let deleteErrorStyles = {color: 'white',display: 'inline-block',backgroundColor: 'seagreen',padding: '7px',cursor: 'pointer',textAlign: 'center',fontSize: '13px',borderRadius: '2px'}
     //#eed2d2

       
       

        return(

           
    
           
                 
                <ul className="upload-cv-success-list">

                    {
                        confirmType === 'delete'
                        ? <>
                        
                        <li className="upload-cv-success-list-i bx-raised-1 upload-cv-success-list-i-c " style={{...repBg}} onClick={()=>{this.deletecv('delete')}}>

                                 <p style={{marginTop: '5px',marginBottom: '30px'}}>You are about to delete your cv from Jobbri, are you sure this is what you want?</p>
                                <span style={checkStyles} aria-hidden="true">
                                {option.trim() === 'delete' 
                                 ? <i className="fa fa-lx fa-check" style={iconStyles}></i> 
                                 : null
                                 }
                                </span>
                         
                            <span style={confirmTextStyles}  >Yes, delete my cv</span>
                            
                        </li>

 

    

                         </>
                        : <>
                        
                            <li className="upload-cv-success-list-i bx-raised-1 upload-cv-success-list-i-c" style={{...repBg}} onClick={()=>{this.showUpload('only')}}>
                                <span style={checkStyles} aria-hidden="true">
                                {option.trim() === 'only' 
                                 ? <i className="fa fa-lx fa-check" style={iconStyles}></i> 
                                 : null
                                 }
                                </span>
                         
                            <span style={confirmTextStyles}  >Replace CV only</span>
                            <p style={textStyles}>This option will only delete your uploaded cv, any other information related to your current cv will remain.</p>
                            </li>

                            <li className="upload-cv-success-list-i bx-raised-1 upload-cv-success-list-i-c" style={{...allBg}} onClick={()=>{this.showUpload('all')}}>
                                <span style={checkStyles} aria-hidden="true">
                                 {option.trim() === 'all' 
                                 ? <i className="fa fa-lx fa-check" style={iconStyles}></i> 
                                 : null
                                 }
                                </span>
                                <span style={confirmTextStyles} >Replace Everything</span>
                                <p style={textStyles}>This option will replace your current cv along with any cv profile information you had completed.</p>
                            </li>

                          </>
                    }
                    
                   
                    {message 
                    
                    ?  <span style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                        <small style={{display: 'block',color: 'red',marginBottom: '15px',fontSize: '14px'}}>File has failed to delete</small>
                        <span style={deleteErrorStyles} onClick={()=>{this.deletecv('delete')}}>Let me try again</span>
                     </span> 
                    
                    :showActionMessage 
                        ?  <span style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                        
                            {option === 'delete' ? 'The system is deleting your cv...' : 'Uploader is openning...'}
                            </span> 
                        : null}
                        
                </ul>
               




          

          
        )
    }


}


export default ActionConfirm


