
import React,{Component} from 'react'



class Top extends Component{
    
    
    render(){

        return(

            <div className="home__top pd-left-fl-x-bt">

                TOP CATEGORIES
            </div>
        )
    }

}


export default Top