import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import { downloadResume } from '../ViewResume/actions'
import ActionConfirm from './successconfirm'





class Success extends Component{
    
    constructor(){

        super()

        this.state = {
            showReplaceConfirm: false,
            showDeleteConfirm: false
        }

        
    }

    open = (url)=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push(url)
    }

    componentDidMount(){

        const {props} = this 
        const {setTitle,initiatorType=''} = props 

        if(initiatorType){
            initiatorType === 'all' ?  setTitle('CV data replace success') :  setTitle('Replace success')
        }else{

            setTitle('Upload success')

        }

    }

    confirmReplace = (e)=>{

      e.preventDefault()

      this.setState({...this.state,showReplaceConfirm: !this.state.showReplaceConfirm})

    }

    confirmDelete = (e)=>{

        e.preventDefault()
  
        this.setState({...this.state,showDeleteConfirm: !this.state.showDeleteConfirm})
  
      }

    downloadCV = ()=>{


        const {actions,userProfile} = this.props
        const {downloadUploadResume} = actions 
        const {profile} = userProfile
        const {personal} = profile 
        const {resume_url} = personal 
        let parts = resume_url.indexOf('//') >= 0 ? resume_url.split('//') : resume_url.split('\\') 
        const send = {fileName: parts[parts.length - 1]}
        downloadUploadResume(send)

    }

    getDocumentExtension = ()=>{

        
        const {userProfile} = this.props
        const {profile} = userProfile
        const {personal} = profile 
        const {resume_url,first_name} = personal 
        const cvNameParts = first_name.split(' ') 
        const cvName = cvNameParts.length === 1 ? `${cvNameParts[0]}-cv${resume_url}` : `${cvNameParts[0]}-${cvNameParts[1]}-cv`   
        let parts = resume_url.split('.') 
        let ext = parts[parts.length - 1] 

        if(ext === 'pdf') return(
        <>
        <span className="upload-cv__files-icon"> <i style={{fontSize: 'red'}} className="fa fa-lg fa-file-pdf-o"></i></span> 
        <span className="upload-cv__files-name">{`${cvName}.${ext}`}</span>
        </>)
        if(ext === 'docx') return (
        <>
        <span className="upload-cv__files-icon"> <i style={{fontSize: 'red'}} className="fa fa-lg fa-file-word-o"></i></span>
        <span className="upload-cv__files-name">{`${cvName}.${ext}`}</span>
        </>) 
        return null



    }

    deleteCV = ()=>{


        const {actions,userProfile} = this.props
        const {deleteUploadResume} = actions 
        const {profile} = userProfile
        const {personal} = profile 
        const {resume_url,id} = personal 
        let parts = resume_url.indexOf('//') >= 0 ? resume_url.split('//').join('\\') : resume_url
        const send = {fileName: parts}
        deleteUploadResume(send,id)

    }

    

    componentWillUnmount(){

        this.setState({...this.state,showDeleteConfirm:false,showReplaceConfirm: false})
    }

    


    
    render(){


       const {state,props} = this 
       const {showDeleteConfirm,showReplaceConfirm} = state 
       const {openUpload,setTitle,userProfile} = props 
    //    const {profile} = userProfile 
    //    const {personal} = profile
    //    const {first_name,resume_url} = personal 
      
       if(showReplaceConfirm) return <ActionConfirm confirmType="replace" openUpload={openUpload} setTitle={setTitle} deleteCV={this.deleteCV.bind(this)} userProfile={userProfile}  /> 
       if(showDeleteConfirm) return <ActionConfirm confirmType="delete" setTitle={setTitle} deleteCV={this.deleteCV.bind(this)} userProfile={userProfile} />

        return(

           
    
            <div className="upload-cv-success">

                 <h2 className="upload-cv-success-title">
                 <span className="upload-cv-success-text">
                    Your cv has successfully been uploaded.
                 </span> 
                 {/* <span style={{marginTop: '8px'}}>
                    <i className="fa fa-lx fa-smile-o" style={{color: 'seagreen'}} aria-hidden="true"></i>
                    </span> */}
                </h2>

                <section className="upload-cv__files">
                    {this.getDocumentExtension()}
                    {/* <span className="upload-cv__files-name">{cvName}</span> */}
                </section>

                

                <ul className="upload-cv-success-list">
                    <li className="upload-cv-success-list-i">
                        <span className="upload-cv-success-list-i-icon"><i className="fa fa-lx fa-eye" style={{color: 'seagreen'}} aria-hidden="true"></i></span>
                        {/* <span className="upload-cv-success-list-i-name">name</span> */}
                        <span className="upload-cv-success-list-i-action" onClick={()=>{this.open('/cv-profile')}}>View</span>
                    </li>

                    <li className="upload-cv-success-list-i">
                        <span className="upload-cv-success-list-i-icon"><i className="fa fa-lx fa-download" style={{color: 'seagreen'}} aria-hidden="true"></i></span>
                        {/* <span className="upload-cv-success-list-i-name">name</span> */}
                        <span className="upload-cv-success-list-i-action" onClick={()=>{this.downloadCV()}}>Download</span>
                    </li>

                    <li className="upload-cv-success-list-i">
                        <span className="upload-cv-success-list-i-icon"><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></span>
                        {/* <span className="upload-cv-success-list-i-name">name</span> */}
                        <span className="upload-cv-success-list-i-action" onClick={this.confirmReplace}>Replace</span>
                    </li>

                    <li className="upload-cv-success-list-i">
                        <span className="upload-cv-success-list-i-icon"><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></span>
                        {/* <span className="upload-cv-success-list-i-name">name</span> */}
                        <span className="upload-cv-success-list-i-action upload-cv-success-list-i-action-delete" onClick={this.confirmDelete}>Delete</span>
                    </li>

                    
                </ul>
               




            </div>

          
        )
    }


}


export default Success


