
import React from 'react'


class Tooltip extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        displayTooltip: true
      }
      this.hideTooltip = this.hideTooltip.bind(this)
      this.showTooltip = this.showTooltip.bind(this)
    }
    
    hideTooltip () {
      this.setState({displayTooltip: false})
     
      
    }
    showTooltip () {
      this.setState({displayTooltip: true})
    }

    renderList(list){

      let elList = null 
      const errorStyles = {
        
        // backgroundColor: 'red',
        color: 'red',
        display: 'block',
        margin: 0,
        padding: '6px',
        fontSize: '15px'
      }

      const validStyles = {
        
        // backgroundColor: 'green',
        color: 'seagreen',
        display: 'block',
        margin: 0,
        padding: '6px',
        fontSize: '15px'
      }

      
      const spanStyles = {
        
        
        display: 'inline-block',
        marginRight: '10px',
        width: '15px',
        verticalAlign: 'top'
       
      }

      const spanTextStyles = {
        
        
        display: 'inline-block',
        width: '90%',
        verticalAlign: 'top'
       
      }






      elList = (<ul style={{listStyleType: 'circle'}}>

        {
          list.map((it,i)=>{

            if(it.isValid) {
              return <li key={i} style={validStyles}>
              <span style={spanStyles}>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
              </span>
              <span style={spanTextStyles}>{it.message}</span>
              </li>
              }

             return <li key={i} style={errorStyles}>

              <span style={spanStyles}>
              <i class="fa fa-times" aria-hidden="true"></i>

              </span>
              <span style={spanTextStyles}>{it.message}</span>

                </li>
                      
            })
          }

      </ul>)

      return elList
  }
  
    render() {

      const {props} = this 
      const {message='',list=null} = props
      //let message = this.props.message
      let position = this.props.position 
      let active = this.props.active 
      const parentPad = list ? {padding: '0',
      width: '80%', 
      backgroundColor: `white`, 
      '&:before': {color: 'green'}} : {padding: '10px'}

      console.log('THE TOOLTIP IS RENDERED')
      console.log(list)
      return (
      
          active ? 
        //   this.state.displayTooltip ?


                <div className="tooltip is-visible">

                <span className='tooltip-label' style={parentPad} onMouseLeave={this.hideTooltip}>
                    {/* <div className={`tooltip-bubble tooltip-${position}`}>
                        <div className='tooltip-message'>{message}</div>
                    </div> */}
                    {/* {message} */}

                    {list ? this.renderList(list) : message}
                   
                </span>
                </div>
            
            : null

          

          
        //   {/* <span 
        //     className='tooltip-trigger'
        //     onMouseOver={this.showTooltip}
        //     >
        //     {this.props.children}
        //   </span> */}
        
      )
    }
  }

  export default Tooltip
  
//   class App extends React.Component {
//     render() {
//       return (
//         <div className='container'>
//           <p>Here is a <Tooltip message={'Hello, I am a super cool tooltip'} position={'top'}>tooltip</Tooltip> on top.</p>
//           <p>Here is a <Tooltip message={'Hello, I am a super cool tooltip'} position={'bottom'}>tooltip</Tooltip> on bottom.</p>
//           <p>Here is a <Tooltip message={'Hello, I am a super cool tooltip'} position={'left'}>tooltip</Tooltip> on left.</p>
//           <p>Here is a <Tooltip message={'Hello, I am a super cool tooltip'} position={'right'}>tooltip</Tooltip> on right.</p>
//         </div>
//       )
//     }
//   }
//   ReactDOM.render(
//     <App />,
//     document.getElementById('app')
//   )