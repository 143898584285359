

import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import Modal from 'react-modal'; 
import Form from './form'
import { ThemeContext } from '../../../../context';
 
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '60%',
    padding               : 0,
    border                : 'none',
    background            : 'none',
  
    borderRadius          : 0
  }
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement')
 

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)'

class AlertModal extends Component{


  constructor(props){
    super(props)
    this.state ={
      modalIsOpen: false
    }
  }
  // const [modalIsOpen,setIsOpen] = React.useState(false);
  openModal(){
    this.setState({...this.state,modalIsOpen: true})
  }
 
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
 
  closeModal(){
    // console.log('THE VALUE OF THIS')
    // console.log(this)
    // console.log(this.setState)
    this.setState({...this.state,modalIsOpen: false})
  }

  
  viewPage(url,e){

    // e.preveventDefault()

 

    const {props} = this 
    const {actions} = props 
    const {push} = actions

    push(url)
    

}
 
static contextType = ThemeContext
    
render(){

    const {theme, themeName} = this.context 
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
    const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}

    
    const {state,props,closeModal,openModal,afterOpenModal} = this 
    const {modalIsOpen} = state 
    const {search,auth,actions,userProfile,width} = props 
    const {appAuth} = auth 
    const {isAuth=false} = appAuth 
    const {profile={}} = userProfile 
    const {alertsCount=0} = profile 
    // const {searchy} = search 
    const {jq} = search 
    const disabledInput=true
    const keyWordStyles = {
      color: '#ee4c7c',
      display: 'inline-block',
      fontSize: '1.5rem'
    }
    // const containerMobileStyles = width <= 800 ? {display: 'inline-block',width: '90px',padding:0,margin:0} : null 
    if(width > 900 && width <= 1200) customStyles.content.width = '80%'
    if(width <= 900) customStyles.content.width = '90%'

    const modFoot =  width <= 800 && alertsCount === 3 ? {marginTop: '-5rem'} : null
    const contClass = width <= 800 ? 'job__jobs--lt-mobile-containers' : ''
    const mobileStyles = width <= 800 ? {

      display: 'inline-block',
      width: 'auto',
      backgroundColor: 'transparent',
      padding: 0,
      margin: 0,
    } : null

    return (
      <div className={contClass}>
          <div className="job__jobs--lt-alert" style={mobileStyles} onClick={openModal.bind(this)}>

            {width > 800 ?
            
              <>
                <span className="job__jobs--lt-alert-i">
                    <Icon>
                        <Notifications />
                    </Icon>
                </span>

                <span className="job__jobs--lt-alert-text"  >
                  Email me jobs like this                     
                </span>

            </>
            : <button className="job__jobs--lt-mobile-card-rect-4-sub-btn">
                <span className="job__jobs--lt-mobile-card-rect-4-sub-btn-rect"></span>
                <span className="job__jobs--lt-mobile-card-rect-4-sub-btn-rect-2">
                    <small><Icon><Notifications  /> </Icon></small>
                    </span> 

             </button>
            }


          </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal.bind(this)}
          style={customStyles}
          contentLabel="Example Modal"
        >

        

            <section className="alert__modal">

              <article className="alert__modalcontainer" style={boxesStyling}>

                  <div className="alert__modalbody">
                  
                    {/* <h2>Be the first to immediately know when <span style={keyWordStyles}>{jq}</span> jobs ship</h2>
                    <section>
                      <Form auth={auth} search={search} closeModal={closeModal.bind(this)} actions={actions} />
                    </section> */}

                    { isAuth 
                    
                      ? alertsCount < 3


                          ? <h2 style={textColorStyles}>Be the first to immediately know when <span style={keyWordStyles}>{jq}</span> jobs ship</h2>
                          
                          : <h2 style={{marginBottom: '5rem',fontWeight: 'normal',...textColorStyles}}>
                              <span style={{display: 'block'}}>You have reached your maximum allowed job alerts. </span>
                              <small style={{display: 'inline-block',color: 'grey',width: '80%',marginTop: '1rem',...textColorStylesAccent, fontSize:'1.5rem'}}>Jobbri allows a maximum of three different job alerts per account, to add another one, delete one or more of your set job alerts.</small>
                            </h2>
                         
                      : <h2>Be the first to immediately know when <span style={keyWordStyles}>{jq}</span> jobs ship</h2>
                   
                    }
                    <section style={alertsCount < 3 ? {marginBottom: 0} : {marginBottom: '5rem'}}>
                      { alertsCount < 3 
                      ? <Form auth={auth} disabledInput={disabledInput} search={search} alertsCount={alertsCount} userProfile={userProfile} closeModal={closeModal.bind(this)} actions={actions} />
                      : <button className="alert__modalfootr--cancel" onClick={()=>this.viewPage('/alerts')}>Let Me Manage My Alerts</button>
                      }
                    </section>

                  </div>
                  
              </article>

              <div className="alert__modalfootr bx-raised-1" style={modFoot}>

                <section className="alert__modalfootr--container" style={boxesStyling}>

                  <div className="alert__modalfootr--policy">
                    <p style={{fontSize: '1.5rem',...textColorStyles}}>By creating a job alert, you agree to Jobbri’s terms of use and <a href="/privacy" style={{color: 'seagreen',fontSize: '1.7rem'}} className="link" target="_blank">privacy policy.</a></p>
                  </div>
                  <button className="alert__modalfootr--cancel" onClick={closeModal.bind(this)}>Cancel</button>

                </section>

                <section className="alert__modalfootr--discover">
                  <p>We’ll help you discover great job opportunities</p>
                </section>
                

              </div>
            </section>

        
 
         
          
        </Modal>
      </div>
    )
  
  }
}
 
export default AlertModal