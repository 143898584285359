import React, { Component } from 'react';
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import Contacts from '@material-ui/icons/People';
import { withStyles } from '@material-ui/core/styles'
import Dropdown from '../Dropdown/dropdown'
import SlidingMenu from './slidingmenu'
import Questionnaire from './questionnaire'
import { ThemeContext } from '../../../context';
// import Navigation from "../../../Common/navigation/navigation"
// import links from './links'

const styles = {
    root: {
     
      color:"green",
      fontSize: 20,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Men extends Component{

    constructor(props){


        super()

        this.state = {
          
          toggleMenu: false

        }

        this.handleClick = this.handleClick.bind(this)

      }


    componentDidUpdate(){

          const {toggleMenu} = this.state
          
      
          if(toggleMenu === true){
      
            // ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'hidden' : ''
            // document.body.style.overflow = 'hidden' 
            //document.body.style.pointerEvents ='none'
          //   document.body.style.opacity = '0.4'
      
          }else{
      
            // ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'visible' : ''
            //document.body.style.pointerEvents ='all'
          //   document.body.style.opacity = '1'
              // document.body.style.overflow = 'visible' 
          }
        
        }

      componentWillMount(){

        // ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'visible' : "" 
          // document.body.style.overflow = 'visible'
      } 

      handleClick() {

          console.log('THE MENU BUTTON HAS BEEN CLICKED')
          console.log(this.state.toggleMenu);
          this.setState({toggleMenu: !this.state.toggleMenu});

        }




      // toggleSelected(id, key){

      //   let temp = this.state[key]
      //   temp[id].selected = !temp[id].selected
      //   this.setState({
      //     [key]: temp
      //   })

      // }

  
      static contextType = ThemeContext
    
      render(){
  
          const {theme, themeName} = this.context 
          const boxesStyling = { backgroundColor: themeName == 'dark' 
          ? theme.colors.complimentary['accent-3'] 
          :  theme.colors.complimentary.base }
          const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
          const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
          const textColorStyles = {color:  theme.colors.complimentary.foreground}
          const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
          const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}
  
  

      let slideClass;
      this.state.toggleMenu
        ? slideClass = 'showMenu'
        : slideClass = 'hideMenu'; 
      const {props} = this 
      const {actions,width} = props

      

      let menuStyles = width > 900 
      
        ? {
                                        
         
          fontSize: `35px`,
          display: `block`,
          left:  `25px`,
          position: 'fixed',
          color: 'red'
          

        }
        : {
                                        
          
          fontSize: '30px',
          display: `block`,
          left: '10px',
          position: 'relative',
          color: 'red',
          top: '-30px'
       
          

        }

    return(

        <div >


        

                
                    <section className={`bg-primary ${this.props.path === '/' && this.props.width > 768 ? 'menu-default': 'menu-custom'}`}>
                       

                        {/* <Dropdown

                            
                            activator={true}
                            list={this.state.location}
                            toggleItem={this.toggleSelected.bind(this)}

                        /> */}

                      <button style={{zIndex:110003,position:'relative',
                                          border: 'none',
                                          backgroundColor: 'transparent',
                                          cursor: 'pointer',
                                         
                                      }} 
                                  onClick={this.handleClick}>
                                          
                            {
                                this.state.toggleMenu 
                                ? 
                                    <p className=" cursor-pointer left-offset-fl-xx-tn link pos-rel d-block">
                
                                      <span style={menuStyles} >x</span>

                                            
                                
                                    </p>
                                
                                : <p className=" cursor-pointer left-offset-fl-xx-tn link pos-rel d-block">
                
                                  <span className="bar bar-1"></span>
                                  <span className="bar bar-2"></span>
                                  <span className="bar bar-3"></span>
                                        
                            
                                </p>
                                
                            }
                                  
                                    
                      </button>

                      <SlidingMenu slideClass={slideClass} onClick={this.handleClick}>

                        {this.props.width > 900 ? <Questionnaire actions={actions} width={width} /> : null}
                        <section className="sliding-menu-nav-cont" style={boxesStyling}>
                            <ul>
                              <li><Link to="/" style={textColorStyles}>Home</Link></li>
                              <li><Link to="/about">About</Link></li>
                              <li><Link to="/contact">Contact</Link> </li>
                              <li><Link to="/services">Services</Link></li>
                              <li><Link to="/blog">Blog</Link></li>
                              <li><Link to="/faqs">Faqs</Link></li>
                              <li><Link to="/privacy">Privacy</Link></li>
                            </ul>
                        </section>

                        {this.props.width <= 900 ? <Questionnaire actions={actions} width={width} /> : null}

                        <div className="engagement">
                          <p className="engagement__applicant">
                            <span className="engagement__applicant-plus">+</span>
                            <span className="engagement__applicant-text">Create Cv</span>
                          </p>
                          <p className="engagement__employer" >
                            <span className="engagement__employer-plus">+</span>
                            <span className="engagement__employer-text">Post jobs</span>
                          </p>
                        </div>

                      </SlidingMenu>
                      

                    </section>


        </div>
    )
  }
}

export default withStyles(styles)(Men) 





// import React from 'react' 
// import {Link} from 'react-router-dom';
// import SlidingMenu from './slidingmenu' 
// const ExecutionEnvironment = require('exenv');



// class Primary extends React.Component{
//     constructor(props) {
//       super(props);
//       this.state = {
//         toggleMenu: false
//       }
//       this.handleClick = this.handleClick.bind(this);
//     }
    
//     handleClick() {
//       console.log('THE MENU BUTTON HAS BEEN CLICKED')
//       console.log(this.state.toggleMenu);
//       this.setState({toggleMenu: !this.state.toggleMenu});
//     }

//     componentDidUpdate(){

//         const {toggleMenu} = this.state
        
    
//         if(toggleMenu === true){
    
//           ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'hidden' : ''
//         //   document.body.style.pointerEvents ='none'
//         //   document.body.style.opacity = '0.4'
    
//         }else{
    
//           ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'visible' : ''
//         //   document.body.style.pointerEvents ='all'
//         //   document.body.style.opacity = '1'
//         }
       
//       }

//     componentWillMount(){

//       ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'visible' : ""
//     } 

    
    
    

   
    
//     render(){
//       let slideClass;
//       this.state.toggleMenu
//         ? slideClass = 'showMenu'
//         : slideClass = 'hideMenu';
      
//       return(
//         <div className="header__menu--bars">
//             <button style={{zIndex:9001,position:'relative',
//                             border: 'none',
//                             backgroundColor: 'transparent',
//                             cursor: 'pointer'
//                         }} 
//                     onClick={this.handleClick}>
                            
//               {
//                   this.state.toggleMenu 
//                   ? <span style={{
                           
//                            color: 'white',
//                            fontSize: `15px`
//                         }} >x</span>
                   
//                   : <p style={{marginTop:0,paddingTop: 0}}><span className="header__menu--bars-menubar-1"></span>
//                   <span className="header__menu--bars-menubar-2"></span></p>
                  
//               }
            
              
//             </button>
//             <SlidingMenu slideClass={slideClass} onClick={this.handleClick}>
//               <Link to="/">Home</Link>
//               <Link to="/about">About</Link>
//               <Link to="/contact">Contact</Link> 
//               <Link to="/services">Services</Link>
//               <Link to="/faqs">Faqs</Link>
//               <Link to="/privacy">Privacy</Link>
//             </SlidingMenu>
//         </div>
//       );
//     }
//   }
  
// export default Primary