
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-textarea-autosize';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeContext } from '../../context';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

        const {skill={}} = props 
        const {name,score,years} = skill
        this.state = {
            form : {
                    
                    
                
                name:{
                    
                    control: 'input',
                    value: name || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "name",
                        type: 'text',
                        // placeholder: 'surprise@turtrozz.co.za'
                        // disabled: false
                        
                    }
                    
                },
                score:{
                    
                    control: 'input',
                    value:score || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "score",
                        type: 'text',
                        //placeholder: 'surprise@turtrozz.co.za',
                        // disabled: false
                        
                    }
                    
                },
                years:{
                    
                    control: 'input',
                    value: years || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "years",
                        type: 'text',
                        //placeholder: 'surprise@turtrozz.co.za',
                        // disabled: false
                        
                    }
                    
                },
                
               
              
                
               

              
            
            },
           
            
            
        };
    }

    showSuggestScreen(key,e){

        e.preventDefault() 

        console.log('THE KEY')
        console.log(key)

        
    
        // console.log('THE FORM SUGGESTIONS')
        // console.log(key)
        // console.log(e)
        const {state} = this 
        let newForm ={...state.form} 

        // if(newForm[key].value.length >= 2) return
        newForm[key].isSuggest = true
        this.setState({...this.state,form: newForm,showSuggest: true,showSuggestId: key})

    }

    hideSuggestScreen(key,value=''){



       
        const {state} = this 
        let newForm ={...state.form} 
        newForm[key].isSuggest = false 

        if(value.trim() !== ''){
            newForm[key].value = value 
            newForm[key].isValid = true
        }
      

        

        this.setState({
            ...state,
            form: newForm,
            showSuggest: false,
            showSuggestId: '',
          
            
            }) 
        


    }


    setDate = (id,date)=>{

        // console.log('UDPATING DATE')
        // console.log(date)
        // console.log(type)
        // console.log(`start${type}Date`)

        const  {form} = this.state 
        let newForm = {...form} 
        newForm[id] = {...newForm[id],value: date,isTouched: true,isValid: true}
        
        this.setState({...this.state,form: newForm})
    }


    
    updator = (upControl)=>{
        
            // console.log('THE UPDATE CONTROL')
            // console.log(upControl)
            let newForm ={...this.state.form} 
            let updateControl = {...newForm[upControl.id]}
            updateControl.value = upControl.e.target.value 
            //console.log(updateControl)

        
            
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1]

                    if(!updateControl.isValid){

                        for(let k in newForm){ 


                            if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                                newForm[k].config.disabled = true
                            }
                        }
                    }else{

                        for(let k in newForm){ 

                            if(newForm[k].config.disabled === true){
                                newForm[k].config.disabled = false
                            }
                        }

                    }
                    
                }

            }
        
            updateControl.isTouched = upControl.blur
            newForm[upControl.id] = updateControl 



            if(upControl.blur && updateControl.isValid){

                this.setState({
                    form: newForm,
                    showTextBoxInput: false,
                    textBoxID: ''
                    
                })
    

            }else{

                this.setState({
                    form: newForm
                    
                })
    

            }
            
           

    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.maxLetters && (value.length > isRequiredFormat.maxLetters) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }
   

    submitForm = (e)=>{
        
        e.preventDefault() 
        
       
        
        
        const {props,state} = this
        const {actions,userProfile,isAdding=false,skill={}} = props 
        const {profile} = userProfile 
        const {personal} = profile
        const {id} = personal
        const skillId = skill.id 
        const {updateResumeItem,addResumeItem} = actions 

      
        
        let {form} = this.state 
        let allRequiredAddKeys = Object.keys(form).length
        let {notify} = this
        let validatedAndFetched = this.validateAndFetchInput([form])
        const {isValid,submittData} = validatedAndFetched
      
        if(!isValid) return notify({message:'skill information must be valid before saving',type: 'warning',className: 'notify-warn'})
        if(Object.keys(submittData).length === 0) return notify({message:'skill information must be updated before saving',type: 'warning',className: 'notify-warn'})

        if(isAdding){

          
            if(Object.keys(submittData).length < allRequiredAddKeys){
                console.log(submittData)
                console.log(Object.keys(submittData).length)
                console.log(allRequiredAddKeys)
                return notify({message:'Form must be fully filled before saving',type: 'warning',className: 'notify-warn'})

            }else{

                console.log('THE DATA ADDING')
                console.log(submittData)
                addResumeItem({skill: [{...submittData,u_id: id}]},id)
            }
            
            //addResumeItem({experience: submittData})

        }else{

            console.log('THE USER IS EDITING')
            console.log(submittData)
           
            updateResumeItem({skill: {...submittData,id:skillId}},id)
        }
        // if(!formIsValid) return
        // if(!isCaptchaVerified) return  notify({message:'Recaptcha must be selected before signin',type: 'warning',className: 'notify-warn'})
        
        
        
    
    }

   

    validateAndFetchInput = (forms)=>{


        let submittData = {} 
        let formIsValid = true 
        let shouldOuterBreak = false

        for(let it=0; it < forms.length; it++){
            
            let form = forms[it]

            for(let k in form){
            
                // console.log('THE SUBMIT CONTROL')
                // console.log(form[k])
                if(form[k].validation && form[k].validation.isRequiredFormat){
    
                    console.log('THE CONTROL REQUIRES VALIDATION')
    
                    if(form[k].isTouched && form[k].isValid === false){
    
                        // console.log('CONTROL:',k, ' is not valid')
                        formIsValid = false 
                        shouldOuterBreak = true
                        
              
        
                        //break
    
    
                    }else if(form[k].isTouched){
     
                        submittData[k] = form[k].value
    
                    }
    
                }else{
    
                    if(form[k].value.trim() !== ''){
    
                        submittData[k] = form[k].value
    
                    }
                   
    
                }
              
            
            } 

        }

        return {isValid: formIsValid,submittData: submittData}

       

            
    }
    
    

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        // const {props} = this 
        // const {actions,auth} = props 
        // const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        // const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        // if(errMessage !== ''){

        //     removeShownMessage()

        // }
        
        
    }



    
   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        
           const {props,state} = this 
           const {allowEdit,setIsEditing,skill={},isSkillAdding,isSkillAdd=false,width} = props
           const editIconStyles = {opacity: '.4',pointerEvents: 'none'} 
        // const {actions,isEdit,allowEdit, institution,index} = props 
        // //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // // const {push} = actions
        // // const {message = ''} = auth 
        // // console.log('tHE MESSAGE')
        // // console.log(message)
        // // console.log(message.trim() !== '')
        // console.log('THE INDEX')
        // console.log(index)
        // console.log(parseInt(index))
        // let card = index === 0 ? '' : `resume__education--card-${parseInt(index)+1}` 
        // console.log(card)



        return(

                   

                       
                        

            <li className="resume__curated--card-pointee-list-i" style={boxesStyling}>

                <form>

                    <span>
                    <div className="resume__about--bio-contact-form--control  ">

                            {width <= 1200 
                                ?  <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Name</label>
                                : null
                            }

                            <FormControl 

                                styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                id = 'name'
                                controlData={this.state.form.name}
                                change={(control)=>this.updator(control)}
                                placeHolder={skill.name || ''}
                                style={{...boxesStylingAccent,...textColorStylesAccent}}

                                

                            />
                       
                        </div>
                    </span>
                    <span> <div className="resume__about--bio-contact-form--control  ">

                            {width <= 1200 
                                ?  <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Score</label>
                                : null
                            }
                        
                            <FormControl 

                                styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                id = 'score'
                                controlData={this.state.form.score}
                                change={(control)=>this.updator(control)}
                                placeHolder={skill.score || ''}
                                style={{...boxesStylingAccent,...textColorStylesAccent}}

                                

                            />
                       
                        </div></span>
                    <span>
                        
                        <div className="resume__about--bio-contact-form--control  ">

                        {width <= 1200 
                                ?  <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Years</label>
                                : null
                            }
                        
                        <FormControl 

                            styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                            id = 'years'
                            controlData={this.state.form.years}
                            change={(control)=>this.updator(control)}
                            placeHolder={skill.years || ''}
                            style={{...boxesStylingAccent,...textColorStylesAccent}}

                            

                        />
                   
                      </div>
                    </span>
                    <span>
                        <small style={editIconStyles} ><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
            
                        <small style={editIconStyles}><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                    </span>
                

                </form>

                <div className="resume__about--bio-edit-sub-btn" style={{left: 'revert'}}>

                    <button className="resume--edit-btn " onClick={(e)=>this.submitForm(e)}>
                            <span className="resume--edit-btn-rect" style={{width: '100%'}}></span>
                            <span className="resume--edit-btn-rect-2" style={{width: '100%'}}>
                                <small>Save</small></span>
                    </button>
                    <button className="resume--edit-btn " onClick={()=> isSkillAdd ? isSkillAdding() : setIsEditing(0)}>
                            <span className="resume--edit-btn-rect" style={{width: '100%'}}></span>
                            <span className="resume--edit-btn-rect-2" style={{width: '100%'}}>
                                <small>Cancel</small></span>
                    </button>
                </div>
                
            </li>
           

       
                            

                  

                    
               

             
             
           
        )
    }




}

export default Bio