import React, { Component } from 'react';
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import Contacts from '@material-ui/icons/People';
import { withStyles } from '@material-ui/core/styles'
import SlidingMenu from './slidingmenu'
import Questionnaire from './questionnaire'
import { ThemeContext } from '../../../context';
// import Navigation from "../../../Common/navigation/navigation"
// import links from './links'

const styles = {
    root: {
     
      color:"green",
      fontSize: 20,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Men extends Component{

    constructor(props){


        super(props)

        this.state = {
          location: [
            {
                id: 0,
                title: 'My account',
                selected: false,
                key: 'location',
                to: '/access'
            },
            {
              id: 1,
              title: 'Jobs',
              selected: false,
              key: 'location',
              to: '/jobs'
            },
            {
              id: 2,
              title: 'Post jobs',
              selected: false,
              key: 'location',
              to: '/postjob'

            },
            {
              id: 3,
              title: 'Job alert',
              selected: false,
              key: 'location',
              to: '/alert'
            },
            {
              id: 4,
              title: 'About',
              selected: false,
              key: 'location',
              to: '/about'
            },
            {
              id: 5,
              title: 'Blog',
              selected: false,
              key: 'location',
              to: '/blog'
            }
          ],
          toggleMenu: false

        }

        this.handleClick = this.handleClick.bind(this)

      }
      static contextType = ThemeContext


    componentDidUpdate(){

          const {toggleMenu} = this.state
          
      
          if(toggleMenu === true){
      
            // ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'hidden' : ''
            // document.body.style.overflow = 'hidden' 
            //document.body.style.pointerEvents ='none'
          //   document.body.style.opacity = '0.4'
      
          }else{
      
            // ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'visible' : ''
            //document.body.style.pointerEvents ='all'
          //   document.body.style.opacity = '1'
              // document.body.style.overflow = 'visible' 
          }
        
        }

      componentWillMount(){

        // ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'visible' : "" 
          // document.body.style.overflow = 'visible'
      } 

      handleClick() {

          console.log('THE MENU BUTTON HAS BEEN CLICKED')
          console.log(this.state.toggleMenu);

          this.getAndStyleElement(this.state.toggleMenu)
          this.setState({toggleMenu: !this.state.toggleMenu});

        }

        getAndStyleElement(tog){

          const wrappedElement = document.getElementById('search');

          console.log('THE SEARCH ELEMENT')
          console.log(wrappedElement)
          if(tog){

            if(!wrappedElement) return 
            wrappedElement.style.display = 'block'
          }else{
            if(!wrappedElement) return 
            wrappedElement.style.display = 'none'
          }
         
        }


        openPage = (url)=>{

          const {props} = this 
         const {actions} = props  
         const {push} = actions

         console.log('openpage gets a call')
         console.log(url)

         push(url)


        }




      // toggleSelected(id, key){

      //   let temp = this.state[key]
      //   temp[id].selected = !temp[id].selected
      //   this.setState({
      //     [key]: temp
      //   })

      // }

  
      static contextType = ThemeContext
    
      render(){
  
          const {theme, themeName} = this.context 
          const boxesStyling = { backgroundColor: themeName == 'dark' 
          ? theme.colors.complimentary['accent-3'] 
          :  theme.colors.complimentary.base }
          const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
          const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
          const textColorStyles = {color:  theme.colors.complimentary.foreground}
          const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
          
          const specialStylingText =  themeName === "dark" ? {color: theme.colors.complimentary.foreground} : {}
  

      let slideClass;
      this.state.toggleMenu
        ? slideClass = 'showMenu'
        : slideClass = 'hideMenu'; 
      const {props} = this 
      const {actions,path,width} = props  
     
      const iconStyle =  {
        color: theme.colors.complimentary.foreground
      }
 


      // const {actions,width,upload} = props  
      // const Upload = upload


      // let menuStyles = width > 900 
      
      //   ? {
                                        
         
      //     fontSize: `35px`,
      //     display: `block`,
      //     left:  `25px`,
      //     position: 'fixed',
      //     color: 'red'
          

      //   }
      //   : {
                                        
          
      //     fontSize: '30px',
      //     display: `block`,
      //     left: '10px',
      //     position: 'relative',
      //     color: 'red',
      //     top: '-30px'
       
          

      //   }

       

    return(

        <div >


        

                
                    <section className={`bg-primary ${this.props.path === '/' && this.props.width > 1200 ? 'menu-default': 'menu-custom'}`}>
                       

                        {/* <Dropdown

                            
                            activator={true}
                            list={this.state.location}
                            toggleItem={this.toggleSelected.bind(this)}

                        /> */}

                      <button style={{zIndex:110003,position:'relative',
                                          border: 'none',
                                          backgroundColor: 'transparent',
                                          cursor: 'pointer',
                                         
                                      }} 
                                  onClick={this.handleClick}>
                                          
                            {
                                this.state.toggleMenu 
                                ? 
                                    <p className=" cursor-pointer left-offset-fl-xx-tn link pos-rel d-block">
                
                                      <span className="header__menuCKS header__menuCKS--1" ></span>

                                      <span className="header__menuCKS header__menuCKS--2" >
                                        <small>x</small></span>



                                            
                                
                                    </p>
                                
                                : <p className=" cursor-pointer left-offset-fl-xx-tn link pos-rel d-block">
                
                                  <span className="bar bar-1"></span>
                                  <span className="bar bar-2"></span>
                                  <span className="bar bar-3"></span>
                                        
                            
                                </p>
                                
                            }
                                  
                                    
                      </button>

                      <SlidingMenu slideClass={slideClass} onClick={this.handleClick}  >

                        {this.props.width > 900 ? <Questionnaire actions={actions} width={width} path={path} toggleMenu={this.handleClick.bind(this)} /> : null}
                        <section className="sliding-menu-nav-cont" style={boxesStyling}>
                            <ul>
                              <li><Link to="/" style={textColorStyles}>Home</Link></li>
                              <li><Link to="/about" style={textColorStyles}>About</Link></li>
                              <li><Link to="/contact" style={textColorStyles}>Contact</Link> </li>
                              <li><Link to="/jobs?jq=all&jl=South Africa" style={textColorStyles}>Jobs</Link></li>
                              <li><Link to="/blog" style={textColorStyles}>Blog</Link></li>
                              <li><Link to="/faqs" style={textColorStyles}>Faqs</Link></li>
                              <li><Link to="/privacy" style={textColorStyles}>Privacy</Link></li>
                            </ul>
                        </section>

                        {this.props.width <= 900 ? <Questionnaire actions={actions} width={width} path={path} toggleMenu={this.handleClick.bind(this)} /> : null}

                        <div className="engagement">

                          {/* <Upload button={Button}/> */}
                           

                          <p className="engagement__applicant"  onClick={()=>this.openPage('/humanoid')}>
                            <span className="engagement__applicant-plus" style={boxesStylingAccent}>+</span>
                            <span className="engagement__applicant-text" style={{...boxesStylingAccent,...specialStylingText}}>Create Cv</span>
                          </p>
                                              
                          <p className="engagement__employer" onClick={()=>this.openPage('/post-job')}>
                            <span className="engagement__employer-plus" style={{...boxesStylingAccent}}>+</span>
                            <span className="engagement__employer-text" style={{...boxesStylingAccent,...specialStylingText}}>Post jobs</span>
                          </p>
                        </div>

                      </SlidingMenu>
                      

                    </section>


                

     

            {this.props.path === '/' && this.props.width > 1200? 
                <section className="job-type bx-raised-1" 
                style={{
                  backgroundColor: themeName == 'dark' 
                  ? theme.colors.complimentary['accent-3'] 
                  :  theme.colors.complimentary.base 
              }}
                >

                    <Link to="/jobs?jq=full-time"  className="menu-link link" style={iconStyle}>
                        <span className="d-block text-align-center" ><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Full-time</span>
                    </Link>
                    <Link  to="/jobs?jq=part-time" className="menu-link link" style={iconStyle}>
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Part-time</span>
                    </Link>

                    <Link  to="/jobs?jq=freelance" className="menu-link link" style={iconStyle}>
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Freelance</span>
                    </Link>

                    <Link  to="/jobs?jq=internship" className="menu-link link" style={iconStyle}>
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Internship</span>
                    </Link>
                    <Link  to="/jobs?jq=remote" className="menu-link link" style={iconStyle}>
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Remote</span>
                    </Link>
                    <Link  to="/jobs?jq=contract" className="menu-link link" style={iconStyle}>
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Contract</span>
                    </Link>

                </section>
                : null

                }

                {/* {

                  this.props.path === '/jobs'
                    ? <BannerAd />
                    : null
                } */}

        </div>
    )
  }
}

export default withStyles(styles)(Men) 





// import React from 'react' 
// import {Link} from 'react-router-dom';
// import SlidingMenu from './slidingmenu' 
// const ExecutionEnvironment = require('exenv');



// class Primary extends React.Component{
//     constructor(props) {
//       super(props);
//       this.state = {
//         toggleMenu: false
//       }
//       this.handleClick = this.handleClick.bind(this);
//     }
    
//     handleClick() {
//       console.log('THE MENU BUTTON HAS BEEN CLICKED')
//       console.log(this.state.toggleMenu);
//       this.setState({toggleMenu: !this.state.toggleMenu});
//     }

//     componentDidUpdate(){

//         const {toggleMenu} = this.state
        
    
//         if(toggleMenu === true){
    
//           ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'hidden' : ''
//         //   document.body.style.pointerEvents ='none'
//         //   document.body.style.opacity = '0.4'
    
//         }else{
    
//           ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'visible' : ''
//         //   document.body.style.pointerEvents ='all'
//         //   document.body.style.opacity = '1'
//         }
       
//       }

//     componentWillMount(){

//       ExecutionEnvironment.canUseDOM ? document.body.style.overflow = 'visible' : ""
//     } 

    
    
    

   
    
//     render(){
//       let slideClass;
//       this.state.toggleMenu
//         ? slideClass = 'showMenu'
//         : slideClass = 'hideMenu';
      
//       return(
//         <div className="header__menu--bars">
//             <button style={{zIndex:9001,position:'relative',
//                             border: 'none',
//                             backgroundColor: 'transparent',
//                             cursor: 'pointer'
//                         }} 
//                     onClick={this.handleClick}>
                            
//               {
//                   this.state.toggleMenu 
//                   ? <span style={{
                           
//                            color: 'white',
//                            fontSize: `15px`
//                         }} >x</span>
                   
//                   : <p style={{marginTop:0,paddingTop: 0}}><span className="header__menu--bars-menubar-1"></span>
//                   <span className="header__menu--bars-menubar-2"></span></p>
                  
//               }
            
              
//             </button>
//             <SlidingMenu slideClass={slideClass} onClick={this.handleClick}>
//               <Link to="/">Home</Link>
//               <Link to="/about">About</Link>
//               <Link to="/contact">Contact</Link> 
//               <Link to="/services">Services</Link>
//               <Link to="/faqs">Faqs</Link>
//               <Link to="/privacy">Privacy</Link>
//             </SlidingMenu>
//         </div>
//       );
//     }
//   }
  
// export default Primary