
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import { withRouter } from 'react-router-dom';

import Upload from '../Upload/component'
import ChatHeader from '../ChatHeader/component'

import Men from './components/Men/men'
import Brand from './components/Brand/brand'
import Primary from './components/Primary/primary' 
import {ThemeContext} from '../context/index'








class Header extends Component{


    constructor(props){

        super()
        this.state = {

            scrolled: false,
            width: 0,
            height: 0,
            //headerClasses : ['header__offset']
            headerClasses : ['header__offset','header__sticky']
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    static contextType = ThemeContext



       
    handleScroll = () => {

          const offset=window.scrollY;

          if(offset > 200 ){

            this.setState({...this.state,headerClasses: ['header__offset','header__sticky'],scrolled: true},()=>{

                window.removeEventListener('scroll',this.handleScroll) 
            });
          }
          else{

            this.setState({...this.state,headerClasses: ['header__offset'],scrolled: false});
          }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        //window.addEventListener('scroll',this.handleScroll)
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
        //window.removeEventListener('scroll',this.handleScroll) 
     }

    //  compo(){

    //     const {headerClasses,scrolled} = this.state  
    //     scrolled ? headerClasses.push('header__sticky') : ''

    //  }
        
    

  
    //   let x=['navbar'];
    //   if(scrolled){
    //     x.push('scrolled');
    //   }
       

    // componentWillMount(){

    //     console.log('THE HEADER COMPONENT IS GOING TO MOUNT')
    //     console.log(this.props.state)
    // }


    render(){

        const {headerClasses,width} = this.state  
        const {props} = this 
        const {actions} = props 
        const pathName = this.props.location.pathname 
        const theme = this.context.theme
        
        
        return(

            // <header className={` ${this.props.location.pathname === '/jobs'  ?  headerClasses.join(" ") : 'pd-bottom-fd-xxx-sm'}`} > 
            <header className={` ${pathName === '/jobs' || pathName === '/humanoid'  || width <= 1200   ?  headerClasses.join(" ") : 'pd-bottom-fd-xxx-sm'}`} style={{backgroundColor:theme.colors.complimentary.base}} >

                
                
                        
                   {pathName === '/humanoid' 
                   
                   ? <ChatHeader />
                   : 

                        <section>
                        <div>
                            
                            <section className="hr-size-fl-xx-sm float-left bg-black">
                            <Men  path = {this.props.location.pathname} actions={actions} width={width} upload={Upload}  />
                            </section>
                            
                            <section className="hr-size-fl-xx-tn float-left">
                                <Brand path={this.props.location.pathname} width={width} />
                            </section>

                            <section className="hr-size-fl-md float-left">
                                <Primary actions={this.props.actions}  path={this.props.location.pathname} width={width} upload={Upload} />
                                
                                
                            
                            </section>
                            <strong className="clearfix"></strong>

                        </div>
                        
                        
                        </section>
                    }
                  
                    
                    
                   
                   
               

            </header>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        state:{
            ...state.router
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions},dispatch)
    }
}

export default withRouter(connect(mapStateToProps,mapDispachToProps)(Header))