
import React,{Component} from 'react'
import {BrowserRouter as Router, Link} from 'react-router-dom'

class Tagline extends Component{
    
    
    render(){

        return(

           

                
           
                      
                    <section className="verify__tagline">

                        <span className="verify__tagline-text">Aaah...Surprise, it's so sad to watch you go, we'd like you to stay.</span>

                    </section>
                   
     
               
                

           
        )
    }

}


export default Tagline