
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
// import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import { Helmet } from "react-helmet";



import Bio from './components/bio/bio' 
import Players from './components/players/players'
import Services from './components/services/services'
import {trackPageView} from "../../utilities/googleGa"

 



class About extends Component{


    constructor(props){

        super(props)
    }

    componentDidMount(){
        trackPageView()
    }

   
    render(){

        const {props} = this 
        const {actions} = props

        return(

            
            <article className="about">
                <Helmet>
                    <title>Jobbri - About</title>
                
                </Helmet>
                
                <Bio />
                <Players />
                <Services actions={actions} />
               
             </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        state:{
            ...state.router
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(About)