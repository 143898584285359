
import React from 'react' 



function Shapes() {
    

    return(

           

                

             

                    <section className="home__deziner--shapes">


                     

                      {/* <span className="home__deziner--shape home__deziner--shape-circle home__deziner--shape-circle1"></span> */}
                      {/* <span className="home__deziner--shape home__deziner--shape-circle home__deziner--shape-circle2"></span> */}
                      {/* <span className="home__deziner--shape home__deziner--shape-circle home__deziner--shape-circle3"></span> */}
                      {/* <span className="home__deziner--shape home__deziner--shape-circle home__deziner--shape-circle4"></span> */}
                      <span className="home__deziner--shape home__deziner--shape-circle home__deziner--shape-circle5"></span>
                      {/* <span className="home__deziner--shape home__deziner--shape-egg home__deziner--shape-egg1"></span> */}
                      <span className="home__deziner--shape home__deziner--shape-egg home__deziner--shape-egg2"></span>
                      {/* <span className="home__deziner--shape home__deziner--shape-egg home__deziner--shape-egg3"></span> */}
                    </section> 

                   

           
               
               
           
    )

}


export default Shapes