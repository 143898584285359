
export const SEND_USER_PASSWORD_REQUEST = "SEND_USER_PASSWORD_REQUEST"
export const SEND_USER_PASSWORD_SUCCESS = "SEND_USER_PASSWORD_SUCCESS"
export const SEND_USER_PASSWORD_FAILURE = "SEND_USER_PASSWORD_FAILURE"

export const UNSET_MESSAGE_NOTIFICATION_DISPLAY = "UNSET_MESSAGE_NOTIFICATION_DISPLAY"
export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"




