export default[


        {
            styles: {child:{className: "bg-lt-grey-lta",add: true}},
            path: "/jobs",
            name: "Jobs",
        // icon: "pe-7s-graph",
        },
        {
        path: "/about",
        name: "About",
        // icon: "pe-7s-graph",

        },

        {
            styles: {child:{className: "bg-lt-grey-lta",add: true}},
            path: "/contact",
            name: "Contact",
        // icon: "pe-7s-graph",
        },
        {
        path: "/blog",
        name: "Blog",
        // icon: "pe-7s-graph",

        },
        {
            styles: {child:{className: "bg-lt-grey-lta",add: true}},
            path: "/post-job",
            name: "+Post a job",
        // icon: "pe-7s-graph",
        }
  ]
