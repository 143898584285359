
import React, {Component} from 'react' 
import { ThemeContext } from '../../../context'
import Card from './card'





class SearchHistory extends  Component{


    constructor(props){

        super(props)
    }

    listJobs(list){

        // console.log('THE LIST FROM SAVED BODY')
        // console.log(list)
        return list.map((job,i)=>{

            // console.log('THE JOB TO THE CARD')
            // console.log(job)
            return <Card key={i}  job={job}/>
        })
        
       

    }

   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        const {actions,userProfile} = this.props 

        return(


               <div>

               
                    <section className="dashboard__searchhistory--i">

                        <div className="dashboard__searchhistory--i-box bx-raised-1 ">

                                <span className="dashboard__searchhistory--i-box-title" style={textColorStyles}>Search history</span>
                     
             
                        </div>


                    </section>

                    {

                        this.props.searchHistory.map((search,i)=>{

                                return <Card key={i} userProfile={userProfile} actions={actions}  job={search}/>
                                //return this.listJobs(list)
                            

                        })

                    }

                




           
                </div>

             
             
           
        )
    }




}

export default SearchHistory