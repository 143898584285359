import React, { Component } from 'react'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import FolderIcon from '@material-ui/icons/Folder';
import { toast } from 'react-toastify';



const styles = {
    root: {
     
     
      fontSize: 20,
      color: 'seagreen'
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Upload extends Component {

    constructor(props) {

      super()
      this.state ={
        selectedFile: null,
        selectedFileUrl: null
       }

    }
    onChangeHandler= event =>{

        var files = event.target.files 
        const {props} = this 
        const {updateCaptured} = props

        console.log('THE UPLOADED FILES ONCHANGE HANDLER')
        console.log(files) 

        if(this.maxSelectFile(event) && this.checkMimeType(event) && this.checkFileSize(event)){ 
        // if return true allow to setState
           this.setState({
                selectedFile: files[0],
                selectedFileUrl: URL.createObjectURL(files[0])
            },()=>{

                updateCaptured(this.state.selectedFileUrl)
            })
        }

    }

    onClickHandler = (e) => {

        console.log('THE CLICK OCCURED')
        console.log(e)
        e.preventDefault()
        if(!this.state.selectedFile) return this.notify({message: 'No valid file has been selcted for submission',type: 'error',className: 'notify-error'})
        const data = new FormData() 

        console.log('THE FORM DATA')
        console.log(data)
        console.log(this.state.selectedFile[0])
        // for(var x = 0; x < this.state.selectedFile.length; x++){

        //     data.append('file', this.state.selectedFile[x])
        // }

        const {profile_url,sendUserAvatarUpdate,version,id} = this.props 
        console.log(this.props)
        console.log(data.append)
        console.log(data.append('test','THEFORMDATATEST'))


        data.append('file', this.state.selectedFile)
        data.append('action','changeAvatar')
        data.append('ID',id)
        data.append('old',profile_url) 
        data.append('version',version)

        let accessedData = {}

        for(let [key,value] of data.entries()){

            accessedData[key] = value
            console.log(key,value)
        }
        console.log('THE FORM DATA BEFORE SUBMITTION AVATAR')
        console.log(data) 
        console.log(accessedData)
        sendUserAvatarUpdate(data,'avatarEdit')


        // fetch("http://localhost:8000/upload", data, { 
        //     // receive two    parameter endpoint url ,form data
        // }).then(res => { // then print response status
        //     console.log(res.statusText)
        //  })
        

    }

    maxSelectFile=(event)=>{

        let files = event.target.files // create file object
            if (files.length > 1) { 
               const msg = 'Only 1 file can be uploaded at a time'
               event.target.value = null // discard selected file 
               this.notify({message: msg,type: 'error',className: 'notify-error'}) 
               console.log(msg)
              return false;
     
          }
        return true;
     
    }

    checkMimeType=(event)=>{
        //getting file object
        let files = event.target.files 
        //define message container
        let err = ''
        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'image/gif']
        // loop access array
        for(var x = 0; x<files.length; x++) {
         // compare file type find doesn't matach
             if (types.every(type => files[x].type !== type)) {
             // create error message and assign to container   
             err += files[x].type+' is not a supported format\n';
           }
         };
      
       if (err !== '') { // if message not same old that mean has error 
            event.target.value = null // discard selected file
            this.notify({message: err,type: 'error',className: 'notify-error'}) 
            console.log(err)
             return false; 
        }
       return true;
      
    }

    checkFileSize=(event)=>{

        let files = event.target.files
        let size = 2048000
        let err = ""; 
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
            
            err += `${files[x].type} ${files[x].size} ${size} is too large, please pick a smaller file\n`;
        }
     };
     if (err !== '') {
        event.target.value = null 
        this.notify({message: err,type: 'error',className: 'notify-error'}) 

        console.log(err)
        return false
   }
   
   return true;
   
   }

   notify = (config) => {

       
    const {message} = config || ''
    const type = config.type || 'info'
    toast[type](message,
        {position: toast.POSITION.BOTTOM_LEFT,...config});

    }

    updateUploadState = (updateFile)=>{

      
               this.setState({
                    selectedFile: updateFile,
                    selectedFileUrl: URL.createObjectURL(updateFile)
                })
            
    

    }

    // componentDidUpdate(){

    //     console.log()
    //     const {isActionSuccessful,launcher,actions} = this.props 
    //     const  {removeNotification} = actions

    //     if(isActionSuccessful && launcher === 'avatarEdit' ) {
    //         this.notify({message:'Profile picture successfully updated',type: 'success',className: 'notify-success'}) 
    //         removeNotification()
    //     }
        
    // }

  
    render() {

        const {state,props} = this 
        const {selectedFile} = state 
        const {allowEdit} = props
        return (
            

              

                  
                    

                        
                            <form>

                                    
                                    
                            
                            <label htmlFor="avatar-upload" style={{display: 'block'}} type="file">

                                <span type="button" className="resume__about--profile-avatar-edit-browse-behind" ></span>
                                <span type="button" className="resume__about--profile-avatar-edit-browse" >Change</span> 
                                <input style={{display: 'none'}}  id="avatar-upload" type="file" name="file" onChange={this.onChangeHandler}/>
                            </label>

                            <div className="resume__about--bio-edit-sub-btn" style={{left: 0}}>

                                <button className="resume--edit-btn " onClick={this.onClickHandler.bind(this)}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small style={{top:'-10px'}}>Save</small></span>
                                </button>
                                <button className="resume--edit-btn " onClick={allowEdit}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small style={{top: '-10px'}}>Cancel</small></span>
                                </button>
                            </div>
                           

                            </form>

                            
                        

                    

                       

                   
                
               
            
          )
    }
    

}

export default withStyles(styles)(Upload)