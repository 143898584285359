
import React, {Component} from 'react' 
import ClientErrorHandler from '../ClientErrorHandler/component'





class LoggedOutErrorBoundary extends Component{


    constructor(props) {
        super(props);
        this.state = { hasError: false };
      }
    
      static getDerivedStateFromError(error) {    
          
        // Update state so the next render will show the fallback UI.    
        return { hasError: true };  
      }
      componentDidCatch(error, errorInfo) {    

        console.log('ERROR BOUNDARY ERROR')
        console.log(error)
        console.log(errorInfo)

          // You can also log the error to an error reporting service
            //logErrorToMyService(error, errorInfo);  
     }

    render(){


          const errorMeta = {
              header: 'Oh! Nah!',
              headerText: 'Something has went wrong',
              buttonText: 'Go To Home'
          }

           
            if(this.state.hasError) {      
                // You can render any custom fallback UI      
                return <ClientErrorHandler errorMeta={errorMeta} action="error" />
            }
            return this.props.children; 
        
    }




}



export default LoggedOutErrorBoundary