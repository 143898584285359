
import * as types from './types'
import { CALL_API } from '../../middlwares/api'




  export const sendUserVerificationLink = (data)=> {

    return {

        [CALL_API]: {
          endpoint: `/subscriber`,
          method: 'POST',
          request: 'URL',
          payload: {action: 'sendVerificationLink',payload: {link: {...data}}},
          authencitcated: false,
          types: [types.SEND_USER_VERIFICATION_LINK_REQUEST, types.SEND_USER_VERIFICATION_LINK_SUCCESS, types.SEND_USER_VERIFICATION_LINK_FAILURE]
        }
      }

  }

  export const removeNotification = ()=>{

    return {
  
      type: types.UNSET_NOTIFICATION_DISPLAY,
      payload: false
    }
  }

  export const removeMessage = ()=>{

    return {
  
      type: types.UNSET_MESSAGE_NOTIFICATION_DISPLAY,
      payload: false
    }
  }
  


