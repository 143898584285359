
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import { ThemeContext } from '../../context';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getExperiences = (experienceData)=>{
      const {e,i,styles={} } = experienceData
     
        let card = i === 0 ? '' : `resume__experience--card-${i+1}` 
        let toPresent = !e.to_date ? `${new Date(e.from_date).getFullYear()}-Present` : `${new Date(e.from_date).getFullYear()}-${new Date(e.to_date).getFullYear()}`
        let experience = (

            <section className={`resume__experience--card ${card}`} key={i}>

            <div className="resume__experience--card-pointer">
            <small>{e.number}</small>

            </div>

            <div className="resume__experience--card-pointee">

                <h5 className="resume__experience--card-pointee-period">{toPresent}</h5>
                <h6 className="resume__experience--card-pointee-employer" style={styles?.textColorStylesAccent}>{e.company}</h6>
                <span className="resume__experience--card-pointee-role" style={styles?.textColorStylesAccent}>{e.position}</span>
                <p className="resume__experience--card-pointee-description" style={styles?.textColorStylesAccent}>
                    {e.description}
                </p>

            </div>

            </section>
        )
        

        return experience



    
    }
 

               

   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        
         const {props,state} = this 
        //  const {bioText} = state
         const {experience} = props 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    

                        
                       
                        <>{experience.map((e,i)=> this.getExperiences({e,i,styles:{textColorStyles,textColorStylesAccent,boxesStyling}}))}</>

           
             
             
           
        )
    }




}

export default Bio