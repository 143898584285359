import {applyMiddleware,createStore} from 'redux'
// import {routerMiddleware} from 'connected-react-router'
import {reducer as notificationsReducer} from 'reapop';
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";
import history from '../history' 

import {routerMiddleware} from '../middlwares/reduxroute'
import apiMiddleware from '../middlwares/api'
import {operationalErrorMiddleware} from '../middlwares/operationalError';

// import syncStoreHistoryWithBrowser from '../middlwares/history'
import appRall from '../middlwares/rall'
import reducers from '../reducers/reducers'
import startListener from '../utilities/listener'
import {push} from '../middlwares/withcustommiddlewares' 

const persistConfig = { // configuration object for redux-persist
    key: 'root',
    storage, // define which storage to use
    whitelist: ['auth','userProfile'] // only navigation will be persisted
}
const reduxStateSyncConfig = {

    blacklist: ["persist/PERSIST", "persist/REHYDRATE"]
}
const persistedReducer = persistReducer(persistConfig, reducers) // create a persisted reducer


// const catchHistoryActionCreators = routerMiddleware(history)

const store = createStore(
        persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
        composeWithDevTools(
            applyMiddleware(
                thunk,
                routerMiddleware(history),
                apiMiddleware,
                operationalErrorMiddleware,
                createStateSyncMiddleware(reduxStateSyncConfig)
              
               
              
                )
        )
    )

startListener(history,store)
initMessageListener(store)
const  persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export {
    store,
    history,
    persistor
}


