
import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    FacebookShareCount,
   
  } from "react-share";
import { ThemeContext } from '../../../context';

import Related from './blogrelated'




class BlogArticle extends Component{
    
    
    constructor(props) {
        super(props);
        this.state = {
            months: [
                'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
            ]
        }
      
    }

   
    getDate = (date)=>{

        const {state} = this 
        const {months} = state
        let setDate = new Date(date) 
        let getDate = setDate.getDate() 
        let formAtedDate = getDate < 10 ? `0${getDate}` : getDate

        return `${months[setDate.getMonth()]} ${formAtedDate}, ${setDate.getFullYear()}`

    }

    getHeaderGrafik = (image)=>{

        return (
            <section className="post__post--text-header-grafik">

                <figure className="post__post--text-header-grafik-fig">
                        <img src={image} className="post__post--text-header-grafik-pik" />
                </figure>

            </section>
        )
    }
      
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}

        const {props} = this 
        const {post,posts,openPage,openBlogPage,width} = props 
        const {slug,is_primary=0,title,body,created_at,image,author,post_topic_name} = post 
        const shareUrl = `https://test.jobbri.co.za/blog/post/${slug}`
     
        // console.log('ARTICLE PROPS')
        // console.log(props)
        // let link = p === 1 ? "link d-block" : "link d-block blog__primary--post-offset"
        

        return(

           

                
                <div  className="post__post">

                   <section className="post__post--text">

                        <div className="post__post--text-header bx-raised" style={boxesStyling}>
                            {width <= 900 ?  <h1 className="post__post--text-header-meta-title" style={textColorStyles}>{title}</h1> : null}
                            {width <= 900 ? this.getHeaderGrafik(image) : null}
                            <section className="post__post--text-header-meta">

                               
                                {width > 900 ?  <h1 className="post__post--text-header-meta-title" style={textColorStylesAccent}>{title}</h1> : null}
                                <section className="post__post--text-header-meta-author" title="Post author">


                                    <div>
                                        <span className="post__post--text-header-meta-author-by" style={textColorStyles}>By </span>
                                       
                                        <section>
                                        <div class="post__post--text-header-meta-author-card-rect  bx-raised">
                                        </div>
                                        <div class="post__post--text-header-meta-author-card-rect post__post--text-header-meta-author-card-rect-2 bx-raised">
                                        </div>
                                        <div class="post__post--text-header-meta-author-card-rect post__post--text-header-meta-author-card-rect-3 bx-raised" style={boxesStylingAccent}>
                                            <img src="https://res.cloudinary.com/hsjvf6r09/image/upload/v1619088251/jobbri/blog/zhcdavi9bqntbnpmj2ax.png" className="post__post--text-header-meta-author-pik" />
                                        </div>
                                        </section>
                                        <p className="post__post--text-header-meta-author-wrap">
                                            <span className="post__post--text-header-meta-author-name" style={textColorStyles}>
                                                {author}
                                            </span>
                                            <span className="post__post--text-header-meta-author-date">{this.getDate(created_at)}</span>
                                        </p>

                                    </div>


                                     <div className="post__post--text-social">
                                
                                    <section className="post__post--text-social-s-wrap">
                                        <button className="post__post--text-social-share">Share</button>
                                    </section>


                                        <section className="post__post--text-social-p-wrap">

                                            <div className="post__post--text-social-p-wrap-1" title="Facebook post share">

                                                <FacebookShareButton
                                                    url={shareUrl}
                                                    quote={title}
                                                   
                                                >
                                                    <FacebookIcon size={32} round />
                                                </FacebookShareButton>
                                                                                        {/* <div>
                                                    <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                                                    {count => count}
                                                    </FacebookShareCount>
                                                </div> */}
                                            </div>

                                            <div className="post__post--text-social-p-wrap-1" title="Twitter post share">


                                                <TwitterShareButton
                                                    url={shareUrl}
                                                    quote={title}
                                                   
                                                >
                                                    <TwitterIcon size={32} round />
                                                </TwitterShareButton>

                                                                                        {/* <div>
                                                    <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                                                    {count => count}
                                                    </FacebookShareCount>
                                                </div> */}
                                            </div>

                                            <div className="post__post--text-social-p-wrap-1" title="Whatsapp post share">
 
                                                <WhatsappShareButton
                                                    url={shareUrl}
                                                    quote={title}
                                                   
                                                >
                                                    <WhatsappIcon size={32} round />
                                                </WhatsappShareButton>                                    {/* <div>
                                                    <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                                                    {count => count}
                                                    </FacebookShareCount>
                                                </div> */}
                                            </div>


                                            {/* <Link to="https://www.facebook.com/"  className="post__post--text-social-p-wrap-1 link">
                                                <img className="access__header--social-img" src="/img/social/facebook.png" />
                                            </Link >
                                            <Link to="https://www.facebook.com/"  className="post__post--text-social-p-wrap-2 link">
                                                <img className="access__header--social-img" src="/img/social/twitter.png" />
                                            </Link >
                                            <Link to="https://www.facebook.com/" className="post__post--text-social-p-wrap-3 link">
                                            <img className="access__header--social-img" src="/img/social/instagram.png" />
                                            </Link >
                                            <Link to="https://www.facebook.com/" className="post__post--text-social-p-wrap-4 link">
                                            <img className="access__header--social-img" src="/img/social/googlePlus.png" />
                                            </Link > */}

                                        </section>
                                </div>
                                

                                </section>

                                <section className="post__post--text-header-meta-crumb" onClick={(e)=>openBlogPage({topic:post_topic_name,url: '/blog/topic/'},e)}>
                                    <p>
                                        <span className="post__post--text-header-meta-crumb-text">#{post_topic_name}</span>
                                    </p>
                                </section>
                                <strong className="clearfix" />


                            </section>

                            {width > 900 ? this.getHeaderGrafik(image) : null}
                            
                            <strong className="clearfix" />
                        </div>

                        

                            <div className="post__post--text-wrap">

                               
                               
                                <section className="post__post--text-content" style={textColorStyles}>
                                    <p className="post__post--text-content-p" dangerouslySetInnerHTML={{__html:body}}>
                                    </p>
                                      
                                </section>

                                

                            </div>

                            {/* <div className="post__post--text-social">
                                
                                 <section className="post__post--text-social-s-wrap">
                                     <button className="post__post--text-social-share">Share</button>
                                 </section>


                                 <section className="post__post--text-social-p-wrap">

                                    <Link to="https://www.facebook.com/"  className="post__post--text-social-p-wrap-1 link">
                                        <img className="access__header--social-img" src="/img/social/facebook.png" />
                                    </Link >
                                    <Link to="https://www.facebook.com/"  className="post__post--text-social-p-wrap-2 link">
                                        <img className="access__header--social-img" src="/img/social/twitter.png" />
                                    </Link >
                                    <Link to="https://www.facebook.com/" className="post__post--text-social-p-wrap-3 link">
                                    <img className="access__header--social-img" src="/img/social/instagram.png" />
                                    </Link >
                                    <Link to="https://www.facebook.com/" className="post__post--text-social-p-wrap-4 link">
                                    <img className="access__header--social-img" src="/img/social/googlePlus.png" />
                                    </Link >

                                </section>
                                 */}
                         


                        

                      

                 
                       

                   </section> 

                   

                   {/* <section className="post__post--grafik">

                       
                           

                               
                                <figure className="post__post--grafik-fig">
                                    <img src={image} className="post__post--grafik-pik" />
                                </figure>
                                
                                <section className="post__post--grafik-author" title="Post author">


                                    <div>
                                        <span className="post__post--grafik-author-by">By </span>
                                        <img src="https://res.cloudinary.com/hsjvf6r09/image/upload/v1619088251/jobbri/blog/zhcdavi9bqntbnpmj2ax.png" className="post__post--grafik-author-pik" />
                                        <p className="post__post--grafik-author-wrap">
                                            <span className="post__post--grafik-author-name">
                                                {author}
                                            </span>
                                            <span className="post__post--grafik-author-date">Feb 02 2020</span>
                                        </p>

                                    </div>
                                    
                                </section>

                          

                       
                   </section> */}
                   <strong className="clearfix" />
                    {posts.length > 0 ? <Related posts={posts} openPage={openPage} /> : null} 
                </div>
               
                

           
        )
    }

}


export default BlogArticle