
import React, {Component} from 'react' 
import {BrowserRouter as Router,Link} from 'react-router-dom'
import { ThemeContext } from '../../../context'
import DeactivateButton from './deactivatebutton'
import DeactivatePopup from './deletepopup'


// import Jobs from './jobs/jobs'
// import Brand from './brand/brand'
// import Primary from './primary/primary'
// import Secondary from './secondary/secondary'



class Navigation extends  Component{


    constructor(props){

        super(props)
    }

    notifyActive(active,e){

        e.preventDefault()
        e.stopPropagation()

        this.props.handleActive(active)

    }

   
    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        const {userProfile,actions} = this.props
        const Modal = this.props.modal
        const componentProps = {compClass:"link dashboard__candsettings--settingpane-link"}

        return(


               <nav>

                 <Link onClick={this.notifyActive.bind(this,'profile')} to="" className="link dashboard__candsettings--settingpane-link" style={boxesStylingA}><span style={textColorStylesAccent}>Profile</span></Link>
                 <Link onClick={this.notifyActive.bind(this,'alerts')} to="" className="link dashboard__candsettings--settingpane-link" style={boxesStylingA}><span style={textColorStylesAccent}>Job alerts</span></Link>
                 <Modal popUp={DeactivatePopup} componentProps={componentProps} userProfile={userProfile} actions={actions} button={DeactivateButton} />
                
               

                </nav>

             
             
           
        )
    }




}

export default Navigation