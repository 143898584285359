
import React,{Component} from 'react'
import { ThemeContext } from '../../../context'
import Us from './us/us' 



class Bio extends Component{
    
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const textColorStyles = {color: theme.colors.complimentary.foreground}

        return(

           

                
                <div  className="about__us">
                    
                     <Us styles={{boxesStyling, textColorStyles}} />
                </div>
               
                

           
        )
    }

}


export default Bio