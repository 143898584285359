
import React, {Component} from 'react' 
import {Router, Switch,Route,Redirect} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as userActions from '../UserProfile/actions'
import LoggedInHeader from '../LoggedHeader/component'
import UserSideBar from '../UserSideBar/component'
import Footer from '../Footer/component'
import LoggedInFooter from '../LoggedFooter/component'
import LoggedErrorBoundary from '../LoggedErrorBoundary/component'
import ProgressBarCircular from '../ProgressBarCircular/component'
//let loggedIn = true




class LoggedIn extends Component{


     constructor(props){

        super(props)
     }


    //  componentDidMount(){

    //     const {userProfile,actions,auth} =  this.props 
    //     const {profile} = userProfile
    //     const {fetchUserProfile} = actions
    //     const {appAuth} = auth 
    //     const {isAuth} = appAuth

    //     if(isAuth){

            

    //         if(Object.keys(profile).length < 1){

    //             fetchUserProfile({ID:1})
               
    //         }else{

    //             console.log('THE USER IS LOGGED IN')
    //             console.log('PROFILE DATA IS AVAILABLE NO NEED TO FETCH')

    //         }
            

    //     }else{
           
    //         console.log('THE COMPONENT DID MOUNT::::LOGGEDIN COMPONENT')

    //     }
       
    //  }

    //  componentDidUpdate(){

    //     console.log('THE LOGGEDIN COMPONENT HAS BEEN UPDATED')
    //     console.log('THE LOGGEDIN COMPONENT HAS BEEN UPDATED')
        
    //  }

    render(){


        const {userProfile,auth,component: Component,...rest} = this.props 
       
        const {isFetching,isProgressBar} = userProfile 

        //if(isFetching === true && isProgressBar === false) return  <article className="dashboard__candsettings"><ProgressBarCircular /></article>;
        // console.log('THE LOGGED IN COMPONENT:: DOES IT RERENDER ONCE RENDERED') 
        // console.log(this.props)
        const {appAuth} = auth
         const {isAuth} = appAuth
         console.log('THE LOGGEDIN CHECKER')
         console.log(isAuth)
         console.log(this.props) 
        
        // // console.log({...rest})
      
        return(

            // <LoggedErrorBoundary>
            <>
            
             {
             
             isAuth === true ?
                <>
                        <LoggedInHeader />
                        <UserSideBar userType="candiddate" />
                                    
                                
                            <Route {...rest} render={(props)=>{

                                
                                // return loggedIn
                                //         ? <Component {...props} />
                                //         : <Redirect to="/access" /> 
                                return <Component {...props} />
                            }} 
                            
                            />
                    </>
                
                : <Redirect to="/access" />
             }
             </>

            
            // </LoggedErrorBoundary>
        )


    }


}


const mapStateToProps = (state)=>{

    const {auth,userProfile} = state

    return{
        auth,
        userProfile
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...userActions},dispatch)
    }
}


export default connect(mapStateToProps,mapDispachToProps)(LoggedIn)
