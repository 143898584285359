
import * as types from './types'
import * as props from './props'
import { actions } from 'reapop'



export default (state = props,action)=>{


        switch(action.type){

        case types.SEND_USER_ALERT_VERIFY_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isActionSuccessful: false,
                message: ''
                
              
                
            }

        case types.SEND_USER_ALERT_VERIFY_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true,
                text: action.response.text || ''
                
               
                
            }

        case types.SEND_USER_ALERT_VERIFY_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
                message: action.error
                
            }
            default:
                return state
        }

}