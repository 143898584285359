import React, { Component } from 'react' 





class PlusExpander extends Component{
    
    constructor(props){

        super(props)

    }


    render(){

        return(

           

            <section className="job__jobs--expi">

                <span className="job__jobs--expi-bg">
                    +
                </span>
                <span className="job__jobs--expi-sm">
                    +
                </span>
            
           </section>
        )
    }

}


export default PlusExpander