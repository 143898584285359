
import React, {Component} from 'react' 
import AvatarChangeEdit from './avataredit'
// import AvatarChangeBtn from './avatarbtn'
import InfoUpdate from './infoupdate'



class Profile extends  Component{


    constructor(props){

        super(props)
    }

    photoUpload = e =>{

        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result
          });
        }
        reader.readAsDataURL(file);


      }

   

    render(){

      //   const {compData,isActionSuccessful,isFetching,launcher,isProgressBar,actions} = this.props
      //  const {avatar,profile_url,first_name,last_name,id,version} = compData 
      //  const {sendUserAvatarUpdate} = actions 
      //  console.log('THE PROFILE URL IN PROFILE')
      //  console.log(profile_url)
      //  console.log(compData)

      const {userProfile,actions} = this.props

      

        return(

                <div>
                    <section className="dashboard__candsettings--editpane-avatar-cont">

                        {/* <AvatarChangeEdit avatar={avatar} profile_url={profile_url} id={id} version = {version} isFetching={isFetching} isProgressBar={isProgressBar} isActionSuccessful={isActionSuccessful} launcher={launcher} sendUserAvatarUpdate={sendUserAvatarUpdate} actions={actions}  /> */}
                        <AvatarChangeEdit userProfile={userProfile} actions={actions}  />
                        {/* <AvatarChangeBtn /> */}
                       
                   
                    </section>
                    <InfoUpdate userProfile={userProfile} actions={actions} />

                   
                </div>

             
             
           
        )
    }




}

export default Profile 