import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import ExperienceInfo from './experienceinfo'
import ExperienceForm from './experienceform'
import MissingExperience from './missingexperience'
import { ThemeContext } from '../../context'





class Experience extends Component{
    
    constructor(){

        super()
        this.state ={

            rectHeight: 0,
            rectOffsetTop: 0,
            // experience: [{

            //     from: '2009',
            //     to: '',
            //     company: 'Steep Peel',
            //     position: 'Foreman',
            //     description: `I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background who possesses self-discipline and the ability to work with the minimum of supervision.`
            // },
            // {

            //     from: '2013',
            //     to: '2015',
            //     company: 'MR Price',
            //     position: 'Sales Assistant',
            //     description: `I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background who possesses self-discipline and the ability to work with the minimum of supervision.`
            // },
            // {

            //     from: '2018',
            //     to: '2018',
            //     company: 'Innov8',
            //     position: 'Senior Software Engineer',
            //     description: 'I used to build applications for a financial management system that incorporated bookkeeping'
            // }],
            experience: [],
           
            isEdit: false,
            isExperienceAdd: false
        }

        
    }

    getRectHeight = ()=>{

        let el = document.getElementById('experience-rect')

        let heightRect = el.clientHeight 
        let rectOffsetTop = el.offsetTop
        if(heightRect !== this.state.rectHeight){

            this.setState({...this.state,rectHeight: heightRect,rectOffsetTop: rectOffsetTop})
        }
        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    
   componentDidMount(){

      this.getRectHeight()
   }

   
   allowEdit = (e)=>{

    e.preventDefault()
    
    //  let editing = `${key}Edit` 

     let isEdit = this.state.isEdit
     this.setState({...this.state,isEdit: !isEdit})
}

getEditExperienceForm = (e,i)=>{

    // console.log('THE VALUE OF I')
    // console.log(i)
    // console.log(e)
    const {props} = this 
    const {actions,userProfile} = props
    return (<ExperienceForm userProfile={userProfile} experience={e} actions={actions} index={i} />)

}

experienceAdd = ()=>{

    this.setState({...this.state,isExperienceAdd : !this.state.isExperienceAdd})
}


scrollToBottom = (id)=>{

        let scrollEl = document.getElementById(id)

        if(scrollEl) scrollEl.scrollIntoView({behavior: 'smooth'})

    
}


componentDidUpdate(prevState){

    if(this.state.isExperienceAdd){

        
        this.scrollToBottom('experience-add')
        
    }

   this.getRectHeight()
    

    console.log('THE PREVSTATE')
    console.log(prevState)

    

    
    
    // else{

    //     console.log('THE PREVIOUS STATE')
    //     console.log(prevState)
    //     if(prevState.isExperienceAdd === true){
    //         this.scrollToBottom('experience-top')
    //     }
       
    // }
}



    
static contextType = ThemeContext
  
render(){

    const {theme, themeName} = this.context 
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

       const {state,props,allowEdit,experienceAdd} = this 
       const {rectHeight,rectOffsetTop, isEdit,isExperienceAdd} = state 
       const {experience=[],actions,userProfile} = props
    //    const depRectStyles = !isEdit || !isExperienceAdd ? {height: rectHeight, marginTop: rectHeight * .18 - rectHeight} : {height: rectHeight, marginTop: (2 * 100) - rectHeight}
       const parentHeight = {height: rectHeight + 450}
       const depRectStyles = {height: rectHeight, top: 420, position: 'absolute'} 
       console.log('THE RECT HEIGHT')
       console.log(rectHeight)
       console.log(rectOffsetTop)

        return(

           
    
            <div className="resume__experience" style={parentHeight} id="experience-top">

              
                 
                 <h2 className="resume__experience--title">
                     <span className="resume__experience--title-circle"></span>
                     <span className="resume__experience--title-text" style={textColorStylesAccent}>Experience</span>
                 </h2>

                 <div className="resume__experience-rect" id="experience-rect" style={boxesStyling}>

                   

                    {!isEdit && experience.length > 0
                    ? experience.length < 5 && !isExperienceAdd
                        ?  <p>
                            
                            <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={allowEdit.bind(this)}>
                            <i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                            </small>

                            <small className="resume--edit-pen resume__about--bio-edit-pencil" style={{right: '2%'}} onClick={experienceAdd.bind(this)}>
                            <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                            </small>
                            </p>

                        : !isExperienceAdd ? <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={allowEdit.bind(this)}>
                            <i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                          </small>
                          : null 
                    : null 
                    }

                    {isEdit 
                    ? experience.map((e,i)=>this.getEditExperienceForm(e,i))
                    : experience.length > 0 ? <ExperienceInfo experience={experience} /> : !isExperienceAdd ? <MissingExperience allowEdit={this.experienceAdd.bind(this)} /> : null  }

                    {isExperienceAdd ? <ExperienceForm userProfile={userProfile} isAdding={isExperienceAdd} experienceAdd={this.experienceAdd.bind(this)} actions={actions} />  : null}

                    {isEdit 
                    ?
                        <div className="resume__about--bio-edit-sub-btn" style={{width: '100px',textAlign: 'right',left: '75%'}}>


                            <button className="resume--edit-btn " onClick={allowEdit.bind(this)}>
                                    <span className="resume--edit-btn-rect"></span>
                                    <span className="resume--edit-btn-rect-2">
                                        <small>Cancel</small></span>
                            </button>
                        </div>
                     : null

                     }



                     {/* <section className="resume__experience--card">

                     <div className="resume__experience--card-pointer">
                         <small>1</small>

                    </div>

                    <div className="resume__experience--card-pointee">

                        <h5 className="resume__experience--card-pointee-period">2019-Present</h5>
                        <h6 className="resume__experience--card-pointee-employer">Steel Peel</h6>
                        <span className="resume__experience--card-pointee-role">Foreman</span>
                        <p className="resume__experience--card-pointee-description">I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background 
                            who possesses self-discipline and the ability to work with the minimum of supervision.
                        </p>

                    </div>

                     </section>

                     <section className="resume__experience--card resume__experience--card-2">

                     <div className="resume__experience--card-pointer">
                         <small>1</small>

                    </div>

                    <div className="resume__experience--card-pointee">

                        <h5 className="resume__experience--card-pointee-period">2014-2015</h5>
                        <h6 className="resume__experience--card-pointee-employer">Steel Peel</h6>
                        <span className="resume__experience--card-pointee-role">Foreman</span>
                        <p className="resume__experience--card-pointee-description">I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background 
                            who possesses self-discipline and the ability to work with the minimum of supervision.
                        </p>

                    </div>

                     </section>

                     <section className="resume__experience--card resume__experience--card-3">

                     <div className="resume__experience--card-pointer">
                         <small>1</small>

                    </div>

                    <div className="resume__experience--card-pointee">

                        <h5 className="resume__experience--card-pointee-period">2014-2015</h5>
                        <h6 className="resume__experience--card-pointee-employer">Steel Peel</h6>
                        <span className="resume__experience--card-pointee-role">Foreman</span>
                        <p className="resume__experience--card-pointee-description">I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background 
                            who possesses self-discipline and the ability to work with the minimum of supervision.
                        </p>

                    </div>

                     </section>
                    
                    
                    
                    
                    */}

                </div>

                <div className="resume__experience-rect-2" style={depRectStyles}>

                    
                 </div>
                



            </div>

          
        )
    }


}


export default Experience


