import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Search from '@material-ui/icons/Search';
import { ThemeContext } from '../../../context';

// import DeletePopup from './deletepopup'
// import DeleteButton from './button'

// import ManagePopup from './managepopup'
// import ManageButton from './managebutton'



const styles = {
    root: {
    
      fontSize: 20,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Card extends Component{

     
    
    constructor(props){

        super(props)

    }

    

    milliSecondsToTime(millis){

        let seconds = (millis / 1000).toFixed()
        let minutes = (millis / (1000 * 60)).toFixed()
        let hours = (millis / (1000 * 60 * 60)).toFixed()
        let days = (millis / (1000 * 60 * 60 * 24)).toFixed() 

        if(seconds <= 60 ) return `${seconds} seconds ago`
        if(minutes <= 60 ) return `${minutes}m ago`
        if(hours <= 24 ) return `${hours}hrs ago` 
        if(days >= 30){

            let months = days / 30

            return `${months.toFixed()} months ago`
        }
       
        if(days <= 1) return `${days} day ago`
        return `${days} days ago`
    }

    formatDate(date){

        // const {milliSecondsToTime} = this
        // let nw = new Date()
        let setDate = new Date(date) 
        // console.log('THE SETDATE')
        // console.log(setDate)
        // console.log(nw)
        // let diffInTime = nw.getTime() - setDate.getTime() 
        // let diffInDays = diffInTime / (1000 * 3600 * 24) 

        // console.log('THE TIME IN DAYS')
        // console.log(this.milliSecondsToTime(diffInTime))

        // return this.milliSecondsToTime(diffInTime)
        let month = setDate.getMonth() < 10 ? `0${setDate.getMonth()}` : setDate.getMonth()
        return `${setDate.getFullYear()}-${month}-${setDate.getDate()}`





    }


    openLinkPage(url,e){

        // e.preveventDefault()

     

        const {props} = this 
        const {actions} = props 
        const {push} = actions

        push(url)
        

    }

   


    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        const {openLinkPage} = this
        const {classes,viewPage,alert,setAlertId,userProfile,actions} = this.props 
        const Modal = this.props.modal 
        const DeletePopup = this.props.deletePopr 
        const ManagePopup = this.props.managePopr
        const DeleteButton = this.props.deleteButton 
        const ManageButton = this.props.manageButton
        const componentProps = {id: alert.id,compClass:"dashboard__candalertlist__card__extra-manage-actions-icon"} 
        const delComponentProps = {id: alert.id,compClass:"dashboard__candalertlist__card__extra-manage-actions-icon"} 
        console.log('THE CURRENT ALERT')
        console.log(alert)
       

        return(

          

            <div className="dashboard__candalertlist__card" style={boxesStyling}>

                {/* <div className="dashboard__candalertlist__card__alertflag">
                    <span className="jobcard__social-text">Alert for</span>
                </div> */}
                
                <section className="dashboard__candalertlist__card__i">
                    <h3 className="dashboard__candalertlist__card__i-title-cont">
                        <span className="dashboard__candalertlist__card__i-title" style={textColorStyles}>
                            {alert.job_keyword}
                        </span>
                        
                    </h3>
                    <i className="dashboard__candalertlist__card__i-salary" style={textColorStylesAccent}>{this.formatDate(alert.date_created)}</i>
                </section>
                <section className="dashboard__candalertlist__card__more">
                    <span className="dashboard__candalertlist__card__more-location" style={textColorStylesAccent}>{alert.frequency}</span>
                </section>
                <section className="dashboard__candalertlist__card__extra">
                   
                    <p className="dashboard__candalertlist__card__extra-manage-actions">
                            <section className="dashboard__candalertlist__card__extra-manage-actions-icon">
                                <button className="dashboard__candalertlist__card__extra-manage-actions-icon" style={{border:'none',cursor:'pointer',backgroundColor: 'transparent'}}
                                 onClick={openLinkPage.bind(this,`/jobs?jq=${alert.job_keyword}`)}>
                                    <Icon><Search className={classes.root}  /></Icon>
                                </button>
                            </section>
                           
                            <Modal popUp={ManagePopup} userProfile={userProfile} actions={actions} button={ManageButton} componentProps={componentProps} />
                            <Modal popUp={DeletePopup} userProfile={userProfile} actions={actions} button={DeleteButton} componentProps={delComponentProps}  />


                            
                            {/* <Modal popUp={ManagePopup} userProfile={userProfile} actions={actions} componentProps={componentProps} >
                                <ManageButton />
                            </Modal>
                            <Modal popUp={DeletePopup} userProfile={userProfile} actions={actions}  componentProps={delComponentProps}  >
                                <DeleteButton />
                            </Modal>
                             */}
                            
                        </p>
                </section>
                <strong className="clearfix" />
        
        </div>
        )
    }

}


export default withStyles(styles)(Card)