
import React, {Component} from 'react' 
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 


import qs from 'query-string'
import Tagline from './tagline/tagline' 
import Hero from './hero/hero' 
import Verification from './verification/verification'
import Postdate from './hero/reasons'
import { isActionSuccessful } from '../CandJobAlertsManage/props'
import { message } from '../Listing/props'




class AlertAccountVerify extends Component{


    constructor(props){

        super()
        this.state = {timer: null,skipUpdate: false}
    }

    componentDidMount(){

        const {props} = this 
        const {actions,router} = props 
        const {push,sendUserAlertVerificationCreds} = actions
        const {search} = router 

     

        let params =  qs.parse(search) 

        console.log('THE USER PARAMS')
        console.log(params)
        console.log(search)

        if(params.code && params.id && params.keyword){

            let data = {hash:params.code,id: params.id}
            sendUserAlertVerificationCreds(data)

        }else{

            //push('/invalid-verification')

        }


    }

    
    // componentDidUpdate(){

        
    //     const {props} = this
    //     const {alertAccountVerify,actions,router} = props 
    //     const {isActionSuccessful,isFetching} = alertAccountVerify
    //     const {search} = router 

    //     const params =  qs.parse(search) 
    //     const {keyword} = params
    //     const {push} = actions
      
    
     

    //    // console.log(isAuth)

       
        


    //      if(isActionSuccessful){

        
           
    //     //    return setTimeout(()=>{

    //     //         console.log('THE PASSED KEYWORD')
    //     //         console.log(keyword)
    //     //         return push(`/jobs?jq=${keyword}`)

    //     //     },1000,keyword)
            

    //         push(`/jobs?jq=${keyword}`)
           
          
    //      }
         
        

    // }



    retryLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 

        push('/resend-verification')
    }




    render(){

        const {props} = this 
        const {alertAccountVerify,router,actions} = props 
        const {isFetching,message} = alertAccountVerify

        const {search} = router 

        const params =  qs.parse(search) 
        const {keyword} = params
        
      

        // let styles = {
        //     marginBottom: '30px',
        //     fontSize: '1.4rem',
        //     width: '50%',
        //     height: '300px',
        //     marginLeft: '25%',
        //     paddingTop: '150px',
        //     boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04)'
        

        // }

       
        if(message.trim()) return <article className="verify verify--out">
             <Helmet>
                    <title>Jobbri - Job alerts verify</title>
                
                </Helmet>
            <Verification retryLogin={this.retryLogin.bind(this)} /></article>


        return(

          
            <article className="verify verify--out">

                <Helmet>
                    <title>Jobbri - Job alerts verify</title>
                
                </Helmet>
                  
                {/* <Tagline /> */}

             

                { isFetching
                
                ? <section className="verify__verifying">

                    <div>
                        <div className="verify__loader "></div>
                        <h2 className="verify__loader__text">Verifying alert subscription...</h2>
                    </div>
                </section>
                
                : <Hero alertAccountVerify={alertAccountVerify} actions={actions} keyword={keyword} /> }
               
            </article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        alertAccountVerify: {...state.alertAccountVerify},
        router: {...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(AlertAccountVerify)