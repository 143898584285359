import React, { Component } from 'react'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import FolderIcon from '@material-ui/icons/Folder';
import { toast } from 'react-toastify';





const styles = {
    root: {
     
     
      fontSize: 20,
      color: 'seagreen'
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



const designs = [

    {name: 'jobbri'},
    {name: 'headsot'},
    {name: 'hireme'},
    {name: 'professional'},
    {name: 'resumey'},
    {name: 'showcase'},
    
]

class DownloadMulti extends Component {

    constructor(props) {

      super()
      this.state = {

         templateID: '',
         isTemplateSelected: false,
         format: 'pdf'
        
      }
     

    }
   

   notify = (config) => {

       
    const {message} = config || ''
    const type = config.type || 'info'
    toast[type](message,
        {position: toast.POSITION.BOTTOM_LEFT,...config});

    }

  

    componentDidMount(){

        const {props} = this 
        const {setTitle} = props 
        setTitle('Choose Download Design')

    }

    selectTemplate = (id)=>{

        this.setState({...this.state,templateID: id,isTemplateSelected: !this.isTemplateSelected})
    }

    onClickHandler = (e) => {

        console.log('THE CLICK OCCURED')
        console.log(e)
        e.preventDefault() 
        this.downloadCV()
        

    }

    downloadCV = ()=>{


        const {state,props} = this 
        const {format,templateID='jobbri'} = state
        const {actions,userProfile} = props
        const {downloadShareResume} = actions 
        const {profile} = userProfile
        // const {personal} = profile 
        // const {resume_url_hash} = personal 
        // let parts = resume_url.indexOf('//') >= 0 ? resume_url.split('//') : resume_url.split('\\') 
        const send = {format: format,template: templateID,downloadResume: profile} 
        const postLaunch = {format: format,template:templateID}
        downloadShareResume(send,'',postLaunch)

    }

    setDownloadFormat = (format)=>{

        this.setState({...this.state,format: format})
    }

    showDesign = (d,i)=>{

        //  const imgUrl = `/img/${d.name}.png`
        const {state} = this
        const {templateID,isTemplateSelected,format} = state
        const imgUrl = `/img/headshot.png`
        const identifierStyles = {bottom: `-2.5rem`,left:'1rem',position:'absolute',display:'block',fontSize:'1.3rem'}
         return(<div className="downloadmulti-i" key={i}>
                    <figure className="downloadmulti-i-fig" onClick={()=> this.selectTemplate(d.name)}>
                        <img src={imgUrl} title={`${d.name} CV Template`} alt={`Jobbri ${d.name} CV Template`}  />
                        <span style={identifierStyles}>{d.name}</span>
                        {isTemplateSelected && templateID === d.name
                            ? <section className="downloadmulti-i-fig-overlay">
                                <span className="downloadmulti-i-fig-overlay-icon">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i>

                                </span>
                            </section>
                            : null
                        }
                    </figure>
                    
                </div>)

    }

    

  
    render() {

        const {state,props} = this 
        const {templateID,isTemplateSelected,format} = state
        const {userProfile} = props
        const {isFetching=false,message='',isInitFetching=false,isInitMessage} = userProfile 
        let downloadBtnStyles = isTemplateSelected ? {pointerEvents: 'visible',opacity: 1} : {pointerEvents: 'none',opacity: .5}
        let confirmTextStyles = {display: 'inline-block'}
        let checkStyles = {borderRadius: '5px',marginRight: '5px',textAlign: 'center',display: 'inline-block',border: 'solid maroon 1px',height: '15px',width: '15px'}
        let iconStyles = {color: 'seagreen',position:'relative',bottom:'2px',display: 'inline-block'}
        // let deleteErrorStyles = {color: 'white',display: 'inline-block',backgroundColor: 'seagreen',padding: '7px',cursor: 'pointer',textAlign: 'center',fontSize: '13px',borderRadius: '2px'}

        return (
            

                <div className="upload-cv">

                    <p className="upload-cv__formats">

                        <i className="fa fa-lg fa-file-pdf-o"></i>
                        <i className="fa fa-lg fa-file-word-o" aria-hidden="true"></i>
                        {/* <i className="fa fa-lg fa-file-text" aria-hidden="true"></i> */}


                    </p>
                    <section className="donwloadmulti">


                        {designs.map((d,i)=>{

                            return this.showDesign(d,i)
                        })}

                        
                      


                       


                        

                            
                     

                        {/* {
                            isFetching && isInitFetching === false
                            ? <section className="upload-cv__files">   
                                <span className="upload-cv__files-name">File is uploading...</span>
                              </section>
                            :message && isInitMessage === false
                                ? <section className="upload-cv__files" style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                                        <small style={{display: 'block',color: 'red',marginBottom: '10px',marginTop: '15px',fontSize: '14px'}}>File has failed to upload</small>
                                        <span style={deleteErrorStyles} onClick={this.onClickHandler.bind(this)}>Let me try again</span>
                                  </section> 
                                :selectedFile 
                                    ? <section className="upload-cv__files">
                                            <span className="upload-cv__files-icon"> <i style={{fontSize: 'red'}} className="fa fa-lg fa-file-pdf-o"></i></span>
                                            <span className="upload-cv__files-name">{selectedFile.name}</span>
                                    </section>
                                    :  null 
                                
                        } */}

                        

                            <div className="downloadmulti-selector" onClick={()=>{this.setDownloadFormat('pdf')}}>

                                <span style={checkStyles} aria-hidden="true">
                                        
                                  {
                                  
                                  format.trim() && format === 'pdf' 
                                    ? <i className="fa fa-lx fa-check" style={iconStyles}></i> 
                                    : null
                                  }
                                            
                                </span>
                                <span style={confirmTextStyles}>Download PDF</span> 
                                <span><i className="fa fa-lg fa-file-pdf-o"></i></span>
                            </div>

                            <div className="downloadmulti-selector" onClick={()=>{this.setDownloadFormat('docx')}}>

                            <span style={checkStyles} aria-hidden="true">

                                {
                                  
                                  format.trim() && format === 'docx' 
                                    ?  <i className="fa fa-lx fa-check" style={iconStyles}></i> 
                                    : null
                                  }
                                    
                           
                                        
                            </span>
                            <span style={confirmTextStyles}>Download Word</span> 
                            <span><i className="fa fa-lg fa-file-word-o" aria-hidden="true"></i></span>
                            </div>
                    </section>
                    
                    {

                    isFetching && isInitFetching === false
                        ? <div className="upload-cv__submit">
                            <section className="huloader huloader-co">
                            <div className="huloader__loading huloader__loading-co">
                                <div></div>
                                <div></div>
                            </div>  
                           </section></div>
                        :<section className="upload-cv__submit" style={downloadBtnStyles} onClick={(e)=>{this.onClickHandler(e)}}>
                            <button className="upload-cv__submit-submit" >
                                <span className="upload-cv__submit-submit-rect"></span>
                                <span className="upload-cv__submit-submit-rect-2">
                                    <small>Download Cv</small></span>
                            </button>
                         </section>
                    }

                   
                </div>
                
               
            
          )
    }
    

}

export default withStyles(styles)(DownloadMulti)