
export const SEND_USER_VERIFICATION_LINK_REQUEST = "SEND_USER_VERIFICATION_LINK_REQUEST"
export const SEND_USER_VERIFICATION_LINK_SUCCESS = "SEND_USER_VERIFICATION_LINK_SUCCESS"
export const SEND_USER_VERIFICATION_LINK_FAILURE = "SEND_USER_VERIFICATION_LINK_FAILURE"

export const UNSET_MESSAGE_NOTIFICATION_DISPLAY = "UNSET_MESSAGE_NOTIFICATION_DISPLAY"
export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"




