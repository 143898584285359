import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 






class Experience extends Component{
    
    constructor(){

        super()
        this.state ={

            rectHeight: 0
        }

        
    }

    getRectHeight = ()=>{

        let heightRect = document.getElementById('experience-rect').clientHeight 
        this.setState({...this.state,rectHeight: heightRect})
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    
   componentDidMount(){

      this.getRectHeight()
   }

    
    render(){


       const {state} = this 
       const {rectHeight} = state
       const depRectStyles = {height: rectHeight}
       const parentHeight = {height: rectHeight * 2 - 100}

        return(

           
    
            <div className="resume__experience" style={parentHeight}>

                 
                 <h2 className="resume__experience--title">
                     <span className="resume__experience--title-circle"></span>
                     <span className="resume__experience--title-text">Experience</span>
                 </h2>

                 <div className="resume__experience-rect" id="experience-rect">



                     <section className="resume__experience--card">

                     <div className="resume__experience--card-pointer">
                         <small>1</small>

                    </div>

                    <div className="resume__experience--card-pointee">

                        <h5 className="resume__experience--card-pointee-period">2019-Present</h5>
                        <h6 className="resume__experience--card-pointee-employer">Steel Peel</h6>
                        <span className="resume__experience--card-pointee-role">Foreman</span>
                        <p className="resume__experience--card-pointee-description">I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background 
                            who possesses self-discipline and the ability to work with the minimum of supervision.
                        </p>

                    </div>

                     </section>

                     <section className="resume__experience--card resume__experience--card-2">

                     <div className="resume__experience--card-pointer">
                         <small>1</small>

                    </div>

                    <div className="resume__experience--card-pointee">

                        <h5 className="resume__experience--card-pointee-period">2014-2015</h5>
                        <h6 className="resume__experience--card-pointee-employer">Steel Peel</h6>
                        <span className="resume__experience--card-pointee-role">Foreman</span>
                        <p className="resume__experience--card-pointee-description">I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background 
                            who possesses self-discipline and the ability to work with the minimum of supervision.
                        </p>

                    </div>

                     </section>

                     <section className="resume__experience--card resume__experience--card-3">

                     <div className="resume__experience--card-pointer">
                         <small>1</small>

                    </div>

                    <div className="resume__experience--card-pointee">

                        <h5 className="resume__experience--card-pointee-period">2014-2015</h5>
                        <h6 className="resume__experience--card-pointee-employer">Steel Peel</h6>
                        <span className="resume__experience--card-pointee-role">Foreman</span>
                        <p className="resume__experience--card-pointee-description">I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background 
                            who possesses self-discipline and the ability to work with the minimum of supervision.
                        </p>

                    </div>

                     </section>
                    
                    
                    
                    
                   

                </div>

                <div className="resume__experience-rect-2" style={depRectStyles}>

                    
                 </div>
                



            </div>

          
        )
    }


}


export default Experience


