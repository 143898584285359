import React, { Component } from 'react' 
import Radio from '../radio/radio'





class Reasons extends Component{
    
    constructor(){

        super()

        this.state = {
            form : 'form'
        };
    }

    doLabel(list){

        // const {props} = this 
        // console.log('THE POSTDATE PROPS')
        // console.log(props)
        // const {search} = props 
        // const {jdp,resetFilter} = search 
        // const {value} = jdp 
        // console.log('THE JDP')
        // console.log(value)

        let doneList = []

        list.map((i,p)=>{

            // let checked = p === 0 ? true : false 

            let checked = i === 0 ? true : false

            doneList.push({

                label: `${i.label}`,
                value: {value: i.value, numValue: i.numValue},
                // numValue: i.numValue,
                checked: checked
            })

        })

        return doneList

    }

    handleChecked(value){

        // console.log('THE VALUE OF FROM THE CHECKBOX SURPRISE')
        // console.log(e.target.value)
        // alert(e.target.value) 
        const {props} = this
        const {actions} = props 
        const {saveJobSearchParams} = actions 
        // const {crudJobFilter} = actions 
        const i = value
        // const {numValue,value} = i
        
        // if(i !== 0){
        	 
        // 	 crudJobFilter({
        // 	 	key: 'jdp',
        // 	 	value: i,
        // 	 	operation: 'add'
        // 	 })
        	
        // }else{
        	
        // 	crudJobFilter({
        // 		key:'jdp',
        // 		value: i,
        // 		operation: 'remove'
        // 	})
        // } 

        

            saveJobSearchParams({
                jdp: i,
                sort: 'date',
                // canSetHistory: true,
                fetchJobs: true,
                 resetFilter: false
            })
        


        
    }


    
    render(){


        const {props} = this 
       

        return(

           
    
            <Radio list={this.doLabel(dates)} legend={false} handle={(e)=>{this.handleChecked(e)}} />

          
        )
    }


}


export default Reasons


const dates =[

    {label: `I'm just taking a break`,value: 'all',numValue: 0},
    {label: 'I receive a lot of alert emails',value: 'last-hour',numValue: 1},
    {label: 'I found a job',value: 'last-7-days',numValue: 168},
    {label: 'I rather not say',value: 'I rather not say',numValue: 168},
    
   
]

// const dates =[

//     {label: 'All',value: 0},
//     {label: 'Last hour',value: 1},
//     {label: 'Last 7 days',value: 168},
//     {label: 'Last 14 days',value: 336},
//     {label: '30 days',value: 672}
   
// ]