
import React, { Component } from 'react' 

import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
 
import SearchIcon from '@material-ui/icons/Search';
import { toast } from 'react-toastify';




const styles = {
    root: {
     
    //   color:"#999",
      fontSize: 22,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




 class Mobile extends Component{
    

    constructor(props){
        super()

    }

    openSearch(e){

        e.preventDefault()

        const {props} = this 
        const {openForm} = props 
        openForm(true)


    }

    render(){


        const {openSearch,props}= this 
        const {search} = props 
        const {jq=''} = search

        return(
        
            <section className="job__search--mobile">

                <span className="job__search--mobile-input" style={{cursor: 'pointer'}} onClick={openSearch.bind(this)}>
                    <small>{jq}</small>
                    <span className="job__search--mobile-input-icon">
                        <Icon><SearchIcon style={styles.root} /></Icon>
                    </span>
                </span>
                
            </section>
        
        )

    }

}


export default withStyles(styles)(Mobile)