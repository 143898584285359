
import React, { Component } from 'react' 




 function Dash (props){
    
  
    const openPage = (e)=>{

        props.actions.push('/access')
    }
    return(

      
           <section className="home__jobdash">

               <div className="home__jobdash--b1 " style={props.styles}>

                      <p className="font-fd-xx-tn fg-dark" style={{...props.textStyle,...props.styles}} >

                         Read simple stats and be notified when new jobs become available. Our intuitive user interface makes it easier for you to manage your job search activities. 



                        </p>
               </div>

               <div className="home__jobdash--b2">

                   <section className="home__jobdash--b2-dash">

                    
                       <p className="font-fd-xx-tn fg-dark">

                            <img src="/img/userdash.jpg"/>

                        </p>

                       

                   </section>

                   <section className="home__jobdash--b2-sign" onClick={openPage}>
                       <span className="font-fd-xx-tn">
                          Sign up</span>
                   </section>
                    

                   

               </div>

              

               <strong className="clearfix" />
             
           </section>
      
    )

}


export default Dash