import * as types from './types'
import { CALL_API } from '../../middlwares/api'



export const fetchBlogPosts = (data={isBlog: true})=> {


  return {

      [CALL_API]: {
        endpoint: `/blog`,
        request: 'URL',
        method: 'POST',
        payload: {action: 'getBlogPosts',payload: {blog: data }},
        authencitcated: false,
        types: [types.FETCH_BLOG_POSTS_REQUEST, types.FETCH_BLOG_POSTS_SUCCESS, types.FETCH_BLOG_POSTS_FAILURE]
      }
    }

}

export const fetchBlogArchive = (data={isBlog: true})=> {


  return {

      [CALL_API]: {
        endpoint: `/blog`,
        request: 'URL',
        method: 'POST',
        payload: {action: 'getBlogArchive',payload: {blog: data}},
        authencitcated: false,
        types: [types.FETCH_BLOG_ARCHIVE_REQUEST, types.FETCH_BLOG_ARCHIVE_SUCCESS, types.FETCH_BLOG_ARCHIVE_FAILURE]
      }
    }

}

export const fetchBlogPostWith = (id)=> {

 

  return {

      [CALL_API]: {
        endpoint: `/blog`,
        request: 'URL',
        method: 'POST',
        payload: {action: 'getBlogPostWith',payload: {blog: {postID: id}}},
        authencitcated: false,
        types: [types.FETCH_BLOG_POST_REQUEST, types.FETCH_BLOG_POST_SUCCESS, types.FETCH_BLOG_POST_FAILURE]
      }
    }

}


// export const sendUserAvatarUpdate = (data,launcher='')=> {

//   return {

//       [CALL_API]: {
//         endpoint: `/profile`,
//         request: 'URL',
//         method: 'POST',
//         headers: {'Content-Type': 'multipart/form-data'},
//         payload: data,
//         authencitcated: false,
//         isProgress: true,
//         launcher: launcher,
//         types: [types.SEND_USER_AVATAR_UPDATE_REQUEST, types.SEND_USER_AVATAR_UPDATE_SUCCESS, types.SEND_USER_AVATAR_UPDATE_FAILURE]
//       }
//     }

// }

  
//   export const sendUserProfileUpdate = (data,id,launcher)=> {

//     return {

//         [CALL_API]: {
//           endpoint: `/profile`,
//           request: 'URL',
//           method: 'POST',
//           payload: {action: 'updateUser',payload: {update: {...data},ID:id}},
//           authencitcated: false,
//           isProgress: true,
//           launcher: launcher || '',
//           types: [types.SEND_USER_PROFILE_UPDATE_REQUEST, types.SEND_USER_PROFILE_UPDATE_SUCCESS, types.SEND_USER_PROFILE_UPDATE_FAILURE]
//         }
//       }

//   } 



// // RESUME ACTIONS 




// export const fetchResume = (id)=> {

//   return {

//       [CALL_API]: {
//         endpoint: `/resume`,
//         request: 'URL',
//         method: 'POST',
//         payload: {action: 'getResume',payload: {ID:id,resume: true}},
//         authencitcated: false,
//         isProgress: true,
//         launcher:  '',
//         types: [types.FETCH_RESUME_REQUEST, types.FETCH_RESUME_SUCCESS, types.FETCH_RESUME_FAILURE]
//       }
//     }

// }


// export const addResumeItem = (data,id,launcher)=> {

//     return {

//         [CALL_API]: {
//           endpoint: `/resume`,
//           request: 'URL',
//           method: 'POST',
//           payload: {action: 'saveResume',payload: {resume: {...data},ID:id}},
//           authencitcated: false,
//           isProgress: true,
//           launcher: launcher || '',
//           types: [types.ADD_RESUME_ITEM_REQUEST, types.ADD_RESUME_ITEM_SUCCESS, types.ADD_RESUME_ITEM_FAILURE]
//         }
//       }

// }


// export const updateResumeItem = (data,id,launcher='')=> {

//     return {

//         [CALL_API]: {
//           endpoint: `/resume`,
//           request: 'URL',
//           method: 'POST',
//           payload: {action: 'updateResume',payload: {resume: {...data},ID:id}},
//           authencitcated: false,
//           isProgress: true,
//           launcher: launcher || '',
//           types: [types.UPDATE_RESUME_ITEM_REQUEST, types.UPDATE_RESUME_ITEM_SUCCESS, types.UPDATE_RESUME_ITEM_FAILURE]
//         }

//       }
      

// }


// export const deleteResumeItem = (data,id,launcher='')=> {

//     return {

//       [CALL_API]: {
//         endpoint: `/resume`,
//         request: 'URL',
//         method: 'POST',
//         payload: {action: 'deleteResume',payload: {resume: {...data},ID:id}},
//         authencitcated: false,
//         isProgress: true,
//         launcher: launcher || '',
//         types: [types.DELETE_RESUME_ITEM_REQUEST, types.DELETE_RESUME_ITEM_SUCCESS, types.DELETE_RESUME_ITEM_FAILURE]
//       }
    
//       }

// }


// export const deleteResume = (data,id,task,launcher)=> {

//     return {

//         [CALL_API]: {
//           endpoint: `/resume`,
//           request: 'URL',
//           method: 'POST',
//           payload: {action: task,payload: {update: {...data},ID:id}},
//           authencitcated: false,
//           isProgress: true,
//           launcher: launcher || '',
//           types: [types.DELETE_RESUME_REQUEST, types.DELETE_RESUME_SUCCESS, types.DELETE_RESUME_FAILURE]
//         }
  
//       }

// }

// export const deleteUploadResume = (data,id,launcher='')=> {

//     return {

//         [CALL_API]: {
//           endpoint: `/resume`,
//           request: 'URL',
//           method: 'POST',
//           payload: {action: 'deleteUploadResume',payload: {...data,ID:id}},
//           authencitcated: false,
//           // isProgress: true,
//           launcher: launcher || '',
//           types: [types.DELETE_UPLOAD_RESUME_REQUEST, types.DELETE_UPLOAD_RESUME_SUCCESS, types.DELETE_UPLOAD_RESUME_FAILURE]
//         }
  
//       }

// }

// export const saveUploadResume = (data,launcher='')=> {

//   return {

//     [CALL_API]: {
//       endpoint: `/resume`,
//       request: 'URL',
//       method: 'POST',
//       headers: {'Content-Type': 'multipart/form-data'},
//       payload: data,
//       authencitcated: false,
//       isProgress: true,
//       launcher: launcher || '',
//       types: [types.SAVE_UPLOAD_RESUME_REQUEST, types.SAVE_UPLOAD_RESUME_SUCCESS, types.SAVE_UPLOAD_RESUME_FAILURE]
//     }
//   }
// }


//   export const replaceResume = (data,launcher='')=> {

//     return {

//         [CALL_API]: {
//           endpoint: `/resume`,
//           request: 'URL',
//           method: 'POST',
//           headers: {'Content-Type': 'multipart/form-data'},
//           payload: data,
//           authencitcated: false,
//           isProgress: true,
//           launcher: launcher || '',
//           types: [types.REPLACE_RESUME_REQUEST, types.REPLACE_RESUME_SUCCESS, types.REPLACE_RESUME_FAILURE]
//         }
  
//       }

//   }
  // export const sendUserAvatarUpdate = (data,launcher='')=> {

  //   return {
  
  //       [CALL_API]: {
  //         endpoint: `/profile`,
  //         request: 'URL',
  //         method: 'POST',
  //         headers: {'Content-Type': 'multipart/form-data'},
  //         payload: data,
  //         authencitcated: false,
  //         isProgress: true,
  //         launcher: launcher,
  //         types: [types.SEND_RESUME_AVATAR_UPDATE_REQUEST, types.SEND_RESUME_AVATAR_UPDATE_SUCCESS, types.SEND_RESUME_AVATAR_UPDATE_FAILURE]
  //       }
  //     }
  
  // }

//   export const downloadUploadResume = (data,launcher='')=> {

//     return {
  
//       [CALL_API]: {
//         endpoint: `/resume`,
//         request: 'URL',
//         method: 'POST',
//         payload: {action: 'downloadResume',payload: {...data}},
//         authencitcated: false,
//         isProgress: true,
//         launcher: launcher || '',
//         isBlob: true,
//         types: [types.DOWNLOAD_UPLOAD_RESUME_REQUEST, types.DOWNLOAD_UPLOAD_RESUME_SUCCESS, types.DOWNLOAD_UPLOAD_RESUME_FAILURE]
//       }
    
//       }
  
//   }
  


export const removeNotification = ()=>{

    return {
  
      type: types.UNSET_NOTIFICATION_DISPLAY,
      payload: false
    }
  }

  export const removeMessage = ()=>{

    return {
  
      type: types.UNSET_MESSAGE_NOTIFICATION_DISPLAY,
      payload: false
    }
  }
  


//   export const receiveUserProfile = (user)=> {

//     return {
//       type: types.RECEIVE_USER_PROFILE,
//       isFetching: false,
//       isAuth: true,
//       user: {...user}
//     }

//   }

  
