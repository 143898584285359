
import React, {Component} from 'react' 
import Helmet from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import { toast } from 'react-toastify';
// import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import * as userProfileActions from '../UserProfile/actions'
import qs from 'query-string'
// import * as userProfileActions from '../UserProfile/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'

import About from './components/about'
import Experience from './components/experience'
import Portfolio from './components/portfolio'
import Education from './components/education' 
import Curated from './components/curated'
import ProgressBar from './components/progressBar'

 



class ViewResume extends Component{


    constructor(props){

        super()
        this.state ={
            update: false
            
        }

    }

    componentDidMount(){

        // const {props} = this 
        // const {userProfile,actions,userProfile} = props 
        // const {profile} = userProfile 
        // const {fetchResume} = actions
        // // const {id,isPending} = profile 
        // // if(isPending === 1) return push('/account-verification')
        // if(!userProfile){
        //     fetchResume(52)
        // }

        // const {props} = this 
        // // const {push,sendUserAlertVerificationCreds} = actions
      
        // const {actions,userProfile,router} = props 
        // const {profile} = userProfile
        // const {search} = router 
        // // const {profile} = userProfile 
        // const {fetchResume} = actions

     

        // let params =  qs.parse(search) 

        // console.log('THE USER PARAMS')
        // console.log(params)
        // console.log(search)

        // if(Object.keys(profile).length <= 0){

        //     console.log('THE rESUME HAS NOTHING')
        //     // if(!params.r) return push('/invalid-verification')
        //     // if(params.r.indexOf('-') < 0) return push('/invalid-verification')
        //     let hashDictionary = params.r.split('-')
        //     let cvID = hashDictionary[hashDictionary.length - 1]
        //     fetchResume(cvID)
        // }

        
       

        //this.notify({message:'Information has successfully been updated',type: 'success',className: 'notify-success'})
       
        
    }

    componentDidUpdate(){

      const {props} = this 
      const {userProfile,actions} = props 
      const {isActionSuccessful = false,message='',text} = userProfile 
       const {removeNotification,removeMessage} = actions 

        if(isActionSuccessful) {

            let msg = text.trim() || 'Information has successfully been updated.'
            this.notify({message:msg,type: 'success',className: 'notify-success'}) 
            removeNotification() 
            
          
           
        }else if(message.trim()){

            this.notify({message: message,type: 'error',className: 'notify-error'}) 
            removeMessage() 
            
        }

    }

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        // const {props} = this 
        // const {actions,auth} = props 
        // const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        // const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        // if(errMessage !== ''){

        //     removeShownMessage()

        // }
        
        
    }

    
    render(){

         const {userProfile,actions} = this.props 
         const {profile} = userProfile
         const {isFetching,isInitFetching,launcher=''} = userProfile
         const {personal={},experience=[],education=[],qualification=[]} = profile
     
        if(isFetching === true && isInitFetching === true) return  <article className="resume" style={{height: '100vh',width: '70%',position: 'relative'}}>
             <Helmet>
                    <title>Jobbri - View shared cv</title>
                </Helmet>
            <ProgressBarCircular style={{width: '100%'}} /></article>;


        // console.log('THE JOBS PROP')
        // console.log(listing)
      
        return(

            <article className="resume">

                <Helmet>
                    <title>Jobbri - View shared cv</title>
                </Helmet>
                
                <About userProfile={userProfile} personal={personal} actions={actions} resume={profile} />
                <Experience userProfile={userProfile} experience={experience} actions={actions} />
                {/* <Portfolio /> */}
                <Education userProfile={userProfile} actions={actions} education={education} qualification={qualification} />
                <Curated userProfile={userProfile} actions={actions} />
                {isFetching === true ? <ProgressBar isFetching={true} /> : null } 
               
               
             </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{

        // viewprofile: {...state.viewResume},
        userProfile: {...state.userProfile},
        router: {...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...userProfileActions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(ViewResume)


