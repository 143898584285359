
import React, {Component} from 'react' 
import { ThemeContext } from '../../context'

import CuratedSkillInfo from './cuskillsinfo'

// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getPortfolio = (sData)=>{

           const {p,i} = sData
            return <CuratedSkillInfo skill={p} key={i} index={i} />
        
        
    
    }

               

   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        
         const {props,state} = this 
        //  const {bioText} = state
         const { isSkillAdd = false,skill,isEditing=0,allowEdit,setIsEditing,isSkillAdding,isAdding} = props 
        
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    

                        
                       
                     

                        <section className="resume__curated--card">

                       
                        <div className="resume__curated--card-pointer"></div>
   
                        <div className="resume__curated--card-pointee">
   
                           <h5 className="resume__curated--card-pointee-period" >Skill</h5>
                           <p className="resume__curated--card-pointee-legend">
                               
                                <span style={textColorStyles}>Skill</span>
                                <span style={textColorStyles}>Score</span>
                                <span style={textColorStyles}>Years</span>
                                <span style={textColorStyles}>Actions</span>
                               
                            </p>
                            <ul className="resume__curated--card-pointee-list">

                            {skill.map((p,i)=> this.getPortfolio({p,i,styles:{textColorStyles, textColorStylesAccent, boxesStyling}}))} 
                          
                            </ul>
                           
                       
   
                           </div>
                        </section>

           
             
             
           
        )
    }




}

export default Bio