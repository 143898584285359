
import React,{Component} from 'react'

import {BrowserRouter as Router,Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import DashboardIcon from '@material-ui/icons/Dashboard';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MailIcon from '@material-ui/icons/Mail';



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };

// import SearchForm from './searchform/launchform' 


class Young extends Component{


    openPage = (e)=>{
        
        e.preventDefault()
        const {props} = this 
        const {actions} = props 
        const {push} = actions 

        push('/access')
    }
    
    
    render(){

        return(

                <section className="home__young">


                    <div className="home__young--text">
                        <section className="home__young--text-message">Our intent is to combine technology and human interaction to make your job search easier and fun</section>
                        <button className="home__young--text-btn" onClick={this.openPage.bind(this)}>Join</button> 
                    </div>

                           

                    <div className="home__young--piks">
                        
                        <img src="/img/young_1.jpg" className="home__young--piks-pik home__young--piks-1" />
                        <img src="/img/young_2.jpg" className="home__young--piks-pik home__young--piks-2" />
                        <img src="/img/young.jpg" className="home__young--piks-main" />
                        <img src="/img/young_3.jpg" className="home__young--piks-pik home__young--piks-3" />
                        <img src="/img/young_4.jpg" className="home__young--piks-pik home__young--piks-4" /> 
                        <img src="/img/young_5.jpg" className="home__young--piks-pik home__young--piks-5" />
                        <img src="/img/young_6.jpg" className="home__young--piks-pik home__young--piks-6" /> 
                        <img src="/img/young_7.jpg" className="home__young--piks-pik home__young--piks-7" />
                        
                    </div>

                    <strong className="clearfix" />
                    <div className="home__young--dot2" />
                    <div className="home__young--dot" />
                   


                 


                   

                    

                </section> 

               


           

            

                
            
        )
    }

}


export default  withStyles(styles)(Young)