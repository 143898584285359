import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import { ThemeContext } from '../../context'
import EducationInfo from './educationeinfo'





class Education extends Component{
    
    constructor(){

        super()
      
        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    
  
    
    scrollToBottom = (id)=>{
    
       
    
            let scrollEl = document.getElementById(id)
    
            if(scrollEl) scrollEl.scrollIntoView({behavior: 'smooth'})
    
        
       
        
    }
    
    

    


    
    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}



       
        const {props} = this 
      
        const {education =[]} = props
       
        return(

           
    
            <div className="resume__education">

                 
                 <h2 className="resume__education--title">
                     <span className="resume__education--title-circle"></span>
                     <span className="resume__education--title-text" style={textColorStyles}>Education</span>
                 </h2>

                 <div className="resume__education-rect" style={boxesStyling}>

                    

                  
                    {education.length > 0 ? <EducationInfo  education={ education}  /> : null}
                   

                    

                   
                    
                    
                    
                    
                   

                </div>

                {/* <div className="resume__education-rect-2">

                    
                 </div>
                 */}



            </div>

          
        )
    }


}


export default Education


