import React, { Component } from 'react' 
import faqs from './faqs'
import Accordion from './accordion'
import Upload from './upload'






class Faqi extends Component{
    
    constructor(props){

        super(props)

    }


    doFaqs = (faq,i)=>{

    const contents = faq.map((f,i)=>{

                const {isDangerously=false} = f

            if(f.content){
                
                return <Accordion name={f.title} content={f.content} isDangerously={true} isSub={true} key={i} />
                

            }else{
                return null
            }
            
        })

        
       return(<div className="faqs__i--content" key={i}>{contents}</div>)

      

    }

    


    render(){

        return(

           

            <section className="faqs__i faqs__i--privacy">

               {faqs.map((faq,i)=>{

    
                    //  const Contents = this.doFaqs(faq.faqs,i)
                    return(<Accordion name={faq.title} isComponent={true} key={i} >
                            {this.doFaqs(faq.faqs,i)}
                        </Accordion>
                        ) 
                   

               
                
               })} 
            
           </section>
        )
    }

}


export default Faqi