
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import Accordion from './accordion'
import { ThemeContext } from '../../context';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getInstitutions = (e,i)=>{

     
        let card = i === 0 ? '' : `resume__education--card-${i+1}` 
        let toPresent = !e.to ? `${e.from}-` : `${e.from}-${e.to}`
        let pos = i+1
        let institution = (

    

            <section className={`resume__education--card ${card}`} key={i}>

                <div className="resume__education--card-pointer">
                    <small>{pos}</small>

                </div>

                <div className="resume__education--card-pointee">

                    <h5 className="resume__education--card-pointee-period">{toPresent}</h5>
                    <h6 className="resume__education--card-pointee-employer">{e.institution}</h6>
                    <span className="resume__education--card-pointee-role">{e.qualification}</span>
                    <p className="resume__education--card-pointee-description">
                       {e.majors}
                    </p>

                </div>

                

            </section>

            
        )
        

        return institution



    
    }
 

               

   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        
         const {props,state} = this 
        //  const {bioText} = state
         const {portfolio,setIsEditing,index,isEditing,isPortAdd=false,removeItem,width} = props 
         const editIconStyles  = isEditing > 0 || isPortAdd ? {opacity: '.4',pointerEvents: 'none'}  : {opacity: 1,pointerEvents: 'auto'} 
         const longTextStyles = {marginTop: '1.1rem',marginBottom: '2rem'}
         const revertStyles = {marginTop: 0,marginBottom: 0}
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    

                        
                       
                  
                        
                        
   
                                    
                        <>

                        {width <= 1200
                    
                    ? <li><Accordion name={portfolio.p_name}>

                        
                             
                            <ul className="resume--list">
                                <li className="resume--list-li">
                                    <span className="resume--list-li-title" style={{width: '150px'}}>Description:</span>
                                    <span className="resume--list-li-value" style={longTextStyles}>{portfolio.p_description}</span>
                                </li>
                                <li className="resume--list-li">
                                    <span className="resume--list-li-title">Role:</span>
                                    <span className="resume--list-li-value" style={revertStyles} >{portfolio.p_role}</span>
                                </li>
                                <li className="resume--list-li">
                                    <span className="resume--list-li-title">Link:</span>
                                    <span className="resume--list-li-value" style={revertStyles}><a href={portfolio.p_link} className="link" target="_blank">View</a></span>
                                </li>

                                <li className="resume--list-li">
                                    <span className="resume--list-li-icons">
                                        <small className="resume--list-li-icons-icon" onClick={()=>{setIsEditing(index+1)}}><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small className="resume--list-li-icons-icon"  onClick={(e)=>{removeItem(e,portfolio.id)}}><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                </li>
                            
                            </ul>
                        


                    </Accordion></li>
                
                    :   <li className="resume__curated--card-pointee-list-i" style={boxesStylingA}>
                            <span style={textColorStylesAccent}>{portfolio.p_name}</span>
                            <span  style={textColorStylesAccent}>{portfolio.p_description}</span>
                            <span  style={textColorStylesAccent}>{portfolio.p_role}</span>
                            <span  style={textColorStylesAccent}><a href={portfolio.p_link} target="_blank">View</a></span>
                            <span>
                                <small style={editIconStyles} onClick={()=>{setIsEditing(index+1)}}><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                    
                                <small style={editIconStyles}  onClick={(e)=>{removeItem(e,portfolio.id)}}><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            </span>
                            
                        </li>
                    
                }

                </>
    

   
                                  
                                   
   
   
                            
                      


             
           
        )
    }




}

export default Bio