export const profile = {} 
// export const isActionSuccessful = false 
export const isProgressBar = false 
export const isProfile = false

export const isActionSuccessful = false 
export const isInitSuccess = false
export const isFetching = true
export const isInitFetching = false 
export const isInitMessage = false
export const isResumeFetchError = false
export const isResumeDeleted = false 
export const isUploadResumeReplaced = false 
export const isResumeReplaced = false 
export const isUploadResume = false 
export const isResumeUpdate = false
export const message = ''
export const text = ''
// export const resume = {} 
export const profileCompletenessItems = [
    'personal',
    'education',
    'experience',
    'language',
    'skill',
    'portfolio'
]