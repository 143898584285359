
import * as types from './types'
import { CALL_API } from '../../middlwares/api'

export const sendUserAlertVerificationCreds = (data)=> {

    return {

      [CALL_API]: {
        endpoint: `/subscriber`,
        request: 'URL',
        method: 'POST',
        payload: {action:'verifyUser',payload:{verify: {...data}}},
        authencitcated: false,
        types: [types.SEND_USER_ALERT_VERIFY_REQUEST, types.SEND_USER_ALERT_VERIFY_SUCCESS, types.SEND_USER_ALERT_VERIFY_FAILURE]
      }
    }

}

