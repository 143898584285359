
import React from 'react';

class Huloader extends React.Component {

  constructor(props){

    super()
  }
//   state = {
//     loading: true,
//   };
  
//   componentDidMount(){
//     this.isLoading = setTimeout(()=>{this.setState({loading: false})}, 2300);
//   }
//   componentWillUnmount() {
//      clearTimeout(this.isLoading);
//   }
  
//   timer = () => setTimeout(()=>{
//     this.setState({loading: false})
//   }, 2300);

registerUser(e){

  e.preventDefault()

  const {props } = this 
  const {actions} = props 
  const {push} = actions

  push('/access')

}


  render() {

    const {registerUser} = this 

    return (
        <section className="job__reccomend-submit" onClick={registerUser.bind(this)}>
            <span className="job__reccomend-submit-rect"></span>
            <span className="job__reccomend-submit-rect-2">
              <small>Sign up</small>
            </span>
        </section>
    )
  }
}

export default Huloader