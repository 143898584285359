
import React, { Component } from 'react' 
import { isFetching } from '../UserProfile/props.js'
import FormControl from './formcontrol.js'

import { toast } from 'react-toastify';
import { saveJoUserJobAlert } from '../UserProfile/actions.js';


 class Form extends Component{
    
    
    constructor(props){
    	
    	 super(props) 

         const {disabledInput,userProfile} = props 
         const {profile} = userProfile 
         const {personal} = profile 
         const {email} = personal
    	 
    	  this.state = {
    	  	
    	  	  form : {
                    
                    
                    email:{
                        
                        control: 'input',
                        value: email || '',
                        validation: {
                            isRequiredFormat:{
                              
                                specialCharacters: false,
                                isEmail: true,
                                isRequired: true,
                                
                            }
                        
                             
                        },
                        isValid: true,
                        isTouched: false,
                        isSuggest: false,
                        validationMessage: '',
                        validationStyles: {
                            position: 'bottom'
                        },
                        config: {
                            
                            name: "email",
                            type: 'email',
                            placeholder: 'Enter your email',
                            disabled: true
                            
                        }
                       
                        
                    },
                    jobKeyword:{
                        
                        control: 'input',
                        value:'',
                        validation: {
                            isRequiredFormat:{
                                isRequired: true,
                                minLetters: 2,
                                maxLetters: 15
                                
                            }
                        
                             
                        },
                        isValid: false,
                        isTouched: false,
                        isSuggest: false,
                        validationMessage: '',
                        validationStyles: {
                            position: 'bottom'
                        },
                        config: {
                            
                            name: "jobKeyword",
                            type: 'text',
                            placeholder: 'Enter job title',
                           
                            
                        }
                       
                        
                    },
                    
                
            },
            
        
    	  	
    	  }
    	
    } 
    
    
    
    updator = (upControl)=>{
        
        console.log('THE UPDATE CONTROL')
        console.log(upControl) 
        const {state} = this
        let newForm ={...state.form} 
        let updateControl = {...newForm[upControl.id]}
        updateControl.value = upControl.e.target.value 
       


        // if(updateControl.isActive === false) updateControl.isActive = true

    
       
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

            
                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1] 
              
                    

                }

            }
        
    
    
        updateControl.touched = upControl.blur
        newForm[upControl.id] = updateControl
        
        this.setState({
            form: newForm
        
        })

    
        	
                
        
        


    } 

    controlValidate = (validateControl)=>{
    
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.maxLetters && (value.length > isRequiredFormat.maxLetters) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        // const {props} = this 
        // const {actions,auth} = props 
        // const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        // const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        // if(errMessage !== ''){

        //     removeShownMessage()

        // }
        
        
    }


    submitForm = (e)=>{
                    
       e.preventDefault() 
       console.log('THE FORM IS SUBMITTED')
       
        
        
      
        let submittData = {} 
        let formIsValid = true 
        let {form} = this.state 
        const {props,notify} = this 
        const {actions,userProfile} = props 
        const {profile} = userProfile 
        const {personal,alertsCount,alertSubscriberID} = profile 
        const {id} = personal
        // const {subscribeAndAddUserAlert,saveJoUserJobAlert} = actions 
        const {saveJoUserJobAlert} = actions 
        console.log('THE ALERTSEND FORM')
        console.log(props) 
       // return closeModal()

    
        
        
        
        for(let k in form){
            
           
           

                if(form[k].validation && form[k].validation.isRequired){

                    if(form[k].isValid === false){

                
                        formIsValid = false 

                        notify({message:'Filled form data must be valid',type: 'warn',className: 'notify-warn'})
                        break


                    }else{

                        submittData[k] = form[k].value
                    }

                }else if(form[k].value.trim() !== ''){

                    if(form[k].isValid === false){
                        formIsValid = false 
                        break
                    }else{

                        submittData[k] = form[k].value

                    }
                
                }
            
        
        
        } 

        console.log('THE SUBMIT CONTROL')
        console.log(submittData)

        if(!formIsValid) return notify({message:'Filled form data is invalid',type: 'warn',className: 'notify-warn'})

        // {"ID":52,"save":{"email":"mashu@testmail.com","jobKeyword": "Web Designer"}}
        //const {alertMailID,jobKeyword,frequency='weekly'} = save
        return saveJoUserJobAlert({ID:id, save: {job_keyword: submittData.jobKeyword}})
        // subscribeAndAddUserAlert({ID:id,save:{...submittData}})

        
            // closeModal(true)
              // saveJobSearchParams({
        //     keywords: submittData.jobKeyword,
        //     location: submittData.jobLocation,
        //     fetchJobs: true
        // })


    


        
        

    }

    componentDidUpdate(){

        const {props}= this 
        const {userProfile,actions,closeModal} = props 
        const {isActionSuccessful=false,message='',isAlertDelete=false} = userProfile 
        const {removeNotification,removeMessage} = actions

      
        if(isActionSuccessful) {

            closeModal()
            removeNotification() 
            
        }
     
        
    }

   

   
   
   
  
    render(){ 
    
    //   const {props,state,hideSuggestScreen,showSuggestScreen,searchLocations } = this 
    //   const {suggestions,locations} = props 
    //   const {showSuggest,showSuggestId,searchedLocations} = state 
    //   let  newLocations = [...locations] 
    //   let refinedLocations = searchedLocations.length >= 1 ? searchedLocations : newLocations.splice(0,4)

    const {props} = this 
    const {userProfile} = props 
    const {isFetching=false,profile} = userProfile 
    const {alertsCount} = profile 
      
    
    return(

      
            <section className="alert__modalbody--form">

                <form className="form" autocomplete="off">

                <p className="alert__modalbody--form-i">

                       <FormControl 

                                    styles={{child:'bx-raised alert__modalbody--form-text',error: ''}}
                                    id = 'email'
                                    controlData={this.state.form.email}
                                    change={(control)=>this.updator(control)} 
                                   
                                   
                          />
                        
                    
            

                    
                </p>

                <p className="alert__modalbody--form-i">

                       <FormControl 

                                    styles={{child:'bx-raised alert__modalbody--form-text',error: ''}}
                                    id = 'jobKeyword'
                                    controlData={this.state.form.jobKeyword}
                                    change={(control)=>this.updator(control)} 
                                   
                                   
                          />
                        
                    
            

                    
                </p>

              
                <strong className="clearfix" />
               


                <div className="alert__modalbody--form-submit">

                   {
                   !isFetching
                     ?    alertsCount < 3 
                            ? <input onClick={(e)=>this.submitForm(e)} type="submit" className="cursor-pointer" value="Send"/>
                            : null 
                        
                   : <section className="huloader huloader-co">
                        <div className="huloader__loading huloader__loading-co">
                            <div></div>
                            <div></div>
                        </div>  
                     </section>
                   }

                </div>


            </form>
        
    </section>
        
    )
    
    }

}


export default Form