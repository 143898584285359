
import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import { ThemeContext } from '../../../context';
import Related from './blogrelated'




class BlogRelated extends Component{
    
    
    constructor(props) {
        super(props);
      
    }


    getRelated = (rPost)=>{

        const {relatedPost,i,openPage} = rPost
        const {slug,image,title} = relatedPost
        let url = `/blog/post/${slug}`


        return (

            <div className="post__related--post" key={i}>
                                
            <section onClick={(e)=>openPage({slug:slug,url:url},e)} style={{cursor: 'pointer'}} className="link d-block">

                <img src={image} className="post__related--post-pik" />
                <h2 className="post__related--post-title">{title}</h2>
            
            </section>


        </div>

        )

    }

      
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}


        const {props} = this 
        const {posts,openPage} = props 
       

        return(

           

                
                <div  className="post__related">


                        <section className="post__related--post-wrap">

                            <h2 className="post__related--title" style={textColorStyles}>Related posts</h2>

                            {posts.map((post,i)=>{

                                return this.getRelated({relatedPost:post,i,openPage,styles:{textColorStyles, boxesStyling}})
                            })}

                           

                            {/* <div className="post__related--post">
                                
                                <Link to='/' className="link d-block">

                                    <img src="/img/users/250_2.jpg" className="post__related--post-pik" />
                                    <h2 className="post__related--post-title">Kick-start your career...</h2>
                                
                                </Link>


                            </div> */}


                        </section>
                        
                 

                </div>
               
                

           
        )
    }

}


export default BlogRelated