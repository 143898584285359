import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import Favorite from '@material-ui/icons/Favorite'
import { ThemeContext } from '../../../../context'



const styles = {
    root: {
     
      color:"red",
      opacity: .5,
      fontSize: 20,
      '&:hover':{
          color: "red",
          opacity: 1
      }
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class JobsNotFound extends Component{

     
    
    constructor(props){

        super(props)
        this.state = {
            dateInterVals: [
                '1h','2h','today','yesterday',
                '1w','2w','30 days'
            ]
        }
       

    }


  
    handleSearch(i,e){

        console.log('HANDLE SEARCH JOBSNOTFOUND')
        console.log(i)
        console.log(this)
        const {props} = this
        const {actions} = props 
        const {saveJobSearchParams} = actions 
        // const {crudJobFilter,saveJobSearchParams} = actions 
        
        // if(i.checked){
        	 
        // 	 crudJobFilter({
        // 	 	key: 'jcts',
        // 	 	value: i.value,
        // 	 	operation: 'add'
        // 	 })
        	
        // }else{
        	
        // 	crudJobFilter({
        // 		key:'jcts',
        // 		value: i.value,
        // 		operation: 'remove'
        // 	})
        // } 

        saveJobSearchParams({
            jl: i,
            jq: '',
            // jcts: i.value,
            // canSetHistory: true,
            fetchJobs: true,
            
        })

        
       
    }
   


    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}

        const {props} = this
        const {search} =  props 
        // const {searchy} = search
        const {jq,jl} = search
       
        return(

            <div className="job__jobs--notfound">

                 <section className="job__jobs--notfound-header" style={{...specialStyling, ...textColorStyles}}> 
                     <p>The job search "{jq}" jobs in {jl} did not match any job</p>
                </section>    

                <section>

                    <div className="job__jobs--notfound-search">
                        <p style={textColorStyles}>Please modify your search and try again</p> 
                        <span style={textColorStylesAccent}>You may want to try:</span><span><Link style={{color: theme.colors.primary.base, fontWeight:"bolder", fontSize: 20}} onClick={(e)=>{this.handleSearch(jl,e)}}>Jobs in {jl} </Link></span>
                    </div>
                    <div className="job__jobs--notfound-hits">
                            <h2 style={textColorStylesAccent}>Search hints:</h2>

                            <section className="job__jobs--notfound-hits-container">
                                <ul style={textColorStyles}>

                                <li>Check for spelling errors and try again</li>
                                <li>Try with a single or different keyword</li>
                                <li>Try a less specific term for more results</li>
                                <li>Remove a filter and try again</li>
                                

                                </ul>
                            </section>
                    </div>
                    <strong className="clearfix" />
                </section>   

            </div>

      
        )
    }

}


export default withStyles(styles)(JobsNotFound)