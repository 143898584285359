
import * as types from './types'
import * as props from './props'



export default (state = props, action)=>{

    switch (action.type) {

        case types.SEND_QUERY_MESSAGE_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isActionSuccessful: false,
                formType: 'alert',
                message: '',
                
                
            }

        case types.SEND_QUERY_MESSAGE_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true,
                text: action.response.text || '',
                message: ''
               
              
               
                
            }

        case types.SEND_QUERY_MESSAGE_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
                formType: '',
                message: action.error
                
            }

        case types.UNSET_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                isActionSuccessful: false,
                message: ''
            }

      
        default:
        return state
    }


}