
import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import { ThemeContext } from '../../../context';
import Related from './blogrelated'




class BlogArticle extends Component{
    
    
    constructor(props) {
        super(props);
        this.state = {
            months: [
                'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
            ]
        }
      
    }

   
    getDate = (date)=>{

        const {state} = this 
        const {months} = state
        let setDate = new Date(date) 
        let getDate = setDate.getDate() 
        let formAtedDate = getDate < 10 ? `0${getDate}` : getDate

        return `${months[setDate.getMonth()]} ${formAtedDate}, ${setDate.getFullYear()}`

    }

    getPreviewText = (text)=>{

      
        const {props} = this 
        const {width} = props
        let prevText = text.substr(text.indexOf('>')+1,text.indexOf("</p>"))
        console.log('THE PREVTEXT')
        console.log(prevText)
        console.log(text.indexOf('>'))
        console.log(text.indexOf('</p>'))
        console.log(prevText.length)
        // prevText = prevText.length > 240 ? prevText.substr(0, prevText.length - (prevText.length - 252)) : prevText
     

        // return `${prevText}...`
        if(width <= 1200 ) return `${prevText.substr(0,100)}...`
        return `${prevText.substr(0,250)}...`

    }

    getHeaderGrafik = ()=>{

        return (
            <section className="topic__post--text-header-grafik">

                <figure className="topic__post--text-header-grafik-fig">
                        <img src="https://res.cloudinary.com/hsjvf6r09/image/upload/v1619882015/jobbri/blog/nvdljhpfva6b3lxqyvyi.jpg" className="topic__post--text-header-grafik-pik" />
                </figure>

            </section>
        )
    }

    getPosts = (bPost)=>{

       const {post,p,styles={}} = bPost
        // posts.array.forEach(element => {
            
        // });
        let companionElement = null
       

            
            const {slug,is_primary=0,title,body,created_at,image,author} = post 
            let link =  "link d-block"

           

                companionElement = ( <Link to={`/blog/post/${slug}`} className={link} k={p}>

                                        <div className="topic--post">

                                            <section className="topic--post-pik">

                                                <figure className="topic--post-pik-fig">
                                                    <img src={image} className="topic--post-pik-fig-pik" />
                                                </figure>

                                            </section>

                                            
                                            
                                            <section className="topic--post-prev">

                                                <h2 className="topic--post-prev-title" style={styles?.textColorStyles}>
                                                    {title}
                                                </h2>
                                                <small className="topic--post-prev-date">{this.getDate(created_at)}</small>
                                                <p className="topic--post-prev-author" style={{fontSize: '1.5rem',...styles?.textColorStyles}}>
                                                        By {author} Mashele
                                                </p>
                                                <p className="topic--post-prev-text" style={styles?.textColorStylesAccent}>{this.getPreviewText(body)}</p>
                                            </section>
                                            <strong className="clearfix" />

                                        </div>
                                    </Link>
                                )
         

            

        
        return companionElement



    }

   

    
      
    
    static contextType = ThemeContext
    
   render(){

       const {theme, themeName} = this.context 
       const boxesStyling = { backgroundColor: themeName == 'dark' 
       ? theme.colors.complimentary['accent-3'] 
       :  theme.colors.complimentary.base }
       const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
       const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
       const textColorStyles = {color:  theme.colors.complimentary.foreground}
       const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
       const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}

        const {props} = this 
        const {posts,openPage,openBlogPage,width,topic} = props 
        // const {slug,is_primary=0,body,created_at,image,author,post_topic_name} = post 
        const titleTextOne = "Blog" 
        const titleTextTwo = "Posts Displayed By Topic"
        // console.log('ARTICLE PROPS')
        // console.log(props)
        // let link = p === 1 ? "link d-block" : "link d-block topic--post-offset"
        

        return(

           

                
                <div  className="topic__post">


                        <section className="topic__post--text">

                        <div className="topic__post--text-header bx-raised" style={boxesStyling}>
                            {/* {width <= 900 ?  <h1 className="topic__post--text-header-meta-title">{title}</h1> : null} */}
                            {/* {width <= 900 ? this.getHeaderGrafik() : null} */}
                            <section className="topic__post--text-header-meta">

                           

                            
                                {/* {width > 900 ?  <h1 className="topic__post--text-header-meta-title">{title}</h1> : null} */}
                                <section className="topic__post--text-header-meta-author" title="Post author">


                                    <div>
                                      
                                    
                                        <section className="topic__blob">

                                        {/* <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#FA4D56" 
                                            d="M41.7,-50.7C50.6,-42.2,52.1,-25.8,54,-10.2C55.9,5.4,58.2,20.1,53.1,32.3C48,44.4,35.5,53.8,20.2,62.5C4.8,71.2,-13.5,79.2,-31.3,76.7C-49.2,74.3,-66.6,61.4,-70.7,45.2C-74.8,29,-65.6,9.5,-59,-7.3C-52.3,-24.2,-48.3,-38.4,-38.9,-46.8C-29.5,-55.2,-14.7,-57.7,0.8,-58.7C16.4,-59.7,32.8,-59.2,41.7,-50.7Z" 
                                            transform="translate(100 100)" /> 
                                           
                                        </svg> */}

                                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#FA4D56" 
                                            d="M50.9,-59.5C60.3,-52.8,58.3,-31.4,59.7,-12.2C61.2,7,66,24,62.3,41.2C58.6,58.5,46.4,76.1,30.7,79.8C15.1,83.5,-4,73.3,-21.7,64.6C-39.3,56,-55.7,48.8,-66.1,35.8C-76.5,22.7,-81,3.8,-79.1,-15.2C-77.3,-34.2,-69.1,-53.4,-54.8,-59.2C-40.6,-65,-20.3,-57.5,0.2,-57.8C20.8,-58.1,41.5,-66.2,50.9,-59.5Z" 
                                            transform="translate(100 100)" />
                                             
                                        </svg>

                                        <div className="topic__blob--content">

                                            <h1 className="topic__blob--content-title">
                                               <span className="topic__blob--content-title-1" style={textColorStyles}> {titleTextOne}</span> 
                                               <span className="topic__blob--content-title-2">{titleTextTwo}</span>
                                            </h1>
                                                <section className="topic__blob--content-crumb">

                                                    <p>
                                                        <span className="topic__blob--content-crumb-text">#{topic}</span>
                                                    </p>
                                                </section>

                                        </div>

                                        

                                        {/* <div class="topic__post--text-header-meta-author-card-rect  bx-raised">
                                        </div>
                                        <div class="topic__post--text-header-meta-author-card-rect topic__post--text-header-meta-author-card-rect-2 bx-raised">
                                        </div>
                                        <div class="topic__post--text-header-meta-author-card-rect topic__post--text-header-meta-author-card-rect-3 bx-raised">
                                            <img src="https://res.cloudinary.com/hsjvf6r09/image/upload/v1619088251/jobbri/blog/zhcdavi9bqntbnpmj2ax.png" className="topic__post--text-header-meta-author-pik" />
                                        </div> */}
                                        </section>

                                      

                                        

                                    </div>


                                    
                                

                                </section>

                                {/* <section className="topic__post--text-header-meta-crumb">
                                    <p>
                                        <span className="topic__post--text-header-meta-crumb-text">#{topic}</span>
                                    </p>
                                </section> */}
                                <strong className="clearfix" />


                            </section>

                            {/* {width > 900 ? this.getHeaderGrafik() : null} */}
                            {/* {this.getHeaderGrafik()} */}
                            
                            <strong className="clearfix" />
                        </div>



                            <div className="topic__post--text-wrap">

                            
                            
                                <section className="topic__post--text-content">

                                {posts.map((p,i)=>{

                                    return this.getPosts({post:p,p:i,styles:{textColorStyles, textColorStylesAccent}})
                                    })}
                                   
                                    
                                </section>

                                

                            </div>

                            {/* <div className="topic__post--text-social">
                                
                                <section className="topic__post--text-social-s-wrap">
                                    <button className="topic__post--text-social-share">Share</button>
                                </section>


                                <section className="topic__post--text-social-p-wrap">

                                    <Link to="https://www.facebook.com/"  className="topic__post--text-social-p-wrap-1 link">
                                        <img className="access__header--social-img" src="/img/social/facebook.png" />
                                    </Link >
                                    <Link to="https://www.facebook.com/"  className="topic__post--text-social-p-wrap-2 link">
                                        <img className="access__header--social-img" src="/img/social/twitter.png" />
                                    </Link >
                                    <Link to="https://www.facebook.com/" className="topic__post--text-social-p-wrap-3 link">
                                    <img className="access__header--social-img" src="/img/social/instagram.png" />
                                    </Link >
                                    <Link to="https://www.facebook.com/" className="topic__post--text-social-p-wrap-4 link">
                                    <img className="access__header--social-img" src="/img/social/googlePlus.png" />
                                    </Link >

                                </section>
                                */}
                        









                        </section> 





                </div>

                   
                  
               
               
                

           
        )
    }

}


export default BlogArticle