import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import Search from '@material-ui/icons/Search';
import Trash from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles'
import { ThemeContext } from '../../context';




const styles = {
    root: {
     
        color:"red",
        opacity: .5,
        fontSize: 20,
        '&:hover':{
            color: "red",
            opacity: 1
        }
        
      },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Empty extends Component{

     
    
    constructor(props){

        super(props)

    }


 
    // openLinkPage(url,e){

    //     // e.preveventDefault()

    //     console.log('THE URL VALUE')
    //     console.log(url)
    //     console.log(e) 
    //     if(!url) return 

    //     const {props} = this 

    //     //window.open(url)
        

    // }
   


    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}



        // const {classes,job,viewPage} = this.props 
        // const {bookMarkJob,openLinkPage,props} = this
        // const {job,classes} =  props 
        // const {url} = job 

        return(

                <section className="dashboard__savedjobs--empty bx-raised">

                    <p className="dashboard__savedjobs--empty-text" style={textColorStylesAccent}>You currently have no search history</p>
                   
                    <a href={`/jobs?jq=full-time`} target="_blank" className="dashboard__action-btns">

                           <span className="dashboard__action-btns-rect dashboard__action-btns-rect-2"></span>
                           <span className=" dashboard__action-btns-rect dashboard__action-btns-rect-3"></span>
                           <span className="dashboard__action-btns-rect dashboard__action-btns-rect-4" style={boxesStyling}>
                               <small className="dashboard__action-btns-rect-4-icon">
                               <i class="fa fa-search" aria-hidden="true"></i>

                               </small>
                               <small style={textColorStyles}>Find New Jobs</small>
                           </span>
               </a>
                    
                </section>
                
                
       


      
        )
    }

}


export default withStyles(styles)(Empty)