
import { showIO } from "./view"
export const milliSecondsToTime = (millis)=>{

    let seconds = (millis / 1000).toFixed()
    let minutes = (millis / (1000 * 60)).toFixed()
    let hours = (millis / (1000 * 60 * 60)).toFixed()
    let days = (millis / (1000 * 60 * 60 * 24)).toFixed() 

    if(seconds <= 60 ) return `${seconds} seconds ago`
    if(minutes <= 60 ) return `${minutes}m ago`
    if(hours <= 24 ) return `${hours}hrs ago` 
    if(days >= 30){

        let months = days / 30

        return `${months.toFixed()} months ago`
    }
   
    if(days <= 1) return `${days} day ago`
    return `${days} days ago`
}

export const formatDate = (date)=>{

    // const {milliSecondsToTime} = this
    let nw = new Date()
    let setDate = new Date(date) 

    let hours = setDate.getHours() 
    let minutes = setDate.getMinutes()
    // console.log('THE SETDATE')
    // console.log(setDate)
    // console.log(nw)
    // let diffInTime = nw.getTime() - setDate.getTime() 
    // // let diffInDays = diffInTime / (1000 * 3600 * 24) 

    // console.log('THE TIME IN DAYS')
    // console.log(this.milliSecondsToTime(diffInTime))

    // return this.milliSecondsToTime(diffInTime)
    minutes = minutes < 10 ? `0${minutes}` : minutes
    return `${hours}:${minutes}`




}

export const clearMessages = (self=null,e =null)=>{

    if(e) e.preventDefault()
    const {props} = self 
    const {actions} = props 
    const {clearMessages} = actions 
    clearMessages()

}


export const delayResponseMessage = (self=null,message,anyProps=null,delayTime=15000,delayTyping=3000,delay=true)=>{

       
    // console.log('SHOULD BE SETTING DELAY')
    const {props} = self
    const {actions} = props 
    const {saveChatText,updateTypingStatus,updateUserMessageIgnoreStatus} = actions 

    let newDelayTime = delayTime === 15000 ? getRandomDelay() : delayTime  
    // console.log('THE NEW DELAYTIME')
    // console.log(newDelayTime)

    if(!delay) return saveChatText(message)

    setTimeout(()=>{
        console.log('SETTING TYPING STATUS TO TRUE',message)
        updateTypingStatus(true)
        setTimeout(()=>{

            // console.log('THE TIMEOUT RUNS')
            // console.log(this.saveChat)
            // this.saveChat(null,true)
            updateTypingStatus(false)
            updateUserMessageIgnoreStatus(false)
            let dateUpdatedMessages =  message.map((m,i)=>{
    
                //if(anyProps) return {text: m.text,date: m.date = new Date(),isSending: true}
                return {text: m.text,date: m.date = new Date()}
            })
            // console.log('THE NEW MESSAGES')
            // console.log(dateUpdatedMessages)
            console.log('ANYPROPS;;;',anyProps)
            console.log('DATE UPDATED MESSAGE;;;',dateUpdatedMessages)
            if(anyProps) {saveChatText(dateUpdatedMessages,anyProps.q,anyProps.index); return scrollToBottom()}
            saveChatText(dateUpdatedMessages)
            scrollToBottom()
    
        },Math.floor(Math.random()*newDelayTime))

    },Math.floor(Math.random()*delayTyping))
    
  
}


export const getRandomDelay = ()=>{


     const delayTimes = [15000,10000,5000,3000, 12000]

     return randomize(delayTimes)
}

export const delayMessage = (self=null,funk=()=>{},misProps=null,q=null,delayTime=8000,delayTyping=0)=>{

    
    // console.log('SHOULD BE SETTING DELAY')
    // console.log("the SET DELAY TIME")
    // console.log(delayTime)
    const {props} = self
    const {actions} = props 
    const {updateTypingStatus,updateUserMessageIgnoreStatus} = actions
    let newDelayTime = delayTime

    setTimeout(()=>{

        updateTypingStatus(true)
        setTimeout(()=>{

            // console.log('THE TIMEOUT RUNS')
            // console.log(funk)
            // console.log(misProps)
            updateTypingStatus(false)
            updateUserMessageIgnoreStatus(false)
            if(misProps) {funk(self,{...misProps}); return scrollToBottom()}
            funk(self,q)
            scrollToBottom()
        },Math.floor(Math.random()*newDelayTime))

    },delayTyping)
    
}

export const randomize = (dictionary)=>{

    let foundElement = dictionary[Math.floor(Math.random() * dictionary.length)]
    return foundElement
}

export const updateInput = (self=null,e=null)=>{

    e.preventDefault()
    let text = e.target.value
    console.log('THE VALUE')
    console.log(text)
    // console.log(e) 

    //if(text.trim() === '') return
    
    self.setState({...self.state,text: text,aliasText: ''})
}

export const updateAndSaveChat = (self=null,e=null,funk=()=>{},text='',optText='')=>{

    e.preventDefault()
   
    //let userText = optText.trim() ? optText : text
    self.setState({...self.state,text: text,aliasText: optText},()=>{
        console.log('THE OPTTEXT VALUE')
        console.log(optText)
        console.log(text)
        funk(self,text)
    })
   
}

export const manipulateProfilePersonalUpdate = (self=null,e=null,text='',funk=()=>{})=>{

    if(e) e.preventDefault()
    
    const {props} = self 
    // const {text} = state 
    const {humanoid,actions} = props 
    const {questionas,profileQuestions} = humanoid 
    const {setProfileDynamicQuestion} = actions 
    let newQuestionas = {...questionas}
    const {personal} = newQuestionas 
    const {questions} = personal 


    if(questions.length > 0){

   
      for(let k=0; k < questions.length; k++){

        if(questions[k][text]) break; 
        if(k === questions.length - 1){

            const toSetQuestion = questions[text] 
            questions.push(toSetQuestion)
            newQuestionas = {...newQuestionas,personal: {questions: [...questions]}}
            setProfileDynamicQuestion(newQuestionas)
        }
     }


    }else{

        const toSetQuestion = questions[text] 
        questions.push(toSetQuestion)
        newQuestionas = {...newQuestionas,personal: {questions: [...questions]}}
        setProfileDynamicQuestion(newQuestionas)

    }
   
}

export const manipulateProfileFeature = (self=null,e=null,funk=()=>{},gIO,text='',optText='',feature='')=>{

    
    
    const {props} = self 
    const {actions,userProfile} = props 
    const {setCurrentProfileFeature} = actions 
    const {profile} = userProfile 

    const profileFeature = profile[feature] 

    setCurrentProfileFeature(feature)
    profileFeature.length > 0 ? funk(self,e,gIO,text,optText) : funk(self,e,gIO,'profileitems',optText)
   
}
export const profileFeatureEditItems = (self=null,e=null,funk=()=>{},gIO,text='',optText='')=>{

    
    
    const {props} = self 
    const {actions,userProfile,humanoid} = props 
    const {currentProfileFeature,questionas} = humanoid
    const {profile} = userProfile 
    const {setUpdateType,updateProfileItems} = actions 
    const {profileitems} = questionas 

    // console.log('THE CURRENT PROFILE FEATURE')
    // console.log(currentProfileFeature)
    // console.log(profile[currentProfileFeature])
    updateProfileItems(emptyProfileItems(profileitems))
    setUpdateType('edition')
    const profileFeature = profile[currentProfileFeature] 
    profileFeature.length === 1 ? funk(self,e,gIO,'askeditkeys',optText) : funk(self,e,gIO,text,optText)
   
}

export const profileFeatureAddItems = (self=null,e=null,funk=()=>{},gIO,text='',optText='')=>{

    
    
    const {props} = self 
    const {actions,userProfile,humanoid} = props 
    const {questionas,profileQuestions,currentProfileFeature} = humanoid 
    const {updateProfileItems,setUpdateType} = actions 
    const {profile} = userProfile 
    const profileFeature = profile[currentProfileFeature] 

    const {profileitems} = questionas 
    // const {questions} = profileitems 
    const profileQuestionsFeature = profileQuestions[currentProfileFeature] 
    const {add} = profileQuestionsFeature 

    console.log('THE ADD QUESTIONS')
    console.log(add)


    if(profileFeature.length === 3){
        funk(self,e,gIO,'addlimitreached',optText)
    }else{

    
            console.log('THE SYSTEM IS ADDING ADD UPDATE QUESTIONS')
            updateProfileItems(emptyProfileItems(profileitems))
            setUpdateType('addition')
            let newProfileItems = {...profileitems} 
            newProfileItems.questions = [...add]
            updateProfileItems(newProfileItems)
            setTimeout(()=>{
                funk(self,e,gIO,text,optText)
            },50)
            
    
    

    }
    


    //funk(self,e,gIO,text,optText)


    // const profileFeature = profile[currentProfileFeature] 
    // profileFeature.length === 3 ? funk(self,e,gIO,'addlimitreached',optText) : funk(self,e,gIO,text,optText)
   
}

export const profileFeatureDeleteItems = (self=null,e=null,funk=()=>{},gIO,text='',optText='')=>{

    
    
    const {props} = self 
    const {actions} = props 
    const {questionas} = humanoid
    const {profileitems} = questionas
    const {setUpdateType,updateProfileItems} = actions
    updateProfileItems(emptyProfileItems(profileitems))
    setUpdateType('deletion')
    funk(self,e,gIO,text,optText)
   
}
export const profileFeatureDeleteRecord = (self=null,e=null,funk=()=>{},gIO,text='',optText='')=>{

    
    
    const {props} = self 
    const {actions,userProfile,humanoid} = props 
    const {currentProfileFeature} = humanoid
    const {profile} = userProfile 

    funk(self,e,gIO,text,optText)

    // console.log('THE CURRENT PROFILE FEATURE')
    // console.log(currentProfileFeature)
    // console.log(profile[currentProfileFeature])


    // const profileFeature = profile[currentProfileFeature] 
    // profileFeature.length === 1 ? funk(self,e,gIO,'profileitems',optText) : funk(self,e,gIO,text,optText)
   
}


export const profileFeatureEditItemKeys = (self=null,e=null,funk=()=>{},gIO,text='',optText='',recordID=-1)=>{

    
    
    const {props} = self 
    const {actions,userProfile,humanoid} = props 
    const {currentProfileFeature} = humanoid
    const {setCurrentProfileFeatureRecord} = actions 
    const {profile} = userProfile 

    const profileFeature = profile[currentProfileFeature] 

    for(let i=0; i < profileFeature.length; i++){

        if(profileFeature[i].id === recordID){

            setCurrentProfileFeatureRecord(profileFeature[i])
            funk(self,e,gIO,text,optText) 
            break;
        }
    }
   
   
}


export const saveEditItem = (self=null,e=null,propertyID)=>{

    e.preventDefault()
    
    
    const {props} = self 
    const {actions,userProfile,humanoid} = props 
    const {updateProfileItems} = actions 
    const {profile} = userProfile 
    const {questionas,profileQuestions,currentProfileFeature} = humanoid 
    const {profileitems} = questionas 
    // const {questions} = profileitems 
    const profileQuestionsFeature = profileQuestions[currentProfileFeature] 
    const {edit} = profileQuestionsFeature 

    for(let ip=0; ip < edit.length; ip++){

        if(edit[ip].key === propertyID){

            let newProfileItems = {...profileitems} 
            newProfileItems.questions = [...newProfileItems.questions,edit[ip]]
            updateProfileItems(newProfileItems)
            break;
        }
    }

   
}

export const loadContextItems = (self=null,e=null,funk=()=>{},gIO,text='',optText='')=>{

    
    
    e.preventDefault()
    
    
    const {props} = self 
    const {actions,userProfile,humanoid} = props 
    const {updateProfileItems} = actions 
    const {profile} = userProfile 
    const {questionas,profileQuestions,currentProfileFeature} = humanoid 
    const {profileitems} = questionas 
    // const {questions} = profileitems 
    const profileQuestionsFeature = profileQuestions[currentProfileFeature] 
    const {edit} = profileQuestionsFeature 

    


    if(text.trim() === 'all'){

        let newProfileItems = {...profileitems} 
        newProfileItems.questions = [...newProfileItems.questions,...edit]
        updateProfileItems(newProfileItems)
        funk(self,e,gIO,text,optText)

    }else{

        funk(self,e,gIO,text,optText)
    }

    // setCurrentProfileFeature(feature)
    // profileFeature.length > 0 ? funk(self,e,gIO,text,optText) : funk(self,e,gIO,'profileitems',optText)
   
}

export const completeFeatureEdit = (self=null,e=null,funk=()=>{},gIO,text='',optText='',answer='')=>{


    const {props} = self 
    const {humanoid} = props 
    const {currentQuestionr} = humanoid 

    if(optText.trim() === 'yes'){

        switch(currentQuestionr){

            case 'editrecordcomplete':{

                funk(self,e,gIO,'askedit',text,optText)   
            }
            break;
            case 'editfeaturecomplete':{

                funk(self,e,gIO,'profileask',text,optText)   
            }
            break;
            default: funk(self,e,gIO,'profile',text,optText)
        }


    }else{

        switch(currentQuestionr){

            case 'editrecordcomplete':{

                funk(self,e,gIO,'editfeaturecomplete',text,optText)   
            }
            break;
            case 'editfeaturecomplete':{

                funk(self,e,gIO,'editprofilecomplete',text,optText)   
            }
            break;
            default: funk(self,e,gIO,'complete',text,optText)
        }

    }

    

    
}


export const getFiller = (self,fillerText)=>{


   
    console.log('THE GETFILLER FUNCTION RECEIVES A REQUEST')
    const {props} = self 
    const {humanoid} = props 
    const {fillers} = humanoid 

    if(!fillers) return fillerText 
    if(fillerText.indexOf('|') < 0) return fillerText 

    let splitFillerText = fillerText.split('|') 
    let fillerID = splitFillerText[1]

    if(fillers[fillerID]) return fillerText.replace(/\[+([^\][]+)]+/g,filler[fillerID]) 
    //replace(/|?|/,fillers[fillerID])
    return fillerText
   
    

    
}


export const scrollToBottom = ()=>{

    let scrollEl = document.getElementById('scroll-to')

    if(scrollEl) scrollEl.scrollIntoView({behavior: 'smooth'})
    
}


export const emptyProfileItems = (profileitems)=>{


    let newProfileItems = {...profileitems} 
    newProfileItems.questions = []
    return newProfileItems

}

export const setHumanoidProcessMesg = (self,isProcessing)=>{

    showIO(self,{responseText: 'Processing your data',processor:isProcessing}) 


}


