
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE' 

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE' 


export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE' 

export const CAPTCHA_VERIFY_REQUEST = 'CAPTCHA_VERIFY_REQUEST'
export const CAPTCHA_VERIFY_SUCCESS = 'CAPTCHA_VERIFY_SUCCESS'
export const CAPTCHA_VERIFY_FAILURE = 'CAPTCHA_VERIFY_FAILURE'


export const SEND_USER_VERIFY_ACCOUNT_REQUEST = "SEND_USER_VERIFY_ACCOUNT_REQUEST"
export const SEND_USER_VERIFY_ACCOUNT_SUCCESS = "SEND_USER_VERIFY_ACCOUNT_SUCCESS"
export const SEND_USER_VERIFY_ACCOUNT_FAILURE = "SEND_USER_VERIFY_ACCOUNT_FAILURE"



export const REMOVE_SHOWN_MESSAGE = 'REMOVE_SHOWN_MESSAGE'



