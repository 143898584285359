import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import Cuport from './cuports'
import CuLanguages from './culanguages'
import CuSkills from './cuskills'
import { ThemeContext } from '../../context'





class Curated extends Component{
    
    constructor(){

        super()
   

        
    }





    
    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


       
       const {props} = this 
       
       const {userProfile} =  props 
       const {profile} = userProfile
       const {portfolio=[],language=[],skill=[]} = profile

        return(

           
    
            <div className="resume__curated" >

                 
                 <h2 className="resume__curated--title">
                     <span className="resume__curated--title-circle"></span>
                     <span className="resume__curated--title-text" style={textColorStylesAccent}>More Information About Me</span>
                 </h2>

                 <div className="resume__curated-rect" style={boxesStyling}>

                 <CuLanguages language={language}  />

                     
                     <section className="resume__curated--card resume__curated--card-2">
                    
                     <CuSkills skill={skill}  />

                     

                     </section>

                     <Cuport portfolio={portfolio}  />
                     
                     
                    
                    
               

                </div>

               
                



            </div>

          
        )
    }


}


export default Curated


