
import React, {Component} from 'react'
import { ThemeContext } from '../context';

class ClientErrorHandler extends Component{
    
    
    constructor(props) {
        super(props);
      
    }

    postError = ()=>{

        return false

    }


      
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}


        const {props,postError} = this 
        const {errorMeta={}} = props 
        const {
            postErrorAction = postError,
            actionColor='warn',
            header='OOPS!',
            headerText='Something went wrong!',
            errorText= 'We have unfortunately encountered an error processing a request',
            buttonText= 'Please Try Again'} = errorMeta 
        const errorClasses = actionColor === 'warn'  
                                            ?  'blog__error--card-rect blog__error--card-rect-warn'
                                            : 'blog__error--card-rect blog__error--card-rect-error'

        return(

           
            <article className="blog blog-error">
                
                <div  className="blog__error">

                   <section className="blog__error--container">

                    
                                <div className={`${errorClasses}  bx-raised`}>
                                </div>
                                <div className={`${errorClasses} blog__error--card-rect-2 bx-raised`}>
                                </div>
                                <div className="blog__error--card-rect blog__error--card-rect-3 bx-raised" style={boxesStyling}>

                                    <p className="blog__error--card-rect-3-header">{header}</p>
                                    <p className="blog__error--card-rect-3-sub-header" style={textColorStyles}>{headerText}</p> 
                                    <p className="blog__error--card-rect-3-text" style={textColorStyles}>{errorText}</p>

                                    

                                    <button class="blog__error--card-btn" onClick={()=> postErrorAction()}>
                                        <span class="blog__error--card-btn-rect"></span>
                                        <span class="blog__error--card-btn-rect-2">
                                            <small>{buttonText}</small>
                                        </span>
                                    </button>

                                </div>

                          

                   </section> 

                  
                </div>

                </article> 
               
                

           
        )
    }

}

export default ClientErrorHandler