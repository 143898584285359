
import * as types from './types'
import * as props from './props'





export default (state = props, action)=>{

    switch (action.type) {


        case types.SEND_LAUNCH_REQUEST:

            return  {

                ...state,
                isFetching: true,
                message: '',
                isActionSuccessful: false
               
            }

        case types.SEND_LAUNCH_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true,
                text: action.response.text || ''
               
              
               
                
            }
     
        case types.SEND_LAUNCH_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            }
           
        case types.UNSET_MESSAGE_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                message: '',
            }

        
        case types.UNSET_NOTIFICATION_DISPLAY:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
           
                
            } 
    
    
        default:
        return state
    }


}



