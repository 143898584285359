
import React, {Component} from 'react' 






class Bio extends  Component{


    constructor(props){

        super()

    }

    
    
    
   

    render(){

        
         const {props} = this 
        //  const {bioText} = state
         const {allowEdit} = props 
      


        return(

            <section style={{padding: '50px'}} id={'experience'}>

                <div className="resume--add-info">

                    <div class="resume--add-info-rect resume--add-info-rect-1 bx-raised">
                    </div>
                    <div class="resume--add-info-rect resume--add-info-rect-2 bx-raised">
                    </div>
                    <div class="resume--add-info-rect resume--add-info-rect-3 bx-raised"></div>
                    <div class="resume--add-info-rect resume--add-info-rect-4 bx-raised" onClick={allowEdit}>

                            <p>
                                
                                <small style={{fontSize: '20px',display: 'block',textAlign: 'center'}}>
                                <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                                </i>
                                
                                </small>
                                <small style={{fontSize: '15px',display: 'block',textAlign: 'center'}}>Add experience, employers love it.</small>
                            </p>
                    </div>

                </div>
            </section>


                    
        )
    }




}

export default Bio