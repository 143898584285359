
import React, {Component} from 'react'
import {Helmet} from 'react-helmet' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import * as userActions from '../UserProfile/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'
import ProgressBarLinear from '../ProgressBarLinear/component'
import Settings from './components/dashboard/settings' 
import CandidateModal from '../CandidateModal/component'
import CandJobAlerts from '../CandJobAlerts/component'

import DeletePopup from '../CanDelAlertPopr/component'
import DeleteButton from '../CanDelAlertPopr/button'

import ManagePopup from '../CandManAlertPopr/component'
import ManageButton from '../CandManAlertPopr/button'








class CandSettings extends Component{


    constructor(props){

        super(props)
    }


    componentDidMount(){

        // const {candJobAlertsManage,actions} = this.props
        // const {candJobAlertsList} = candJobAlertsManage
        // const {alertID} = candJobAlertsList
        console.log('THE COMONENTDIDMOUNT PROFILE')
        // const {actions} = this.props 
        const {props} = this 
        const {userProfile,actions} = props 
        const {push} = actions
        const {profile} = userProfile 
        const {personal} = profile 
        const {id,is_pending} = personal 
        
        

        if(is_pending === 1) return push('/account-verification')
        // actions.fetchUserAlertsSubscriptions({ID:id})
        
    }



    
    componentWillUnmount(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message=''} = userProfile 
        const {removeNotification,removeMessage} = actions


        if(isActionSuccessful) {
            removeNotification() 
            
        }
        
        if(message.trim()){
            removeMessage() 
            
        }

     
        
    }
   


    // componentDidUpdate(){

    //     const {actions,userProfile} = this.props 
    //     const {isProfile,isFetching} = userProfile 
    //     const {unsetIsProfile} = actions

    //     console.log('SETTINGS COMPONENT DID UPDATE::CANDSETTINGS') 
    //     console.log(isProfile)

    //     if(isProfile && isFetching === false){

    //         console.log('isProfile is true, about to unset it') 
    //         console.log(this.name)
    //         unsetIsProfile()
    //     }
    // }

    // shouldComponentUpdate(prevProps,nextProps){

    //     if(prevProps.isActionSuccessful && nextProps.isActionSuccessful === false ){

    //         return false
    //     }else{

    //         return true
    //     }


    // }



    render(){


       
        // const {actions,candSettings,userProfile} = this.props 
        const {userProfile,actions} = this.props 
        // const {profile,isProfile} = userProfile 
        // const {alertsEmails = null} = candSettings
        // console.log('IS THIS A PROFILE')
        // console.log(isProfile)


        // const {isFetching,isProgressBar,isActionSuccessful,launcher} = isProfile ? userProfile : candSettings 
        //const {profile,isFetching,isProgressBar,isActionSuccessful} = candSettings  

        // if(isFetching && isProgressBar === false) return  <article className="dashboard__candsettings">
        //     <Helmet>
        //             <title>Jobbri - Settings</title>
        //     </Helmet>
        //     <ProgressBarCircular /></article>;

        // console.log('THE CANDIDATE SETTINGS')
        // console.log(candSettings) 
        // console.log(isActionSuccessful)

        return(

            <article className="dashboard__candsettings">

                <Helmet>
                    <title>Jobbri - Settings</title>
                </Helmet>
                  
                  {/* <Settings style={isProgressBar ? {pointerEvents:"none",opacity:"0.4"} : {}} profile={profile} alertsEmails={alertsEmails} isActionSuccessful={isActionSuccessful} isFetching={isFetching} isProgressBar={isProgressBar} launcher={launcher} actions={actions} /> */}
                  <Settings  userProfile={userProfile} actions={actions} modal={CandidateModal} candJobAlerts={CandJobAlerts} deletePopr={DeletePopup} managePopr={ManagePopup} deleteButton={DeleteButton} manageButton={ManageButton} />
                  {/* <ProgressBarCircular /> */}
                  {/* <ProgressBarCircular />  */}
                  {/* <ProgressBarLinear /> */}
                 
            </article>
        )
    }




}



const mapStateToProps = (state)=>{

    const {userProfile} = state

    return{
        userProfile: userProfile
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions,...userActions},dispatch)
    }
}

export default  connect(mapStateToProps,mapDispachToProps)(CandSettings)