
import React, {Component} from 'react' 
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import NotfoundHeader from './notfoundheader/notfoundheader'
import { ThemeContext } from '../context'





class NotFound404 extends Component{


    constructor(props){

        super(props)
    }

 

    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}

        return(

            <article className="grid-row pd-bottom-fd-xxx-sm notfound">
                  
                  <section>
                      <NotfoundHeader />
                  </section>

                 <section className="notfound__content">

                     <div className="notfound__ow">

                         <span className="notfound__ow--f-1">4</span>
                         <span className="notfound__ow--o">
                             <small className="notfound__ow--o-num" style={textColorStylesAccent}>0</small>
                             <small className="notfound__ow--o-text">Forbidden</small>
                         </span>
                         <span className="notfound__ow--f-2">3</span>

                     </div>
                     <div className="notfound__message">

                        <h3 className="notfound__message--title" style={textColorStyles}>Missing permissions</h3>

                        <p className="notfound__message--text" style={textColorStyles}>
                            You got to this page because the thing you were trying to reach is forbidden for some reason.

                        </p>

                        <Link to="/" className="link">
                            <button className="notfound__message--btn">Go Home</button>
                        </Link>
                     </div>
                 </section>

                 <strong className="clearfix" />

            </article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        login: {
            ...state.auth
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(NotFound404)