import React from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Button extends React.Component{

  constructor(props){
    super();

  }

 


  render(){

    const {openPopup} = this.props
    return(

      <p className="engagement__applicant" onClick={openPopup}>
      <span className="engagement__applicant-plus">+</span>
      <span className="engagement__applicant-text">Create Cv</span>
    </p>
      
    )
  }
}

export default withStyles(styles)(Button)