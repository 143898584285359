
import React, {Component} from 'react' 
import { ThemeContext } from '../../../context'
import Card from './card'





class AlertsList extends  Component{




    constructor(props){

        super(props)
    }

    // listJobs(list){

    //     // console.log('THE LIST FROM SAVED BODY')
    //     // console.log(list)
    //     return list.map((job,i)=>{

    //         // console.log('THE JOB TO THE CARD')
    //         // console.log(job)
    //         return <Card key={i}  job={job} />
    //     })
        
       

    // }

    viewPage(location){

        const {push} = this.props 

        console.log('THE VIEWPAGE LOCATION')
        console.log(location)
        push(location)

    }

    setAlertIdAndPush(alert){

        const {setAlertId} = this.props 
        setAlertId(alert)
        this.viewPage('/alerts/manage')
    }

   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


      
        const {props} = this
        const {userProfile,actions,modal,deletePopr,managePopr,deleteButton,manageButton} = props 
        const {profile} = userProfile 
        const {alerts} = profile 
       const {viewPage,setAlertIdAndPush} = this

        return(


               <div>

               
                    <section className="dashboard__searchhistory--i">

                        <div className="dashboard__searchhistory--i-box bx-raised-1 ">

                                <span className="dashboard__searchhistory--i-box-title" style={textColorStyles}>Added Job alerts</span>
                     
             
                        </div>


                    </section>

                    {

                        alerts.map((alert,i)=>{

                            
                                return <Card key={i}
                                            deletePopr={deletePopr} managePopr={managePopr} deleteButton={deleteButton} manageButton={manageButton}
                                            modal={modal} userProfile={userProfile} actions={actions}  alert={alert}   
                                             viewPage={viewPage.bind(this)} setAlertId={setAlertIdAndPush.bind(this)}/>
                            

                        })

                    }

                




           
                </div>

             
             
           
        )
    }




}

export default AlertsList