
import React, {Component} from 'react'
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import { toast } from 'react-toastify';
import * as actions from './actions'
import Mail from './components/mail/mail'
import Touch from './components/touch/touch'
import {trackPageView} from "../../utilities/googleGa"




class Contact extends Component{


    componentDidUpdate(){
        
        // console.log()
        // const {isActionSuccessful,launcher,isFetcing,actions} = this.props 
        // const  {removeNotification} = actions 
        // console.log('THE LAUNCHER INFOUPDATE::')
        // console.log(launcher)

        // if(isActionSuccessful && launcher === 'infoUpdate') {
        //     this.notify({message:'Profile info successfully updated',type: 'success',className: 'notify-success'}) 
        //     removeNotification()
        // }
        trackPageView()

        const {contact,actions} = this.props
        const {isActionSuccessful,message='',formType,text,launcher=''} = contact
        const {unsetNotificationDisplay} = actions
        // const {removeNotification,removeMessage} = actions 

        if(isActionSuccessful) {

            let msg = 'You have been successfully added to the notify list.'
            if(formType === 'alert') msg = text || 'Your information request has been sent.'
            this.notify({message:msg,type: 'success',className: 'notify-success'}) 
            unsetNotificationDisplay()
            // removeNotification() 
            
          
           
        }else if(message.trim() && launcher.trim() === 'footer'){

            this.notify({message: message,type: 'error',className: 'notify-error'}) 
            unsetNotificationDisplay()
            //removeMessage() 
            
        }

        


       
        
    }

    notify = (config) => {

            
        console.log('THE TOAST CONFIG')
        console.log(config)
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

    }




    render(){

        const {props} = this 
        const {actions,contact} = props

        return(

            <article className="grid-row pd-bottom-fd-xxx-sm">
                <Helmet>
                    <title>Jobbri - Contact Us</title>
                </Helmet>
                 <Mail contact={contact} actions={actions} />
                 <Touch />

            </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        contact:{
            ...state.contact
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(Contact)