
import React, {Component} from 'react'
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as userActions from '../UserProfile/actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import ProgressBarCircular from '../ProgressBarCircular/component'
import AlertsList from './components/alertslist/alertslist'
import Empty from './components/empty'

import CandidateModal from '../CandidateModal/component'

import DeletePopup from '../CanDelAlertPopr/component'
import DeleteButton from '../CanDelAlertPopr/button'

import ManagePopup from '../CandManAlertPopr/component'
import ManageButton from '../CandManAlertPopr/button'
import { ThemeContext } from '../context'






class CandJobAlertsList extends Component{


    constructor(props){

        super(props)
    }


    componentDidMount(){

      
        const {actions,userProfile} = this.props
        const {push} = actions
        const {profile} = userProfile 
        const {personal} = profile 
        const {id,is_pending} = personal 

        if(is_pending === 1) return push('/account-verification')
        //actions.fetchAlertsList({ID:id,catID:alertCurrentCategory}) 

        
    }

    componentWillUnmount(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message=''} = userProfile 
        const {removeNotification,removeMessage} = actions


        if(isActionSuccessful) {
            removeNotification() 
            
        }
        
        if(message.trim()){
            removeMessage() 
            
        }

     
        
    }

    
    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        const {actions,userProfile} = this.props
        const {profile} = userProfile 
        const {alerts} = profile 
        // const alerts = []
        // const {candJobAlertsListMod} = candJobAlertsList 
        // const {alertsList,isFetching} = candJobAlertsListMod 
        // const alertsList = []

        // if(isFetching) return  <article className="dashboard__candsettings">
        //      <Helmet>
        //             <title>Jobbri - Job Alerts List</title>
        //         </Helmet>
        //     <ProgressBarCircular /></article>;

        return(

            <article className="dashboard__candalertlist">
                 <Helmet>
                    <title>Jobbri - Job Alerts List</title>
                </Helmet>
                  
                  <div>

                    {
                     alerts.length > 0
                       ? <AlertsList userProfile={userProfile} actions={actions} modal={CandidateModal} deletePopr={DeletePopup} managePopr={ManagePopup} deleteButton={DeleteButton} manageButton={ManageButton}  />
                       : <section> 
                        <div style={{marginBottom: '5rem',width: '250px'}} className="dashboard__searchhistory--i-box bx-raised-1 ">

                            <span className="dashboard__searchhistory--i-box-title" style={textColorStyles}>Added Job Alerts</span>
            
                        </div>
                        <Empty />
                        </section>
                     }


                     

                  </div>
                  
                 
            </article>
        )
    }




}

const mapStateToProps = (state)=>{

    const {userProfile} = state

    return{
        userProfile
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...navigatorActions,...userActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(CandJobAlertsList)



