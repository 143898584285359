
export const FETCH_BLOG_POSTS_REQUEST = 'FETCH_BLOG_POSTS_REQUEST'
export const FETCH_BLOG_POSTS_SUCCESS = 'FETCH_BLOG_POSTS_SUCCESS'
export const FETCH_BLOG_POSTS_FAILURE = 'FETCH_BLOG_POSTS_FAILURE' 

export const FETCH_BLOG_POST_REQUEST = 'FETCH_BLOG_POST_REQUEST'
export const FETCH_BLOG_POST_SUCCESS = 'FETCH_BLOG_POST_SUCCESS'
export const FETCH_BLOG_POST_FAILURE = 'FETCH_BLOG_POST_FAILURE' 

export const FETCH_BLOG_ARCHIVE_REQUEST = 'FETCH_BLOG_ARCHIVE_REQUEST'
export const FETCH_BLOG_ARCHIVE_SUCCESS = 'FETCH_BLOG_ARCHIVE_SUCCESS'
export const FETCH_BLOG_ARCHIVE_FAILURE = 'FETCH_BLOG_ARCHIVE_FAILURE' 


export const UNSET_MESSAGE_NOTIFICATION_DISPLAY = "UNSET_MESSAGE_NOTIFICATION_DISPLAY"
export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"





