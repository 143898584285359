
import React,{Component} from 'react'
import { ThemeContext } from '../../context'
import Dash from './dash/dash' 



class Jobdash extends Component{
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        // const boxesStyling = { backgroundColor: themeName == 'dark' 
        // ? theme.colors.complimentary['accent-3'] 
        // :  theme.colors.complimentary.base }
        const boxesStyling = {backgroundColor: theme.colors.complimentary.base }
        const textColorStyles = {color: theme.colors.complimentary.foreground}


        return(

            <div className="grid-row">

                
                <div  className="home__jobdash--container">
                    <h2 className="home__jobdash--conv">More Convenience for you</h2>
                     <Dash actions={this.props.actions} textStyle={textColorStyles} styles={boxesStyling} />
                  
                 
                </div>
               
                

            </div>
        )
    }

}


export default Jobdash