
import React,{Component} from 'react'
import {BrowserRouter as Router, Link} from 'react-router-dom'
import { ThemeContext } from '../../context'

class Header extends Component{
    
    
    static contextType = ThemeContext
    
      render(){
  
          const {theme, themeName} = this.context 
          const boxesStyling = { backgroundColor: themeName == 'dark' 
          ? theme.colors.complimentary['accent-3'] 
          :  theme.colors.complimentary.base }
          const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
          const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
          const textColorStyles = {color:  theme.colors.complimentary.foreground}
          const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
          
          const specialStylingText =  themeName === "dark" ? {color: theme.colors.complimentary.foreground} : {}
  

        return(

           

                
                <div  className="blog__header--container">
                      
                    <section className="blog__header bx-shadow-dark" style={boxesStyling}>

                        <span className="blog__header--signup" style={textColorStyles}>Privacy Policy</span>
                        <span className="blog__header--signin" style={textColorStyles}>Policy</span>


                    </section>
                   
                </div>
               
                

           
        )
    }

}


export default Header