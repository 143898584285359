
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-textarea-autosize';
import Tooltip from '../tooltip'
import { ThemeContext } from '../../context';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

        const {bio} = props
      
        this.state = {
            textAreas: {

                bio:{

                    value: bio || '',
                    validation: {
                        isRequiredFormat:{

                            minLetters: 100,
                            maxLetters: 500
                
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'top'
                    },
                    config: {
                        
                        name: "bio",
                        // type: 'text',
                        // placeholder: '...type description',
                        // disabled: false
                        
                    }
                    
               }

            },
            // baseKeysLen: 3,
            // keys: ['name','description','link'],
            // validActionsReached: false,
            // showTextBoxInput: false,
            // textBoxID: ''
            
        };
    }

    
    updator = (upControl)=>{
        
            console.log('THE UPDATE CONTROL')
            console.log(upControl)
            let newForm ={...this.state.textAreas} 
            let updateControl = {...newForm[upControl.id]}
            updateControl.value = upControl.e.target.value 
            //console.log(updateControl)

        
            
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 

                 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1]

                    if(!updateControl.isValid){

                        for(let k in newForm){ 


                            if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                                newForm[k].config.disabled = true
                            }
                        }
                    }else{

                        for(let k in newForm){ 

                            if(newForm[k].config.disabled === true){
                                newForm[k].config.disabled = false
                            }
                        }

                    }
                    
                }

            }
        
            updateControl.isTouched = true 
            console.log('THE UPDATECONTROL')
            console.log(updateControl)
            newForm[upControl.id] = updateControl 



          

                this.setState({
                    textAreas: newForm,
                    
                    
                },()=>{

                    console.log('TEH UPDATED FORM')
                    console.log(this.state.textAreas)
                })
    

            
           

    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.maxLetters && (value.length > isRequiredFormat.maxLetters) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }
   

    submitForm = (e)=>{
        
        e.preventDefault() 
        
       
        
        
        const {actions,beginSubmit,userProfile} = this.props
        const {profile} = userProfile 
        const {personal} = profile
        const {id} = personal
        const {updateResumeItem} = actions 
        let {textAreas} = this.state 
        let {notify} = this

        
        let formInput = beginSubmit()
        const {input=null,isInputValid,message=''} = formInput 
        console.log('THE FORMINPUT')
        console.log(formInput)
        if(!isInputValid) return notify({message:message,type: 'warning',className: 'notify-warn'})
        
        // let allRequiredAddKeys = Object.keys(textAreas).length
        console.log('THE TEXTAREA')
        console.log(textAreas)
        let validatedAndFetched = this.validateAndFetchInput([textAreas])
        const {isValid,submittData} = validatedAndFetched 
        console.log('THE VAIDATED')
        console.log(validatedAndFetched)
      
        if(!isValid) return notify({message:'Bio must be valid before saving',type: 'warning',className: 'notify-warn'})
        if(Object.keys(submittData).length === 0 && !input) return notify({message:'Personal information must be updated before saving',type: 'warning',className: 'notify-warn'})
       
        if(Object.keys(submittData).length > 0 && input) return updateResumeItem({personal:{...submittData,...input}},id) 
        if(Object.keys(submittData).length > 0) return updateResumeItem({personal: {...submittData}},id) 
        return updateResumeItem({personal:{...input}},id)
        
           
        //updateResumeItem({personal: {...submittData,...input,id:id}},ID)
        
        
       
        
        
    
    }

    validateAndFetchInput = (forms)=>{


        let submittData = {} 
        let formIsValid = true 
        let shouldOuterBreak = false

        for(let it=0; it < forms.length; it++){
            
            let form = forms[it]

            for(let k in form){
            
                // console.log('THE SUBMIT CONTROL')
                // console.log(form[k])
                if(form[k].validation && form[k].validation.isRequiredFormat){
    
                    console.log('THE CONTROL REQUIRES VALIDATION')
                    console.log(form[k])
                    console.log(form[k].isTouched)
    
                    if(form[k].isTouched && form[k].isValid === false){
    
                        // console.log('CONTROL:',k, ' is not valid')
                        formIsValid = false 
                        shouldOuterBreak = true
                        
              
        
                        //break
    
    
                    }else if(form[k].isTouched){
     
                        submittData[k] = form[k].value
    
                    }
    
                }else{
    
                    if(form[k].value.trim() !== ''){
    
                        submittData[k] = form[k].value
    
                    }
                   
    
                }
              
            
            } 

        }

        return {isValid: formIsValid,submittData: submittData}

       

            
    }
    

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        // const {props} = this 
        // const {actions,auth} = props 
        // const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        // const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        // if(errMessage !== ''){

        //     removeShownMessage()

        // }
        
        
    }

    count = (event,id)=>{

        console.log('I AM CHANGING')
        console.log(event)
        console.log(event.target)
        console.log(event.target.value)
        console.log(event.target.value.trim().length)

        let newTextareas ={...this.state.textAreas} 
        let updateControl = {...newTextareas[id]}
        updateControl.value = event.target.value 

        let validData = this.controlValidate(updateControl) 
        console.log('THE VALIDATE TEXTBOX RESPONSE')
        console.log(validData)
                    
        updateControl.isValid = validData[0] 
        updateControl.validationMessage = validData[1]
        updateControl.isTouched = true

        newTextareas[id] = updateControl 

        this.setState({

            textAreas: newTextareas
                    
        })
    

            
        


    }
    
        
    

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        
         const {props,state} = this 
         const {textAreas} = state
         const {isEdit,allowEdit,bio=''} = props 
         const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
         console.log('TEH TEXTAREAS')
         console.log(textAreas)
         console.log(!textAreas.bio.isValid)
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    <section className="resume__about--bio-edit" style={editContStyles} id={'bio'}>

                        {!isEdit && bio.trim() !== ''
                        ?   <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={allowEdit}>
                            <i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                            </small>
                        : null
                        }

                        {
                        isEdit 
                        
                        // ?  <FormControl 
                                    
                        //     styles={{child:'resume__about--bio-text resume__about--bio-text-edit',error: ''}}
                        //     id = 'bio'
                        //     controlData={this.state.form.bio}
                        //     change={(control)=>this.updator(control)}

                            

                        //     >{bio}</FormControl>
                        // 
                        ? <div>
                            <TextareaAutosize style={{display: 'block',marginBottom: '10px',...boxesStyling,...textColorStyles}} className="resume__about--bio-text resume__about--bio-text-edit" defaultValue={textAreas.bio.value} onChange={(e)=> this.count(e,'bio')} />
                            {!textAreas.bio.isValid && textAreas.bio.validationMessage.trim()
                                ? <Tooltip message={textAreas.bio.validationMessage} position={'top'} active={true} /> 
                                : null
                            }
                            <span className="textarea-counter">
                                <small className="textarea-counter-count">{textAreas.bio.value.length}</small>
                                <small className="textarea-counter-sep" style={textColorStyles}>Of</small>
                                <small className="textarea-counter-total">{textAreas.bio.validation.isRequiredFormat.maxLetters}</small>
                            </span>
                          </div>
                        
                            
                        : bio.trim() === '' 
                        ?  <p className="resume__about--bio-text" style={{cursor: 'pointer',}} onClick={allowEdit}>
                                <small style={{fontSize: '20px',display: 'block',textAlign: 'center'}}>
                                <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                                </i>
                                
                                </small>
                                <small style={{fontSize: '15px',display: 'block',textAlign: 'center'}}>Add bio for improved profile</small>
                            </p>
                        : <p className="resume__about--bio-text" style={{...boxesStyling,...textColorStylesAccent}}>
                            {bio}
                        </p>}

                            

                        { isEdit 
                            ?<div className="resume__about--bio-edit-sub-btn">

                                <button className="resume--edit-btn " onClick={(e)=>this.submitForm(e)}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Save</small></span>
                                </button>
                                <button className="resume--edit-btn " onClick={allowEdit}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Cancel</small></span>
                                </button>
                            </div>
                        : null
                        }
                        
                            

                    </section>

                    
               

             
             
           
        )
    }




}

export default Bio