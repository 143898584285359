
import React, { Component } from 'react' 
import { isActionSuccessful } from '../Humanoid/props'
import { toast } from 'react-toastify';






class ActionConfirm extends Component{
 
    componentWillUnmount(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message=''} = userProfile 
        const {removeNotification,removeMessage} = actions

        console.log('THE COMPONENT WILL UNMOUNT')


        if(isActionSuccessful) {
            removeNotification() 
            
        }
        
        if(message.trim()){
            removeMessage() 
            
        }

     
        
    }


  

   
    render(){


     const {props} = this 
     const {componentProps} = props 
     const {successText} = componentProps
    
       
       

        return(

           
    
           
                 
                <ul className="upload-cv-success-list">

                        <li>  
                            
                            <span style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                                    {successText}
                             </span> 
                    </li>              
                            
                                
                            

 

    

                        
                 
                        
                </ul>
               




          

          
        )
    }


}


export default ActionConfirm


