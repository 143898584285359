
export const SEND_ALERT_SUBSCRIBER_REQUEST = "SEND_ALERT_SUBSCRIBER_REQUEST"
export const SEND_ALERT_SUBSCRIBER_SUCCESS = "SEND_ALERT_SUBSCRIBER_SUCCESS"
export const SEND_ALERT_SUBSCRIBER_FAILURE = "SEND_ALERT_SUBSCRIBER_FAILURE"
export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"
// export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"





