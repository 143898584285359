// import {reduxForm} from './reduxform'

// export const jobs = {posts: [],categories: [],locations: []}
// export const message = ''
// export const isFetching = false 
// export const listFetched = false

// export const search = {
                        
//                         keyword: 'Office',location: 'Malamulele',category: '',
//                         pageLimit: 15,skip:0,currentPage:1,totalPages:0,totalJobs:0,fetchJobs: true,initLoad: true,
//                         filters: {categories: [],datePosted: '',jobType:[],location: '',salaryRange:[],experience:[],gender:[],
//                                    qualification:[],careerLevel:[]}
//                     }


export const jobs = {posts: [],categories: [],locations: []} 
export const suggestions = ['admin','beginner','general worker','no experience']
export const message = ''
export const isFetching = false 
export const listFetched = false 
export const isSkipRender = false 
export const setHistory= false
// export const form = reduxForm



export const search = {
                        jq: 'all', jl:'South Africa',jdp: {numValue: 0, value: 'all'},
                        sort: 'relevance',
                        pageLimit: 15,skip:0,currentPage:1,
                        totalPages:0,totalJobs:0,fetchJobs: false,initLoad: false,
                        resetFilter: false,
                        canSetHistory: false,
                        // filters: {
                        //            categories: [],datePosted: '',
                        //            jobType:[],location: '',salaryRange:[],
                        //            experience:[],gender:[], qualification:[],careerLevel:[]
                        // }
                       
                    } 
                    
