
import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { ThemeContext } from '../../../context';

const styles = {
    root: {
     
    //   color:"#ff6633",
      fontSize: 18,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };

//   const filterStyles = {
//     transition: 'opacity 5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
//     opacity: 1
//   }


class Phone extends Component{
    
    constructor(){

        super()
        this.state ={
            isFilterOpened: false,
            isDelayed: false
        }

        
    }

    // componentDidMount(){

    //     setTimeout(()=>{

    //         setInterval(()=>{
    //             const {state} = this 
    //             const {isFilterOpened} = state 

    //             if(isFilterOpened === true){

    //                 this.setState({...this.state,isFilterOpened: false,isDelayed: false})
                    
                    

    //             }else{
    //                 this.setState({...this.state,isFilterOpened: !isFilterOpened})
    //             }
    //         },10000)

    //         const {state} = this 
    //         const {isFilterOpened} = state 
    //         this.setState({...this.state,isFilterOpened: !isFilterOpened})
        
            
    //     },5000)
    // }


    delayMenu = ()=> {

        setTimeout(()=>{

            const {state} = this 
            const {isDelayed} = state
            this.setState({...this.state,isDelayed: !isDelayed})
        },1500)
    }

    
    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    


    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: theme.colors.complimentary.base }
        const boxesStylingAccent = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const textColorStyles = {color: theme.colors.complimentary.foreground}
       const phoneAfter = { content: '""',
       position: "absolute",
       width: "54px",
       height: "4px",
       transform: "translate(-27px)",
       border: "solid 1px #f7f4f4",
       top: "13px",
       left: "50%",
       borderRadius: "5px",
       background: "#111010"}
       let logo = themeName === "dark" ? '/img/jo_white.png' : '/img/jobbrilogo.png'



       const {state} = this
       const {isFilterOpened,isDelayed} = state
       const pointerClasses = isFilterOpened ? "footer__phone--content-menu-pointer footer__phone--content-menu-pointer-an": "footer__phone--content-menu-pointer"

        return(

           
    
            <div className="footer__phone" style={{...boxesStyling}}>

                <section className="footer__phone--screen" style={{"::after":{...phoneAfter}}}>

                   

                    <div className="footer__phone--content">

                        <section className="footer__phone--content-header" style={boxesStyling}>

                        <span className="footer__phone--content-primary">
                            <small className="footer__phone--content-primary-bar footer__phone--content-primary-bar-1"></small>
                            <small className="footer__phone--content-primary-bar footer__phone--content-primary-bar-2"></small>
                            <small className="footer__phone--content-primary-bar footer__phone--content-primary-bar-3"></small>
                        </span>
                        <span  className="footer__phone--content-brand"><img src={logo} width="60" /></span>
                        <span  className="footer__phone--content-secondary">

                        <small  className="footer__phone--content-secondary-person">
                            <Icon>
                                <PersonIcon style={styles.root} className="fg-primary" />
                            </Icon>
                        </small>

                        <small>
                            <Icon>
                                <MenuIcon style={styles.root} className="fg-primary" />
                            </Icon>
                            </small>
                        </span>

                        </section>
                        
                        <section className="footer__phone--content-list">

                          <div className="phone__jobcard" style={boxesStyling}>

                              <h2 className="phone__jobcard--title" style={textColorStyles}> 
                                  VP of finance - Contract
                              </h2>
                              <span className="phone__jobcard--date">2hrs ago</span>
                              <section className="phone__jobcard--details">
                                  <div className="phone__jobcard--details-brand">
                                        <small>T</small>
                                  </div>
                                  <div className="phone__jobcard--details-salary" style={textColorStyles}>
                                        <span>Turtrozz</span>
                                        <span>ZAR 35K</span>
                                  </div>
                                  <div className="phone__jobcard--details-location" style={textColorStyles}>
                                        <span>Pretoria</span>
                                        <button>Apply</button>
                                  </div>
                              </section>



                          </div>

                          <div className="phone__jobcard" style={boxesStyling}>

                            <h2 className="phone__jobcard--title" style={textColorStyles}>
                                Gen farm worker - TGL
                            </h2>
                            <span className="phone__jobcard--date">2 days ago</span>
                            <section className="phone__jobcard--details">
                                <div className="phone__jobcard--details-brand">
                                    <small>G</small>
                                </div>
                                <div className="phone__jobcard--details-salary" style={textColorStyles}>
                                    <span>TGL</span>
                                    <span>ZAR 15K</span>
                                </div>
                                <div className="phone__jobcard--details-location" style={textColorStyles}>
                                    <span>Pretoria</span>
                                    <button>Apply</button>
                                </div>
                            </section>



                            </div>

                        </section>

                        <section className="footer__phone--content-menu">

                            <span>
                                <Icon>
                                    <SearchIcon style={styles.root} className="fg-primary" />
                                </Icon>
                            </span>
                            <span>
                                <Icon>
                                    <InsertDriveFileIcon style={styles.root} className="fg-primary" />
                                </Icon>
                            </span>
                            <span>
                                <Icon><FilterListIcon style={styles.root} /></Icon>
                            </span>
                            <span>
                                <Icon>
                                    <PersonIcon style={styles.root} className="fg-primary" />
                                </Icon>
                            </span>
                            <span>
                                <Icon>
                                    <SettingsIcon style={styles.root} className="fg-primary" />
                                </Icon>
                            </span>

                            <p className={pointerClasses}><i className="fa fa-hand-pointer-o" aria-hidden="true"></i></p>

                        </section>

                        {isFilterOpened 
                        ? isDelayed ? <section className="footer__phone--content-filter" ></section> : this.delayMenu()
                        : null }
                    </div>

                </section>

                

            </div>

          
        )
    }


}


export default Phone


