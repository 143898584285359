



export const profileQuestionsProps = {



    personal:{

        

          first_name:{
              text:`Please type the name you are changing to`,
              key: `first_name`,
              value: ''
              
          },
          last_name:{
            text:`Please type the last name you are changing to`,
            key: `last_name`,
            value: ''
            
        },
         nick_name:{

          text:`Please type the nick name you are changing to`,
          key: `nick_name`,
          value: ''
            
        },
        bio:{
          text:`Please type your bio`,
          key: `bio`,
          value: ''
          
        },
        profession:{
          text:`Please type your profession`,
          key: `profession`,
          value: ''
        },
        aleternateEmail:{
          text:`Please type alternative email update`,
          key: `alternate_email`,
          value: ''
        },
        aleternateEmail:{
          text:`Please type alternative email update`,
          key: `alternate_email`,
          value: ''
        }
        
        
        

          
    },
    education:{
      identifierText: 'institution',
      add:[
      {
        
        chain: 'profileitems',
        key: 'institution',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: `What's your institution name?`,
        
      },
      {
        chain: 'profileitems',
        key: 'from',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'When have you begun your studies from this institution?',
        
      },
      {
        // key: 'qualification',
        //chain: 'profileitems',
        expected: ['yes','no'],
        expectedFormat: {isString: true},
        prefferedAnswer: 'yes',
        text: 'Have you obtained any qualification from this institution?',
        questions:[{
  
          chain: 'profileitems.qualification',
          key: 'qualification',  
          text: `What qualification have you obtained from the provided institution?`
        },
        {
  
          chain: 'profileitems.qualification',
          key: 'to',  
          text: `When have you completed your studies from the institution?`
        }
        ]
              
      },
      {
        // key: 'majors',
        expected: ['yes','no'],
        expectedFormat: {isString: true},
        prefferedAnswer: 'yes',
        text: 'Do you have any majors from this institution?',
        questions:[{
  
          chain: 'profileitems.majors',
          key: 'major',  
          text: `Please type your majors`
        }
        ]
              
      },
     
    ],
    edit:[
      {
        key: 'institution',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: `Please enter institution update value?`,
        
      },
      {
        key: 'from_date',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'Please enter institution from update value?',
              
      },
      {
        key: 'to_date',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'Please enter institution to update value?',
      },
      {
        key: 'qualification',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'Please enter institution qualification update value?'
              
      },
      {
        key: 'majors',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'Please enter institution majors update value(s)?'
              
      },
     
    ]},
    experience:{
      add:[
      {
        key: 'company',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: `Please provide the company that you have worked or working for?`,
        
      },
      {
        key: 'from',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'When have you begun working for this company?',
        questions:[{
  
          chain: 'qualification',
          key: 'qualification',  
          text: `What's the qualification have you obtained from the provided institution?`
        }
        ]
              
      },
      {
        key: 'qualification',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'Have you obtained any qualification from this institution?',
        questions:[{
  
          chain: 'qualification',
          key: 'qualification',  
          text: `What's the qualification have you obtained from the provided institution?`
        },
        {
  
          chain: 'qualification',
          key: 'to',  
          text: `When have you completed your studies from the institution?`
        }
        ]
              
      },
      {
        key: 'majors',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'Do you have any majors from this institution?',
        questions:[{
  
          chain: 'majors',
          key: 'qualification',  
          text: `Please type your majors`
        }
        ]
              
      },
     
    ],
    edit:[
      {
        key: 'institution',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: `What's your institution name?`,
        
      },
      {
        key: 'from',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'When have you begun your studies from this instituion?',
        questions:[{
  
          chain: 'qualification',
          key: 'qualification',  
          text: `What's the qualification have you obtained from the provided institution?`
        }
        ]
              
      },
      {
        key: 'qualification',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'Have you obtained any qualification from this institution?',
        questions:[{
  
          chain: 'qualification',
          key: 'qualification',  
          text: `What's the qualification have you obtained from the provided institution?`
        },
        {
  
          chain: 'qualification',
          key: 'to',  
          text: `When have you completed your studies from the institution?`
        }
        ]
              
      },
      {
        key: 'majors',
        expectedFormat: {isString: true,maxLen: 30,minLen: 2},
        text: 'Do you have any majors from this institution?',
        questions:[{
  
          chain: 'majors',
          key: 'qualification',  
          text: `Please type your majors`
        }
        ]
              
      },
     
    ]
    },
  
  

  
      
}
         
    
    


