
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-textarea-autosize';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tooltip from '../tooltip'
import Suggestions from './suggestions'
import { ThemeContext } from '../../context';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

        const {institution = {}} = props 
        const {from_date,to_date,qualification='',majors} = institution
        this.state = {
            form : {
                    
                    
                
                from_date:{
                    
                    control: 'input',
                    value: from_date ? new Date(Date.parse(from_date)) : new Date(),
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "from",
                        type: 'text',
                        // placeholder: 'surprise@turtrozz.co.za'
                        // disabled: false
                        
                    }
                    
                },
                to_date:{
                    
                    control: 'input',
                    value: to_date ? new Date(Date.parse(to_date)) : new Date(),
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "to",
                        type: 'text',
                        //placeholder: 'surprise@turtrozz.co.za',
                        // disabled: false
                        
                    }
                    
                },
                institution:{
                    
                    control: 'input',
                    value: institution.institution || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "school",
                        type: 'text',
                        //placeholder: 'surprise@turtrozz.co.za',
                        // disabled: false
                        
                    }
                    
                },
                qualification:{
                    
                    control: 'input',
                    value: qualification || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    isSuggest: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "qualification",
                        type: 'text',
                        //placeholder: 'surprise@turtrozz.co.za',
                        // disabled: false
                        
                    }
                    
                }
               
               
              
                
               

              
            
            },
            textAreas: {

                majors:{

                    value: majors || '',
                    validation: {
                        isRequiredFormat:{

                            minLetters: 10,
                            maxLetters: 150
                
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'top'
                    },
                    config: {
                        
                        name: "Majors",
                        // type: 'text',
                        // placeholder: '...type description',
                        // disabled: false
                        
                    }
                    
            }

            },
            startFromDate: null,
            startToDate: null,
            showSuggest: false,
            showSuggestId: '',
            suggestions: [

                'Pre-matric',
                'Higher Certificate',
                'National Diploma',
                'Bachelor',
                'Honours',
                'Masters',
                'Doctorate',
                'Matric'
            ]
           
            
        };
    }

    showSuggestScreen(key,e){

        e.preventDefault() 

        console.log('THE KEY')
        console.log(key)

        
    
        // console.log('THE FORM SUGGESTIONS')
        // console.log(key)
        // console.log(e)
        const {state} = this 
        let newForm ={...state.form} 

        // if(newForm[key].value.length >= 2) return
        newForm[key].isSuggest = true
        this.setState({...this.state,form: newForm,showSuggest: true,showSuggestId: key})

    }

    hideSuggestScreen(key,value=''){



       
        const {state} = this 
        let newForm ={...state.form} 
        newForm[key].isSuggest = false 

        if(value.trim() !== ''){
            newForm[key].value = value 
            newForm[key].isValid = true
        }
      

        

        this.setState({
            ...state,
            form: newForm,
            showSuggest: false,
            showSuggestId: '',
          
            
            }) 
        


    }


    setDate = (id,date)=>{

        // console.log('UDPATING DATE')
        // console.log(date)
        // console.log(type)
        // console.log(`start${type}Date`)

        const  {form} = this.state 
        let newForm = {...form} 
        newForm[id] = {...newForm[id],value: date,isTouched: true,isValid: true}
        
        this.setState({...this.state,form: newForm})
    }


    
    updator = (upControl)=>{
        
            // console.log('THE UPDATE CONTROL')
            // console.log(upControl)
            let newForm ={...this.state.form} 
            let updateControl = {...newForm[upControl.id]}
            updateControl.value = upControl.e.target.value 
            //console.log(updateControl)

        
            
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1]

                    if(!updateControl.isValid){

                        for(let k in newForm){ 


                            if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                                newForm[k].config.disabled = true
                            }
                        }
                    }else{

                        for(let k in newForm){ 

                            if(newForm[k].config.disabled === true){
                                newForm[k].config.disabled = false
                            }
                        }

                    }
                    
                }

            }
        
            updateControl.isTouched = upControl.blur
            newForm[upControl.id] = updateControl 



            if(upControl.blur && updateControl.isValid){

                this.setState({
                    form: newForm,
                    showTextBoxInput: false,
                    textBoxID: ''
                    
                })
    

            }else{

                this.setState({
                    form: newForm
                    
                })
    

            }
            
           

    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.maxLetters && (value.length > isRequiredFormat.maxLetters) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }
   

    submitForm = (e)=>{
        
        e.preventDefault() 
        
       
        
        
        const {props,state} = this
        const {actions,userProfile,isAdding=false,institution={}} = props 
        const {profile} = userProfile 
        const {personal} = profile
        const {id} = personal
        const inId = institution.id 
        const {updateResumeItem,addResumeItem} = actions 

      
        
        let {form,textAreas} = this.state 
        let allRequiredAddKeys = Object.keys(form).length + Object.keys(textAreas).length
        let {notify} = this
        let validatedAndFetched = this.validateAndFetchInput([form,textAreas])
        const {isValid,submittData} = validatedAndFetched
      
        if(!isValid) return notify({message:'Education information must be valid before saving',type: 'warning',className: 'notify-warn'})
        if(Object.keys(submittData).length === 0) return notify({message:'Education information must be updated before saving',type: 'warning',className: 'notify-warn'})

        if(isAdding){

          
            if(Object.keys(submittData).length < allRequiredAddKeys){
                console.log(submittData)
                console.log(Object.keys(submittData).length)
                console.log(allRequiredAddKeys)
                return notify({message:'Form must be fully filled before saving',type: 'warning',className: 'notify-warn'})

            }else{

                console.log('THE DATA ADDING')
                console.log(submittData)
                addResumeItem({education: [{...submittData,u_id: id}]},id)
            }
            
            //addResumeItem({experience: submittData})

        }else{
            console.log('THE USER IS EDITING')
            console.log(submittData)
           
            updateResumeItem({education: {...submittData,id:inId}},id)
        }
        // if(!formIsValid) return
        // if(!isCaptchaVerified) return  notify({message:'Recaptcha must be selected before signin',type: 'warning',className: 'notify-warn'})
        
        
        
    
    }

    removeItem = (e,itemId)=>{
        
        e.preventDefault() 
        
       
    
        console.log('THE ID OF THE DELETING ITEM')
        console.log(itemId)
        
        const {props} = this
        const {actions,userProfile} = props 
        const {profile} = userProfile 
        const {personal} = profile
        const {id} = personal
        const {deleteResumeItem} = actions
      
     
         deleteResumeItem({education: {id:itemId}},id)
        
        // if(!formIsValid) return
        // if(!isCaptchaVerified) return  notify({message:'Recaptcha must be selected before signin',type: 'warning',className: 'notify-warn'})
        
        
        
    
    }

    validateAndFetchInput = (forms)=>{


        let submittData = {} 
        let formIsValid = true 
        let shouldOuterBreak = false

        for(let it=0; it < forms.length; it++){
            
            let form = forms[it]

            for(let k in form){
            
                // console.log('THE SUBMIT CONTROL')
                // console.log(form[k])
                if(form[k].validation && form[k].validation.isRequiredFormat){
    
                    console.log('THE CONTROL REQUIRES VALIDATION')
    
                    if(form[k].isTouched && form[k].isValid === false){
    
                        // console.log('CONTROL:',k, ' is not valid')
                        formIsValid = false 
                        shouldOuterBreak = true
                        
              
        
                        //break
    
    
                    }else if(form[k].isTouched){
     
                        submittData[k] = form[k].value
    
                    }
    
                }else{
    
                    if(form[k].value.trim() !== ''){
    
                        submittData[k] = form[k].value
    
                    }
                   
    
                }
              
            
            } 

        }

        return {isValid: formIsValid,submittData: submittData}

       

            
    }
    
    

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        // const {props} = this 
        // const {actions,auth} = props 
        // const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        // const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        // if(errMessage !== ''){

        //     removeShownMessage()

        // }
        
        
    }

    count = (event,id)=>{

        console.log('I AM CHANGING')
        console.log(event)
        console.log(event.target)
        console.log(event.target.value)
        console.log(event.target.value.trim().length)

        let newTextareas ={...this.state.textAreas} 
        let updateControl = {...newTextareas[id]}
        updateControl.value = event.target.value 

        let validData = this.controlValidate(updateControl) 
        console.log('THE VALIDATE TEXTBOX RESPONSE')
        console.log(validData)
                    
        updateControl.isValid = validData[0] 
        updateControl.validationMessage = validData[1]
        updateControl.isTouched = true

        newTextareas[id] = updateControl 

        this.setState({

            textAreas: newTextareas
                    
        })

            
        


    }


   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        
          const {props,state,showSuggestScreen,hideSuggestScreen} = this 
        // const {startDate,textAreas} = state
        const {textAreas,form,showSuggest,showSuggestId,suggestions} = state
        const fromDate = form.from_date
        const toDate = form.to_date
        const {institution={},qualification =[], index,isAdding=false,educationAdd} = props 
        // const suggestions = []
        console.log('THE QUALIFICATION VALUES')
        console.log(suggestions)
        console.log(showSuggest)
        console.log(showSuggestId)
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')
        console.log('THE INDEX')
        console.log(index)
        console.log(parseInt(index))
        let card = index === 0 ? '' : `resume__education--card-${parseInt(index)+1}` 
        console.log(card)



        return(

                   

                       
                        

            <section className={`resume__education--card ${card}`}  style={{marginBottom: `50px`}} id="education-add">


                <div className="resume__education--card-pointer">
                    <small></small>
                </div>
                <div className="resume__education--card-pointee">
                <form className="form resume__about--bio-contact-form">
            
                 <h3 style={textColorStyles}>{ institution.institution || ''}</h3>


                 <div className="resume__about--bio-contact-form--control  ">

            <section className="resume__about--bio-contact-form--control-combine">

                    <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Institution</label>
                    <FormControl 

                        styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                        id = 'institution'
                        controlData={this.state.form.institution}
                        change={(control)=>this.updator(control)}
                        style={{...boxesStylingAccent,...textColorStylesAccent}}
                        // placeHolder={ education.institution || ''}

                        

                    />
                </section>

                <section className="resume__about--bio-contact-form--control-combine">

                        <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Qualification</label>
                        <FormControl 

                            styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                            id = 'qualification'
                            controlData={this.state.form.qualification}
                            change={(control)=>this.updator(control)}
                            click={showSuggestScreen.bind(this,'qualification')} 
                            style={{...boxesStylingAccent,...textColorStylesAccent}}
                            // placeHolder={ education.qualification || ''}

                            

                        />
                        {showSuggest && suggestions && showSuggestId  === 'qualification' ? <Suggestions list={suggestions} id="qualification" hideScreen={hideSuggestScreen.bind(this)} /> : null}
                </section>
                </div>

                <div className=" resume__about--bio-contact-form--control  ">

                        
                        <section className="resume__about--bio-contact-form--control-combine">

                                <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>From</label>
                                {/* <FormControl 

                                    styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                    id = 'from'
                                    controlData={this.state.form.from}
                                    change={(control)=>this.updator(control)}
                                    placeHolder={ institution.from}
                                
                                    

                                /> */}

                                <DatePicker
                                    selected={fromDate.value}
                                    onChange={this.setDate.bind(this,'from_date')}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    className="resume__about--bio-contact-form--control-input"
                                />
                            </section>

                            

                            <section className="resume__about--bio-contact-form--control-combine">

                                <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>To</label>
                                {/* <FormControl 

                                    styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                    id = 'to'
                                    controlData={this.state.form.to}
                                    change={(control)=>this.updator(control)}
                                    placeHolder={ institution.to}

                                    

                                /> */}

                                <DatePicker
                                    selected={toDate.value}
                                    onChange={this.setDate.bind(this,'to_date')}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    className="resume__about--bio-contact-form--control-input"
                                />
                            </section>

                        
                        </div> 

                       

                        <div className="resume__about--bio-contact-form--control  ">

                            <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Majors</label>
                            <TextareaAutosize className="resume__about--bio-text resume__about--bio-text-edit" style={{width: '100%',marginBottom: '10px',...boxesStylingAccent,...textColorStylesAccent}} defaultValue={ institution.majors || ''} onChange={(e)=> this.count(e,'majors')} />
                            {!textAreas.majors.isValid && textAreas.majors.validationMessage.trim()
                                ? <Tooltip message={textAreas.majors.validationMessage} position={'top'} active={true} /> 
                                : null
                            }
                            <span className="textarea-counter">
                                <small className="textarea-counter-count">{textAreas.majors.value.length}</small>
                                <small className="textarea-counter-sep" style={textColorStyles}>Of</small>
                                <small className="textarea-counter-total">{textAreas.majors.validation.isRequiredFormat.maxLetters}</small>
                            </span>
                        

                        </div> 


                        <div className="resume__about--bio-edit-sub-btn">

                                <button className="resume--edit-btn " onClick={(e)=>this.submitForm(e)}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Save</small></span>
                                </button>

                                {!isAdding 
                               
                               ? <button className="resume--edit-btn " onClick={(e)=>this.removeItem(e,institution.id)}>
                                           <span className="resume--edit-btn-rect"></span>
                                           <span className="resume--edit-btn-rect-2">
                                               <small>Remove</small></span>
                                   </button>
                                   : null
                               }

                               {isAdding ? <button className="resume--edit-btn " onClick={(e)=> educationAdd()}>
                                       <span className="resume--edit-btn-rect"></span>
                                       <span className="resume--edit-btn-rect-2">
                                           <small>Cancel</small></span>
                               </button> : null}
                             
                        </div>
            
            </form>
            </div>

        </section>
                        
                            

                  

                    
               

             
             
           
        )
    }




}

export default Bio