
import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import LazyLoad from 'react-lazyload';
import { ThemeContext } from '../../../context';




class PrimaryPosts extends Component{
    
    
    constructor(props) {
        super(props);
        this.state = {
            months: [
                'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
            ]
        }
      
    }

    getDate = (date)=>{

        const {state} = this 
        const {months} = state
        let setDate = new Date(date) 
        let getDate = setDate.getDate() 
        let formAtedDate = getDate < 10 ? `0${getDate}` : getDate

        return `${months[setDate.getMonth()]} ${formAtedDate}, ${setDate.getFullYear()}`

    }

    
    getPreviewText = (text)=>{

      
        let prevText = text.substr(text.indexOf('>')+1,text.indexOf("</p>"))
        console.log('THE PREVTEXT')
        console.log(prevText)
        console.log(text.indexOf('>'))
        console.log(text.indexOf('</p>'))
        console.log(prevText.length)
        // prevText = prevText.length > 240 ? prevText.substr(0, prevText.length - (prevText.length - 252)) : prevText
     

        // return `${prevText}...`
        return `${prevText.substr(0,200)}...`

    }


    getPrimaryPost = (bPosts)=>{

        const {posts,styles={}} = bPosts
        let primaryElement = null
        for(let p=0; p < posts.length;  p++){

            const post = posts[p] 
            const {slug,is_primary=0,title,body,created_at,image,author} = post
            if(p === 0){

                primaryElement = ( 
                    <section className="blog__primary--lg">

                            <div className="blog__priamry--post">

                                <Link to={`/blog/post/${slug}`} className="link d-block">

                                <h2 className="blog__primary--post-lg-title"> 
                                        <span className="blog__primary--post-lg-title-sp" style={styles?.textColorStyles}>{title}</span></h2>

                                <section className="blog__primary--post-lg-author" title="Post author">
                                        <span className="blog__primary--post-lg-author-name">{author} </span>
                                        <img src="https://res.cloudinary.com/hsjvf6r09/image/upload/v1619088251/jobbri/blog/zhcdavi9bqntbnpmj2ax.png" className="blog__primary--post-lg-author-pik" />
                                    </section>

                                   

                                    <div className="blog__primary--post-lg-container">
                                       
                                        <small className="blog__primary--post-lg-date">{this.getDate(created_at)}</small>
                                        <figure className="blog__primary--post-lg-fig">

                                        <LazyLoad  height={200} offset={100}>
                                            <img src={image} className="blog__primary--post-lg-pik" />
                                        </LazyLoad>
                                            
                                        </figure>
                                        <section className="blog__primary--post-content" style={styles?.textColorStylesAccent}>
                                            <p>{this.getPreviewText(body)}
                                            </p>
                                        </section>
                                    </div>
                                   
                                    <strong className="clearfix" />

                                </Link>

                            </div>

                    </section>
                    )
            break;

            }

        }
        return primaryElement


    }

    getCompanionPosts = (bpPosts)=>{

        const {post,i:p,styles={}} = bpPosts
        // posts.array.forEach(element => {
            
        // });
        let companionElement = null
       

            
            const {slug,is_primary=0,title,body,created_at,image,author} = post 
            let link = p === 1 ? "link d-block" : "link d-block blog__primary--post-offset"

            if(p === 1 || p === 2){

                companionElement = ( <Link to={`/blog/post/${slug}`} className={link} k={p}>

                                        <div className="blog__priamry--post">

                                            <h2 className="blog__primary--post-sm-title" style={styles?.textColorStyles}>
                                                {title}
                                               </h2>
                                            <small className="blog__primary--post-sm-date">{this.getDate(created_at)}</small>
                                            <figure className="blog__primary--post-sm-fig">

                                            <LazyLoad  height={200} offset={100}>
                                                <img src={image} className="blog__primary--post-sm-pik" />
                                            </LazyLoad>
                                                
                                            </figure>
                                            <p style={{fontSize: '1.5rem',...styles?.textColorStyles}}>
                                                By {author} Mashele
                                            </p>

                                        </div>
                                    </Link>
                                )
         

            }

        
        return companionElement



    }

   
    
      
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}


        const {props} = this 
        const {blogr,width} = props 
        const {posts} = blogr

        return(

           

                
                <div  className="blog__primary">

                    {
                        width <= 1200 
                        ? <>
                                 {this.getPrimaryPost({posts,styles:{textColorStyles, textColorStylesAccent, boxesStyling}})}
                                <section className="blog__primary--sm">

                                {posts.map((p,i)=>{

                                return this.getCompanionPosts({post:p,i,styles:{textColorStyles, textColorStylesAccent, boxesStyling}})
                                })}

                                </section> 
                                <strong className="clearfix" />
                          </>
                        : <>

                            <section className="blog__primary--sm">

                            {posts.map((p,i)=>{

                            return this.getCompanionPosts({post:p,i,styles:{textColorStyles, textColorStylesAccent, boxesStyling}})
                            })}

                            </section> 

                            {this.getPrimaryPost({posts,styles:{textColorStyles, textColorStylesAccent, boxesStyling}})}
                            <strong className="clearfix" />
                        
                          </>
                    }

                  
                </div>
               
                

           
        )
    }

}


export default PrimaryPosts