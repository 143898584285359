
import React, {Component} from 'react' 
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
//import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import * as userProfileActions from '../UserProfile/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'
import CandJobAlerts from '../CandJobAlerts/component'
import Dashboard from './components/dashboard/dashboard'
import { ThemeContext } from '../context'

 



class CandidateDashboard extends Component{


    constructor(props){

        super()
        this.state ={update: false}
    }

  
    componentDidMount(){

        console.log('The component did mount and now we have props')
        

        // const {listing} = this.props
        // const {search} = listing
        // console.log('THE SEARCH INFORMATION FROM THE REDUX STATE')
        // console.log(search)
        const {props} = this 
        const {userProfile,actions} = props 
        const {profile} = userProfile 
        const {push} = actions
        const {personal={}} = profile 
        const {id,is_pending} = personal 
        if(is_pending === 1) return push('/account-verification')
        // this.props.actions.fetchDashStats(id)
       
        
    }


    componentWillUnmount(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message=''} = userProfile 
        const {removeNotification,removeMessage} = actions


        if(isActionSuccessful) {
            removeNotification() 
            
        }
        
        if(message.trim()){
            removeMessage() 
            
        }

     
        
    }

    
    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        

         const {userProfile,actions} = this.props
         //const {isFetching = false} = userProfile 
         const {profile} = userProfile 
         let {alertsCount,savedCount,searchCount,alertSubscriberID} = profile 
        
        //  if(isFetching === true) return  <article className="dashboard__candsettings"> 
        //     <Helmet>
        //     <title>Jobbri - Dashboard</title>
        //     </Helmet><ProgressBarCircular />
        // </article>;


        // console.log('THE JOBS PROP')
        // console.log(listing)

        return(

            <article className="dashboard__candidatedash">
                 <Helmet>
                    <title>Jobbri - Dashboard</title>
                </Helmet>
                
                <div>

                     <Dashboard alertSubscriberID={alertSubscriberID} userProfile={userProfile} actions={actions} stats={{alertsCount,savedCount,searchCount}} candJobAlerts={CandJobAlerts} push={actions.push} />

                   </div>
               
             </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        // candidateDashboard:{
        //     ...state.candidateDashboard
        // },
        userProfile: {...state.userProfile},
        auth: {...state.auth}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...userProfileActions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(CandidateDashboard)


