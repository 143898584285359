import React from 'react';
import Card from './card' 
import Huloader from './huloader'



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Complete extends React.Component{

  constructor(props){
    super();
    
  }




  componentDidMount(){

 
    const {props} = this 
    // console.log('the complete statement')
    // console.log(props)
    const {values,actions} = props  
    const {push} = actions 
   
    const keys = Object.keys(values).slice(2)
    console.log('THE KEYS')
    console.log(keys)
    console.log(values[keys[3]])
  
    setTimeout(() => {

        let url = `/jobs?jq=${values[keys[3]]}&jl=${values[keys[0]]}&=jt${values[keys[1]]}&je=${values[keys[2]]}`
        push(url)
        
    }, 1000);

  }






  render(){

    const {props} = this 
    const {nextStep,prevStep,handleChange,values} = props 
    const text = 'Hang tight while our humanoid finds you jobs that best fit your interests'
   
   
    return(


      <div className='questionnaire__i'>
        
       

        <section className="questionnaire__options">

          
            <div className="complete">

             <Card text={text} >
                <Huloader />
             </Card>
             

            </div>
          

  
            
          
        </section>

      
       
      </div>


    );
  }
}

export default Complete