
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import { ThemeContext } from '../../context';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

        const {contact} =  props
        const {street,city,state,zip_code,phone,alternate_phone,alternate_email} = contact 

        this.state = {
            form : {
                    
                    
                
                street:{
                    
                    control: 'input',
                    value: street || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "street",
                        type: 'text',
                        placeholder: 'street address'
                        // disabled: false
                        
                    }
                    
                },
                city:{
                    
                    control: 'input',
                    value: city || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "city",
                        type: 'text',
                        placeholder: 'city',
                        // disabled: false
                        
                    }
                    
                },
                state:{
                    
                    control: 'input',
                    value: state || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            minLetters: 3,
                            maxLetters: 30,
                            
                           
                           
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "state",
                        type: 'text',
                        placeholder: 'province',
                        // disabled: false
                        
                    }
                    
                },
                zip_code:{
                    
                    control: 'input',
                    value: zip_code || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            minLetters: 4,
                            maxLetters: 4,
                            isNumber: true
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "code",
                        type: 'text',
                        placeholder: 'postal code',
                        // disabled: false
                        
                    }
                    
                },
                phone:{
                    
                    control: 'input',
                    value: phone || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            minLetters: 10,
                            maxLetters: 10,
                            isNumber: true
                          
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "phone",
                        type: 'text',
                        placeholder: '0000000000',
                        // disabled: false
                        
                    }
                    
                },
                alternate_phone:{
                    
                    control: 'input',
                    value: alternate_phone || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            minLetters: 10,
                            maxLetters: 10,
                            isNumber: true
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "altername_phone",
                        type: 'text',
                        placeholder: '0000000000',
                        // disabled: false
                        
                    }
                    
                },
                alternate_email:{
                    
                    control: 'input',
                    value: alternate_email || '',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            isEmail: true
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "email",
                        type: 'text',
                        placeholder: 'type alternative email...',
                        // disabled: false
                        
                    }
                    
                },
               
            
              
            
            }
           
            
        };
    }

    
    updator = (upControl)=>{
        
            // console.log('THE UPDATE CONTROL')
            // console.log(upControl)
            let newForm ={...this.state.form} 
            let updateControl = {...newForm[upControl.id]}
            updateControl.value = upControl.e.target.value 
            //console.log(updateControl)

        
            
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1]

                    if(!updateControl.isValid){

                        for(let k in newForm){ 


                            if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                                newForm[k].config.disabled = true
                            }
                        }
                    }else{

                        for(let k in newForm){ 

                            if(newForm[k].config.disabled === true){
                                newForm[k].config.disabled = false
                            }
                        }

                    }
                    
                }

            }
        
            updateControl.isTouched = upControl.blur
            newForm[upControl.id] = updateControl 

            this.setState({
                    form: newForm
                    
            })
    

            
           

    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.maxLetters && (value.length > isRequiredFormat.maxLetters) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }
   

    submitForm = (e)=>{
        
        e.preventDefault() 
        
       
        
        const {props,state} = this
        const {actions,userProfile} = props 
        const {profile} = userProfile 
        const {personal} = profile
        const {id} = personal
        const {updateResumeItem} = actions 
        

        let submittData = {} 
        let formIsValid = true 
        let {form} = state 
        let {notify} = this
        
        
        
        for(let k in form){
            
            // console.log('THE SUBMIT CONTROL')
            // console.log(form[k])
            if(form[k].validation && form[k].validation.isRequiredFormat){

                console.log('THE CONTROL REQUIRES VALIDATION')

                if(form[k].isTouched && form[k].isValid === false){

                    // console.log('CONTROL:',k, ' is not valid')
                    formIsValid = false 
                    
                    console.log(notify)
                    return notify({message:'Information must be valid before saving',type: 'warning',className: 'notify-warn'})
                    //break


                }else if(form[k].isTouched){
 
                    submittData[k] = form[k].value

                }

            }else{

                if(form[k].value.trim() !== ''){

                    submittData[k] = form[k].value

                }
               

            }
          
        
        } 

      

        if(Object.keys(submittData).length === 0) return notify({message:'Information must be updated before saving',type: 'warning',className: 'notify-warn'})
        //if(!formIsValid) return 

        // console.log('THE SUBMIT DATA')
        // console.log(submittData)
        updateResumeItem({personal: submittData},id)
        
        
    
    }
    

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        // const {props} = this 
        // const {actions,auth} = props 
        // const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        // const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        // if(errMessage !== ''){

        //     removeShownMessage()

        // }
        
        
    }
        

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        
         const {props} = this 
         const {allowEdit} = props
        //  const {bioText} = state
        //  const {actions,isEdit,allowEdit} = props 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                      <section>

                        <form className="form resume__about--bio-contact-form">


                        <div className=" resume__about--bio-contact-form--control  ">

                        
                           <section className="resume__about--bio-contact-form--control-combine">

                                <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Street Address</label>
                                <FormControl 

                                    styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                    id = 'street'
                                    controlData={this.state.form.street}
                                    change={(control)=>this.updator(control)}
                                    style={{...boxesStylingAccent,...textColorStylesAccent}}
                                
                                    

                                />
                            </section>

                            

                             <section className="resume__about--bio-contact-form--control-combine">

                                <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>City</label>
                                <FormControl 

                                    styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                    id = 'city'
                                    controlData={this.state.form.city}
                                    change={(control)=>this.updator(control)}
                                    style={{...boxesStylingAccent,...textColorStylesAccent}}

                                    

                                />
                            </section>

                        
                        </div> 

                        <div className="resume__about--bio-contact-form--control  ">

                        <section className="resume__about--bio-contact-form--control-combine">

                                <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Province</label>
                                <FormControl 

                                    styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                    id = 'state'
                                    controlData={this.state.form.state}
                                    change={(control)=>this.updator(control)}
                                    style={{...boxesStylingAccent,...textColorStylesAccent}}


                                    

                                />
                        </section>

                        <section className="resume__about--bio-contact-form--control-combine">

                                <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Postal code</label>
                                <FormControl 

                                    styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                    id = 'zip_code'
                                    controlData={this.state.form.zip_code}
                                    change={(control)=>this.updator(control)}
                                    style={{...boxesStylingAccent,...textColorStylesAccent}}


                                    

                                />
                        </section>
                        </div>

                        <div className="resume__about--bio-contact-form--control  ">

                                
                        <section className="resume__about--bio-contact-form--control-combine">

                            <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Mobile Number</label>
                            <FormControl 

                                styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                id = 'phone'
                                controlData={this.state.form.phone}
                                change={(control)=>this.updator(control)}
                                style={{...boxesStylingAccent,...textColorStylesAccent}}


                                

                            />
                            </section>

                            <section className="resume__about--bio-contact-form--control-combine">

                            <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Alternative Number</label>
                            <FormControl 

                                styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                id = 'alternate_phone'
                                controlData={this.state.form.alternate_phone}
                                change={(control)=>this.updator(control)}
                                style={{...boxesStylingAccent,...textColorStylesAccent}}


                                

                            />
                            </section>


                            </div> 

                            
                            

                        <div className="resume__about--bio-contact-form--control  ">

                            
                            <section className="resume__about--bio-contact-form--control-combine">
                            <label className="resume__about--bio-contact-form--control-label" style={textColorStyles}>Alternative Email Address</label>
                            <FormControl 
                        
                                styles={{child:'resume__about--bio-contact-form--control-input',error: ''}}
                                id = 'alternate_email'
                                controlData={this.state.form.alternate_email}
                                change={(control)=>this.updator(control)}
                                style={{...boxesStylingAccent,...textColorStylesAccent}}

                            
                            />
                            </section>


                        </div> 

                        

                            
                        </form>

                        <div className="resume__about--bio-edit-sub-btn">

                                <button className="resume--edit-btn " onClick={(e)=>this.submitForm(e)}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Save</small></span>
                                </button>
                                <button className="resume--edit-btn " onClick={allowEdit}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Cancel</small></span>
                                </button>
                            </div>
                        </section>
                        

                        /* { isEdit 
                            ?<div className="resume__about--bio-edit-sub-btn">

                                <button className="resume--edit-btn " onClick={(e)=>this.submitForm(e)}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Save</small></span>
                                </button>
                                <button className="resume--edit-btn " onClick={allowEdit}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Cancel</small></span>
                                </button>
                            </div>
                        : null
                        }
                         */
                        
                            

                  

                    
               

             
             
           
        )
    }




}

export default Bio