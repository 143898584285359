
import React, {Component} from 'react' 

import ContactInfo from './contactinfo'
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()
      
    }

    
    
    
   

    render(){

        
         const {props} = this 
         const {isEdit,allowEdit,contact = {},userProfile,actions} = props 
         const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
         //let contact = true
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    <section className="resume__about--bio-edit" style={editContStyles}>
                       
                        <ContactInfo   contact={contact}/> 
                            
                
                            

                    </section>

                    
               

             
             
           
        )
    }




}

export default Bio