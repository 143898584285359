
import React from 'react' 
import primaryLinks from './links'
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import DashboardIcon from '@material-ui/icons/Dashboard';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import MailIcon from '@material-ui/icons/Mail';
import { useAppThemeContext } from '../../../context';



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };


function Navigation(props) {

    const {theme, themeName} = useAppThemeContext()

    const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


  
    

    return(

        


            <div>
                <Link to="/dashboard" className="dashboard__sidebar--nav-link link">
                    <span className="dashboard__sidebar--nav-icon" style={textColorStylesAccent}>
                        <Icon><DashboardIcon /></Icon>
                    </span>
                    <span className="dashboard__sidebar--nav-text">Dashboard</span>
                </Link>

                <Link to="/cv-profile" className="dashboard__sidebar--nav-link link">
                    <span className="dashboard__sidebar--nav-icon" style={textColorStylesAccent}>
                        <Icon><PermIdentityIcon /></Icon>
                    </span>
                    <span className="dashboard__sidebar--nav-text">My Profile</span>
                </Link>


                <Link to="/saved" className="dashboard__sidebar--nav-link link">
                    <span className="dashboard__sidebar--nav-icon" style={textColorStylesAccent}>
                        <Icon><FavoriteIcon /></Icon>
                    </span>
                    <span className="dashboard__sidebar--nav-text">Saved jobs</span>
                </Link>

                <Link to="/alerts" className="dashboard__sidebar--nav-link link">
                    <span className="dashboard__sidebar--nav-icon" style={textColorStylesAccent}>
                        <Icon><MailIcon /></Icon>
                    </span>
                    <span className="dashboard__sidebar--nav-text">Job alerts</span>
                </Link>

                <Link to="/searched" className="dashboard__sidebar--nav-link link">
                    <span className="dashboard__sidebar--nav-icon" style={textColorStylesAccent}>
                        <Icon><HistoryIcon /></Icon>
                    </span>
                    <span className="dashboard__sidebar--nav-text">Search history</span>
                </Link>

                <Link to="/settings" className="dashboard__sidebar--nav-link link">
                    <span className="dashboard__sidebar--nav-icon" style={textColorStylesAccent}>
                        <Icon><SettingsIcon /></Icon>
                    </span>
                    <span className="dashboard__sidebar--nav-text">Settings</span>
                </Link>
                {/* <span  onClick={()=>props.logoutUser()}>
                <PowerSettingsNewIcon />
                </span> */}
               

            </div>
          

         
    )

}


export default withStyles(styles)(Navigation)