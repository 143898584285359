
export const SEND_QUERY_MESSAGE_REQUEST = "SEND_QUERY_MESSAGE_REQUEST"
export const SEND_QUERY_MESSAGE_SUCCESS = "SEND_QUERY_MESSAGE_SUCCESS"
export const SEND_QUERY_MESSAGE_FAILURE = "SEND_QUERY_MESSAGE_FAILURE"
export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"






