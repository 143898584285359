
import React,{Component} from 'react'
import SearchBar from './searchbar/searchbar'
import Form from './form/form' 
import Common from './common/common'
import Mobile from './mobile/mobile'
import { ThemeContext } from '../../../context'




class Search extends Component{
    
    constructor(props){

        super()
        this.state = {

            scrolled: false,
            isMobile: false,
            width: 0,
            height: 0
            // mainSearchClasses : ['job__search']
        }
        this.updateWindowDimensions = this.updateWindowDimensions
    }



        handleScroll = () => {

                const offset=window.scrollY;

                if(offset > 200 ){

                this.setState({...this.state,scrolled: true});
                }
                // else{

                //     this.setState({...this.state,mainSearchClasses: ['job__search'],scrolled: false});

                // }
        }


        updateWindowDimensions = () => {

            this.setState({ ...this.state,width: window.innerWidth, height: window.innerHeight });
          }
    
        componentDidMount(){
    
            this.updateWindowDimensions();
            window.addEventListener('resize', ()=>{

                this.debounce(this.updateWindowDimensions,1000)
            });
            // window.addEventListener('scroll',this.handleScroll)
        }
    
        componentWillUnmount(){
    
            window.removeEventListener('resize', this.updateWindowDimensions);
            //window.removeEventListener('scroll',this.handleScroll) 
         }
    

        openForm(status){


            this.setState({...this.state,isMobile: status},()=>{

                console.log('IS MOBILE')
                console.log(this.state.isMobile)
            })
        }


        debounce (method,delay){

            clearTimeout(method._tID)

            method._tID = setTimeout(function(){
                method()
            },delay)

        }

        // componentWillUnmount(){
        //     window.removeEventListener('scroll',this.handleScroll) 
        // }

        static contextType = ThemeContext
    
        render(){
    
            const {theme, themeName} = this.context 
            const boxesStyling = { backgroundColor: themeName == 'dark' 
            ? theme.colors.complimentary['accent-3'] 
            :  theme.colors.complimentary.base }
            const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
            const boxesStylingAccent = {backgroundColor: theme.colors.complimentary['accent-3']}
            const textColorStyles = {color:  theme.colors.complimentary.foreground}
            const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
    
        

        const {props,state} = this 
        const {scrolled,isMobile,width} = state 
        const {actions,locations=[],suggestions,search,listing} = props 
        // let mainSearchClasses =  width > 1200 && scrolled 
        // ? 'job__search job__search-sticky' 
        // : isMobile ? 'job__search job__search-sticky' : 'job__search' 
        let mainSearchClasses = 'job__search job__search-sticky' 
        let mobStyles = width <= 600 && isMobile ? {width: '100%',marginLeft: 0,height: '100vh'}  : null
        
        
        return(

           
                
                
                <div  className={mainSearchClasses} style={{...mobStyles,...boxesStyling}} id='search'>
                    
                     {/* <SearchBar /> */}
                     
                    {
                    
                        width > 1200 || isMobile
                        ? <Form actions={actions} search={search} listing={listing} suggestions={suggestions} locations={locations} isScrolled={scrolled} width={width} openForm={this.openForm.bind(this)}  />
                        : <Mobile search={search} openForm={this.openForm.bind(this)} />

                    }

                     { width > 1200 ? <Common actions={actions} search={search} isScrolled={scrolled} /> : null}
                </div>
               
                

           
        )
    }

}


export default Search