
import React, {Component} from 'react' 
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
// import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import * as authActions from '../Auth/actions'

import qs from 'query-string'
import Tagline from './tagline/tagline' 
import Hero from './hero/hero' 
import Verification from './verification/verification'
import Postdate from './hero/reasons'
import { isActionSuccessful } from '../CandJobAlertsManage/props'
import { message } from '../Listing/props'




class AlertAccountVerify extends Component{


    constructor(props){

        super()
        this.state = {timer: null,skipUpdate: false}
    }

   

    
  

    cancelLoginOnVerification = ()=>{


        const {state,props} = this 
        const {actions} = props 
        const {push} = actions
        const {timer} = state 

        if(timer) clearTimeout(timer)
        push('/')

    }

    retryLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 

        push('/access')
    }




    render(){

        const {props} = this 
        const {alertAccountVerify} = props 
        const {isActionSuccessful,isFetching,message} = alertAccountVerify

        let styles = {
            marginBottom: '30px',
            fontSize: '1.4rem',
            width: '50%',
            height: '300px',
            marginLeft: '25%',
            paddingTop: '150px',
            boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04)'
        

        }

       
        


        return(

          
            <article className="verify verify--out"><Verification retryLogin={this.retryLogin.bind(this)} /></article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        alertAccountVerify: {...state.alertAccountVerify},
        router: {...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...authActions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(AlertAccountVerify)