
import React from 'react';

class Huloader extends React.Component {
//   state = {
//     loading: true,
//   };
  
//   componentDidMount(){
//     this.isLoading = setTimeout(()=>{this.setState({loading: false})}, 2300);
//   }
//   componentWillUnmount() {
//      clearTimeout(this.isLoading);
//   }
  
//   timer = () => setTimeout(()=>{
//     this.setState({loading: false})
//   }, 2300);

  render() {

    return (
        <section className="huloader">
            <div className="huloader__loading">
                <div></div>
                <div></div>
            </div>  
        </section>
    )
  }
}

export default Huloader