
import * as types from './types'
import { CALL_API } from '../../middlwares/api'




  export const sendUserUnsubscription = (data)=> {

    return {

        [CALL_API]: {
          endpoint: `/subscriber`,
          method: 'POST',
          request: 'URL',
          payload: {action: 'unsubscribeUser',payload: {subscriber: {...data}}},
          authencitcated: false,
          launcher: 'unsubscribe',
          types: [types.SEND_USER_UNSUBSCRIPTION_REQUEST, types.SEND_USER_UNSUBSCRIPTION_SUCCESS, types.SEND_USER_UNSUBSCRIPTION_FAILURE]
        }
      }

  }

  export const unsetNotificationDisplay = ()=> {

    return {

        type: types.UNSET_NOTIFICATION_DISPLAY,
        payload: false
        
      }

  }


