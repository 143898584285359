import React,{useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Upload from '../upload'
import Register from '../register' 
import Success from '../sucess'
import DeleteSuccess from '../deletesuccess' 
import Confirm from '../successconfirm' 
import DownloadMulti from '../downloadmulti'
import { useAppThemeContext } from '../../context';




export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [openUpload,setOpenUpload] = React.useState({open: false,type: ''})
  const [title,setTitle] = React.useState('')
  const {theme, themeName} = useAppThemeContext()

        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
       
  
  const {auth,actions,userProfile,popUp=''} = props
  const {appAuth} = auth 
  const {isAuth} = appAuth  

  const CustomButton = props.button ? props.button : null


  const {isActionSuccessful=false,isInitSuccess=false,message='',isFetching=false,isResumeDeleted=false,isUploadResumeReplaced,
    isResumeReplaced=false,isUploadResume=false} = userProfile
    const {removeNotification,removeMessage} = actions
    // const {removeNotification} = actions

  // const actionTitle = isResumeDeleted ? 'Uploaded cv deleted' : isAuth ? isActionSuccessful ? 'Upload Success' : 'Upload CV' : 'Login or Register'

  const handleClickOpen = () => {
    setOpenUpload({open: false, type: ''})
    setOpen(true);
  };

  const handleOpenUpload = (replace='') => {

    if(isActionSuccessful && isUploadResume) removeNotification()
    setOpenUpload({open: true,type: replace});

  };

  const handleSetTitle = (title) => {

    // if(replace) return setOpenUpload(true);
    // console.log('THE SET TITLE')
    // console.log(title)
    setTitle(title);
  };


  // useEffect(() => {
    
  //   if(isActionSuccessful) removeNotification()
  // });


 const deleteCV = ()=>{


    const {actions,userProfile} = props
    const {deleteUploadResume} = actions 
    const {profile} = userProfile
    const {personal} = profile 
    const {resume_url,id} = personal 
    let parts = resume_url.indexOf('//') >= 0 ? resume_url.split('//').join('\\') : resume_url
    const send = {fileName: parts}
    deleteUploadResume(send,id)

}

  const handleClose = () => {

    
    if(isActionSuccessful && isUploadResume) removeNotification() 
    if(message) removeMessage() 
    
    
    setOpenUpload({open: false, type: ''});
    setOpen(false);
  };


 

  // console.log('THE ACTION SUCCESSFULL STATUS')
  // console.log(isActionSuccessful)
  // console.log('tHE PROPS CHILDREN')
  // console.log(props) 

  return (


    <div >

      {
      
      props.button
      ? <CustomButton openPopup={handleClickOpen} />
      : props.children 
        ? <div style={{display: 'inline-block'}} onClick={handleClickOpen} >{props.children}</div>
        : <Button  style={props.styles ? props.styles : {border: "none",color: theme.colors.complimentary.foreground,fontSize: 1.1+"rem"}} onClick={handleClickOpen}>
            {props.text ? props.text : 'Open dialog'}
          </Button>
        
      }

    {/* {
      
      props.children 
      ? props.children
      : <Button  style={{border: "none",color: "black",fontSize: 1.1+"rem"}} onClick={handleClickOpen}>
          {props.text ? props.text : 'Open dialog'}
        </Button>
      } */}


     
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" 
          PaperProps={{
            style: {
              ...boxesStylingA
            
            },
      }} >
        <DialogTitle id="form-dialog-title" style={textColorStyles} >{title}</DialogTitle>
        <DialogContent >

           {popUp.trim() && isActionSuccessful === false && openUpload.open === false
              ? popUp === 'replace' || popUp === 'delete'
                ? <Confirm confirmType={popUp} openUpload={handleOpenUpload} setTitle={setTitle} deleteCV={deleteCV} userProfile={userProfile} />
                : <DownloadMulti actions={actions} userProfile={userProfile} setTitle={handleSetTitle} />
              :openUpload.open && isActionSuccessful === false
                ? <Upload actions={actions} userProfile={userProfile} openUpload={openUpload} setTitle={handleSetTitle} />
                :  isActionSuccessful && isResumeDeleted
                  ? <DeleteSuccess actions={actions} userProfile={userProfile} openUpload={handleOpenUpload} setTitle={handleSetTitle} />
                  : isActionSuccessful && isUploadResume
                      ? <Success actions={actions} userProfile={userProfile} openUpload={handleOpenUpload} setTitle={handleSetTitle} initiatorType={openUpload.type} />
                      : isAuth ? <Upload actions={actions} userProfile={userProfile} openUpload={openUpload} setTitle={handleSetTitle} /> : <Register setTitle={handleSetTitle} actions={actions} />
                     
          } 
        
          {/* {isAuth ? <Upload actions={actions} /> : <Register actions={actions} />} */}
          {/* <Upload actions={actions} userProfile={userProfile} /> */}
          {/* <Success actions={actions} userProfile={userProfile} openUpload={handleOpenUpload} setTitle={handleSetTitle} /> */}
          
          {/* <Register actions={actions} /> */}
        
         
         
         
         
         
           
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{color: 'seagreen'}}>
            Close
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
