
import React, {Component} from 'react' 
import { ThemeContext } from '../../context'


class Bio extends  Component{


    constructor(props){

        super()

    }




    getInstitutions = (eData)=>{
        const {e,i,styles} = eData


        // console.log('THE CERTIFICATE')
        // console.log(certificate)
        // let institutionCertificate = certificate.filter((c,ci)=>{

        //      console.log(c)
        //      console.log(e)
        //      console.log(c.id === e.certificate_id)
        //      if(c.id === e.certificate_id) return c
        //     }) 
        // console.log('THE INSTITUTE MATCH ID')
        // console.log(institutionCertificate)
        // let instiDocument = {...institutionCertificate[0]}
        // console.log(instiDocument)
        let card = i === 0 ? '' : `resume__education--card-${i+1}` 
        let toPresent = !e.to_date ? `${new Date(e.from_date).getFullYear()}-Present` : `${new Date(e.from_date).getFullYear()}-${new Date(e.to_date).getFullYear()}`
        let pos = i+1
        let institution = (

    

            <section className={`resume__education--card ${card}`} key={i}>

                <div className="resume__education--card-pointer" >
                    <small>{pos}</small>

                </div>

                <div className="resume__education--card-pointee">

                    <h5 className="resume__education--card-pointee-period" >{toPresent}</h5>
                    <h6 className="resume__education--card-pointee-employer" style={styles?.textColorStylesAccent}>{e.institution}</h6>
                    <span className="resume__education--card-pointee-role" style={styles?.textColorStylesAccent}>{e.qualification}</span>
                    <p className="resume__education--card-pointee-description" style={styles?.textColorStylesAccent}>
                       {e.majors}
                    </p>

                </div>

            </section>

            
        )
        

        return institution



    
    }
 

               

   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        
         const {props,state} = this 
        //  const {bioText} = state
         const {education} = props 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    

                        
                       
                        <>{education.map((e,i)=> this.getInstitutions({e,i,styles:{textColorStyles, textColorStylesAccent, boxesStyling}}))}</>

           
             
             
           
        )
    }




}

export default Bio