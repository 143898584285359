import React, { Component } from 'react' 
import Checkbox from './checkbox/checkbox'





class JobType extends Component{
    
    constructor(){
        super()

        this.state = {
            form : 'form'
        };
    }

    doLabel(list){

        const {props} = this 
        const {search} = props 
        const {jq,resetFilter} = search

        let doneList = []

        list.map((i,p)=>{

            // let checked = p === 0 ? true : false 
            let checked = resetFilter ? false : typeof jq !== 'undefined' ? jq.toLowerCase().trim() === i.value.toLowerCase().trim() ? true : false : false
            doneList.push({

                label: `${i.label}`,
                value: i.value,
                checked: checked
            })

        })

        return doneList

    }

    handleChecked(i){


        const {props} = this
        const {actions,search} = props 
        const {saveJobSearchParams} = actions 
        const {jq} = search   

        let value = i.value.toLowerCase().trim()

        if(value === 'internship' || value === 'learnership' || value  === 'remote'){

            if(value === 'remote'){

                saveJobSearchParams({
                    jq: `${jq} ${i.value}`,
                    // jcts: i.value,
                    // canSetHistory: true,
                    fetchJobs: true,
                     resetFilter: false
                })
            }else{

                saveJobSearchParams({
                    jq:i.value,
                    // jcts: i.value,
                    // canSetHistory: true,
                    fetchJobs: true,
                     resetFilter: false
                })
            }
            

        }else if(value === 'full time' || value === 'part time'){

            saveJobSearchParams({
                jcpd: i.value,
                // jcts: i.value,
                // canSetHistory: true,
                fetchJobs: true,
                 resetFilter: false
            })

        }else{

            saveJobSearchParams({
                jt: i.value,
                // jcts: i.value,
                // canSetHistory: true,
                fetchJobs: true,
                 resetFilter: false
            })

        }

        

        

        // const {props} = this
        // const {actions} = props 
        // const {crudJobFilter} = actions 
        
        // if(i.checked){
        	 
        // 	 crudJobFilter({
        // 	 	key: 'jt',
        // 	 	value: i.value,
        // 	 	operation: 'add'
        // 	 })
        	
        // }else{
        	
        // 	crudJobFilter({
        // 		key:'jt',
        // 		value: i.value,
        // 		operation: 'remove'
        // 	})
        // } 
        
        // console.log('THE VALUE OF FROM THE CHECKBOX SURPRISE')
        // console.log(i)
        // alert('the value of e',i.value)
    }


    
    render(){

        return(

           

           

            <section className="cat__filter pd-left-fl-xxx-tn hr-size-fl-xx-bg">
                
                <Checkbox list={this.doLabel(types)} help={false} legend={false} handle={(e)=>{this.handleChecked(e)}} />

             </section>
          
        )
    }


}


export default JobType


const types =[

    {label: 'Full-time',value: 'full time'},
    {label: 'Part-time',value: 'part time'},
    {label: 'Contract',value: 'contract'},
    {label: 'Temporary',value: 'temporary'},
    {label: 'Internship',value: 'internship'},
    {label: 'Learnership',value: 'learnership'},
    {label: 'Remote',value: 'remote'},
    {label: 'Permanent',value: 'permanent'},
    {label: 'Training',value: 'training'},
    {label: 'Voluntary',value: 'voluntary'}
   
]