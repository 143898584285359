
import * as types from './types'
import * as props from './props'
import { actions } from 'reapop'



export default (state = props, action)=>{

    switch (action.type) {

        case types.SEND_USER_UNSUBSCRIPTION_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isActionSuccessful: false,
                message: '',
                text: ''
                
            }

        case types.SEND_USER_UNSUBSCRIPTION_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                message: '',
                text: action.response.text || '',
                isActionSuccessful: true,
                // launcher: action.launcher || ''
              
               
                
            }

        case types.SEND_USER_UNSUBSCRIPTION_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
                text: '',
                message: action.error
                
            }

        case types.UNSET_NOTIFICATION_DISPLAY:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
                message: ''
                
            } 
    

      
        default:
        return state
    }


}