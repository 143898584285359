
import React, {Component} from 'react' 
import Card from './card'
import Empty from './empty'
import { toast } from 'react-toastify';





class AlertsList extends  Component{




    constructor(props){

        super(props)
    }

    // listJobs(list){

    //     // console.log('THE LIST FROM SAVED BODY')
    //     // console.log(list)
    //     return list.map((job,i)=>{

    //         // console.log('THE JOB TO THE CARD')
    //         // console.log(job)
    //         return <Card key={i}  job={job} />
    //     })
        
       

    // }

    viewPage(location){

        const {push} = this.props 

        console.log('THE VIEWPAGE LOCATION')
        console.log(location)
        push(location)

    }

    setAlertIdAndPush(alert){

        const {setAlertId} = this.props 
        setAlertId(alert)
        this.viewPage('/alerts/manage')
    }


      notify = (config) => {

       
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});
    }



    componentDidUpdate(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message='',isAlertDelete=false} = userProfile 
        const {removeNotification,removeMessage} = actions

      
        if(isActionSuccessful) {

            this.notify({message:'Job alert action successfully completed',type: 'success',className: 'notify-success'}) 
            removeNotification() 
            
        }
     
        
    }


   

    render(){


        const {props} = this
        const {userProfile,actions,modal,candJobAlerts,deletePopr,managePopr,deleteButton,manageButton} = props 
        const {profile} = userProfile 
        const {alerts} = profile 
        // const Modal = props.modal
       const {viewPage,setAlertIdAndPush} = this

        return(


               <div>

               
                    {/* <section className="dashboard__searchhistory--i">

                        <div className="dashboard__searchhistory--i-box bx-raised-1 ">

                                <span className="dashboard__searchhistory--i-box-title">Added Job alerts</span>
                     
             
                        </div>


                    </section> */}

                    {
                    
                        alerts.length > 0

                            ?

                            alerts.map((alert,i)=>{

                                
                                    return <Card key={i} 
                                                deletePopr={deletePopr} managePopr={managePopr} deleteButton={deleteButton} manageButton={manageButton}
                                                modal={modal} userProfile={userProfile} actions={actions}  alert={alert} 
                                                viewPage={viewPage.bind(this)} setAlertId={setAlertIdAndPush.bind(this)}/>
                                

                            })
                        
                            : <Empty   userProfile={userProfile} actions={actions} candJobAlerts={candJobAlerts} />

                    }

                




           
                </div>

             
             
           
        )
    }




}

export default AlertsList