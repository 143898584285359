
import React, {Component} from 'react' 
import Helmet from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import { toast } from 'react-toastify';
// import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import * as userProfileActions from '../UserProfile/actions'
// import * as userProfileActions from '../UserProfile/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'
import Upload from '../Upload/component'

import About from './components/about'
import Experience from './components/experience'
import Portfolio from './components/portfolio'
import Education from './components/education' 
import Curated from './components/curated'
import ProgressBar from './components/progressBar'
//import { push } from 'connected-react-router';


 



class UserResume extends Component{


    constructor(props){

        super()
        this.state ={
            update: false
            
        }

    }

    componentDidMount(){

        const {props} = this 
        const {auth,actions} = props 
        const {push} = actions
        // // const {resume} = userResume
        // const {profile} = userProfile 
        // const {fetchResume} = actions 
        // const pathName = this.props.location.pathname 
        const {appAuth} = auth
        const {isAuth} = appAuth
        // const {id,isPending} = profile 
        // if(isPending === 1) return push('/account-verification')
        
        // if(Object.keys(resume).length <= 0){
        //     console.log('userResume component about to fetch data')
        //     fetchResume(52)
        // }


        if(!isAuth) return push('/access')

        

        
     
        //this.notify({message:'Information has successfully been updated',type: 'success',className: 'notify-success'})
       
        
    }

    componentDidUpdate(){

      const {props} = this 
      const {userProfile,actions} = props 
      const {isActionSuccessful = false,message='',text,isInitFetching=false,
      isInitMessage=false,isInitSuccess= false,isResumeUpdate=false} = userProfile
       const {removeNotification,removeMessage} = actions 

        if(isActionSuccessful && isResumeUpdate) {

            let msg = text.trim() || 'Information has successfully been updated.'
            this.notify({message:msg,type: 'success',className: 'notify-success'}) 
            removeNotification() 
            
        }else if(message.trim() && isInitMessage === false){

            this.notify({message: message,type: 'error',className: 'notify-error'}) 
            removeMessage() 
            
        }

    }
    componentWillUnmount(){

        
      const {props} = this 
      const {userProfile,actions} = props 
      const {message=''} = userProfile
       const {removeMessage} = actions 
        
        if(message) removeMessage() 
    }

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        // const {props} = this 
        // const {actions,auth} = props 
        // const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        // const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        // if(errMessage !== ''){

        //     removeShownMessage()

        // }
        
        
    }

    
    render(){

         const {userProfile,actions} = this.props 
         const {profile,isFetching,isInitFetching,launcher='',profileCompletenessItems} = userProfile
         const {experience=[],education=[],qualification=[]} = profile
     
        // if(isFetching === true && isInitFetching === true) return  <article className="resume" style={{height: '100vh',width: '70%',position: 'relative'}}>
        //      <Helmet>
        //             <title>Jobbri - My profile</title>
        //     </Helmet>
        //     <ProgressBarCircular style={{width: '100%'}} /></article>;


        // console.log('THE JOBS PROP')
        // console.log(listing)
      
        return(

            <article className="resume">

                <Helmet>
                    <title>Jobbri - My profile</title>
                </Helmet>
                
                <About userProfile={userProfile} actions={actions} profileCompletenessItems={profileCompletenessItems} upload={Upload} />
                <Experience userProfile={userProfile} experience={experience} actions={actions} />
                {/* <Portfolio /> */}
                <Education userProfile={userProfile} actions={actions} education={education} qualification={qualification} />
                <Curated userProfile={userProfile} resume={profile} actions={actions} />
                {isFetching === true ? <ProgressBar isFetching={true} /> : null } 

               
             </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{

        // candidateDashboard:{...state.candidateDashboard},
        userProfile: {...state.userProfile},
        auth: {...state.auth}
        // userResume: {...state.userResume}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...userProfileActions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(UserResume)


