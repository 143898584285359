import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import Search from '@material-ui/icons/Search';
import Trash from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles'
import { ThemeContext } from '../../../context';




const styles = {
    root: {
     
        color:"red",
        opacity: .5,
        fontSize: 20,
        '&:hover':{
            color: "red",
            opacity: 1
        }
        
      },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Card extends Component{

     
    
    constructor(props){

        super(props)

    }

    
    milliSecondsToTime(millis){

        let seconds = (millis / 1000).toFixed()
        let minutes = (millis / (1000 * 60)).toFixed()
        let hours = (millis / (1000 * 60 * 60)).toFixed()
        let days = (millis / (1000 * 60 * 60 * 24)).toFixed() 

        if(seconds < 60 ) return `${seconds} seconds ago`
        if(minutes < 60 ) return `${minutes}m ago`
        if(seconds < 24 ) return `${hours}hrs ago` 
        if(days >= 30){

            let months = days / 30

            return `${months.toFixed()} months ago`
        }
        return `${days} days ago`
    }

    formatDate(date){

        // const {milliSecondsToTime} = this
        console.log('THE TIME')
        console.log(date)
        let nw = new Date()
        let setDate = new Date(date) 
        console.log('THE SETDATE')
        console.log(setDate)
        console.log(nw)
        let diffInTime = nw.getTime() - setDate.getTime() 
        // let diffInDays = diffInTime / (1000 * 3600 * 24) 

        console.log('THE TIME IN DAYS')
        console.log(diffInTime)
        console.log(this.milliSecondsToTime(diffInTime))

        return this.milliSecondsToTime(diffInTime)




    }

    formatJobType(type){

        if(type.indexOf('_') >= 0){

            let pieces = type.split('_')
            return `${pieces[0]}-${pieces[1]}`
        }else{
            return type
        }

    }
    deleteBookmarkJob(job){


        
        const {props} = this 
        const {userProfile,actions} = props 
        const {profile} = userProfile 
        const {personal} = profile 
        const {id} = personal 
        const {deleteSavedJob} = actions  
        const jobID = job.id 

      
        deleteSavedJob({ID:id,id:jobID})
        // this.forceUpdate()



        // sendUserJobBookmark({})

       
    }
    openLinkPage(url,e){

        // e.preveventDefault()

     

        const {props} = this 
        const {actions} = props 
        const {push} = actions

        window.open(url,'_blank')
        

    }
   


    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        // const {classes,job,viewPage} = this.props 
        const {bookMarkJob,openLinkPage,props} = this
        const {job,classes} =  props 
        const {job_url} = job 

        return(



            <div className="jobcard" style={boxesStyling}>

                   
                    <section className="jobcard__brand" >
                        {job.logo ? <img src={ job.logo} /> : <span className="bx-raised-1 jobcard__job-bx" style={{...boxesStylingA}}><small style={textColorStylesAccent}>{job.employer}</small></span> } 
                    </section>
                    <section className="jobcard__job">
                        <h3 className="jobcard__job-title-cont">
                            <span className="jobcard__job-title" style={textColorStylesAccent}>
                                { job.jobTitle}
                            </span>
                            <span className="jobcard__job-sub-title" style={textColorStyles}>{ job.employer}</span>
                        </h3>
                        <i className="jobcard__job-salary" style={textColorStyles}>{job.currency} {job.jobSalary}</i>
                    </section>
                    <section className="jobcard__more">
                       
                        <span className="jobcard__more-apply" onClick={openLinkPage.bind(this,job_url)}>Apply on website</span>
                        
                    
                    </section>
                    <section className="jobcard__extra"> 
                    
                        <span className="jobcard__extra-type">{ this.formatJobType(job.type)}</span>
                        <span className="jobcard__extra-date">{ this.formatDate(job.dateBookmarked)}</span>

                            <span className="jobcard__extra-icon" style={{right: "-15%"}} onClick={this.deleteBookmarkJob.bind(this,job)}>

                                <Icon>
                                    <Trash className={classes.root} />
                                </Icon>
                            </span>
                            
                        
                    </section>
                    <strong className="clearfix" />

            </div>


          

        //     <div className="jobcard ">

                  
        //             <section className="jobcard__brand">
        //                 {job.logo ? <img src={ job.logo} /> : <span className="bx-raised-1 jobcard__job-bx"><small>{job.employer}</small></span> } 
        //             </section>
        //             <section className="jobcard__job">
        //                 <h3 className="jobcard__job-title-cont">
        //                     <span className="jobcard__job-title">
        //                         { job.jobTitle}
        //                     </span>
        //                     <span className="jobcard__job-sub-title">{ job.employer}</span>
        //                 </h3>
        //                 <i className="jobcard__job-salary">{job.currency} {job.jobSalary}</i>
        //             </section>
        //             <section className="jobcard__more">
        //                 <span className="jobcard__more-location">{ job.city_name /*job.id*/}</span>
                       
        //                 <span className="jobcard__more-apply" onClick={(e)=>viewPage('/')}>Apply on website</span>
                        
                    
        //             </section>
        //             <section className="jobcard__extra">
        //                 <p className="jobcard__extra-type">
        //                     <span className="icon"></span>
        //                     <span className="job">{ job.type}</span></p>
                            
        //                 {/* <p className="jobcard__extra-date">{/* job.date *Last hour</p> */}
        //                  <span>
        //                     <Icon>
        //                         <Trash />
        //                     </Icon>
        //                 </span> 
        //             </section>
        //             <strong className="clearfix" />

        //<span className="jobcard__more-location">{ job.jobCity || 'Cape Town' /*job.id*/}</span>
        
        // </div>
        )
    }

}


export default withStyles(styles)(Card)