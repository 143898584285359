import React from 'react';
  
  class Card extends React.Component {


    constructor(props){
      super()
    }

    render() {

      const {props} = this 
      const {text} = props 
      // let style = { 
      //   backgroundImage: 'url(' + image + ')',
      //  };
      return (
        <article className="complete__card">

          
          <div className="card-body">
          
            
            <h2>{text}</h2>
            {this.props.children}
            
           
        
        </div>
        
        </article>
      )
    }
  }
  
  export default Card
