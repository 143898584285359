
import React, { Component } from 'react' 





 function SearchBar (){
    
   
  

    return(

      
           <section className="job__search--bar">

               <p className="job__search--bar-text">
                      Search for your desired job
               </p>
             
           </section>
      
    )

}


export default SearchBar