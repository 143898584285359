
import * as types from './types'
import * as props from './props'
import { actions } from 'reapop'



export default (state = props, action)=>{

    switch (action.type) {

        case types.LOGIN_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isCaptchaVerified: false,
         
                
            }

        case types.LOGIN_SUCCESS:

            return  {

                ...state,
                appAuth: {...state.appAuth,isAuth: true},
                isFetching: false,
                isCaptchaVerified: false,
                message: ''

                
                
            }

        case types.LOGIN_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isCaptchaVerified: false,
                message: action.message

            
                
            }

        case types.REGISTER_REQUEST:

       

            return  {

                ...state,
                appAuth: {...state.appAuth,isAuth: false},
                isFetching: true,
               
               

                
                
            }


        case types.REGISTER_SUCCESS:

           

            return  {

                ...state,
                appAuth: {...state.appAuth,isAuth: true},
                isFetching: false,
                isCaptchaVerified: false,
                message: ''

        
                
            }


        case types.REGISTER_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.message

                
                
            }
           
        case types.LOGOUT_REQUEST:

            return  {

              
                ...state,
                isFetching: false,
                
                
                
               
                
                
            }
    
        case types.LOGOUT_SUCCESS:

            return  {

                ...state,
                isFetching: false,
                isAccountVerified: false,
                verificationFailed: false,
                appAuth: {...state.appAuth,isAuth: false}

               
                
            }

        case types.LOGOUT_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isCaptchaVerified: false,
                message: action.message
               
                
                
            }

        case types.REMOVE_SHOWN_MESSAGE:

            return  {

                ...state,
                message: action.message
                
                
                
            }
        case types.CAPTCHA_VERIFY_REQUEST:

    

            return  {

                ...state,
                isFetching: true,
                isCaptchaVerified: false,
                       
            }


        case types.CAPTCHA_VERIFY_SUCCESS:
          

            return  {

                ...state,
                isCaptchaVerified: action.isCaptchaVerified,
                isFetching: false,
                message: ''

        
                
            }


        case types.CAPTCHA_VERIFY_FAILURE:

            return  {

                ...state,
                isCaptchaVerified: false,
                isFetching: false,
                message: action.message

                
                
            }

        case types.SEND_USER_VERIFY_ACCOUNT_REQUEST:

            return  {

                ...state,
                isFetching: true,
                
                
            }

        case types.SEND_USER_VERIFY_ACCOUNT_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                appAuth: {...state.appAuth,isAuth: true},
                isAccountVerified: true,
                
                
            }

        case types.SEND_USER_VERIFY_ACCOUNT_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isAccountVerified: false,
                verificationFailed: true,
                message: action.error
                
            }

    
        
    


        default:
        return state
    }


}