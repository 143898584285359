
import home from '../Home/reducer'
import contact from '../Contact/reducer'
import career from '../Career/reducer'
//import candidateDashboard from '../CandidateDashboard/reducer'
import candidateVerify from '../CandidateVerify/reducer'
import candJobAlertsCat from '../CandJobAlertsCat/reducer'
import candJobAlertsList from '../CandJobAlertsList/reducer'
//import candJobAlertsManage from '../CandJobAlertsManage/reducer'
// import candSavedJobs from '../CandSavedJobs/reducer'
// import candHistory from '../CandHistory/reducer'
// import candSettings from '../CandSettings/reducer' 
import userProfile from '../UserProfile/reducer'
import listing from '../Listing/reducer'
import resetPassword from '../ResetPassword/reducer'
import forgotPassword from '../ForgotPassword/reducer'
import unsubscribe from '../Unsubscribe/reducer'
import alertAccountVerify from '../AlertAccountVerify/reducer'
import resendVerification from '../ResendVerification/reducer'
import humanoid from '../Humanoid/reducer'
import blogr from '../Blogr/reducer'
import launch from '../Launch/reducer'
// import userResume from '../UserResume/reducer'
// import viewResume from '../ViewResume/reducer'
// import accountVerify from '../AccountVerify/reducer'
// import navigator from '../Navigator/reducer'
// import history from '../History/reducer'
import header from '../Header/reducer'
import loggedHeader from '../LoggedHeader/reducer'
import chatHeader from '../ChatHeader/reducer'
import footer from '../Footer/reducer'
import login from '../Login/reducer'
import auth from '../Auth/reducer'
import errorHandler from '../ErrorHandler/reducer'


export default {

    home,
    contact,
    //candidateDashboard,
    candidateVerify,
    //candJobAlertsCat,
    //candJobAlertsList,
    //candJobAlertsManage,
    //candSavedJobs,
    //candHistory,
    //candSettings,
    userProfile,
    header,
    loggedHeader,
    chatHeader,
    footer,
    login,
    auth,
    listing,
    resetPassword,
    forgotPassword,
    unsubscribe,
    alertAccountVerify,
    resendVerification,
    humanoid,
    blogr,
    launch,
    errorHandler

}