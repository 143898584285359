import React,{Component} from 'react'
import Icon from '@material-ui/core/Icon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles'
// import Reasons from './reasons'


const styles = {
    root: {
     
      color:"#00b050",
      fontSize: 18,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Hero extends Component{


    constructor(props){

        super()
    }

    resend = (e)=>{

        e.preventDefault()
        const {props} = this
        const {actions,userProfile} = props
        const {resendAccountVerification} = actions 
        const {profile} = userProfile 
        const {personal} = profile
        const {id,email} = personal
        resendAccountVerification({id,email})


    }
    
    
    render(){

        const {props} = this
        const {userProfile,candidateVerify} = props 
        const {profile} =  userProfile 
        const {personal} = profile
        const {email}= personal
        const {isFetching,isResent} = candidateVerify 
        let header = ''
        let subHeader = ''
        let transform = 'rotate(0deg)'
        let msg = ''
        
        if(isFetching){
            header = 'Resending verification email'
            subHeader = 'Verification email resend in progress to'
            transform = 'rotate(20deg)'
        }else if(isResent){

            header = 'Verificatiom email resent'
            subHeader =  `We've resent a verification email to` 
            msg =  `The verification email contains a link that you should click to activate your account.`

        }else{

            header = 'Verification email sent'
            subHeader = `We've sent verification email to `
            transform = 'rotate(-20deg)'
            msg =  `The verification email contains a link that you should click to activate your account.`


        }

        


        const iconContStyles = {

            display: 'inline-block',
            verticalAlign: 'top',
            marginTop: '3px',
            marginLeft: '5px',
            transform: transform
        }
        
        

        return(

            <div className="verify__hero">

               <section className="verify__parallelogram bx-raised">

                  <div className="verify__parallelogram--skew">
                   <div className="verify__parallelogram--h">
                        <h2>{header} <span style={iconContStyles}><Icon ><SendIcon style={styles.root} /></Icon></span></h2>
                        <small>{subHeader} <span style={{color: 'black',opacity: .8}}>{email}</span></small>
                        <span></span>
                    </div> 

                    {/* <figure className="verify__parallelogram--fig">
                        <img src="/img/sad.png" width="120" />
                    </figure> */}

                    {/* <div className="verify__parallelogram--q">
                        <h2>Why are you leaving us?</h2>

                    </div> */}
                    <div className="verify__parallelogram--txt">

                        {
                        isFetching 
                            ?
                            <>
                                <div className="verify__loader"></div>
                                <h2 className="verify__loader__text">Resending verification email...</h2>
                            </>
                            : <p>
                                {msg}
                              </p> 
                        }

                    </div>

                    <div>
                       { !isFetching ? <button className="verify__launchcta-submit" onClick={this.resend.bind(this)}>
                            <span className="verify__launchcta-submit-rect"></span>
                            <span className="verify__launchcta-submit-rect-2">
                                <small>Resend Another email</small></span>
                        </button>
                        :null
                        }

                        
                    </div>

                    </div>


               </section>

               {/* <section className="verify__parallelogram-2 bx-raised"></section>
               <section className="verify__parallelogram-3 bx-raised"></section> */}
            </div>
        )
    }

}

export default withStyles(styles)(Hero)

// export default Hero