
import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import Plan from './plan'
import Sponsored from './sponsor'
import { ThemeContext } from '../../../../context';



const styles = {
    root: {
     
   
      fontSize: 40,
     
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };


 class Price extends Component{
    
    constructor(){
        super()

        this.state ={

            option: 'sponsor',
            current: {

                active: "po__price--options-btn po__price--options-active",
                off: "po__price--options-btn"
                
            }
        }
    }

    changeInsightOption(option,e){

        if(e)  e.preventDefault()

        this.setState({
            option: option
        })

    }

    renderOption(){




        switch(this.state.option){

            case 'plan':
            
                return(<Plan />)
            break;
            case 'sponsor':
                return(<Sponsored />)
            break;
         
            default:
                return null
        }

    }
   
  
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        return(

        
            <section className="po__price">

                <div className="po__price--options">

                    <button className="po__price--options-btn" onClick={(e)=>this.changeInsightOption('plan',e)} style={{...boxesStylingA, ...textColorStylesAccent}}>Plans</button>
                    <button className="po__price--options-btn" onClick={(e)=>this.changeInsightOption('sponsor',e)} style={{...boxesStylingA, ...textColorStylesAccent}}>Sponsored</button>
                </div>

                {this.renderOption()}

  
                
            </section>
        
        )
    }

}


export default withStyles(styles)(Price)