
import * as templates from './templates'
import Icon from '@material-ui/core/Icon';
import CheckIcon from '@material-ui/icons/Check';
import Say, {SayButton} from 'react-say'
import { SaySpeech } from '../components/SaySpeech';


import {delayMessage,delayResponseMessage,randomize,formatDate,getFiller,scrollToBottom} from './util'
import {nextQuestion,readResponse} from './questionr'
import { isProcessing, messages } from '../props';

export const showIO = (self=null,qs,index=0) =>{

    const {multiText=false,question=null,responseText='',enforce=false,processor=false} = qs
    const {props} = self 
    const {actions} = props 
    const {saveChatText,saveMessage} = actions 
    console.log('SHOWIO PROCCESSOR',processor)
    //const {isTyping} = chatHeader 
    //const {messages=[]} = humanoid 
    

    // if(enforce) return saveMessage([{
            
    //     text: responseText,
    //     date: new Date()
    // }])

    if(processor) {
        console.log('THE PROCESSOR MESSAGE SHOULD SHOW')
        console.log('THE RESPONSETEXT FOR PROCESSOR')
        console.log('THE RESPONSE TEXT;;;',responseText)
        delayResponseMessage(self,[{
            
        text: responseText,
        date: new Date()
            }],null,3000)
            return scrollToBottom()
        }

    if(enforce) {
            delayResponseMessage(self,[{
                
            text: responseText,
            date: new Date()
        }],null,6000)
        return scrollToBottom()
    }

    if(!question) {
        saveMessage([{
            
        text: responseText,
        isSender: true,
        date: new Date()
        }])
        return scrollToBottom()
    }
    

    if(multiText){
        
        console.log('mULTITEXT;;;',question)
        console.log(JSON.stringify(question))
        question.forEach((q,i)=>{

            const {text='',isTemplate=false, template_name='',isHigherOrder=false} = q

            if(i === 0){

                // delayResponseMessage(self,[{
            
                //     text: text instanceof Array ? randomize(text) : text,
                //     date: new Date()
                // }],{q,i})

                if(isHigherOrder){

                    delayResponseMessage(self,[{
            
                        text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                        date: new Date()
                    }],{q:q,index:i},0,0)

                }else{

                    delayResponseMessage(self,[{
            
                        text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                        date: new Date()
                    }],{q:q,index:i},0,0)

                }
                

                // saveChatText(
                //     [{
            
                //         text: text instanceof Array ? randomize(text) : text,
                //         date: new Date()
                //     }],
                //     q,
                //     i
                
                // )
            }else{

                if(isTemplate) {
                    delayMessage(self,saveTemplateMessage,{templateName: template_name,q:q,currentIndex: i},null,1000,1000)
                    if(text instanceof Array || text.trim()) delayResponseMessage(self,[{
            
                        text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                        date: new Date()
                    }],{q:q,index:i},0,0)
        
                
                // saveChatText(
                //     [{
        
                //         text: text instanceof Array ? randomize(text) : text,
                //         date: new Date()
                //     }],q,i)
                    
                }else{

                    if(isHigherOrder){

                        delayMessage(self,saveChatText,{text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),date: new Date()},q,i,500,500)
            
        
                    }else{
        
                        delayMessage(self,saveChatText,{text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),date: new Date()},q,i)
        
                    }
        
        
                   
        
                }
        

                
            }


        })

       

    
        
    }else{

        const {template_name='',isTemplate=false,text='', isHigherOrder=false} = question 

        if(isTemplate) {
            delayMessage(self,saveTemplateMessage,{templateName: template_name,q:question,currentIndex: index},null,1000,1000)
           if(text instanceof Array || text.trim()) delayResponseMessage(self,[{
            
                text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                date: new Date()
            }],{q:question,index:index},0,0)
        
            // saveChatText(
            //     [{

            //         text: text instanceof Array ? randomize(text) : text,
            //         date: new Date()
            //     }],
            //     question,
            //     index)
            
        }else{

            if(isHigherOrder){

                delayResponseMessage(self,[{
            
                    text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                    date: new Date()
                }],{q:question,index:index},1000)
    

            }else{

                console.log('DELAYING RESPONSE MESSAGE;;;',text)
                delayResponseMessage(self,[{
            
                    text: text instanceof Array ? getFiller(self,randomize(text)) : getFiller(self,text),
                    date: new Date()
                }],{q:question,index:index})
    

            }

           

            // saveChatText(
            //     [{
        
            //         text: text instanceof Array ? randomize(text) : text,
            //         date: new Date()
            //     }],question,index
            
            // )

        }


        

    }
    scrollToBottom()
    
   
    
}

export const getIO = (self = null,e = null)=>{

    console.log("THE GETIO E", e)
    console.log("THE GEIO SELF", self)
    if(e && (typeof e === 'object')) e.preventDefault() 
    
    const {props,state} = self 
    const {chatHeader,actions,humanoid} = props
    const {isTyping=false,ignoreUserMessage} = chatHeader 
    const {isProcessing=false,isClearingUnresolved=false} = humanoid
    const {updateTypingStatus} = actions
    

    
    
    const text = state.text
    const aliasText= state.aliasText 
    let textOnShow = aliasText.trim() ? aliasText : text

    console.log('TEXTONSHOW;;;,',textOnShow)
    console.log('isTyping;;;',isTyping)

    //if(!skipText && text.trim() === '') return

    // const {actions,humanoid,chatHeader} = props 
    // const {setIsSetupComplete,saveChatText} = actions 
    // const {isTyping} = chatHeader 
    // const {botCurrentStage} = humanoid 
    if(isTyping || ignoreUserMessage) {
       
        showIO(self,{responseText: textOnShow})
        self.setState({...self.state,text: '',aliasText})
        return 
    }
    if(isProcessing || isClearingUnresolved) {

        showIO(self,{responseText: textOnShow})
        self.setState({...self.state,text: '',aliasText})
        return showIO(self,{responseText: '',processor:true})
        
    }
    showIO(self,{responseText: textOnShow})
    readResponse(self,text)
    // nextQuestion(self,text)
    self.setState({...self.state,text: '',aliasText})
    

}

export const saveTemplateMessage = (self,misProps=null)=>{

    const {props} = self 
    const {actions} = props 
    const {saveChatText} = actions 

    // console.log('THE SAVETEMPLATE')
    // console.log(misProps)

    saveChatText(
        [{
            templateName: misProps.templateName,
            template: true,
            date: new Date()
        }],
         misProps.q,
         misProps.currentIndex
        // misProps.hostCurrentState,
        // misProps.hostCurrentStateSID,
        // misProps.hostCurrentStateCQ
    )


}


export const showConversation = (chat,i,self=null,messages=[],styles={}) =>{

    let message = null 
    const {props} = self 
    const {humanoid}= props
    const {isProcessing=false,isProcessingText='',isClearingUnresolved, isClearingUnresolvedText} = humanoid
    
   
    console.log('IS PROCESSING;;;',isProcessing)
    if(messages.length - 1){
        console.log('MESSAGES;;;',messages)
    }
   
    // console.log('jsonIsP;;;',JSON.stringify(isProcessing))
    // console.log('processing Text;;;',isProcessingText)
    // console.log('JSON TEXT',JSON.stringify(proc))

    if(!chat.isSender){
      
        if(i === messages.length - 1){

            if(isProcessing){
                console.log('IT IS PROCESSING;;;',isProcessing)
                return renderHumanoidProcessCard(self,{chat,i,styles})

            }

            if(isClearingUnresolved){

                return renderHumanoidProcessCard(self,{chat,i,styles})

            }

           return renderHumanoidTemplate(self,{chat,i,styles})

            

        }else{
            
          
            if(messages.length - 2) return renderHumanoidText(self,{chat,i,say:true,isProcessing,styles})
            return renderHumanoidText(self,{chat,i,say:false,isProcessing,styles})
        }

      
    }else{


      message = renderUserTextCard(self,{chat,i,styles})

    }
   
    return message

}


const renderUserTextCard = (self,chatings)=>{
   const {chat,i, styles} = chatings
   const {textColorStyles, boxesStyling, boxesStylingAccent, receiveSend, textDateColor} = styles 
    const {receive, send} = receiveSend
    return  (

        
        <div className="humanoid__chat--chats-send" key={i} style={{...boxesStylingAccent,...send}} >
           
            
            <p className="humanoid__chat--chats-send-text" style={textColorStyles}>
            {chat.text}
            
           
            </p>
            <span className="humanoid__chat--chats-send-date" style={textDateColor}>
                <small className="humanoid__chat--chats-send-date-time">

                 {formatDate(chat.date)}
                </small>

                <small className="humanoid__chat--chats-send-date-checks">

                <span>
                    <Icon>
                        <CheckIcon style={{fontSize:'12px'}} className="fg-primary" />
                    </Icon>
                </span>

                <span>
                    <Icon>
                        <CheckIcon style={{fontSize: '12px'}} className="fg-primary" />
                    </Icon>
                </span>
                </small>
            </span>
            {/* <SayButton
                onClick={ event => console.log(event) }
                speak={chat.text}
            >
               {chat.text}
            </SayButton> */}
        </div>
        )


}


const renderHumanoidTemplate = (self,chatings)=>{
    const {chat,i, styles} = chatings
    const {textColorStyles, boxesStyling, boxesStylingAccent, receiveSend, textDateColor} = styles 
    const {receive, send} = receiveSend
    const {text='',template=false,templateName=''} = chat 
    

    return (<div className="humanoid__chat--chats-receive" key={i} style={{...boxesStylingAccent,...receive}}>

                <p className="humanoid__chat--chats-receive-text" style={textColorStyles}>
                {/* <Say text={text} onError={(error)=>{
                    console.log('REACT-SAY HAS ERRORED;;;',error)
                }} onEnd={()=>{

                    console.log('REACT-SAY HAS ENDED;;;')
                }} /> */}
                {/* <SaySpeech userText={text} /> */}
                {template ? templates[templateName](self) : text}
                
                            
                
                            
                {/* <div class="messages">

                    <section className="message">
                        <p className="messages message loading"><span></span></p>
                    </section>
                        
                        </div> */}
                </p>
                <span className="humanoid__chat--chats-receive-date" style={textDateColor}>
                    {formatDate(chat.date)}
                </span>


                </div>)
}

const renderHumanoidText = (self,chatings)=> {


    const {chat,i, styles, say=false, isProcessing=false} = chatings
    const {textColorStyles, boxesStyling, boxesStylingAccent, receiveSend, textDateColor} = styles 
    const {receive, send} = receiveSend
    const {text='',template=false,templateName=''} = chat 
   
    console.log('HUMANOIDTEXT;;;',text)
    console.log('HUMANOIDTEXT CHAT;;;',chat)

    if(!isProcessing && !text.trim()) return null

    return (<div className="humanoid__chat--chats-receive" key={i} style={{...boxesStylingAccent,...receive}} >

    <p className="humanoid__chat--chats-receive-text" style={textColorStyles}>
    {/* {say && <Say text={text} />} */}

                {/* <SaySpeech userText={text} /> */}
{/*                 
    {template 
        ? templates[templateName](self) 
        : isProcessing && !text.trim() 
            ? <small> Please note that we are Processing your information </small> 
            : text} */}
   
        {isProcessing && !text.trim() 
            ? <small> Please note that we are Processing your information </small> 
            : text
        }
    
                
    
                
    {/* <div class="messages">

        <section className="message">
            <p className="messages message loading"><span></span></p>
        </section>
            
            </div> */}
    </p>
    <span className="humanoid__chat--chats-receive-date" style={textDateColor}>
        {formatDate(chat.date)}
    </span>


</div>)

}

const renderHumanoidProcessCard = (self,chatings)=>{

    const {chat,i, styles} = chatings
    const {textColorStyles, boxesStyling, boxesStylingAccent, receiveSend,textDateColor} = styles 
    const {receive, send} = receiveSend
    return (<>{chat.text.trim() ? <div className="humanoid__chat--chats-receive" key={i} style={{...boxesStylingAccent,...send}} >

    <p className="humanoid__chat--chats-receive-text" style={textColorStyles}>
        {chat.text}
    </p>
    <span className="humanoid__chat--chats-receive-date" style={textDateColor}>
        {formatDate(chat.date)}
    </span>

</div> : null}

<div className="humanoid__chat--chats-receive" key={i} style={{...boxesStylingAccent,...receive}} >

    <p className="humanoid__chat--chats-receive-text" style={textColorStyles}>
        <small>Please note that we are Processing your information</small>
    </p>
    <span className="humanoid__chat--chats-receive-date" style={textDateColor}>
        {formatDate(chat.date)}
    </span>

</div>
</>)

}
