
import React, { Component } from 'react' 
import {useAppThemeContext} from '../../../../context/index'





 function Common (props){

    const {theme, themeName} = useAppThemeContext()
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
    
   
     
     
      


   const searchJobs= (job)=>{

       console.log('The current Job')
       console.log(props) 
       const {actions,search} = props 
       const {saveJobSearchParams,push} = actions 
       const {jl='South Africa'} = search
       //console.log(url)
      // push(url)
       saveJobSearchParams({
        jq: job,
        jl: jl,
        // canSetHistory: true,
        fetchJobs: true
    })

   }

  

    return(

      
     
           <section className="job__search--common">

               <p className="job__search--common-title" style={textColorStyles}>Common Searches:</p>
               <ul>
                      <li style={{...textColorStyles,...boxesStylingAccent}} onClick={searchJobs.bind(this,`IT`)}>IT</li>
                      <li style={{...textColorStyles,...boxesStylingAccent}} onClick={searchJobs.bind(this,`Accounting`)}>Accounting</li>
                      <li style={{...textColorStyles,...boxesStylingAccent}} onClick={searchJobs.bind(this,`General worker`)}>General Worker</li>
                      <li style={{...textColorStyles,...boxesStylingAccent}} onClick={searchJobs.bind(this,`Nursing`)}>Nursing</li>
                      <li style={{...textColorStyles,...boxesStylingAccent}} onClick={searchJobs.bind(this,`Cashier`)}>Cashier</li>
                      <li style={{...textColorStyles,...boxesStylingAccent}} onClick={searchJobs.bind(this,`Admin`)}>Admin</li>
               </ul>
             
           </section>
      
    )

}


export default Common