
export const FETCH_USER_ALERTS_SUBSCRIPTIONS_REQUEST = "FETCH_USER_ALERTS_SUBSCRIPTIONS_REQUEST"
export const FETCH_USER_ALERTS_SUBSCRIPTIONS_SUCCESS = "FETCH_USER_ALERTS_SUBSCRIPTIONS_SUCCESS"
export const FETCH_USER_ALERTS_SUBSCRIPTIONS_FAILURE = "FETCH_USER_ALERTS_SUBSCRIPTIONS_FAILURE"

// export const SEND_USER_AVATAR_UPDATE_REQUEST = "SEND_USER_AVATAR_UPDATE_REQUEST"
// export const SEND_USER_AVATAR_UPDATE_SUCCESS = "SEND_USER_AVATAR_UPDATE_SUCCESS"
// export const SEND_USER_AVATAR_UPDATE_FAILURE = " SEND_USER_AVATAR_UPDATE_FAILURE"


// export const SEND_USER_PROFILE_UPDATE_REQUEST = "SEND_USER_PROFILE_UPDATE_REQUEST"
// export const SEND_USER_PROFILE_UPDATE_SUCCESS = "SEND_USER_PROFILE_UPDATE_SUCCESS"
// export const SEND_USER_PROFILE_UPDATE_FAILURE = " SEND_USER_PROFILE_UPDATE_FAILURE" 

export const SEND_USER_ALERT_SETTINGS_UPDATE_REQUEST = "SEND_USER_ALERT_SETTINGS_UPDATE_REQUEST"
export const SEND_USER_ALERT_SETTINGS_UPDATE_SUCCESS = "SEND_USER_ALERT_SETTINGS_UPDATE_SUCCESS"
export const SEND_USER_ALERT_SETTINGS_UPDATE_FAILURE = " SEND_USER_ALERT_SETTINGS_UPDATE_FAILURE" 

export const SEND_USER_NEW_ALERT_REQUEST = "SEND_USER_NEW_ALERT_REQUEST"
export const SEND_USER_NEW_ALERT_SUCCESS = "SEND_USER_NEW_ALERT_SUCCESS"
export const SEND_USER_NEW_ALERT_FAILURE = " SEND_USER_NEW_ALERT_FAILURE"

export const SEND_USER_ALERT_UNSUBSCRIPTION_REQUEST = "SEND_USER_ALERT_UNSUBSCRIPTION_REQUEST"
export const SEND_USER_ALERT_UNSUBSCRIPTION_SUCCESS = "SEND_USER_ALERT_UNSUBSCRIPTION_SUCCESS"
export const SEND_USER_ALERT_UNSUBSCRIPTION_FAILURE = " SEND_USER_ALERT_UNSUBSCRIPTION_FAILURE"

// export const SEND_USER_ACCOUNT_DELETE_REQUEST = "SEND_USER_ACCOUNT_DELETE_REQUEST"
// export const SEND_USER_ACCOUNT_DELETE_SUCCESS = "SEND_USER_ACCOUNT_DELETE_SUCCESS"
// export const SEND_USER_ACCOUNT_DELETE_FAILURE = " SEND_USER_ACCOUNT_DELETE_FAILURE" 


export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"




