import React,{Component} from 'react'
import Icon from '@material-ui/core/Icon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles'
// import Reasons from './reasons'


const styles = {
    root: {
     
      color:"white",
      borderRadius: '50%',
      backgroundColor: '#00b050',
      fontSize: 15,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Hero extends Component{


    constructor(props){

        super()
    }
    
    
    render(){

        const {props} = this
        const {cancelLoginOnVerification} = props

        return(

            <div className="verify__hero">

               <section className="verify__parallelogram bx-raised">

                  <div className="verify__parallelogram--skew">
                   <div className="verify__parallelogram--h">
                        <h2>Succesfully Verified <span><Icon ><CheckCircleIcon style={styles.root} /></Icon></span></h2>
                        <small>Your job alert subscription has Succesfully been verified.</small>
                        <span></span>
                    </div> 

                    {/* <figure className="verify__parallelogram--fig">
                        <img src="/img/sad.png" width="120" />
                    </figure> */}

                    {/* <div className="verify__parallelogram--q">
                        <h2>Why are you leaving us?</h2>

                    </div> */}
                    <div className="verify__parallelogram--txt">
                    <div className="verify__loader"></div>
                        <h2 className="verify__loader__text">Redirecting to jobs...</h2>
                    </div>

                    {/* <div>
                        <button className="verify__launchcta-submit" onClick={cancelLoginOnVerification}>
                            <span className="verify__launchcta-submit-rect"></span>
                            <span className="verify__launchcta-submit-rect-2">
                                <small>Cancel login</small></span>
                        </button>

                        
                    </div> */}

                    </div>


               </section>

               <section className="verify__parallelogram-2 bx-raised"></section>
               <section className="verify__parallelogram-3 bx-raised"></section>
            </div>
        )
    }

}

export default withStyles(styles)(Hero)

// export default Hero