import React, { Component } from 'react' 





class Distance extends Component{
    
    constructor(props){

        super(props)

    }


    render(){

        return(

           

            

            <form className="form pd-bottom-fd-lg ">
                  <p className="job__search--form-i">

                      <input type="text" className="bx-raised job__search--form-text" placeholder="category"/>


                  </p>
              </form>
            
          
        )
    }

}


export default Distance