import React from 'react';
import Card from './card_cta' 
import Huloader from './huloader'



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Complete extends React.Component{

  constructor(props){
    super();
    
  }




  




  render(){

    
    const text = 'Get personalised Job reccomendations. Sign up to Jobbri today.'
    const {props} = this 
    const {actions} = props 
   
   
    return(


     
        
       

        

          
            <div className="job__reccomend" id="job-recommend">

             <Card text={text} >
                <Huloader actions={actions} />
             </Card>
             

            </div>
          

  
            
          
      


    );
  }
}

export default Complete