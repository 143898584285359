import React, { Component } from 'react' 
import Checkbox from './checkbox/checkbox'
import Radio from './radio/radio'
import films from './films'






class Category extends Component{
    
    constructor(){
        super()

        this.state = {
            form : 'form'
        };
    }

    doLabel(list){


        // let doneList = []

        const {props} = this 
        const {search} = props 
        const {jq,resetFilter} = search 

       return list.map((i,p)=>{


            let checked = resetFilter ? false : typeof jq !== 'undefined' ? jq.trim() === i.category_name.trim() ? true : false : false
            // let checked = p === 0 ? true : false
            // doneList.push({

            //     label: `${i.category_name}  (${i.job_count})`,
            //     value: i.id,
            //     checked: checked
            // })

           return {

                label: `${i.category_name}  (${i.job_count})`,
                value: i.category_name,
                id: i.id,
                checked: checked
            }

        })

        // console.log('THE DONELIST CATEGORY::')
        // console.log(doneList)
        // return doneList 

        // const {props} = this 
        // const {search} = props 
        // const {jcts} = search 
       

        // let doneList = []

        // list.map((i,p)=>{

        //     // let checked = p === 0 ? true : false 

        //     let checked = typeof jcts !== 'undefined' ? jcts.trim() === i.value.trim() ? true : false : false

        //     doneList.push({

        //         label: `${i.category_name}  (${i.job_count})`,
        //         value: i.category_name,
        //         // numValue: i.numValue,
        //         checked: checked
        //     })

        // })

        // return doneList

    }

    handleChecked(i){

        const {props} = this
        const {actions} = props 
        const {saveJobSearchParams} = actions 
        // const {crudJobFilter,saveJobSearchParams} = actions 
        
        // if(i.checked){
        	 
        // 	 crudJobFilter({
        // 	 	key: 'jcts',
        // 	 	value: i.value,
        // 	 	operation: 'add'
        // 	 })
        	
        // }else{
        	
        // 	crudJobFilter({
        // 		key:'jcts',
        // 		value: i.value,
        // 		operation: 'remove'
        // 	})
        // } 

        saveJobSearchParams({
            jq: i.value,
            // jcts: i.value,
            // canSetHistory: true,
            fetchJobs: true,
             resetFilter: false
        })

        
       
    }


    
    render(){

        // const {data} = this.props 
        const {props} = this 
        const {search,data} = props


        console.log('THE CATEGORY FILTER PROPS')
        console.log(data)
        console.log(this.props) 

        if(data.length <= 0) return null

        return(

           

           

            <section className="cat__filter scrollHeight">
                
                <Checkbox list={this.doLabel(data)} help={false} legend={false} handle={(e)=>{this.handleChecked(e)}} />
                {/* <Radio list={this.doLabel(data)} legend={false} search={search} handle={(e)=>{this.handleChecked(e)}} /> */}


             </section>
          
        )
    }

}


export default Category