import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import Search from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles'
import Trash from '@material-ui/icons/Delete';
import { ThemeContext } from '../../../context';





const styles = {
    root: {
     
      color:"seagreen",
      fontSize: 20,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    },
    icon: {
     
        color:"red",
        opacity: .5,
        fontSize: 20,
        '&:hover':{
            color: "green",
            opacity: 1
        }
        
      },
  };




class Card extends Component{

     
    
    constructor(props){

        super(props)
        this.state = {
            months: [
                'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
            ]
        }

    }

    formatDate(date){

        // const {milliSecondsToTime} = this
        // let nw = new Date()
        let setDate = new Date(date) 
        const {state} = this 
        const {months} = state
        // console.log('THE SETDATE')
        // console.log(setDate)
        // console.log(nw)
        // let diffInTime = nw.getTime() - setDate.getTime() 
        // let diffInDays = diffInTime / (1000 * 3600 * 24) 

        // console.log('THE TIME IN DAYS')
        // console.log(this.milliSecondsToTime(diffInTime))

        // return this.milliSecondsToTime(diffInTime)

        return `${setDate.getDate()} ${months[setDate.getMonth()]} ${setDate.getFullYear()}`




    }


    openLinkPage(url,e){

        // e.preveventDefault()

     

        const {props} = this 
        const {actions} = props 
        const {push} = actions

        push(url)
        

    }

    deleteSearchHistory(job){


        
        const {props} = this 
        const {userProfile,actions} = props 
        const {profile} = userProfile 
        const {personal} = profile 
        const {id} = personal 
        const {deleteUserSearchHistory} = actions  
        const jobID = job.id 

      
        deleteUserSearchHistory({ID:id,id:jobID})
        // this.forceUpdate()



        // sendUserJobBookmark({})

       
    }

    

   


    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


         const {props} = this 
         const {job,classes} = props
         const {searchTerm,dateSearched} = job
         const iconStyles = {display:'inline-block',verticalAlign:'top'}
        return(

          

            <div className="dashboard__searchhistory--card " style={boxesStyling}>

            
                
                <section className="dashboard__searchhistory--card-job">
                    <h3 className="dashboard__searchhistory--card-job-title-cont">
                        <span className="dashboard__searchhistory--card-job-title" style={textColorStylesAccent}>
                            {searchTerm}
                        </span>
                        
                    </h3>
                    <span style={{display: 'block',fontSize: '1.2rem',marginBottom: '5px',color: 'grey'}}>Date Searched</span>
                    <i className="dashboard__searchhistory--card-job-date" style={{display:'block'}}>{this.formatDate(dateSearched)}</i>
                    <span className="dashboard__searchhistory--card-job-search" 
                        style={{...iconStyles}}
                        onClick={this.openLinkPage.bind(this,`/jobs?jq=${searchTerm}`)}>

                     <i style={styles.root} className="fa fa-search"></i>
                       
                    </span>
                    <span className="dashboard__searchhistory--card-job-search"
                     style={{...iconStyles,color: 'red'}} onClick={this.deleteSearchHistory.bind(this,job)}>
                     
                                 <Icon>
                                    <Trash style={styles.icon} />
                                </Icon>
                       
                    </span>
                </section>
                
                
        
        </div>
        )
    }

}


export default withStyles(styles)(Card)