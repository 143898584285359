
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as authActions from '../Auth/actions'
import ClientErrorHandler from '../ClientErrorHandler/component'





class LoggedErrorBoundary extends Component{


    constructor(props) {
        super(props);
        this.state = { hasError: false };
      }
    
      static getDerivedStateFromError(error) {    
          
        // Update state so the next render will show the fallback UI.    
        return { hasError: true };  
      }
      componentDidCatch(error, errorInfo) {    

        console.log('ERROR BOUNDARY ERROR')
        console.log(error)
        console.log(errorInfo)
        const {props} = this 
        const {actions}  = props 
        const {logoutUser} = actions 
        // logoutUser(false)
        //reportError(error,errorInfo)

          // You can also log the error to an error reporting service
            //logErrorToMyService(error, errorInfo);  
     }

    //  reportError = (error,errorInfo)=>{


    //  }

    render(){


          const errorMeta = {
              header: 'Error!',
              headerText: 'Something has went wrong',
              buttonText: 'Login Again'
          }

           
            if(this.state.hasError) {      
                // You can render any custom fallback UI      
                return <ClientErrorHandler errorMeta={errorMeta} action="error" />
            }
            return this.props.children; 
        
    }




}


const mapStateToProps = (state)=>{

    const {auth,userProfile} = state

    return{
        auth,
        userProfile
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...authActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(LoggedErrorBoundary)