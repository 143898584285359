
import React, {Component} from 'react' 
import Upload from './alert' 






class Modal extends Component{


    constructor(props){

        super()
        
    }



    render(){


       
        const {text='',buttonProps=null,children=null,popUp=null,button=null,styles=null,userProfile,actions,componentProps={}} = this.props 
    
        return(

                 
                  
            <Upload text={text} buttonProps={buttonProps} styles={styles} popUp={popUp} children={this.props.children} actions={actions} button={button} userProfile={userProfile} componentProps={componentProps}  />
                  
        )
    }




}




export default  Modal