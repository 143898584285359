

import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import Modal from 'react-modal'; 
import copy from 'copy-to-clipboard';
import { ThemeContext } from '../../context';
// import Form from './form'
 
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '60%',
    padding               : 0,
    border                : 'none',
    background            : 'none',
  
    borderRadius          : 0
  }
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement')
 

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)'

class AlertModal extends Component{


  constructor(props){
    super(props)
    this.state ={
      modalIsOpen: false,
      textCopied: false,
      emailMessageSubject: `Surprise CV Link`,
      emailMessageBody:  `Hi there, 

      Please find a link to my cv on Jobbri below: 
      
      `,
      userEmail: 'mashelesepru@gmail.com',
      userName: 'Ntsako',
      userLink: 'eireis645wr-ntsako-cv',
      emailTemp: 'mailto:userEmail?subject=userName%20CV%20Link%20on%20Jobbri&body=Hi%20there%2C%0A%0APlease%20find%20a%20link%20to%20my%20cv%20on%20Jobbri%20below%3A%0A%20%0Ahttps%3A%2F%2Fwww.jobbri.co.za%2FuserLink'
    }
  }
  // const [modalIsOpen,setIsOpen] = React.useState(false);
  openModal(){
    this.setState({...this.state,modalIsOpen: true})
  }
 
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
 
  closeModal(){
    console.log('THE VALUE OF THIS')
    console.log(this)
    console.log(this.setState)
    this.setState({...this.state,modalIsOpen: false,textCopied: false})
  }

  onCopy = ()=>{

    this.setState({...this.state,textCopied: true})
  }

  copyTextToCliboard = (text,event)=>{

      copy(text,{onCopy: this.onCopy})
  }

  
 
  static contextType = ThemeContext
    
  render(){

      const {theme, themeName} = this.context 
      const boxesStyling = { backgroundColor: themeName == 'dark' 
      ? theme.colors.complimentary['accent-3'] 
      :  theme.colors.complimentary.base }
      const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
      const boxesStylingAccent = {backgroundColor: theme.colors.complimentary['accent-3']}
      const textColorStyles = {color:  theme.colors.complimentary.foreground}
      const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


    
    const {state,props,closeModal,openModal,afterOpenModal} = this 
    const {modalIsOpen,textCopied,userEmail,userName,userLink,emailTemp} = state 
    const ActionButton  = props.actionButton || null 
    let sendEmail = emailTemp.replace('userEmail',userEmail)
    sendEmail = sendEmail.replace('userName',userName)
    sendEmail = sendEmail.replace('userLink',userLink)
    // console.log('THE SEND EMAIL')
    // console.log(sendEmail)
    // console.log(sendEmail.indexOf('userName'))


    return (
      <div style={{display: 'inline-block',width:'140px', marginRight: '25px'}}>
         <span onClick={openModal.bind(this)}>

            <ActionButton /> 
         </span>
        

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal.bind(this)}
          style={customStyles}
          contentLabel="Example Modal"
        >

        

            <section className="alert__modal">

              <article className="alert__modalcontainer" style={{maxWidth: '600px',position: 'relative'}}>

                  <div className="resume__sharemodal-rect resume__sharemodal-rect-1"></div>
                  <div className="resume__sharemodal-rect resume__sharemodal-rect-2"></div>
                  <div className="resume__sharemodal-rect resume__sharemodal-rect-3">
                        <div style={{width: '90%'}}>

                            <section style={{marginBottom: '30px',marginTop: '15px'}}>

                                <h3 style={{marginBottom: '10px',fontSize: '16px'}}>URL Link to your resume for easy access</h3>
                                <span style={{display: 'inline-block',color: 'seagreen',fontSize: '13px',fontWeight: 'bolder'}}>
                                    <small style={{display:'inline-block',marginRight: '5px',color: 'white',borderRadius: '2px',opacity: .8,position: 'relative',padding: '3px',paddingLeft: '7px',paddingRight: '7px',backgroundColor: 'seagreen'}}>
                                        <i class="fa fa-link" aria-hidden="true"></i>
                                    </small>
                                    <small>
                                    https://www.jobbri.co.za/flsor6whe-surprise-cv
                                    </small></span>
                            </section>

                            <span className="resume--action-btns resume--action-btns-offset ">

                                <span className="resume--action-btns-rect resume--action-btns-rect-2"></span>
                                <span className=" resume--action-btns-rect resume--action-btns-rect-3"></span>
                                <span className="resume--action-btns-rect resume--action-btns-rect-4">
                                    <small className="resume--action-btns-rect-4-icon">
                                    {!textCopied 
                                        ?<i class="fa fa-clipboard" aria-hidden="true"></i>
                                        : <i class="fa fa-check" aria-hidden="true"></i> 
                                        }


                                    </small>
                                    <small onClick={this.copyTextToCliboard.bind(this,'https://www.jobbri.co.za/flsor6whe-surprise-cv')}>{!textCopied ? 'Copy CV URL' : 'Copied'}</small>
                                </span>
                                </span> 

                            <a href={sendEmail} target="_blank" className="resume--action-btns ">

                                <span className="resume--action-btns-rect resume--action-btns-rect-2"></span>
                                <span className=" resume--action-btns-rect resume--action-btns-rect-3"></span>
                                <span className="resume--action-btns-rect resume--action-btns-rect-4">
                                    <small className="resume--action-btns-rect-4-icon" style={boxesStyling}>
                                    <i className="fa fa-envelope" aria-hidden="true"></i>

                                    </small>
                                    <small style={textColorStyles}>Email CV URL</small>
                                </span>
                            </a>


                        </div>
                  </div>
                  
              </article>

              <div className="alert__modalfootr bx-raised-1" style={{marginLeft: 0, maxWidth: '600px',marginTop: '50px'}}>

                <section className="alert__modalfootr--container">

                 
                  <button className="alert__modalfootr--cancel" onClick={closeModal.bind(this)}>Cancel</button>

                </section>

              
              </div>
            </section>

        
 
         
          
        </Modal>
      </div>
    )
  
  }
}
 
export default AlertModal