
import React,{Component} from 'react'
import Jobseeker from './jobseeker/jobseeker' 
import Employer from './employer/employer' 
import Service from './service/service'
import { ThemeContext } from '../../../context'


class Services extends Component{
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color: theme.colors.complimentary.foreground}

        const {props} = this 
        const {actions} = props

        return(

           

                
                <div  className="about__us">
                      <Service styles={{boxesStyling, textColorStyles}} />
                     <Jobseeker actions={actions} />
                     <Employer />
                     
                </div>
               
                

           
        )
    }

}


export default Services