
import React,{Component} from 'react'
import {BrowserRouter as Router, Link} from 'react-router-dom' 


class Facebook extends Component{


 
    
    
    render(){

        return(

           

             
                      
                
                    <section className="access__header--social bx-raised-1">

                       
                        <Link to="https://www.facebook.com/"  className="access__header--social-2 link">
                            <img className="access__header--social-img" src="/img/social/facebook.png" /> 
                        </Link >
                       
                    </section>

                   
       
               
                

           
        )
    }

}


export default Facebook