
import React, {Component} from 'react' 
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import * as authActions from '../Auth/actions'
import * as userProfileActions from '../UserProfile/actions'

// import ProgressBarCircular from '../ProgressBarCircular/component'
import AccessHeader from './accessheader/accessheader'
import SignupForm from './signup/signup'
import SigninForm from './signin/signin'
import NewSignin from './newSignin/launchform'
import NewSignup from './newSignup/launchform'

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
//import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Icon from '@material-ui/core/Icon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles'
import { isFetching } from './props'
import { ThemeContext } from '../context'
// import Reasons from './reasons'


const styles = {
    root: {
     
      color:"white",
      borderRadius: '50%',
      backgroundColor: '#00b050',
      fontSize: 15,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };





class Login extends Component{


    constructor(){

        super()
        this.state = {

            currentForm: 'signup',
            progressBarPercent: 0,
           
            

        }
        this.intervalID = null
        
    }


    componentDidUpdate(){

        console.log('THE LOGIN COMPONENT WILL MOUNT AT SOME POINT')
        console.log('THE VALUE OF AUTH IN DID UPDATE') 
        const {props,intervalID} = this 
        const {auth,actions} = props 
        const {push} = actions
        const {isFetching} = auth
    
     

       // console.log(isAuth)
        


         if(!intervalID && isFetching === true){

           
        
              document.body.style.overflow = 'hidden'
              document.body.style.pointerEvents ='none'
              document.body.style.opacity = '0.9'
            this.startProgressBar()
          
         }else if(isFetching === false && intervalID){

            document.body.style.overflow = 'visible'
            document.body.style.pointerEvents ='all'
            document.body.style.opacity = '1'
            clearInterval(intervalID)
            this.intervalID = null
            this.setState({...this.state,progressBarPercent: 0})

         }
       
         
        

    }

    componentDidMount(){

        console.log('THE LOGIN COMPONENT WILL MOUNT AT SOME POINT')
        console.log('THE VALUE OF AUTH IN DID UPDATE') 
        const {props} = this 
        const {auth,actions} = props 
        const {push} = actions
        const {appAuth} = auth
        const {isAuth} = appAuth

        console.log(isAuth)
      

         if(isAuth){

            console.log('ABOUT TO REDIRECT TO THE DASHBOARD AREA')
            push('/dashboard')
         }
       
         
        

    }

    startProgressBar(){

          
       this.intervalID = setInterval(()=>{

          const {state} = this
          const {progressBarPercent} = state
          let proPercent = progressBarPercent+5
          if(proPercent <= 95) this.setState({...state,progressBarPercent: proPercent })

        },1000)
    }




    renderForm (form,e){

        e.preventDefault()
        this.setState({
            currentForm: form
        })
    }

    registerUser = (user)=>{

        console.log('THIS  LOGIN COMPONENT')
        console.log(this)
        const {props} = this 
        const {actions} = props 
        const {registerUser} = actions
        registerUser(user)
    }

    loginUser = (user)=>{

        console.log('THIS  LOGIN COMPONENT')
        console.log(this)
        const {props} = this 
        const {actions} = props 
        const {loginUser} = actions
        loginUser(user)
    }

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        

        console.log('THE RERENDER OF LOGIN')
        const {props,state,intervalID} = this 
        const {actions,auth} = props 
        const {isFetching} = auth
        const {progressBarPercent} = state 
        console.log('IS FETCHING VALUE:', isFetching)
        // console.log(this.props.login)
        // console.log(this.props.login.auth)

        return(

            <article className="access">

                <Helmet>
                    <title>Jobbri - Login | Sign up</title>
                </Helmet>
               
               <AccessHeader actions={actions} />
               <section className="access__body bx-raised-1" style={boxesStylingA}>
                  
                <div className="access__body--options">
                    <span className={this.state.currentForm === 'signup' ? 'access__body--options-active ' : 'access__body--options-disabled' } onClick={(e)=>this.renderForm('signup',e)} style={this.state.currentForm === 'signin' ? {...textColorStyles} : null  }  >Sign up</span>
                    <span className={this.state.currentForm === 'signin' ? 'access__body--options-active access__body--options-offset' : 'access__body--options-disabled ' } onClick={(e)=>this.renderForm('signin',e)} style={this.state.currentForm === 'signup' ? {...textColorStyles} : null  } >Sign in</span>
                </div>

                <div className="access__body--access" >

                    {this.state.currentForm === 'signin' ? <NewSignin actions={actions} toRedirect='/dashboard' intervalID={intervalID} auth={auth} loginUser={this.loginUser} error={this.props.message} />  : <NewSignup actions={actions} toRedirect='/dashboard' intervalID={intervalID} auth={auth} error={this.props.message} registerUser={this.registerUser} renderForm={this.renderForm.bind(this)} /> }
                    
                    

                </div>

                
 
              {  isFetching === true ?
              
                    <div  style={{width: '80px',
                            position: 'fixed',
                            left: '1%',
                            bottom: 0}}>

                            <CircularProgressbarWithChildren value={progressBarPercent}>
                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                        {/* <img style={{ width: 40, marginTop: -5 }} src="https://i.imgur.com/b9NyUGm.png" alt="doge" /> */}
                        <span style={{marginBottom: '5px', color: '#00b050', fontWeight: 'bolder'}}><Icon><SupervisorAccountIcon /></Icon></span>
                        <div style={{ fontSize: 12, marginTop: -5 }}>
                            <strong>{`${progressBarPercent}%`}</strong> mate
                        </div>
                        </CircularProgressbarWithChildren>;
                            {/* <CircularProgressbar value={percentage} text={`${percentage}%`} /> */}

                    </div>
                    : null
              }
               
               </section>
              

            </article>
        )
    }




}

const mapStateToProps = (state)=>{

    
    


    const {auth,login} = state

    return{

        auth,
        login
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...userProfileActions,...navigatorActions,...authActions},dispatch)
    }
}

export default withStyles(styles)(connect(mapStateToProps,mapDispachToProps)(Login))