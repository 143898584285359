
import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import * as launchActions from '../Launch/actions'

import PostHeader from './components/postheader/postheader'



class ComingSoon extends Component{


    constructor(props){

        super(props) 
        this.state = {

            isHeaderBottom: false,
            isScrollTo: false
        }
    }

    handleScroll = () => {

       
        // const width = window.innerWidth 
        // const {state} = this 
       


        // if(width <= 1200){

        //     this.setState({...this.state,width: width},()=>{

        //         window.addEventListener('scroll',this.handleScroll)

        //     })
           
        // }else{


            const offset= window.scrollY; 
            const wrappedElement = document.getElementById('po-scrollto');
            const wrappedElement2 = document.getElementById('po-header');
            

            if(wrappedElement && wrappedElement2){

                if(this.isBottom(wrappedElement)) return  this.setState({...this.state,isScrollTo: true,isHeaderBottom: false});
                if(this.isBottom(wrappedElement2)) return  this.setState({...this.state,isScrollTo: false,isHeaderBottom: true});
            
            }
       
    }

   

    isBottom(el) {

        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    // componentDidMount(){

    //     this.handleScroll()
    //     window.addEventListener('scroll',this.handleScroll)
    // }




    render(){

        const {state,props} = this 
        const {launch,actions} = props
        const {isHeaderBottom,isScrollTo} = state

        return(

            <article className="po">

                <Helmet>
                    <title>Jobbri - Post Jobs</title>
                </Helmet>
                
                <PostHeader isHeaderBottom={isHeaderBottom} isScrollTo={isScrollTo} launch={launch} actions={actions} />
                <div id="po-scrollto"></div>

            </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        launch:{
            ...state.launch
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...launchActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(ComingSoon)