import * as types from './types'
import { CALL_API } from '../../middlwares/api'



export const sendLaunchNotification = (data)=> {

  

  return {
  
    [CALL_API]: {
      endpoint: `/notifier`,
      request: 'URL',
      method: 'POST',
      payload: {action: 'saveNotifierCandidate',payload: {launch: {...data}}},
      isProgress: true,
      authencitcated: false,
      types: [types.SEND_LAUNCH_REQUEST, types.SEND_LAUNCH_SUCCESS, types.SEND_LAUNCH_FAILURE]
    }
  }

}


export const removeNotification = ()=>{

    return {
  
      type: types.UNSET_NOTIFICATION_DISPLAY,
      payload: false
    }
  }

  export const removeMessage = ()=>{

    return {
  
      type: types.UNSET_MESSAGE_NOTIFICATION_DISPLAY,
      payload: false
    }
  }
  


 

