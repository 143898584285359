import React,{Component} from 'react'
import Icon from '@material-ui/core/Icon';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles'
// import Reasons from './reasons'


const styles = {
    root: {
     
      color:"white",
      borderRadius: '50%',
      backgroundColor: 'red',
      fontSize: 15,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Verification extends Component{
    
    
    render(){

        const {props} = this
        const {retryLogin} = props

        return(

            <div className="verify__hero">

               <section className="verify__parallelogram bx-raised">

                  <div className="verify__parallelogram--skew">
                   <div className="verify__parallelogram--h">
                        <h2>Verification Failed <span><Icon ><CancelIcon style={styles.root} /></Icon></span></h2>
                        <small style={{color: 'red'}}>Your account has not been verified</small>
                        <span></span>
                    </div> 

                    {/* <figure className="verify__parallelogram--fig">
                        <img src="/img/sad.png" width="120" />
                    </figure> */}

                    {/* <div className="verify__parallelogram--q">
                        <h2>Why are you leaving us?</h2>

                    </div> */}
                    <div className="verify__parallelogram--txt">
                    
                        <p>It's likely that the link may have expired or something went wrong on our end. In any case, you can retry the verification by clicking on the link sent to your email or login to your account to resend the verification.</p>
                    </div>

                    <div>
                        <button className="verify__launchcta-submit" onClick={retryLogin}>
                            <span className="verify__launchcta-submit-rect"></span>
                            <span className="verify__launchcta-submit-rect-2">
                                <small>Retry Verification</small></span>
                        </button>

                        
                    </div>

                    </div>


               </section>

               <section className="verify__parallelogram-2 bx-raised"></section>
               <section className="verify__parallelogram-3 bx-raised"></section>
            </div>
        )
    }

}

export default withStyles(styles)(Verification)

// export default Hero