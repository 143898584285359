
import React, {Component} from 'react' 
import FormControl from '../../../formcontrol'
// import ProgressBarLinear from '../../progressbarlinear'
import { toast } from 'react-toastify';



class Form extends  Component{


    constructor(props){

        super()

      
        // console.log('PROPS IN THE CONSTRUCTOR')
        // console.log(props)
        // let initValue = ''
        const {values} = props 
        let initValue = values['keyword'] ? values['keyword'] : ''


        this.state = {
            form : {
                    
                    
                
                keyword:{
                    
                    control: 'input',
                    value: initValue,
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                        
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "keyword",
                        type: 'text',
                        placeholder: 'Job title or keyword',
                        // disabled: false
                        
                    }
                    
                },
              
               

              
            
            },
            
        };
    }

    
    updator = (upControl)=>{
        
            console.log('THE UPDATE CONTROL')
            console.log(upControl)
            const {props} = this 
            const {handleChange} = props
            let newForm ={...this.state.form} 
            let updateControl = {...newForm[upControl.id]}
            updateControl.value = upControl.e.target.value 

        
            
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1]

                    if(!updateControl.isValid){

                        for(let k in newForm){ 


                            if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                                newForm[k].config.disabled = true
                            }
                        }
                    }else{

                        for(let k in newForm){ 

                            if(newForm[k].config.disabled === true){
                                newForm[k].config.disabled = false
                            }
                        }

                    }
                    
                }

            }
        
            updateControl.touched = upControl.blur
            newForm[upControl.id] = updateControl
            
            this.setState({
                form: newForm
                
            },()=>{

                 handleChange({target: {name: upControl.id, value: updateControl.value }})

            })


    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.maxLetters && (value.length > isRequiredFormat.maxLetters) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }
   

    submitForm = (e)=>{
        
        e.preventDefault() 
        
       
        
        
        const {actions} = this.props
        const {loginUser} = actions 
        let submittData = {} 
        let formIsValid = true 
        let {form} = this.state 
        let {notify} = this
        
        
        
        for(let k in form){
            
            // console.log('THE SUBMIT CONTROL')
            // console.log(form[k])
            if(form[k].validation && form[k].validation.isRequiredFormat){

                console.log('THE CONTROL REQUIRES VALIDATION')

                if(form[k].isValid === false){

                    // console.log('CONTROL:',k, ' is not valid')
                    formIsValid = false 
                    
                    console.log(notify)
                    notify({message:'Sign in credentials must be valid',type: 'warning',className: 'notify-warn'})
                    break


                }else{
 
                    submittData[k] = form[k].value

                }

            }else{

                if(form[k].value.trim() !== ''){

                    submittData[k] = form[k].value

                }
               

            }
          
        
        } 

        if(!formIsValid) return

        submittData.strategy = 'anzii'
        loginUser(submittData)
        
        
    
    }
    

    notify = (config) => {

        
        console.log('THE TOAST CONFIG')
        console.log(config)
        const {props} = this 
        const {actions,auth} = props 
        const {removeShownMessage} = actions
        const {message} = config || ''
        const type = config.type || 'info'
        const errMessage = auth.message
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

        if(errMessage !== ''){

            removeShownMessage()

        }
        
        
    }
        
    // componentDidUpdate(){
        
              
    //     console.log('THE LOGIN COMPONENT WILL MOUNT AT SOME POINT')
    //     console.log('THE VALUE OF AUTH IN DID UPDATE') 
    //     const {props} = this 
    //     const {auth,actions,toRedirect = '/dashboard'} = props 
    //     const {push} = actions
    //     const {appAuth} = auth
    //     const {isAuth} = appAuth

    //     console.log(isAuth)


    //     if(isAuth){

    //         console.log('ABOUT TO REDIRECT TO THE DASHBOARD AREA')
    //         push(toRedirect)
    //     }



               
                
    // }
    // shouldComponentUpdate(){

    //     const {props} = this 
    //     const {intervalID,auth} = props 
    //     const {isFetching} = auth

    //     if(intervalID && isFetching === true ){
    //         return false
    //     }else{

    //         return true
    //     }
    // }
    
   

    render(){

        
        // const {props} = this 
        // const {actions,auth} = props 
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')




        return(

                    <section className="home__launchcta">

                        {/* { message.trim() !== ''
                            ? this.notify({message:message,type: 'error',className: 'notify-error'})
                            : null 
                        } */}

                        <form className="form home__launchcta--cont">


                            <div className="home__launchcta--control bg-light bx-raised-1">

                                    

                               
                                <FormControl 

                                    styles={{child:'home__launchcta-input',error: ''}}
                                    id = 'keyword'
                                    controlData={this.state.form.keyword}
                                    change={(control)=>this.updator(control)}
                                    

                                />


                            </div> 

                            


                           
                        </form>

                       
                        

                    </section>

                    
               

             
             
           
        )
    }




}

export default Form