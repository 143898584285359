
export const SEND_LAUNCH_REQUEST = 'SEND_LAUNCH_REQUEST'
export const SEND_LAUNCH_SUCCESS = 'SEND_LAUNCH_SUCCESS'
export const SEND_LAUNCH_FAILURE = 'SEND_LAUNCH_FAILURE' 

export const UNSET_MESSAGE_NOTIFICATION_DISPLAY = "UNSET_MESSAGE_NOTIFICATION_DISPLAY"
export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"





