import React from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check';
import { toast } from 'react-toastify';
import { ThemeContext } from '../../../../context';



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class ContractType extends React.Component{

  constructor(){
    super();
    this.state = {

        options: [

            {value: 'Full-time',name: 'full',isSelected: false},
            {value: 'Part-time',name: 'part',isSelected: false},
            {value: 'Any-time',name: 'all',isSelected: false},
           

        ],
        isLocal:  true
    }
   
  }


  updateOptions(i,e){

        // console.log(e)
        e.preventDefault() 

        const {options} = this.state  
        const {props} = this 
        const {handleChange} = props 

        let updateOpts = [...options]
        let shouldUpdateNew = true
        updateOpts.forEach((opts,ii)=> {

            if(opts.isSelected){

                if(i === ii){

                    opts.isSelected = false 
                    shouldUpdateNew = false

                }else{

                    opts.isSelected = false

                }
            } 
            
        })

        if(shouldUpdateNew){
            let newOptionValue = { value: options[i].value,name: options[i].name, isSelected: !options[i].isSelected}
            updateOpts[i] = newOptionValue 
        }

        
        this.setState({...this.state,options: [...updateOpts]},()=>{

            if(!updateOpts[i].isSelected){

                handleChange({target: {name: updateOpts[i].name, value: updateOpts[i].value}}, true)
            }else{

                handleChange({target: {name: updateOpts[i].name, value: updateOpts[i].value}})
            }
           
        })
  }

//   syncOptionsValues(){

//      const {state,props} = this 
//      const {values} = props 
//      const {options} = state 

//      console.log('SYNC VALUES') 
//      console.log(options)
//      console.log(values)

//      for(let i=0; i < options.length; i++){

//         console.log(options[i].name) 
//         console.log(values[options[i].name])
//         if(!values[options[i].name]){

//             options[i].isSelected = true 
//             this.setState({...state,options: [...options]})
//             break;
//         }
//      }

//   }

  componentDidMount(){

    const {state,props} = this 
    const {values} = props 
    const {options} = state 

    // console.log('THE VALUES')
    // console.log(values)


    for(let ti=0; ti < options.length; ti++){

        if(values[options[ti].name] && options[ti].isSelected === false){

            let updateOpts = [...options] 
            let newOptionValue = { value: options[ti].value,name: options[ti].name, isSelected: true}
            updateOpts[ti] = newOptionValue 
             this.setState({...this.state,options: [...updateOpts]})
            break;

        }

     }

   
  }

  validator(){


    const {state,notify} = this 
    const {options} = state 
    let validationReturn = false 

    for(let ti=0; ti < options.length; ti++){

        console.log('TI')
        console.log(ti) 

        if(options[ti].isSelected === true){

            validationReturn = true 
            break;

        }

     }

     if(!validationReturn){

        notify({message:'Select atleast one option to continue',type: 'warning',className: 'notify-warn'})
     }

     return validationReturn

  }

  notify = (config) => {

        
    // console.log('THE TOAST CONFIG')
    // console.log(config)

    const type = config.type || 'info'
    const message = config.message

    toast[type](message,
        {position: toast.POSITION.BOTTOM_LEFT,...config});

    
    
  }



  renderOptions(renderOptions){


     const {options} = this.state 
     const {values, styles={}} = renderOptions


    let opts = options.map((opt,i)=>{

         if(opt.isSelected){

            
            return (
                <div className="questionnaire__option"  style={{cursor: 'pointer'}} onClick={this.updateOptions.bind(this,i)} key={i}>

                    <section className="questionnaire__box questionnaire__box-bg">
                        <span className="questionnaire__mark">
                            <small>
                                <Icon><CheckIcon /></Icon>
                            </small>
                        </span>
                        <span className="questionnaire__text">{opt.value}</span>
                    </section>
                    <section className="questionnaire__overlay questionnaire__overlay-bg bx-raised-1"></section>
                    
                 
                </div>
            )
         }else{

            return (
                <div className="questionnaire__option" style={{cursor: 'pointer'}} onClick={this.updateOptions.bind(this,i)} key={i}>

                    <section className="questionnaire__box" style={styles?.boxesStyling}>
                        <span className="questionnaire__text" style={styles?.textColorStyles}>{opt.value}</span>
                    </section> 
                    <section className="questionnaire__overlay"></section>

                </div>
            )

         }

     })

     return opts

  }

//   submitCapturedData(e){

//     e.preventDefault() 

//     const {state,props} = this 
//     const {options} = state 
//     const {nextStep} = props 
//     let isSelected = false 
//     let theSelected = null

//     for(let i=0; i <= options.length; i++){

//         if(options[i].isSelected){

//             isSelected = true 
//             theSelected = options[i].value

//         }
//     }

//     if(!isSelected) return Notifica() 

//     nextStep({contractType: theSelected})

//     // console.log('THE CAPTURED DATA')
//     // console.log(e) 









//   }



static contextType = ThemeContext
    
render(){

    const {theme, themeName} = this.context 
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
    
    const specialStylingText =  themeName === "dark" ? {color: theme.colors.complimentary.foreground} : {}



    const {props,validator} = this 
    const {nextStep,handleChange,values} = props 
    

   
   
    return(


      <div className='questionnaire__i'>
        
        <h3  className="questionnaire__question">What job contract type are you looking for?</h3> 

        <section className="questionnaire__options">

          {this.renderOptions({values, styles:{boxesStyling, textColorStyles, textColorStylesAccent, boxesStylingAccent}})}
        </section>

        <button className="questionnaire__next" onClick={nextStep.bind(this,validator.bind(this))}>Next</button>
       
      </div>


    );
  }
}

export default withStyles(styles)(ContractType)