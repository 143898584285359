import { answers } from "../props"
const oBJP = require('object-path')

export const storeAnswers = (self=null,response='')=>{

    

    const {props} = self 
    const {humanoid,actions} = props 
    const {currentQuestion={},currentQuestions=[],currentQuestionIndex=0,currentQuestionr,questionas,answers={}} = humanoid 
    const questionr = questionas[currentQuestionr] 
    const {setAnswer} = actions
    // const currentQuestionrQuestions = questionr.questions
    // const {endAction} = questionr 
    const {key='',chain=''} = currentQuestion 
    const newAnswers = {...answers}

    console.log('STORE ANSWERS')
    console.log(key)
    console.log(chain)

    if(!key.trim()) return
    if(!chain.trim()) return setAnswer({...newAnswers,[key]: response}) 

    const brokenChain = chain.split('.')


    brokenChain.forEach((c,i)=>{

        if(i === 0){

            if(!oBJP.has(newAnswers,c)){

                // console.log('C DOES NOT EXIST SO WE CREATE IT')
                // console.log(c)

                brokenChain.length === 1 ? newAnswers[c] = {[key]: response} : newAnswers[c] = {}
            }else{

                // console.log('THE C EXISTSNO NEED TO CREATE IT')
                // console.log(c)
                // console.log(newAnswers[c])
                brokenChain.length === 1 ? newAnswers[c][key] = response  : ''

            }
        }else{

            let countToBack = i - 1 
            let pathTOO = ``
            // console.log('THE COUNTER TO BACK')
            // console.log(countToBack)

            for(let ii=0; ii <= countToBack; ii++){

                console.log('IN THE LOOP')

                if(ii === 0){

                    pathTOO = `${[brokenChain[ii]]}`

                }else{

                    pathTOO = `${pathTOO}.${[brokenChain[ii]]}`

                }
                
            }

            if(i === brokenChain.length - 1){

                let unverifiedPath = `${pathTOO}.${c}`
                console.log('THE UNVERIFIED PATH')
                console.log(unverifiedPath)
                console.log('PATH TO')
                console.log(pathTOO)
                console.log(c)
                if(oBJP.has(newAnswers,unverifiedPath)){

                    console.log('THE PATH EXISTS')
                    oBJP.set(newAnswers,unverifiedPath,{...oBJP.get(newAnswers,unverifiedPath),[key]: response})

                }else{
                    console.log('THE UNVERIFIED PATH DOES NOT EXIST')
                    oBJP.set(newAnswers,pathTOO,{...oBJP.get(newAnswers,pathTOO),[c]: {[key]: response}})

                }

                

                // if(!oBJP.get(newAnswers,pathTOO)){

                //     oBJP.set(newAnswers,pathTOO,{[c]: {[key]: response}})
                // }else{
                //     oBJP.set(newAnswers,pathTOO,{[c]: {[key]: response}})
                // }
                
            }else{

                console.log('THE PATH TO CHECKING')
                console.log(pathTOO)
                console.log(oBJP.has(newAnswers,pathTOO))
                console.log(JSON.stringify(oBJP.get(newAnswers,pathTOO)))
                console.log(!oBJP.has(newAnswers,pathTOO))

                if(!oBJP.has(newAnswers,pathTOO)) oBJP.set(newAnswers,pathTOO,{})
                // let unverifiedPath = `${pathTOO}.${c}`

                // if(oBJP.has(newAnswers,unverifiedPath)){

                //     oBJP.set(newAnswers,unverifiedPath,{...oBJP.get(newAnswers,unverifiedPath),[key]: response})

                // }else{
                //     oBJP.set(newAnswers,pathTOO,{[c]: {[key]: response}})

                // }

            }



            
        }
    })

    setAnswer(newAnswers) 



 
 }



 export const collectAnswers = (self=null,answersHolder='')=>{

    const {props} = self 
    const {humanoid} = props 
    const {answers} = humanoid 

    console.log('COLLECT ANSWERS;;;',answers)
    console.log('ANSWERS HOLLDER;;;',answersHolder)

    if(!answers.hasOwnProperty(answersHolder)) return null 
    return answers[answersHolder]
    

 
 }

