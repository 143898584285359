
import React, {Component} from 'react' 
import { ThemeContext } from '../../context'



class Bio extends  Component{


    constructor(props){

        super()

    }


 

               

   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        
         const {props} = this 
        //  const {bioText} = state
         const {allowEdit,contact={}} = props 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        //  console.log('THE CONTACT')
        //  console.log(contact)
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    

                        
                       
                        
                         <div>

                            
                            <p className="resume__about--bio-contact resume__about--bio-contact-edit">

                                <span className="resume__about--bio-contact-icon ">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                </span>
                                <span className="resume__about--bio-contact-text" id={'state'} style={textColorStylesAccent}>

                                  

                                   {contact && (contact.city || contact.street || contact.state || contact.zip_code)
                                     ? `${contact.street}, ${contact.state}, ${contact.city || ''} ${contact.zip_code}`
                                     : <span style={{cursor: 'pointer'}} onClick={allowEdit}>

                                         add your address
                                     </span> 
                                     }

                                    
                                </span>

                            </p>

                            <p className="resume__about--bio-contact">

                                <span className="resume__about--bio-contact-icon">
                                <i className="fa fa-mobile" aria-hidden="true"></i>
                                </span>
                                <span className="resume__about--bio-contact-text" style={textColorStylesAccent}>
                                    <small>+</small>

                                     {contact && contact.phone 
                                     ? contact.alternate_phone ? `${contact.phone} | ${contact.alternate_phone}` : contact.phone 
                                     : <span style={{cursor: 'pointer'}} onClick={allowEdit} id={'phone'}>

                                            add your contact number
                                     </span> 
                                     }
                                </span>

                            </p>

                            <p className="resume__about--bio-contact">

                                <span className="resume__about--bio-contact-icon">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                </span>
                                <span className="resume__about--bio-contact-text" style={textColorStylesAccent}>
                                    {contact && contact.email 
                                    ? contact.alternate_email
                                        ? `${contact.email} | ${contact.alternate_email}`
                                        : contact.email
                                    : <span style={{cursor: 'pointer'}} onClick={allowEdit}>

                                             add your contact email
                                        </span> 
                                        
                                        }
                                   
                                </span>

                            </p>

                        </div>
                        
                        

                        
                        
                            

                   

                    
               

             
             
           
        )
    }




}

export default Bio