
export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST'
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS'
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE' 

export const SEND_USER_AVATAR_UPDATE_REQUEST = "SEND_USER_AVATAR_UPDATE_REQUEST"
export const SEND_USER_AVATAR_UPDATE_SUCCESS = "SEND_USER_AVATAR_UPDATE_SUCCESS"
export const SEND_USER_AVATAR_UPDATE_FAILURE = " SEND_USER_AVATAR_UPDATE_FAILURE"


export const SEND_USER_PROFILE_UPDATE_REQUEST = "SEND_USER_PROFILE_UPDATE_REQUEST"
export const SEND_USER_PROFILE_UPDATE_SUCCESS = "SEND_USER_PROFILE_UPDATE_SUCCESS"
export const SEND_USER_PROFILE_UPDATE_FAILURE = " SEND_USER_PROFILE_UPDATE_FAILURE" 

export const SEND_USER_ACCOUNT_DELETE_REQUEST = "SEND_USER_ACCOUNT_DELETE_REQUEST"
export const SEND_USER_ACCOUNT_DELETE_SUCCESS = "SEND_USER_ACCOUNT_DELETE_SUCCESS"
export const SEND_USER_ACCOUNT_DELETE_FAILURE = "SEND_USER_ACCOUNT_DELETE_FAILURE" 

export const RECEIVE_USER_PROFILE = "RECEIVE_USER_PROFILE"
export const UNSET_IS_PROFILE = "UNSET_IS_PROFILE"
export const UNSET_USER_PROFILE = "UNSET_USER_PROFILE"

export const SEND_USER_ALERT_UPDATE_REQUEST = "SEND_USER_ALERT_UPDATE_REQUEST"
export const SEND_USER_ALERT_UPDATE_SUCCESS = "SEND_USER_ALERT_UPDATE_SUCCESS"
export const SEND_USER_ALERT_UPDATE_FAILURE = "SEND_USER_ALERT_UPDATE_FAILURE"
export const SET_ALERT_UPDATE_DATA = "SET_ALERT_UPDATE_DATA" 


export const DELETE_USER_ALERT_REQUEST = "DELETE_USER_ALERT_REQUEST"
export const DELETE_USER_ALERT_SUCCESS = "DELETE_USER_ALERT_SUCCESS"
export const DELETE_USER_ALERT_FAILURE = "DELETE_USER_ALERT_FAILURE"
// export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY" 

export const DELETE_USER_SEARCH_HISTORY_REQUEST = "DELETE_USER_SEARCH_HISTORY_REQUEST"
export const DELETE_USER_SEARCH_HISTORY_SUCCESS = "DELETE_USER_SEARCH_HISTORY_SUCCESS"
export const DELETE_USER_SEARCH_HISTORY_FAILURE = "DELETE_USER_SEARCH_HISTORY_FAILURE"



export const SEND_USER_SEARCH_HISTORY_REQUEST = "SEND_USER_SEARCH_HISTORY_REQUEST"
export const SEND_USER_SEARCH_HISTORY_SUCCESS = "SEND_USER_SEARCH_HISTORY_SUCCESS"
export const SEND_USER_SEARCH_HISTORY_FAILURE = "SEND_USER_SEARCH_HISTORY_FAILURE"



export const FETCH_RESUME_REQUEST = "FETCH_RESUME_REQUEST"
export const FETCH_RESUME_SUCCESS = "FETCH_RESUME_SUCCESS"
export const FETCH_RESUME_FAILURE = "FETCH_RESUME_FAILURE" 


export const ADD_RESUME_ITEM_REQUEST = "ADD_RESUME_ITEM_REQUEST"
export const ADD_RESUME_ITEM_SUCCESS = "ADD_RESUME_ITEM_SUCCESS"
export const ADD_RESUME_ITEM_FAILURE = "ADD_RESUME_ITEM_FAILURE" 


export const UPDATE_RESUME_ITEM_REQUEST = "UPDATE_RESUME_ITEM_REQUEST"
export const UPDATE_RESUME_ITEM_SUCCESS = "UPDATE_RESUME_ITEM_SUCCESS"
export const UPDATE_RESUME_ITEM_FAILURE = "UPDATE_RESUME_ITEM_FAILURE"


export const DELETE_RESUME_ITEM_REQUEST = "DELETE_RESUME_ITEM_REQUEST"
export const DELETE_RESUME_ITEM_SUCCESS = "DELETE_RESUME_ITEM_SUCCESS"
export const DELETE_RESUME_ITEM_FAILURE = "DELETE_RESUME_ITEM_FAILURE"

export const DELETE_RESUME_REQUEST = "DELETE_RESUME_REQUEST"
export const DELETE_RESUME_SUCCESS = "DELETE_RESUME_SUCCESS"
export const DELETE_RESUME_FAILURE = "DELETE_RESUME_FAILURE"  

export const DELETE_UPLOAD_RESUME_REQUEST = "DELETE_UPLOAD_RESUME_REQUEST"
export const DELETE_UPLOAD_RESUME_SUCCESS = "DELETE_UPLOAD_RESUME_SUCCESS"
export const DELETE_UPLOAD_RESUME_FAILURE = "DELETE_UPLOAD_RESUME_FAILURE"

export const REPLACE_RESUME_REQUEST = "REPLACE_RESUME_REQUEST"
export const REPLACE_RESUME_SUCCESS = "REPLACE_RESUME_SUCCESS"
export const REPLACE_RESUME_FAILURE = "REPLACE_RESUME_FAILURE" 

export const SEND_RESUME_AVATAR_UPDATE_REQUEST = "SEND_RESUME_AVATAR_UPDATE_REQUEST"
export const SEND_RESUME_AVATAR_UPDATE_SUCCESS = "SEND_RESUME_AVATAR_UPDATE_SUCCESS"
export const SEND_RESUME_AVATAR_UPDATE_FAILURE = "SEND_RESUME_AVATAR_UPDATE_FAILURE" 

export const SAVE_UPLOAD_RESUME_REQUEST = "SAVE_UPLOAD_RESUME_REQUEST"
export const SAVE_UPLOAD_RESUME_SUCCESS = "SAVE_UPLOAD_RESUME_SUCCESS"
export const SAVE_UPLOAD_RESUME_FAILURE = "SAVE_UPLOAD_RESUME_FAILURE" 

export const DOWNLOAD_UPLOAD_RESUME_REQUEST = "DOWNLOAD_UPLOAD_RESUME_REQUEST"
export const DOWNLOAD_UPLOAD_RESUME_SUCCESS = "DOWNLOAD_UPLOAD_RESUME_SUCCESS"
export const DOWNLOAD_UPLOAD_RESUME_FAILURE = "DOWNLOAD_UPLOAD_RESUME_FAILURE" 

export const DOWNLOAD_SHARE_RESUME_REQUEST = "DOWNLOAD_SHARE_RESUME_REQUEST"
export const DOWNLOAD_SHARE_RESUME_SUCCESS = "DOWNLOAD_SHARE_RESUME_SUCCESS"
export const DOWNLOAD_SHARE_RESUME_FAILURE = "DOWNLOAD_SHARE_RESUME_FAILURE" 


export const SUBSCRIBE_AND_ADD_USER_ALERT_REQUEST = "SUBSCRIBE_AND_ADD_USER_ALERT_REQUEST"
export const SUBSCRIBE_AND_ADD_USER_ALERT_SUCCESS = "SUBSCRIBE_AND_ADD_USER_ALERT_SUCCESS"
export const SUBSCRIBE_AND_ADD_USER_ALERT_FAILURE = "SUBSCRIBE_AND_ADD_USER_ALERT_FAILURE" 

export const SAVE_JO_USER_JOB_ALERT_REQUEST = "SAVE_JO_USER_JOB_ALERT_REQUEST"
export const SAVE_JO_USER_JOB_ALERT_SUCCESS = "SAVE_JO_USER_JOB_ALERT_SUCCESS"
export const SAVE_JO_USER_JOB_ALERT_FAILURE = "SAVE_JO_USER_JOB_ALERT_FAILURE" 

export const DELETE_SAVED_JOB_REQUEST = "DELETE_SAVED_JOB_REQUEST"
export const DELETE_SAVED_JOB_SUCCESS = "DELETE_SAVED_JOB_SUCCESS"
export const DELETE_SAVED_JOB_FAILURE = "DELETEDELETE_SAVED_JOB_FAILURE"


export const SEND_USER_JOB_BOOKMARK_REQUEST = "SEND_USER_JOB_BOOKMARK_REQUEST"
export const SEND_USER_JOB_BOOKMARK_SUCCESS = "SEND_USER_JOB_BOOKMARK_SUCCESS"
export const SEND_USER_JOB_BOOKMARK_FAILURE = "SEND_USER_JOB_BOOKMARK_FAILURE" 


export const SEND_USER_ALERT_SUBSCRIPTION_REQUEST = "SEND_USER_ALERT_SUBSCRIPTION_REQUEST"
export const SEND_USER_ALERT_SUBSCRIPTION_SUCCESS = "SEND_USER_ALERT_SUBSCRIPTION_SUCCESS"
export const SEND_USER_ALERT_SUBSCRIPTION_FAILURE = "SEND_USER_ALERT_SUBSCRIPTION_FAILURE"


export const UNSET_MESSAGE_NOTIFICATION_DISPLAY = "UNSET_MESSAGE_NOTIFICATION_DISPLAY"
export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"





