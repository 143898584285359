import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import SearchForm from './searchform'
import { TheatersRounded } from '@material-ui/icons';
import { ThemeContext } from '../../../../context';

const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Jobseeker extends Component{
    
    constructor(props){

        super(props)

    }

    

    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}



        return(

           <section className="about__jobseeker">

                  <h2 className="about__jobseeker--header-title">
                          
                          <span className="about__jobseeker--header-title-text" style={textColorStyles}>For</span>
                          <span className="about__jobseeker--header-title-sub" style={textColorStyles}>Our Jobseekers</span>
                          <span className="about__jobseeker--header-title-bar-1"></span>
                          <span className="about__jobseeker--header-title-bar-2"></span>
                  </h2>

               <div className="about__jobseeker--b " >

                        <p className="font-fd-x-tn fg-primary" style={boxesStyling}>
                        <span className=" about__jobseeker--b-icon fg-secondary "> <i class="fa fa-search" aria-hidden="true"></i></span>
                            <span className="  about__jobseeker--b-title ">Job search</span>
                            <span className="  about__jobseeker--b-text fg-dark" style={{...textColorStylesAccent,boxesStyling}}>
                            Jobbri allows jobseekers the ability to search for jobs to find their perfect match from thousands of jobs posted on our platform, as well as those aggregated from our partner sites. 



                            </span>
                        </p>
               </div>

               <div className="about__jobseeker--b">

                   <section className="about__jobseeker--b2-listing">

                   <p className="font-fd-x-tn fg-primary" style={boxesStyling}>

                            <span className=" about__jobseeker--b-icon fg-secondary ">
                                <i class="fa fa-circle" aria-hidden="true"></i></span>
                            <span className="  about__jobseeker--b-title ">Jobs Manager</span>
                            <span className="  about__jobseeker--b-text fg-dark" style={{...textColorStylesAccent,boxesStyling}}>
                            Control how you do your search and manage jobs your own way. Jobbri provides you with a simple and intuitive user interface that allows you to control your job activities on Jobbri, from saving jobs to sharing them with friends and family.


                            </span>
                    </p>
                   

                   </section>
                    

                   

               </div>

               <div className="about__jobseeker--b">

                  
                        <p className="font-fd-x-tn fg-primary" style={boxesStyling}>
                        <span className=" about__jobseeker--b-icon fg-secondary "><i class="fa fa-rss" aria-hidden="true"></i></span>
                            <span className="  about__jobseeker--b-title ">Career Guidance</span>
                            <span className="  about__jobseeker--b-text fg-dark" style={{...textColorStylesAccent,boxesStyling}}>
                            Climb up the corporate world with confidence. Jobbri provides you with the corporate world tips and advice that is meant to kick-start or grow your career. 
                            Use our blog to help guide you on your job hunting journey.



                            </span>
                        </p>
               

               </div>

               <strong className="clearfix" />

               <div className="about__jobseeker--influ">

                 <section className="about__jobseeker--influ-search">

                  <SearchForm actions={this.props.actions} styles={{boxesStyling, boxesStylingA, textColorStyles}} />
                </section>


                

                 <strong className="clearfix" />

               </div>

               
             
           </section>
        )
    }

}


export default withStyles(styles)(Jobseeker)