import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 






class Portfolio extends Component{
    
    constructor(){

        super()

        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    


    
    render(){


       
       

        return(

           
    
            <div className="resume__portfolio">

                 
                 <h2 className="resume__portfolio--title">
                     <span className="resume__portfolio--title-circle"></span>
                     <span className="resume__portfolio--title-text">My past work portfolio</span>
                 </h2>

                 <div className="resume__portfolio-rect">



                     <section className="resume__portfolio--card">

                     <div className="resume__portfolio--card-pointer">
                         <small>1</small>

                    </div>

                    <div className="resume__portfolio--card-pointee">

                        <h5 className="resume__portfolio--card-pointee-period">2019-Present</h5>
                        <h6 className="resume__portfolio--card-pointee-employer">Steel Peel</h6>
                        <span className="resume__portfolio--card-pointee-role">Foreman</span>
                        <p className="resume__portfolio--card-pointee-description">I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background 
                            who possesses self-discipline and the ability to work with the minimum of supervision.
                        </p>

                    </div>

                     </section>

                     <section className="resume__portfolio--card resume__portfolio--card-2">

                     <div className="resume__portfolio--card-pointer">
                         <small>1</small>

                    </div>

                    <div className="resume__portfolio--card-pointee">

                        <h5 className="resume__portfolio--card-pointee-period">2014-2015</h5>
                        <h6 className="resume__portfolio--card-pointee-employer">Steel Peel</h6>
                        <span className="resume__portfolio--card-pointee-role">Foreman</span>
                        <p className="resume__portfolio--card-pointee-description">I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background 
                            who possesses self-discipline and the ability to work with the minimum of supervision.
                        </p>

                    </div>

                     </section>

                     <section className="resume__portfolio--card resume__portfolio--card-3">

                     <div className="resume__portfolio--card-pointer">
                         <small>1</small>

                    </div>

                    <div className="resume__portfolio--card-pointee">

                        <h5 className="resume__portfolio--card-pointee-period">2014-2015</h5>
                        <h6 className="resume__portfolio--card-pointee-employer">Steel Peel</h6>
                        <span className="resume__portfolio--card-pointee-role">Foreman</span>
                        <p className="resume__portfolio--card-pointee-description">I'm A bright, talented, ambitious and self-motivated web designer with a strong technical background 
                            who possesses self-discipline and the ability to work with the minimum of supervision.
                        </p>

                    </div>

                     </section>
                    
                    
                    
                    
                   

                </div>

                <div className="resume__portfolio-rect-2">

                    
                 </div>
                



            </div>

          
        )
    }


}


export default Portfolio


