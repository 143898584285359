
export const FETCH_MANAGE_ALERT_REQUEST = "FETCH_MANAGE_ALERT_REQUEST"
export const FETCH_MANAGE_ALERT_SUCCESS = "FETCH_MANAGE_ALERT_SUCCESS"
export const FETCH_MANAGE_ALERT_FAILURE = "FETCH_MANAGE_ALERT_FAILURE"
export const SEND_USER_ALERT_UPDATE_REQUEST = "SEND_USER_ALERT_UPDATE_REQUEST"
export const SEND_USER_ALERT_UPDATE_SUCCESS = "SEND_USER_ALERT_UPDATE_SUCCESS"
export const SEND_USER_ALERT_UPDATE_FAILURE = "SEND_USER_ALERT_UPDATE_FAILURE"
export const SET_ALERT_UPDATE_DATA = "SET_ALERT_UPDATE_DATA" 

export const UNSET_NOTIFICATION_DISPLAY = "UNSET_NOTIFICATION_DISPLAY"



