
import React, {Component} from 'react'
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import * as blogrActions from '../Blogr/actions'
import qs from 'query-string'
import BlogHeader from './components/blogheader/blogheader'
import BlogArticle from './components/blogarticle/blogarticle'
import BlogRelated from './components/blogrelated/blogrelated'
import ErrorHandler from '../ClientErrorHandler/component'
// import { fetchBlogPosts } from '../Blogr/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'



class BlogPost extends Component{

    constructor(props){

        super()
        this.state = {

           
            width: 0,
            height: 0,
            
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


       
  

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

   

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
        const {props} = this 
        const {blogr,actions} = props 
        const {message=''}  = blogr 
        const {removeMessage} = actions

        if(message.trim()) removeMessage()
      
     }

    //  componentDidUpdate(){


    //     const {props} = this 
    //     const {blogr,actions} = props 
    //     const {message=''}  = blogr 
    //     const {removeMessage} = actions
    

    //     if(message.trim()) removeMessage()
       
        


     
    // }



    

    componentDidMount(){


        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions)


        const {props} = this 
        const {actions,match} = props 
        const {params}  = match
        const {topic=''} = params 
        const {fetchBlogPosts,push} = actions
       
        return fetchBlogPosts({topic: topic})
        
        


     
    }

    openPage(path,e){

        e.preventDefault()
        const {props} = this 
        const {actions} = props 
        const {topic,url} = path
        const {fetchBlogPosts,push} = actions
        fetchBlogPosts(topic)
        return push(url)
        

    }

    retryOnError = ()=>{

        const {props} = this 
        const {actions,match} = props 
        const {params}  = match
        const {topic=''} = params 
        const {fetchBlogPosts,push} = actions
       
        return fetchBlogPosts({blog:{topic: topic}}) 


    }

    getErrorMeta = (color='warn')=>{

        let message,header,headerText  = ''
        
        if(color === 'warn'){

            message = 'We have unfortunately encountered an issue getting topic posts' 
            header = 'OH SNAP!'
            headerText = 'Blog posts could not load!'
           
        }else{

            message= 'We have unfortunately encountered an error processing a request'
            header = 'OOPS!'
            headerText = 'Something went wrong!'


        }
       
       

        return {

            header:header,
            headerText:headerText,
            errorText:message,
            buttonText: 'Please Try Again',
            actionColor: color,
            postErrorAction: this.retryOnError
        } 
    }


    

    


    render(){

        
        const {props,state} = this 
        const {blogr,match} = props 
        const {params}  = match
        const {topic=''} = params 
        const {width} = state
        const {posts,message=''} = blogr 
       
        

        const {isFetching=false} = blogr 

        if(isFetching === true) return  <article className="post" style={{height: '100vh',width: '70%',paddingLeft: '17%',position: 'relative'}}>
            <Helmet>
                <title>Jobbri - Blog | Topic</title>
            </Helmet>
            <ProgressBarCircular style={{width: '100%'}} />
        </article>;

        if(message.trim()) return <ErrorHandler errorMeta={this.getErrorMeta('error')}  /> 
        if(posts.length === 0) return <ErrorHandler errorMeta={this.getErrorMeta('warn')}  />

        return(

            <article className="topic">
               
               <Helmet>
                    <title>Jobbri - Blog | Topic</title>
                </Helmet>
               {/* <BlogHeader /> */}
               <BlogArticle posts={posts} width={width} topic={topic}  />
              {/* {related.length > 0 ? <BlogRelated openPage={this.openPage.bind(this)} posts={related} /> : null} */}
               
            </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        
        blogr:{ ...state.blogr},
        router:{...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...navigatorActions,...blogrActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(BlogPost)