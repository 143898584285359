
import React, {Component} from 'react' 
import {BrowserRouter as Router,Link} from 'react-router-dom'
import Navigation from './navigation'
import Profile from './profile'
import Alerts from './alerts'
import AlertsList from './alertslist'



class Settings extends  Component{


    constructor(props){

        super(props)

        this.state={
            active: 'profile'
        }
        this.handleActive = this.handleActive.bind(this)
    }

    handleActive(active){

        this.setState({...this.state,active: active},()=>{

            console.log('THE FEAT VALUE NOW IS: ')
            console.log(this.state.active)
        })

    }

    show(active){

        

        const {userProfile,actions,modal,candJobAlerts,deletePopr,managePopr,deleteButton,manageButton} = this.props 
        // const Modal = this.props.modal
        // const {first_name,last_name,email,id,profile_url,version} = compData 
        // const {actions} = this.props
        // const {sendUserAlertSettingsUpdate} = actions 

        // const profileData = {avatar: profile_url,version: version,first_name: first_name,last_name: last_name,profile_url: profile_url } 
        // const alertsData = {email: email,alertID: id}
        

        switch(active){
            case 'profile':{

                return(
                    <Profile userProfile={userProfile} actions={actions} modal={modal} deletePopr={deletePopr} managePopr={managePopr} />
                )
            }
            break;
            case 'alerts':{

                return(
                    <AlertsList  userProfile={userProfile} actions={actions} modal={modal} candJobAlerts={candJobAlerts}
                    deletePopr={deletePopr} managePopr={managePopr}
                    deleteButton={deleteButton} manageButton={manageButton} />
                )
            }
            break;
            default:{
                return(null)
            }
        }

    }


   

    render(){

        // const {profile,isFetching,isProgressBar,isActionSuccessful,launcher,actions,alertsEmails} = this.props 
        // const {personal} = profile 
        // const {first_name,last_name,id,version,profile_url} = personal
        // const profileData = {avatar: profile_url,first_name: first_name,last_name: last_name,version: version,id: id,profile_url: profile_url }

        // console.log('THE SETTINGS DATA ')
        // console.log(profile)
        console.log('THE PROPS SETTINGS')
        console.log(this.props)

        const {userProfile,actions,modal=null,candJobAlerts=null,deletePopr,managePopr,deleteButton,manageButton} = this.props

        return(


               <div>

               
                    <section className="dashboard__candsettings--settingpane">

                        <div className="dashboard__candsettings--settingpane-box ">

                            
                               <h2 className="dashboard__candsettings--settingpane-title">Settings</h2>
                               
                                <Navigation handleActive={this.handleActive} userProfile={userProfile} actions={actions} modal={modal} />
                               
                                
                        </div>


                    </section>


                    <section className="dashboard__candsettings--editpane">

                        

                            {this.state.active === 'alerts' ? this.show(this.state.active) : <Profile userProfile={userProfile} actions={actions} modal={modal} 
                            deletePopr={deletePopr}  managePopr={managePopr} 
                            deleteButton={deleteButton} manageButton={manageButton} />}
                              
                       


                    </section>


              
                    <strong className="clearfix" />

                </div>

             
             
           
        )
    }




}

export default Settings