
// export const FETCH_JOBS_REQUEST = "FETCH_JOBS_REQUEST"
// export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS"
// export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE"
// export const FETCH_JOBS_BY_FILTER_REQUEST = "FETCH_JOBS_BY_FILTER_REQUEST"
// export const FETCH_JOBS_BY_FILTER_SUCCESS = "FETCH_JOBS_BY_FILTER_SUCCESS" 
// export const FETCH_JOBS_BY_FILTER_FAILURE = "FETCH_JOBS_BY_FILTER_FAILURE"
// export const SAVE_JOB_SEARCH_PARAMS = "SAVE_JOB_SEARCH_PARAMS"
// export const CRUD_JOB_FILTER = "CURD_JOB_FILTER"


export const FETCH_JOBS_REQUEST = "FETCH_JOBS_REQUEST"
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS"
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE"
export const FETCH_JOBS_BY_FILTER_REQUEST = "FETCH_JOBS_BY_FILTER_REQUEST"
export const FETCH_JOBS_BY_FILTER_SUCCESS = "FETCH_JOBS_BY_FILTER_SUCCESS" 
export const FETCH_JOBS_BY_FILTER_FAILURE = "FETCH_JOBS_BY_FILTER_FAILURE" 
export const FETCH_JOBS_TEXT_SUGGESTIONS_REQUEST = "FETCH_TEXT_SUGGESTIONS_REQUEST" 
export const FETCH_JOBS_TEXT_SUGGESTIONS_SUCCESS = "FETCH_TEXT_SUGGESTIONS_SUCCESS"
export const FETCH_JOBS_TEXT_SUGGESTIONS_FAILURE = "FETCH_TEXT_SUGGESTIONS_FAILURE" 
// export const SEND_USER_JOB_BOOKMARK_REQUEST = "SEND_USER_JOB_BOOKMARK_REQUEST"
// export const SEND_USER_JOB_BOOKMARK_SUCCESS = "SEND_USER_JOB_BOOKMARK_SUCCESS"
// export const SEND_USER_JOB_BOOKMARK_FAILURE = "SEND_USER_JOB_BOOKMARK_FAILURE" 
// export const SEND_USER_JOB_SEARCH_HISTORY_REQUEST = "SEND_USER_JOB_SEARCH_HISTORY_REQUEST"
// export const SEND_USER_JOB_SEARCH_HISTORY_SUCCESS = "SEND_USER_JOB_SEARCH_HISTORY_SUCCESS"
// export const SEND_USER_JOB_SEARCH_HISTORY_FAILURE = "SEND_USER_JOB_SEARCH_HISTORY_FAILURE"
// export const UDPATE_FORM_INPUT = "UDPATE_FORM_INPUT"
export const SAVE_JOB_SEARCH_PARAMS = "SAVE_JOB_SEARCH_PARAMS"
export const CRUD_JOB_FILTER = "CURD_JOB_FILTER" 
export const RESET_SEARCH_FILTERS = "RESET_SEARCH_FILTERS"
export const UNSET_CAN_SEND_SEARCH_HISTORY = "UNSET_CAN_SEND_SEARCH_HISTORY"



