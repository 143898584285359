
import * as types from './types'
import * as props from './props'



export default (state = props, action)=>{

    switch (action.type) {

        case types.SEND_USER_PASSWORD_LINK_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isActionSuccessful: false,
                message: '',
                
                
            }

        case types.SEND_USER_PASSWORD_LINK_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true
              
               
                
            }

        case types.SEND_USER_PASSWORD_LINK_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
                message: action.error
                
            }

        // case types.UNSET_NOTIFICATION_DISPLAY: 

        //     return {

        //         ...state,
        //         isActionSuccessful: false
        //     }

      
        default:
        return state
    }


}