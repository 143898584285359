import React,{Component} from 'react'
import Icon from '@material-ui/core/Icon';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles'
// import Reasons from './reasons'


const styles = {
    root: {
     
      color:"white",
      borderRadius: '50%',
      backgroundColor: 'red',
      fontSize: 15,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Verification extends Component{
    
    resend = (e)=>{

        e.preventDefault()
        const {props} = this
        const {actions,userProfile} = props
        const {resendAccountVerification} = actions 
        const {profile} = userProfile 
        const {personal} = profile
        const {id,email} = personal
        resendAccountVerification({id,email})


    }


    render(){

        const {props} = this
        const {candidateVerify} = props 
        const {isFetching} = candidateVerify 
     

        return(

            <div className="verify__hero">

               <section className="verify__parallelogram bx-raised">

                  <div className="verify__parallelogram--skew">
                   <div className="verify__parallelogram--h">
                        <h2>Verification resend Failed <span><Icon ><CancelIcon style={styles.root} /></Icon></span></h2>
                        <small style={{color: 'red'}}>Verification email resend has failed</small>
                        <span></span>
                    </div> 

                    {/* <figure className="verify__parallelogram--fig">
                        <img src="/img/sad.png" width="120" />
                    </figure> */}

                    {/* <div className="verify__parallelogram--q">
                        <h2>Why are you leaving us?</h2>

                    </div> */}
                    <div className="verify__parallelogram--txt">

                    {
                        isFetching 
                            ?
                            <>
                                <div className="verify__loader"></div>
                                <h2 className="verify__loader__text">Trying to resend verification email...</h2>
                            </>
                            : <p>It's likely that something unexpected happened on our end. In any case, you can retry sending the verification again by clicking on the button below or alternatively send an email to <span style={{color: 'blue'}}><a style={{textDecoration: 'none'}} href="mailto:support@jobbri.co.za">support@jobbri.co.za</a></span>.</p>
                        }

                    
                        
                    </div>

                    <div>

                    { !isFetching ? <button className="verify__launchcta-submit" onClick={this.resend.bind(this)}>
                            <span className="verify__launchcta-submit-rect"></span>
                            <span className="verify__launchcta-submit-rect-2">
                                <small>Try Resend</small></span>
                        </button>
                        :null
                        }
                        
                        
                    </div>

                    </div>


               </section>

              
            </div>
        )
    }

}

export default withStyles(styles)(Verification)

// export default Hero