import React, { Component } from 'react' 
import {Link} from 'react-router-dom'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import { ThemeContext } from '../../../../context';


const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Employer extends Component{
    
    constructor(props){

        super(props)

    }


    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}




        return(

           <section className="about__employer">

                      <h2 className="about__employer--header-title">
                          
                          <span className="about__employer--header-title-text" style={textColorStyles}>For</span>
                          <span className="about__employer--header-title-sub" style={textColorStyles}>Our employers</span>
                          <span className="about__employer--header-title-bar-1"></span>
                          <span className="about__employer--header-title-bar-2"></span>
                      </h2>
  

               <div className="about__employer--b " >

                        <p className="font-fd-x-tn fg-primary" style={boxesStyling}>
                        <span className=" about__employer--b-icon fg-secondary ">
                            <i class="fa fa-list-alt" aria-hidden="true"></i>
</span>
                            <span className="  about__employer--b-title ">Post jobs</span>
                            <span className="  about__employer--b-text fg-dark" style={{...textColorStylesAccent,boxesStyling}}>
                            Kick-start your talent search tasks by posting your open position(s) on Jobbri for free to a small fee.  We will provide you with talent that is enthusiastic and ready to work with you. 




                            </span>
                        </p>
               </div>

               <div className="about__employer--b about__employer--b2">

                   <section className="about__employer--b2-listing">

                   <p className="font-fd-x-tn fg-primary" style={boxesStyling}>

                            <span className=" about__employer--b-icon fg-secondary ">
                            <i class="fa fa-hand-rock-o" aria-hidden="true"></i>

                            </span>
                            <span className="  about__employer--b-title ">Promote jobs</span>
                            <span className="  about__employer--b-text fg-dark" style={{...textColorStylesAccent,boxesStyling}}>
                            Get maximum exposure for your vacancies by promoting your posted jobs. Promoted jobs are guaranteed to help you reach more candidates to increase your chances of finding just the perfect match.</span>
                           
                            <Link to="/post-job" className="link">
                                <button className="about__employer--b-button">
                                Promote a job
                                </button>
                            </Link>
                    </p>
                   

                   </section>
                    

                   

               </div>

               <div className="about__employer--b">

                  
                        <p className="font-fd-x-tn fg-primary" style={boxesStyling}>
                        <span className=" about__employer--b-icon fg-secondary ">
                             <i class="fa fa-circle" aria-hidden="true"></i>
                        </span>
                            <span className="  about__employer--b-title ">Manage jobs & campaigns</span>
                            <span className="  about__employer--b-text fg-dark" style={{...textColorStylesAccent,boxesStyling}}>
                            Simplify your hiring process and related activities by using our simple and powerful user interface that is meant to simplify and speed-up your hiring. Start your posted jobs campaigns and control how and when they are executed based on your available budget.



                            </span>
                        </p>
               

               </div>

               <strong className="clearfix" />

               <div className="about__employer--post">

                 
               
                   <Link to="/post-job" className="link">
                   <button className="about__employer--post-button">
                        Start a campaign
                        </button>
                   </Link>
                
              

               </div>

               
             
           </section>
        )
    }

}


export default withStyles(styles)(Employer)