
import React,{Component} from 'react'
import { ThemeContext } from '../../context'
import More from './more/more' 



class Company extends Component{

    constructor(){
        super()
    }

    
   static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const textColorStyles = {color: theme.colors.complimentary.foreground}


        const {footer,actions,width} = this.props

        return(

            <div className="grid-row">

                
                <div  className="mg-bottom-fd-md">
                    
                     <More footer={footer} actions={actions} width={width} textStyle={textColorStyles} styles={boxesStyling} />
                </div>
               
                

            </div>
        )
    }

}


export default Company