
import { transportData } from "./transporter"
import { setHumanoidProcessMesg } from "./util"

export const doAgain = (self)=>{

    console.log('doing again;;;',self)
    const {props} = self
    const {humanoid} = props
    const {doAgainData} = humanoid
    const {transporter} = doAgainData
    const {name} = transporter

    doAgainGeneric(self)
    console.log('doAgainData;;;',doAgainData)
    switch(name){
        case 'registrationTransporter' : transportData(self,name)
        break;
        default : return false
    }

}

const doAgainGeneric = (self)=>{

    const {props} = self
    const {actions,humanoid} = props
    const {setProcessor,setHumanoidActionType} = actions
    const {doAgainData} = humanoid
    const {inContextQuestionr} = doAgainData
    setProcessor(true,'Processing')
    setHumanoidProcessMesg(self,true)
    setHumanoidActionType(inContextQuestionr)
}

export const saveForDoAgain = (self,saving)=>{

    console.log('theDoAgainSaving;;',saving)
    const {props} = self
    const {actions} = props
    const {saveDoAgainData} = actions
    saveDoAgainData(saving)
}




