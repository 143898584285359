
import React, {Component} from 'react' 
import { ThemeContext } from '../../context'

// import ProgressBarLinear from '../progressbarlinear'

// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()
       
    }

 

  
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary['accent-3']}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        
         const {props} = this 
         const {bio=''} = props 
         const editContStyles = {boxShadow: 'none'}

        
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    <section className="resume__about--bio-edit" style={editContStyles}>


               
                        
                            
                        {bio.trim() === '' 
                         ? ''
                         : <p className="resume__about--bio-text" style={{...textColorStylesAccent,...boxesStylingAccent}}>
                            {bio}
                           </p>}

                            

                       
                            

                    </section>

                    
               

             
             
           
        )
    }




}

export default Bio