
import React, {Component} from 'react' 
// import { bindActionCreators } from 'redux'
// import {connect} from 'react-redux'
// import * as actions from './actions'
// import * as navigatorActions from '../ReduxFirstNavigator/actions'
// import * as authActions from '../Auth/actions'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
//import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'




const styles = {
    root: {
     
      color:"white",
      borderRadius: '50%',
      backgroundColor: '#00b050',
      fontSize: 15,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };





class ProgressBar extends Component{


    constructor(){

        super()
        this.state = {

            currentForm: 'signup',
            progressBarPercent: 0,
           
            

        }
        this.intervalID = null
        
    }


    componentDidUpdate(){

        console.log('THE LOGIN COMPONENT WILL MOUNT AT SOME POINT')
        console.log('THE VALUE OF AUTH IN DID UPDATE') 
        const {props,intervalID} = this 
        const {isFetching} = props 
      
     

       // console.log(isAuth)
        


         if(!intervalID && isFetching === true){

           
        
              document.body.style.overflow = 'hidden'
              document.body.style.pointerEvents ='none'
              document.body.style.opacity = '0.9'
            this.startProgressBar()
          
         }else if(isFetching === false && intervalID){

            document.body.style.overflow = 'visible'
            document.body.style.pointerEvents ='all'
            document.body.style.opacity = '1'
            clearInterval(intervalID)
            this.intervalID = null
            this.setState({...this.state,progressBarPercent: 0})

         }
       
         
        

    }


    startProgressBar(){

          
       this.intervalID = setInterval(()=>{

          const {state} = this
          const {progressBarPercent} = state
          let proPercent = progressBarPercent+5
          if(proPercent <= 95) this.setState({...state,progressBarPercent: proPercent })

        },1000)
    }








    render(){

        console.log('THE RERENDER OF LOGIN')
        const {props,state,intervalID} = this 
        const {isFetching} = props 
        const {progressBarPercent} = state 
        

        return(

            
               
            
                    <div  style={{width: '80px',
                            position: 'fixed',
                            left: '1%',
                            bottom: 0}}>

                        <CircularProgressbarWithChildren value={progressBarPercent}>
                        
                            <span style={{marginBottom: '5px', color: '#00b050', fontWeight: 'bolder'}}><Icon><SupervisorAccountIcon /></Icon></span>
                            <div style={{ fontSize: 12, marginTop: -5 }}>
                                <strong>{`${progressBarPercent}%`}</strong> mate
                            </div>
                        </CircularProgressbarWithChildren>;
                            

                    </div>
                    
             
               
               
        )
    }




}


export default withStyles(styles)(ProgressBar)