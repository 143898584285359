import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 






class Education extends Component{
    
    constructor(){

        super()

        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    


    
    render(){


       
       

        return(

           
    
            <div className="resume__education">

                 
                 <h2 className="resume__education--title">
                     <span className="resume__education--title-circle"></span>
                     <span className="resume__education--title-text">Education</span>
                 </h2>

                 <div className="resume__education-rect">



                     <section className="resume__education--card">

                        <div className="resume__education--card-pointer">
                            <small>1</small>

                        </div>

                        <div className="resume__education--card-pointee">

                            <h5 className="resume__education--card-pointee-period">2005-2009</h5>
                            <h6 className="resume__education--card-pointee-employer">Shingwedzi High School</h6>
                            <span className="resume__education--card-pointee-role">Diploma</span>
                            <p className="resume__education--card-pointee-description">
                                Physics, Mathematics, Life Orientation,Life sciences, Computer Application technology
                            </p>

                        </div>

                     </section>

                     <section className="resume__education--card resume__education--card-2">

                     <div className="resume__education--card-pointer">
                         <small>2</small>
                    </div>

                    <div className="resume__education--card-pointee">

                        <h5 className="resume__education--card-pointee-period">2011-</h5>
                        <h6 className="resume__education--card-pointee-employer">UNISA</h6>
                        <span className="resume__education--card-pointee-role">Inomplete</span>
                        <p className="resume__education--card-pointee-description">
                            National Diploma in information and communication technology.
                        </p>

                    </div>

                     </section>

                     
                    
                    
                    
                    
                   

                </div>

                <div className="resume__education-rect-2">

                    
                 </div>
                



            </div>

          
        )
    }


}


export default Education


