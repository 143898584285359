
import React,{Component} from 'react'
import { ThemeContext } from '../../context'
import Launch from './launch/launch' 



class AppLaunch extends Component{
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const textColorStyles = {color: theme.colors.complimentary.foreground}

        const {launch,actions} = this.props

        return(

            <div className="grid-row">

                
                <div  className="mg-bottom-fd-md">
                    
                     <Launch launch={launch} actions={actions} textStyle={textColorStyles} styles={boxesStyling} theme={theme} themeName={themeName}  />
                </div>
                
               
                

            </div>
        )
    }

}


export default AppLaunch