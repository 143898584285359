import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import { ThemeContext } from '../../context'
import EducationInfo from './educationeinfo'
import EducationForm from './educationform'
import MissingEducation from './missingeducation'




class Education extends Component{
    
    constructor(){

        super()
        this.state ={

            // education: [{

            //     from: '2005',
            //     to: '2009',
            //     institution: 'Shingwedzi High',
            //     qualification: 'Diploma',
            //     majors: `Physics, Mathematics, Life Orientation,Life sciences, Computer Application technology.`
            // },
            // {

            //     from: '2011',
            //     to: '',
            //     institution: 'UNISA',
            //     qualification: 'Incomplete',
            //     majors: `National Diploma in information and communication technology.`
            // }
            // ],
          
            isEdit: false,
            isEducationAdd: false,
            

        }

        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    
    allowEdit = (e)=>{

        e.preventDefault()
        
        //  let editing = `${key}Edit` 
    
         let isEdit = this.state.isEdit
         this.setState({...this.state,isEdit: !isEdit})
    }

    getEditEducationForm = (e,i)=>{

        // console.log('THE VALUE OF I')
        // console.log(i)
        // console.log(e)
        const {props} = this 
        const {actions,qualification,userProfile} = props 
        return (<EducationForm userProfile={userProfile} institution={e} qualification={qualification} actions={actions} index={i} />)
    
    }


    educationAdd = ()=>{

        this.setState({...this.state,isEducationAdd : !this.state.isEducationAdd})
    }
    
    scrollToBottom = (id)=>{
    
       
    
            let scrollEl = document.getElementById(id)
    
            if(scrollEl) scrollEl.scrollIntoView({behavior: 'smooth'})
    
        
       
        
    }
    
    

    


    
    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}



       
        const {state,props,allowEdit} = this 
        const {isEdit,isEducationAdd} = state 
        const {education =[],qualification,actions,userProfile} = props
       
        return(

           
    
            <div className="resume__education" >

                 
                 <h2 className="resume__education--title">
                     <span className="resume__education--title-circle"></span>
                     <span className="resume__education--title-text" style={textColorStylesAccent}>Education</span>
                 </h2>

                 <div className="resume__education-rect" style={boxesStyling}>

                    {!isEdit  && education.length > 0
                        ? education.length < 5  && !isEducationAdd
                            ?  <p>
                                 <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={allowEdit.bind(this)}>
                                <i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true">
                                </i>
                                </small>

                                <small  className="resume--edit-pen resume__about--bio-edit-pencil" style={{right: '2%'}}  onClick={this.educationAdd.bind(this)} >
                                <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                               </i>
                                </small>
                                </p>
                            
                            : 
                            !isEducationAdd 
                                ?
                                    <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={allowEdit.bind(this)}>
                                    <i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true">
                                    </i>
                                    </small>
                                :null


                             
                           
                        : null 
                        }

                    

                    {isEdit 
                        ? education.map((e,i)=>this.getEditEducationForm(e,i))
                        : education.length > 0 ? <EducationInfo  education={ education}  /> : !isEducationAdd ? <MissingEducation allowEdit={this.educationAdd.bind(this)} /> : null}
                    {isEducationAdd ? <EducationForm userProfile={userProfile} isAdding={isEducationAdd} qualification={qualification} educationAdd={this.educationAdd.bind(this)} actions={actions} />  : null}

                    {isEdit 
                        ?
                            <div className="resume__about--bio-edit-sub-btn">


                                <button className="resume--edit-btn " onClick={allowEdit.bind(this)}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Cancel</small></span>
                                </button>
                            </div>
                        : null

                     }

                     {/* <section className="resume__education--card">

                        <div className="resume__education--card-pointer">
                            <small>1</small>

                        </div>

                        <div className="resume__education--card-pointee">

                            <h5 className="resume__education--card-pointee-period">2005-2009</h5>
                            <h6 className="resume__education--card-pointee-employer">Shingwedzi High School</h6>
                            <span className="resume__education--card-pointee-role">Diploma</span>
                            <p className="resume__education--card-pointee-description">
                                Physics, Mathematics, Life Orientation,Life sciences, Computer Application technology
                            </p>

                        </div>

                     </section>

                     <section className="resume__education--card resume__education--card-2">

                     <div className="resume__education--card-pointer">
                         <small>2</small>
                    </div>

                    <div className="resume__education--card-pointee">

                        <h5 className="resume__education--card-pointee-period">2011-</h5>
                        <h6 className="resume__education--card-pointee-employer">UNISA</h6>
                        <span className="resume__education--card-pointee-role">Inomplete</span>
                        <p className="resume__education--card-pointee-description">
                            National Diploma in information and communication technology.
                        </p>

                    </div>

                     </section> */}

                     
                    
                    
                    
                    
                   

                </div>

                {/* <div className="resume__education-rect-2">

                    
                 </div> */}
                



            </div>

          
        )
    }


}


export default Education


