import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import CustomScroll from 'react-custom-scrollbar'






class Curated extends Component{
    
    constructor(){

        super()

        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    


    
    render(){


       
       

        return(

           
            <CustomScroll>
            <div className="resume__curated">

                 
                 <h2 className="resume__curated--title">
                     <span className="resume__curated--title-circle"></span>
                     <span className="resume__curated--title-text">Other Information About Me</span>
                 </h2>

                 <div className="resume__curated-rect">



                     <section className="resume__curated--card">

                        <div className="resume__curated--card-pointer">
                            

                        </div>

                        <div className="resume__curated--card-pointee">

                            <h5 className="resume__curated--card-pointee-period">Languages</h5>
                            <p className="resume__curated--card-pointee-legend">
                                <span>Language</span>
                                <span>Speak</span>
                                <span>Write</span>
                                <span>Read</span>
                                <span>Actions</span>
                                
                            </p>
                            <ul className="resume__curated--card-pointee-list">

                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Xitsonga</span>
                                    <span>Excellently</span>
                                    <span>Excellently</span>
                                    <span>Excellently</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>English</span>
                                    <span>Excellently</span>
                                    <span>Excellently</span>
                                    <span>Excellently</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Tshwane</span>
                                    <span>Good</span>
                                    <span>Fairly</span>
                                    <span>Fairly</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                </li>
                            </ul>

                        </div>

                     </section>

                     <section className="resume__curated--card resume__curated--card-2">

                     <div className="resume__curated--card-pointer"></div>

                    <div className="resume__curated--card-pointee">

                        <h5 className="resume__curated--card-pointee-period">Skills</h5>
                        <p className="resume__curated--card-pointee-legend">
                                <span>Skill</span>
                                <span>Score</span>
                                <span>Actions</span>
                               
                            </p>

                        <ul className="resume__curated--card-pointee-list">

                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Mysql</span>
                                    <span>80%</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>PHP</span>
                                    <span>50%</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Javascript</span>
                                    <span>95%</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>


                            </ul>

                    </div>

                    


                     </section>

                     <section className="resume__curated--card">

                     <div className="resume__curated--card-pointer"></div>

                     <div className="resume__curated--card-pointee">

                        <h5 className="resume__curated--card-pointee-period">Portfolio</h5>
                        <p className="resume__curated--card-pointee-legend">
                                <span>Name</span>
                                <span>Description</span>
                                <span>Link</span>
                                <span>Actions</span>
                            
                            </p>

                        <ul className="resume__curated--card-pointee-list">

                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Indym</span>
                                    <span>An independent Modeling agency</span>
                                    <span><a href="https://indym.herokuapp.com" target="_blank">View</a></span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>PHP</span>
                                    <span>An independent Modeling agency</span>
                                    <span><a href="https://indym.herokuapp.com">View</a></span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Javascript</span>
                                    <span>An independent Modeling agency</span>
                                    <span><a href="https://indym.herokuapp.com">View</a></span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>


                            </ul>

                        </div>
                     </section>

                     
                    
                    
                    
                    
                   

                </div>

               
                



            </div></CustomScroll>

          
        )
    }


}


export default Curated


