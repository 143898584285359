
import * as types from './types'
import * as navigatorActions from '../Navigator/actions'
import * as userProfileActions from '../UserProfile/actions' 
const api = process.env.REACT_APP_SERVER_URL_PROTOCOL + '://' + process.env.REACT_APP_SERVER_URL_URI

export const  requestLogin = (creds)=>{
    return {
      type: types.LOGIN_REQUEST,
     
     
    }
  }
  
  export const receiveLogin = ()=> {

    return {
      
      type: types.LOGIN_SUCCESS,
     
    }



  }
  
  export const loginError = (message)=>{

    return {
      type: types.LOGIN_FAILURE,
      message: message

      
    }

  }

  export const  requestRegister = (creds)=>{
    return {
      type: types.REGISTER_REQUEST,
     
      
    }
  }
  
  export const receiveRegister = ()=> {

    return {
      type: types.REGISTER_SUCCESS,

    }

  }
  
  export const registerError = (message)=>{


    return dispatch => dispatch({

      type: types.REGISTER_FAILURE,
      message: message
    })

  }

  export const  requestLogout = ()=>{

    return {
  
      type: types.LOGOUT_REQUEST,
      isFetching: false,
      
  
    }
  
  }
  
  
  export const receiveLogout = ()=>{
  
  
    return {
      type: types.LOGOUT_SUCCESS,
     
      
    }
  
  
  }


  export const registerUser = (creds)=>{
  
    console.log('THE REGISTER CREDS')
    console.log(creds)
    // creds = JSON.stringify(creds)

    let config = {

      method: 'POST',
      headers: { 'Content-Type':'application/json' },
      body: JSON.stringify({action: 'registerUser',payload: {register: creds,strategy: creds.strategy} })

    }
    // let config = {

    //   method: 'POST',
    //   headers: { 'Content-Type':'application/json' },
    //   body: creds

    // }
  
    return dispatch => {

      // We dispatch requestLogin to kickoff the call to the API
      dispatch(requestRegister(creds))
  
      return fetch(`${api}/register`, config)
        .then((response) =>{
          console.log('THE RESPONSE FROM THE SERVER')
          console.log(response)
            return response.json().then(user => ({ user, response }))}
            ).then(({ user, response }) =>  {
              console.log('THE RETURNED USED FROM THE SERVER AS PROCESSED BY fetch module api')
              console.log(user)
              if (!response.ok) {
                // If there was a problem, we want to
                // dispatch the error condition
                dispatch(registerError('System request could not complete successfully'))
                //return Promise.reject(user)
              } else {
                // If login was successful, set the token in local storage

                if(!user.actionStatus){

                    console.log('THE REGISTER FAILURE')
                    console.log(user)
                    const {message = 'System could not complete successfully'} = user

                    if(typeof message === 'string') return dispatch(loginError(message))
                    dispatch(registerError(message.message))

                    
                    // dispatch(registerError(message))

                }else{


                  const {actor = null } = user 
                  

                  if(!actor) return dispatch(registerError('System error has occured trying to register'))
                  const {auth,profile } = actor

                  if(auth.accessToken){

                   
                    localStorage.setItem('user', auth)
                    dispatch(userProfileActions.receiveUserProfile(profile))
                    return dispatch(receiveRegister(actor))
                  
      
                  }else if(auth.taken){

                    let {message = 'Specified email belongs to a registered user'} = user
                    dispatch(registerError(message))
                    

                  }else{


                    if(typeof message === 'string') return dispatch(registerError(message))
                    // dispatch(loginError(message.message))
      
                      //dispatch(loginError(message))
                      // if(message instanceof String) return dispatch(loginError(message))
                      return dispatch(registerError('System error has occured registering user'))

                  

                  }

                


                }
                // if(user.error){

                //   console.log('THE REGISTER FAILURE')
                //   console.log(user)
                //    dispatch(registerError(user.message))

                // }else{

                  
                // }
                
              }
        }).catch((err) =>{ 

          console.log("Error:REGISTER USER:registerUser has failed ", err) 
          const {message = 'System error has occured trying to register user'} = err
          dispatch(registerError(message))
          
        })
    }


  }

  export const loginUser = (creds)=>{

    console.log('THE CREDS')
    console.log(creds)
    // creds = JSON.stringify(creds) 
    // let payload = {
    //   action: 'loginUser',
    //   payload: JSON.stringify(creds) 
    // }

    let config = {

      method: 'POST',
      headers: { 'Content-Type':'application/json' },
      body: JSON.stringify({action: 'loginUser',payload: {login: creds,strategy: creds.strategy} })

    }
  
  
    return dispatch => {

      // We dispatch requestLogin to kickoff the call to the API 

     
      dispatch(requestLogin(config))
  
      return fetch(`${api}/login`, config)
      .then((response) =>{
        console.log('THE RESPONSE FROM THE SERVER')
        console.log(response)
          return response.json().then(user => ({ user, response }))}
          ).then(({ user, response }) =>  {
            console.log('THE RETURNED USED FROM THE SERVER AS PROCESSED BY fetch api')
            console.log(user)
        if (!response.ok) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(loginError(user.message))
          return Promise.reject(user)
        } else {
          // If login was successful, set the token in local storage

          if(!user.actionStatus){

            console.log('THE LOGIN FAILURE')
            console.log(user)
            const {message = 'System could not complete login successfully'} = user

            if(typeof message === 'string') return dispatch(loginError(message))
            
             dispatch(loginError(message.message))

          }else{


             const {actor = null } = user 
             if(!actor) return dispatch(registerError('System error has occured trying to login'))
             const {auth,profile} = actor


            if(auth.accessToken){

              //if(actor.verifyResend) 
             
              
              localStorage.setItem('user', auth)
              dispatch(userProfileActions.receiveUserProfile(profile))
              return dispatch(receiveLogin(actor))

            }else if(auth.badCredentials){

              const {message = 'Bad login credentials'} = user
              dispatch(loginError(message))
              

            }else{

            
              const {message = 'System has error has occured trying to login the user'} = user
              if(typeof message === 'string') return dispatch(loginError(message))
              // dispatch(loginError(message.message))

                //dispatch(loginError(message))
                // if(message instanceof String) return dispatch(loginError(message))
                return dispatch(loginError('System error has occured loging in user'))

            }


            

          }
          
        }
      }).catch((err) =>{ 

        console.log("Error:REGISTER USER:registerUser action creater ", err)
        const {message = 'System error has occured trying to login user'} = err
        dispatch(loginError(message))
        
      })
    }


  }

  export const logoutUser = (shouldNavigate=true) =>{
  
    
    return dispatch => {
  
      dispatch(requestLogout())
      localStorage.removeItem('user')
      dispatch(userProfileActions.unsetUserProfile())
      dispatch(receiveLogout())
      if(shouldNavigate) dispatch(navigatorActions.navigate('/'))
      
  
    }
  
    
  }

export const removeShownMessage = () =>{

  return {

    type: types.REMOVE_SHOWN_MESSAGE,
    message: ''

  }


}

export const  requestCapctchaVerify = ()=>{
  return {
    type: types.CAPTCHA_VERIFY_REQUEST,
    isFetching: true,
    isCaptchaVerified: false
  }
}

export const receiveCaptchaVerify = (verifyStatus)=> {

  return {
    
    type: types.CAPTCHA_VERIFY_SUCCESS,
    isFetching: false,
    isCaptchaVerified: verifyStatus
  }



}

export const captchaVerifyError = (message)=>{


  return dispatch => dispatch({

    type: types.CAPTCHA_VERIFY_FAILURE,
    isFetching: false,
    isCaptchaVerified: false,
    message: message
  })

}


export const verifyGoogleCaptcha = (creds)=>{

  console.log('THE CREDS')
  console.log(creds)
  // creds = JSON.stringify(creds) 
  // let payload = {
  //   action: 'loginUser',
  //   payload: JSON.stringify(creds) 
  // }

  let config = {

    method: 'POST',
    headers: { 'Content-Type':'application/json' },
    body: JSON.stringify({action: 'verifyCaptcha',payload: {captcha: creds} })

  }


  return dispatch => {

    // We dispatch requestLogin to kickoff the call to the API 

   
    dispatch(requestCapctchaVerify(config))

    return fetch(`${api}/verifygc`, config)
    .then((response) =>{
      console.log('THE RESPONSE FROM THE SERVER')
      console.log(response)
        return response.json().then(user => ({ user, response }))}
        ).then(({ user, response }) =>  {
          console.log('THE RETURNED USED FROM THE SERVER AS PROCESSED BY fetch api')
          console.log(user)
      if (!response.ok) {
        // If there was a problem, we want to
        // dispatch the error condition
        dispatch(captchaVerifyError(user.message))
        return Promise.reject(user)
      } else {
        // If login was successful, set the token in local storage 

         console.log('THE ERROR CHECK')
         console.log(user)
         console.log(user)

        if(user.error){

           console.log('IT HAS ERRORED FROM THE SERVER')
           if(user.message && typeof user.message === 'object'){

             const {message} = user 
             const textMessage = message.message 
            return dispatch(captchaVerifyError(textMessage))


           }else{

            const {message} = user
            return  dispatch(captchaVerifyError(message))

           }
        }

        if(!user.actionStatus){

          console.log('THE LOGIN FAILURE')
          console.log(user)
          let {message = 'System could not complete login successfully'} = user
           dispatch(captchaVerifyError(message))

        }else{


           const {actionStatus } = user 
           return dispatch(receiveCaptchaVerify(actionStatus))

          

        
        }
        
      }
    }).catch((err) =>{ 

      console.log("Error:vERIFY USER:VsERIFYUSER action createro ", err)
      const {message = 'System error ha occured trying to login user'} = err
      dispatch(captchaVerifyError(message))
      
    })
  }


}





export const  requestUserVerification = ()=>{
  return {
    type: types.SEND_USER_VERIFY_ACCOUNT_REQUEST,
    isFetching: true,
    
  }
}

export const receiveUserVerification = (verifyStatus)=> {

  return {
    
    type: types.SEND_USER_VERIFY_ACCOUNT_SUCCESS,
    isFetching: false,
    isAccountVerified : verifyStatus,
    verificationFailed: false
    
  }



}

export const userVerificationError = (message)=>{


  return dispatch => dispatch({

    type: types.SEND_USER_VERIFY_ACCOUNT_FAILURE,
    isFetching: false,
    isAccountVerified : false,
    verificationFailed: true,
    message: message
  })

}


export const sendUserVerificationCreds = (creds)=>{

  console.log('THE CREDS')
  console.log(creds)
  // creds = JSON.stringify(creds) 
  // let payload = {
  //   action: 'loginUser',
  //   payload: JSON.stringify(creds) 
  // }

  let config = {

    method: 'POST',
    headers: { 'Content-Type':'application/json' },
    body: JSON.stringify({action: 'verifyUser',payload: {verify: creds} })

  }


  return dispatch => {

    // We dispatch requestLogin to kickoff the call to the API 

   
    dispatch(requestUserVerification(config))

    return fetch(`${api}/register`, config)
    .then((response) =>{
      console.log('THE RESPONSE FROM THE SERVER')
      console.log(response)
        return response.json().then(user => ({ user, response }))}
        ).then(({ user, response }) =>  {
          console.log('THE RETURNED USED FROM THE SERVER AS PROCESSED BY fetch api')
          console.log(user)
      if (!response.ok) {
        // If there was a problem, we want to
        // dispatch the error condition
        dispatch(userVerificationError(user.message))
        return Promise.reject(user)
      }else{
        // If login was successful, set the token in local storage 

         console.log('THE ERROR CHECK')
         console.log(user)
         console.log(user)

        if(user.error){

           console.log('IT HAS ERRORED FROM THE SERVER')
           if(user.message && typeof user.message === 'object'){

             const {message} = user 
             const textMessage = message.message 
            return dispatch(userVerificationError(textMessage))


           }else{

            const {message} = user
            return  dispatch(userVerificationError(message))

           }
        }

        if(!user.actionStatus){

          console.log('THE LOGIN FAILURE')
          console.log(user)
          let {message = 'System could not complete verify user'} = user
           dispatch(userVerificationError(message))

        }else{


          const {actor = null,actionStatus = true} = user 
          if(!actor) return dispatch(userVerificationError('System error has occured trying to verify user'))
          const {auth,profile} = actor


         if(auth.accessToken){

           //if(actor.verifyResend) 
          
           
           localStorage.setItem('user', auth)
           dispatch(userProfileActions.receiveUserProfile(profile))
           return dispatch(receiveUserVerification(actionStatus))

         }else if(auth.badCredentials){

           const {message = 'Bad login credentials'} = user
           dispatch(userVerificationError(message))
           

         }else{

           const {message = 'System error has occured trying to verify the user'} = user
           //dispatch(loginError(message))
           if(message instanceof String) return dispatch(userVerificationError(message))
           return dispatch(userVerificationError('System error has occured trying to verify user'))

         }

          

        
        }
        
      }
    }).catch((err) =>{ 

      console.log("Error:vERIFY USER:VsERIFYUSER action createro ", err)
      const {message = 'System error ha occured trying to verify user'} = err
      dispatch(userVerificationError(message))
      
    })
  }


}



