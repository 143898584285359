import React from 'react';
// import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

// const ColorCircularProgress = withStyles({
//   root: {
//     color: '#00695c',
//   },
// })(CircularProgress);

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#b2dfdb',
  },
  barColorPrimary: {
    backgroundColor: '#00695c',
  },
})(LinearProgress);

// const BorderLinearProgress = withStyles({
//   root: {
//     height: 10,
//     backgroundColor: lighten('#ff6c5c', 0.5),
//   },
//   bar: {
//     borderRadius: 20,
//     backgroundColor: '#ff6c5c',
//   },
// })(LinearProgress);

// Inspired by the Facebook spinners.
// const useStylesFacebook = makeStyles({
//   root: {
//     position: 'relative',
//   },
//   top: {
//     color: '#eef3fd',
//   },
//   bottom: {
//     color: '#6798e5',
//     animationDuration: '550ms',
//     position: 'absolute',
//     left: 0,
//   },
// });

// function FacebookProgress(props) {
//   const classes = useStylesFacebook();

//   return (
//     <div className={classes.root}>
//       <CircularProgress
//         variant="determinate"
//         value={100}
//         className={classes.top}
//         size={24}
//         thickness={4}
//         {...props}
//       />
//       <CircularProgress
//         variant="indeterminate"
//         disableShrink
//         className={classes.bottom}
//         size={24}
//         thickness={4}
//         {...props}
//       />
//     </div>
//   );
// }

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    height: '45vh',
    width: '100%',
    position: 'fixed',
    top: '55vh',
    zIndex: 2000
  },

  margin: {
    margin: theme.spacing(1),

  },

  bar:{

    position: 'relative',
    top: '42vh',
    backgroundColor: 'yellow'


  }

}));

export default function CustomizedProgressBars() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <ColorCircularProgress size={30} thickness={5} /> */}
      <ColorLinearProgress className={`${classes.margin} ${classes.bar}`} />
      {/* <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={50}
      />
      <FacebookProgress /> */}
    </div>
  );
}








