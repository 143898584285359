
import React, {Component} from 'react' 
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import Tagline from './tagline/tagline' 
import Hero from './hero/hero' 
import Postdate from './hero/reasons'
import { toast } from 'react-toastify';




class ForgotPassword extends Component{


    constructor(props){

        super()
    }


    componentDidUpdate(){
        
        // console.log()
        // const {isActionSuccessful,launcher,isFetcing,actions} = this.props 
        // const  {removeNotification} = actions 
        // console.log('THE LAUNCHER INFOUPDATE::')
        // console.log(launcher)

        // if(isActionSuccessful && launcher === 'infoUpdate') {
        //     this.notify({message:'Profile info successfully updated',type: 'success',className: 'notify-success'}) 
        //     removeNotification()
        // }

        const {actions,forgotPassword} = this.props
        const {isActionSuccessful,message} = forgotPassword
        // const {removeNotification,removeMessage} = actions 

        if(isActionSuccessful) {
            this.notify({message:'Password reset link has successfully been sent.',type: 'success',className: 'notify-success'}) 
            // removeNotification() 
            
          
           
        }else if(message.trim()){

            this.notify({message: message,type: 'error',className: 'notify-error'}) 
            //removeMessage() 
            
        }


       
        
    }

    notify = (config) => {

            
        console.log('THE TOAST CONFIG')
        console.log(config)
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

    }

    render(){

        const {props} = this 
        const {actions,forgotPassword} = props 

        return(

            <article className="forgotpassword">
                  
                {/* <Tagline /> */}
                <Hero actions={actions} forgotPassword={forgotPassword} /> 
               
    
            </article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        forgotPassword: {
            ...state.forgotPassword
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(ForgotPassword)