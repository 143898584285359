
import React,{Component} from 'react'
import Passwordform from '../passwordform/passwordform'


class Hero extends Component{
    
    constructor(props){
        super()
    }

    
    
    render(){

        const {props} = this 
        const {footer,actions,email} = props

        return(

            <div className="resetpassword__hero">

               <section className="resetpassword__parallelogram bx-raised">

                  <div className="resetpassword__parallelogram--skew">
                  <div className="resetpassword__parallelogram--h">
                        <h2 style={{color: 'seagreen'}}>Create Jobbri Job Alert</h2>
                        <small>Enter alert information below</small>
                        <span></span>
                  </div> 

                  <Passwordform footer={footer} actions={actions} email={email}/>


                </div>


               </section>

               <section className="resetpassword__parallelogram-2 bx-raised"></section>
               <section className="resetpassword__parallelogram-3 bx-raised"></section>
            </div>
        )
    }

}


export default Hero