
import React,{Component} from 'react'
import PasswordForm from '../passwordform/passwordform'
// import Reasons from './reasons'


class Hero extends Component{
    
    constructor(props){
        super()
    }



    render(){



        const {props} = this 
        const {actions,resendVerification} = props 

        return(

            <div className="forgotpassword__hero">

               <section className="forgotpassword__parallelogram bx-raised">

                  <div className="forgotpassword__parallelogram--skew">
                   <div className="forgotpassword__parallelogram--h">
                        <h2>Resend Verification</h2>
                        <small>Get a verification link to verify your account</small>
                        <span></span>
                    </div> 


                   <PasswordForm actions={actions} resendVerification={resendVerification} />
                   

                    

                    </div>


               </section>

               <section className="forgotpassword__parallelogram-2 bx-raised"></section>
               <section className="forgotpassword__parallelogram-3 bx-raised"></section>
            </div>
        )
    }

}


export default Hero