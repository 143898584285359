
import React,{Component} from 'react'
import Shapes from './shapes/shapes' 
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'

import FavoriteIcon from '@material-ui/icons/Favorite';
import { ThemeContext } from '../../context';



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };

// import SearchForm from './searchform/launchform' 




class Categories extends Component{

    constructor(){

        super()
    }
    static contextType = ThemeContext

    openJobs = (jobKeyword,e)=>{

        e.preventDefault()
        const {props} = this 
        const {actions} = props 
        const {push} = actions 
    
        push(`jobs?jq=${jobKeyword}`)
    }
    
    
    render(){
        const {theme, themeName} = this.context
        const backWallStyles ={
            backgroundColor: themeName == 'dark' 
            ? theme.colors.complimentary['accent-3'] 
            :  theme.colors.complimentary.base 
        }
        const boxStyles = {
            backgroundColor: theme.colors.complimentary.base 
        }
        const textStyles = {
            color: theme.colors.complimentary.foreground
        }
        return(

                <section className="home__cate">


                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall" style={backWallStyles}></section>
                        <section className="home__cate--i-box bx-raised" onClick={this.openJobs.bind(this,'Office administrative')} style={boxStyles}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title" style={textStyles}>Office Administrative</section> 
                           
                        </section>
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall" style={backWallStyles}></section>
                        <section className="home__cate--i-box bx-raised" style={boxStyles}  onClick={this.openJobs.bind(this,'Design and Development')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title" style={textStyles}>Design &amp; Development</section> 
                           
                        </section>
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall" style={backWallStyles}></section>
                        <section className="home__cate--i-box bx-raised" style={boxStyles}  onClick={this.openJobs.bind(this,'Science and Technologies')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title" style={textStyles}>Science &amp; Technologies</section> 
                           
                        </section>

                    
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall" style={backWallStyles}></section>
                        <section className="home__cate--i-box bx-raised" style={boxStyles}  onClick={this.openJobs.bind(this,'Business Development')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title" style={textStyles}>Business Development</section> 
                           
                        </section>

                    
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall" style={backWallStyles}></section>
                        <section className="home__cate--i-box bx-raised" style={boxStyles}  onClick={this.openJobs.bind(this,'Human resources')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title" style={textStyles}>Human Resources</section> 
                           
                        </section>

                    
                    </div>

                    <div className="home__cate--i">
                        <section className="home__cate--i-backwall" style={backWallStyles}></section>
                        <section className="home__cate--i-box bx-raised" style={boxStyles}  onClick={this.openJobs.bind(this,'Retail sales food')}>

                            <small className="home__cate--i-box-icon"><Icon><FavoriteIcon /></Icon></small>
                            <section className="home__cate--i-box-title" style={textStyles}>Retail, Sales &amp; Food</section> 
                           
                        </section>

                    
                    </div>





                   

                    

                </section> 

               


           

            

                
            
        )
    }

}


export default  withStyles(styles)(Categories)