
import React,{Component} from 'react'
import { ThemeContext } from '../../../context'
import Send from './send/send' 



class Mail extends Component{
    
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        const {props} = this 
        const {actions,contact} = props

        return(

           

                
              
            <Send actions={actions} contact={contact} styles={{textColorStyles, textColorStylesAccent, boxesStyling, boxesStylingA}} />
            
               
                

           
        )
    }

}


export default Mail