import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`

	
	html, body{
		background: ${({ theme }) => theme.background};
       
        
	}
    

	
   
    textarea:focus, input:focus{
        outline: none;
    }
    iframe{
        display: none;
    }
	a{
		text-decoration: none;
		color: ${({ theme }) => theme.foreground};
    
    
    `;
