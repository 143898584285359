
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha"
import { ThemeContext } from '../../context';





class launchcta extends  Component{


    constructor(props){

        super(props)

        
        this.state = {
            form : {
                    
                    
               
               
                email:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                            isEmail: true
                            
                        },
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "email",
                        type: 'text',
                        placeholder: 'surprise@turtrozz.co.za',
                        // disabled: false
                        
                    }
                    
                },
                password:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                            isRequiredFormat:{
                                minLetters: 8,
                                maxLetters: 15,
                                
                            },
                            // isPlaceholder: true,
                            list: [
                                {isValid: false, isLowerCase: true,message:'Field contains a lowercase character'},
                                {isValid: false, isUpperCase: true,message:'Field contains uppercase character'},
                                {isValid: false, isNumber: true,message:'Field contains a number'},
                                {isValid: false, specialCharacters: true,message:'Field contains a special character'},
                                {isValid: false, minLetters: 8,message:'Field contains is a minimum of 8 characters'},
                                {isValid: false, maxLetters: 15,message:'Field contains less than 15 characters'},
                                
                            ]
                
                    },
                    isValid: false,
                    isTouched: false,
                    isPressed: false,
                    shouldList: true,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "password",
                        type: 'password',
                        placeholder: '*********',
                        // disabled: false
                        
                    }
                    
                },
                

              
            
            },
            
        };
    }

    shouldComponentUpdate(){

        const {props} = this 
        const {intervalID,auth} = props 
        const {isFetching} = auth

        if(intervalID && isFetching === true ){
            return false
        }else{

            return true
        }
    }
    updator = (upControl)=>{
        
            console.log('THE UPDATE CONTROL')
            console.log(upControl)
            let newForm ={...this.state.form} 
            let updateControl = {...newForm[upControl.id]}
            updateControl.value = upControl.e.target.value 
            const {validation={}} = updateControl 
            const {list=[]} = validation 

            console.log('HTE UPDATE CONTROL')
            console.log(updateControl)
            console.log(JSON.stringify(list))
            

        
            let validatedList = list ? this.byCharacterValidate(list,updateControl.value) : null
            validatedList ? updateControl.validation.list = [...validatedList] : null 
            console.log(validatedList)
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1]

                    if(!updateControl.isValid){

                        for(let k in newForm){ 


                            if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                                newForm[k].config.disabled = true
                            }
                        }
                    }else{

                        for(let k in newForm){ 

                            if(newForm[k].config.disabled === true){
                                newForm[k].config.disabled = false
                            }
                        }

                    }
                    
                }

            }
        
            updateControl.touched = upControl.blur 
            newForm[upControl.id] = updateControl
            
            this.setState({
                form: newForm
                
            })


    } 

    checkPressed = (upControl)=>{
        
        // console.log('THE UPDATE CONTROL')
        // console.log(upControl)
        let newForm ={...this.state.form} 
        let updateControl = {...newForm[upControl.id]}
        updateControl.value = upControl.e.target.value 
        // const {validation={}} = updateControl 
        // const {list=[]} = validation 

        // console.log('HTE UPDATE CONTROL')
        // console.log(updateControl)
        // console.log(JSON.stringify(list))
        

    
        
        if(updateControl.isPressed) return
        updateControl.isPressed = true
        newForm[upControl.id] = updateControl
        
        this.setState({
            form: newForm
            
        })


   } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            // console.log('SPECIAL CHARACTER TEST')
            // console.log(charsArray)
            // console.log(containsSpecialCars)
            // console.log(value)
            // console.log(specialCharactersRegex)
            if(validateControl.shouldList) {



                let pattern = new RegExp(
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
                  )

                if(pattern.test(value)) return true
                return [false,``]

            }
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }


    byCharacterValidate = (list,value='')=>{

        return list.map((li,i)=>{


            if(this.validationRules(li,value.trim())) return {...li,isValid: true}
            return {...li,isValid: false}
        })


    }

    validationRules = (isRequiredFormat,value)=>{



            console.log('THE REQUIRED')
            console.log(isRequiredFormat)
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            let pattern = null 
            //"^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"

        

            

            if(isRequiredFormat.specialCharacters) {

                console.log('Testing the special character')
                pattern = new RegExp(
                    "^(?=.*[-+_!@#$%^&*.,?]).+$" // Match atleast one special character
                  )
                if(pattern.test(value)) return true 
                return false

            }
            if(isRequiredFormat.isUpperCase){

                pattern = new RegExp(
                    "^(?=.*[A-Z]).+$" // Match atleast one uppercase character
                  )
                if(pattern.test(value)) return true 
                return false

            }
            if(isRequiredFormat.isLowerCase) {

                pattern =new RegExp(
                    "^(?=.*[a-z]).+$" //  Match atleast one lowercase character
                  )
                if(pattern.test(value)) return true 
                return false

            }
            if(isRequiredFormat.isNumber) {

                pattern =new RegExp(
                    "^(?=.*\\d).+$" // Match atleas one number
                  )
                if(pattern.test(value)) return true 
                return false

            }
            if(isRequiredFormat.minLetters) {

                if(value.length >= isRequiredFormat.minLetters) return true 
                return false

            }

            if(isRequiredFormat.maxLetters) {

                if(value.length <= isRequiredFormat.maxLetters) return true 
                return false
    
            }
          
            
            return false

      
        // if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return true
        // if(isRequiredFormat.minLetters && (value.length >= isRequiredFormat.minLetters) ) return true
        // if(isRequiredFormat.minPieces && (pieces.length >= isRequiredFormat.minPieces) ) return true
        // if(isRequiredFormat.maxPieces && (pieces.length <= isRequiredFormat.maxPieces) ) return true
        // if(isRequiredFormat.isNumber && !isNaN(value) ) return true
        // if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return FALSE
        // return false
    }
   

    submitForm = (e)=>{
        
        e.preventDefault() 
        
       
        //let formIsValid = true 
        
        
        
        // for(let k in this.state.form){
            
        //     submittData[k] = this.state.form[k].value
        // } 
        
        // console.log('THE SIGN UP DATA')
        // console.log(submittData)
        // submittData.usertype = 'applicant'
        // submittData.strategy = 'anzii'
        // this.props.registerUser(submittData)

        const {actions,auth} = this.props
        const {isCaptchaVerified} = auth
        const {registerUser} = actions 
        let submittData = {} 
        let formIsValid = true 
        let {form} = this.state 
        let {notify} = this
        
        
        
        for(let k in form){
            
            // console.log('THE SUBMIT CONTROL')
            // console.log(form[k])
            if(form[k].validation && form[k].validation.isRequiredFormat){

                console.log('THE CONTROL REQUIRES VALIDATION')

                if(form[k].isValid === false){

                    // console.log('CONTROL:',k, ' is not valid')
                    formIsValid = false 
                    
                    console.log(notify)
                    notify({message:'Sign up credentials must be valid',type: 'warning',className: 'notify-warn'})
                    break


                }else{
 
                    submittData[k] = form[k].value

                }

            }else{

                if(form[k].value.trim() !== ''){

                    submittData[k] = form[k].value

                }
               

            }
          
        
        } 

        if(!formIsValid) return 
        console.log('THE ISCAPTCHA VERIFIED')
        // console.log(isCaptchaVerified)
        // if(!isCaptchaVerified) return  notify({message:'Recaptcha must be selected before signup',type: 'warning',className: 'notify-warn'})

        console.log('THE SIGN UP DATA')
        console.log(submittData)
        console.log(this.extractNameFromEmail(submittData.email))
        submittData.firstname = this.extractNameFromEmail(submittData.email)
        submittData.usertype = 'applicant'
        submittData.strategy = 'anzii'
        registerUser(submittData)


    
        // console.log(submittData)
        // setCurrentSender('modalForm')
        // submittData['launchType'] = 'official'
        // sendContactData(submittData)  
        
        

    }

    notify = (config) => {

        
            console.log('THE TOAST CONFIG')
            console.log(config)
            const {props} = this 
            const {actions,auth} = props 
            const {removeShownMessage} = actions
            const {message} = config || ''
            const type = config.type || 'info'
            const errMessage = auth.message
            toast[type](message,
                {position: toast.POSITION.BOTTOM_LEFT,...config});

            if(errMessage !== ''){

                removeShownMessage()

            }
            
        
    }

    extractNameFromEmail(email){

        return  email.split("@")[0]
    }
        
    componentDidUpdate(){
        
         
            console.log('THE LOGIN COMPONENT WILL MOUNT AT SOME POINT')
            console.log('THE VALUE OF AUTH IN DID UPDATE') 
            const {props} = this 
            const {auth,actions,toRedirect = '/dashboard'} = props 
            const {push} = actions
            const {appAuth} = auth
            const {isAuth} = appAuth

            console.log(isAuth)
    

            if(isAuth){

                console.log('ABOUT TO REDIRECT TO THE DASHBOARD AREA')
                push(toRedirect)
            }
        

                // const {actions,login} = this.props
                // const {isActionSuccessful,message} = login
                // const {removeNotification,unsetCurrentSender} = actions 

               
                   
                // }else if(message.trim()){

                //     this.notify({message:'There was a network error sending message',type: 'error',className: 'notify-error'}) 
                //     removeNotification() 
                //     //unsetCurrentSender()
                // }


               
                
    }
    onChange = (value) => {

        console.log("Captcha value:", value) 
        const {actions} = this.props
        const {verifyGoogleCaptcha} = actions 

        verifyGoogleCaptcha({humanValue: value})

    }

    handleExpired = () => {

       
        const {actions} = this.props
        const {captchaVerifyError} = actions 

        captchaVerifyError('Recaptcha has expired, please verify again.')

        

    }

    handleErrored = () => {

       
        const {actions} = this.props
        const {captchaVerifyError} = actions 

        captchaVerifyError('Recaptcha could not complete.')



    }
   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        const {props} = this 
        const {auth} = props 
        const {isFetching,isCaptchaVerified,message = ''} = auth 
        console.log('tHE MESSAGE')
        console.log(message)
        // console.log(message.trim() !== '')

        return(

                    <section className="access__launchcta">

                        {/* {
                             message.trim() !== ''
                            ? <p tabIndex= '-1' autoFocus={true} style={{color: 'red', fontSize: '12px',textAlign: 'center'}} >{message}</p>
                            : null 

                        } */}

{
                             message.trim() !== ''
                            ? this.notify({message:message,type: 'error',className: 'notify-error'})
                            : null 

                        }

                        <form className="form access__launchcta--cont">
                            
                            

                            <div className="access__launchcta--control bx-raised" style={boxesStyling}>

                                

                                <label className="access__launchcta-label" style={textColorStyles}>Email Address</label>
                                <FormControl 
                            
                                    styles={{child:'access__launchcta-input',error: ''}}
                                    id = 'email'
                                    controlData={this.state.form.email}
                                    change={(control)=>this.updator(control)}
                                    style={{...boxesStyling, ...textColorStyles,paddingLeft: "15px"}}
                                    // click={(control)=>this.checkPressed(conrol)}
                                    
                                
                                />


                            </div> 

                            <div className="access__launchcta--control bx-raised" style={boxesStyling}>

                                

                                <label className="access__launchcta-label" style={textColorStyles}>Password</label>
                                <FormControl 
                            
                                    styles={{child:'access__launchcta-input',error: ''}}
                                    id = 'password'
                                    controlData={this.state.form.password}
                                    change={(control)=>this.updator(control)}
                                    click={(control)=>this.checkPressed(control)}
                                    style={{...boxesStyling, ...textColorStyles, paddingLeft: "15px"}}

                                  
                                    
                                
                                />


                            </div> 

                            {/* <div className="jobbri-google-recapcha">
                            <ReCAPTCHA sitekey="6LcTGC8aAAAAAGf9pR34ElR1rZqtCFe-XDtLzpdp" onChange={this.onChange} onExpired={this.handleExpired} onErrored={this.handleErrored} />
                            </div> */}

                            <div>
                                {
                                    isFetching === true && isCaptchaVerified === true 
                                    ? 
                                        <section className="huloader huloader-co">
                                            <div className="huloader__loading huloader__loading-co">
                                                <div></div>
                                                <div></div>
                                            </div>  
                                        </section>
                                     
                                 : <button className="access__launchcta-submit" onClick={(e)=>this.submitForm(e)}>
                                             <span className="access__launchcta-submit-rect"></span>
                                         <span className="access__launchcta-submit-rect-2">
                                                <small>Sign up</small></span>
                                      </button>
                                

                                    
                                 } 

                            
                            </div>

                            <section>
                                <p className="access__signup--re-cont">
                                    <span className="access__signup--registered" style={textColorStyles}>Already registered?</span>
                                    <span className="access__signup--signin" onClick={(e)=>this.props.renderForm('signin',e)}>Sign in</span></p>
                            </section>

                            {/* <section className="access__signup--btn">
                                <input className="access__signup--submit" onClick={(e)=>this.submitForm(e)} type="submit" value="Sign up" />
                            </section> */}


                

                            {/* <div className="service__modal--form-control">

                                <input type="submit" value="COUNT ME IN" onClick={(e)=>this.submitForm(e)} className="access__launchcta-submit" />
                                
                            </div>  */}

                            



                        
                            
                        </form>

                       
                        <div>

                            {
                                
                                // isProgressBar === true && isFetching === true
                                //     ?  <ProgressBarLinear />
                                //     : null
                            }
        
                        
                        </div>

                    </section>

                    
               

             
             
           
        )
    }




}

export default launchcta