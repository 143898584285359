
import * as types from './types'
import { CALL_API } from '../../middlwares/api'



export const sendQueryMessage = (data,launcher='')=> {

    return {

        [CALL_API]: {
          endpoint: `/contact`,
          method: 'POST',
          request: 'URL',
          payload: {action: 'sendQueryMessage',payload: {sender: {...data}}},
          authencitcated: false,
          launcher: launcher,
          types: [types.SEND_QUERY_MESSAGE_REQUEST, types.SEND_QUERY_MESSAGE_SUCCESS, types.SEND_QUERY_MESSAGE_FAILURE]
        }
      }

  }


  export const unsetNotificationDisplay = ()=> {

    return {

        type: types.UNSET_NOTIFICATION_DISPLAY,
        payload: false
        
      }

}