
import React,{Component} from 'react'
import TouchInfo from './touchinfo/touchinfo' 



class Touch extends Component{
    
    
    render(){

        return(

           

                
                <div  className="contact__touch">
                    
                     <TouchInfo />
                </div>
               
                

           
        )
    }

}


export default Touch