
import React, {Component} from 'react'
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import * as blogrActions from '../Blogr/actions'
import qs from 'query-string'
import BlogHeader from './components/blogheader/blogheader'
import BlogArticle from './components/blogarticle/blogarticle'
import BlogRelated from './components/blogrelated/blogrelated'
import ErrorHandler from '../ClientErrorHandler/component'
// import { fetchBlogPosts } from '../Blogr/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'



class BlogPost extends Component{

    constructor(props){

        super()
        this.state = {

           
            width: 0,
            height: 0,
            
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


       
  

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

   

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
        const {props} = this 
        const {blogr,actions} = props 
        const {message=''}  = blogr 
        const {removeMessage} = actions

        if(message.trim()) removeMessage()
      
     }

    //  componentDidUpdate(){


    //     const {props} = this 
    //     const {blogr,actions} = props 
    //     const {message=''}  = blogr 
    //     const {removeMessage} = actions
    

    //     if(message.trim()) removeMessage()
       
        


     
    // }



    

    componentDidMount(){


        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions)


        const {props} = this 
        const {actions,match} = props 
        const {params}  = match
        const {slug=''} = params 
        const {fetchBlogPostWith,push} = actions
       

        if(!slug.trim()) return push('/404')
        return fetchBlogPostWith(slug)
        //if(!this.findPostLocally(slug)) return fetchBlogPosts(slug)
        


     
    }

    findPostLocally = (identifier)=>{

        const {props} = this 
        const {blogr} = props 
        const {posts} = blogr 

        console.log('THE IDENTIFIER')
        console.log(identifier)
        

        let post = posts.filter((p,i)=>{

            console.log(p)
            console.log(p.slug)

            if(p.slug.toLowerCase().trim() === identifier.toLowerCase().trim()) return true 

        })

        if(post.length === 0) return false 
        return post[0]

         


    }
    openPage(path,e){

        e.preventDefault()

        const {props} = this 
        const {actions} = props 
        const {slug,url} = path
        const {fetchBlogPostWith,push} = actions
        if(!slug.trim()) return push('/404')
        fetchBlogPostWith(slug)
        return push(url)
        // if(!this.findPostLocally(slug)) return fetchBlogPosts(slug)
        

        

    }

    openBlogPage(path,e){

        e.preventDefault()

        const {props} = this 
        const {actions} = props 
        const {topic,url} = path
        const {push} = actions
        return push(`${url}${topic}`)
        // if(!this.findPostLocally(slug)) return fetchBlogPosts(slug)
    
        
    }
    

    retryOnError = ()=>{

        const {props} = this 
        const {actions,match} = props 
        const {params}  = match
        const {slug=''} = params 
        const {fetchBlogPostWith,push} = actions
        return fetchBlogPostWith(slug)


    }

    getErrorMeta = (color='warn')=>{

        let message,header,headerText  = ''
        
        if(color === 'warn'){

            message = 'We have unfortunately encountered an issue getting blog post' 
            header = 'OH SNAP!'
            headerText = 'Blog post could not load!'
           
        }else{

            message= 'We have unfortunately encountered an error processing a request'
            header = 'OOPS!'
            headerText = 'Something went wrong!'


        }
       
       

        return {

            header:header,
            headerText:headerText,
            errorText:message,
            buttonText: 'Please Try Again',
            actionColor: color,
            postErrorAction: this.retryOnError
        } 
    }

    



    // renderForm (form,e){

    //     e.preventDefault()
    //     this.setState({
    //         currentForm: form
    //     })
    // }

    // registerUser = (user)=>{

    //     console.log('THIS  LOGIN COMPONENT')
    //     console.log(this)
    //     this.props.actions.registerUser(user)
    // }

    // loginUser = (user)=>{

    //     console.log('THIS  LOGIN COMPONENT')
    //     console.log(this)
    //     this.props.actions.loginUser(user)
    // }


    render(){

        
        const {props,state} = this 
        const {width} = state
        const {blogr} = props 
        const {post,related,message=''} = blogr 
        const {title='Post'} = post
        
        

        // console.log('THE PARAMS')
        // console.log(params)
        // console.log(router)
        // console.log(match)

        const {isFetching=false} = blogr 

        if(isFetching === true) return  <article className="post" style={{height: '100vh',width: '70%',paddingLeft: '17%',position: 'relative'}}>
            <Helmet>
                <title>Jobbri - Blog | Post</title>
            </Helmet>
            <ProgressBarCircular style={{width: '100%'}} />
        </article>;


        

        if(message.trim()) return <ErrorHandler errorMeta={this.getErrorMeta('error')}  /> 
        if(!post) return <ErrorHandler errorMeta={this.getErrorMeta('warn')}  /> 

        return(

            <article className="post">
               
               <Helmet>
                    <title>Jobbri - Blog | {`${title}`}</title>
                </Helmet>
               {/* <BlogHeader /> */}
               <BlogArticle post={post} posts={related} width={width} openPage={this.openPage.bind(this)} openBlogPage={this.openBlogPage.bind(this)} />
              {/* {related.length > 0 ? <BlogRelated openPage={this.openPage.bind(this)} posts={related} /> : null} */}
               
            </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        
        blogr:{ ...state.blogr},
        router:{...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...navigatorActions,...blogrActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(BlogPost)