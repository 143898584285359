
import {nextQuestionr,askQuestion} from './questionr'
import {showIO} from './view'
import {emptyProfileItems} from './util'
import { updateProfileItems } from '../actions'



export const registerUser = (self=null,text='')=>{

    console.log('THE REGISTER QUESTIONING IS COMPLETE')

    const {props} = self 
    const {setProcessor} = props 
    setProcessor(false,'')
    nextQuestionr(self,'complete')

    

}

export const updateUserProfileSegment = (self=null,isSuccessful=false)=>{

   

    const {props} = self 
    const {actions,humanoid} = props 
    const {updateType,questionas} = humanoid 
    const {profileitems} = questionas
    const {setProcessor,updateProfileItems,unsetAnswer} = actions

    setProcessor(false,'')
    updateProfileItems(emptyProfileItems(profileitems))
    // nextQuestionr(self,'complete')

    if(!isSuccessful){

        if(updateType === 'edition'){


            nextQuestionr(self,'editrecordfailure')
    
    
        }else if(updateType === 'addition'){
    
            nextQuestionr(self,'addrecordfailure')
    
        }else if(updateType === 'deletion'){
    
            nextQuestionr(self,'deleterecordfailure')
    
        }


    }else{


        unsetAnswer()
        if(updateType === 'edition'){

            nextQuestionr(self,'editrecordcomplete')
    
    
        }else if(updateType === 'addition'){
    
            nextQuestionr(self,'addrecordcomplete')
    
        }else if(updateType === 'deletion'){
    
            nextQuestionr(self,'deleterecordcomplete')
    
        }
        
    }

    

    // setTimeout(()=>{
    //     setProcessor(false,'')
    //     nextQuestionr(self,'editrecordcomplete')
    // },60000)
    

    

}







