import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import Cuport from './cuports'
import CuLanguages from './culanguages'
import CuSkills from './cuskills'
import { ThemeContext } from '../../context'





class Curated extends Component{
    
    constructor(){

        super()
        this.state = {

            width: 0,
            height: 0,
            portfolioEdit: false,
            skilEdit: false,
            languageEdit: false,
            isEdit: false,
            isEditing: 0,
            portfolios: [

                {

                    name: 'Indym',
                    description: 'independent Modeling agency',
                    link: 'https://indym.herokuapp.com',
                   
                },
                {

                    name: 'PHP',
                    description: 'independent Modeling agency',
                    link: 'https://indym.herokuapp.com',
                   
                },
                {

                    name: 'Javascript',
                    description: 'independent Modeling agency',
                    link: 'https://indym.herokuapp.com',
                   
                }
            ],
            languages: [

                {

                    language: 'Xitsonga',
                    speak: 'Excellently',
                    write: 'Excellently',
                    read: 'Excellently',
                   
                   
                },
                {

                    language: 'English',
                    speak: 'Good',
                    write: 'Good',
                    read: 'Good',
                   
                   
                },
                {

                    language: 'Tswana',
                    speak: 'Fairly',
                    write: 'Poorly',
                    read: 'Poorly',
                   
                   
                }
               
            ],
            skills: [

                {

                    name: 'Mysql',
                    score: '50%',
                    years: '5',
                   
                   
                   
                },

                {

                    name: 'PHP',
                    score: '50%',
                    years: '5',
                   
                   
                   
                },
                {

                    name: 'Javascript',
                    score: '90%',
                    years: '8',
                   
                   
                   
                },
               
               
            ],
            languageOptions: ['Excellently,Good,Fairly,Poorly'],
            isPortfolioAdding: false,
            isPortfolioFirstAdd: false,
            isPortfolioEditing: 0,
            isLanguageAdding: false,
            isLanguageFirstAdd: false,
            isLanguageEditing: 0,
            isSkillAdding: false,
            isSkillFirstAdd: false,
            isSkillEditing: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        
    }



    allowEdit = (key,e)=>{

        e.preventDefault()

         let editing = `${key}Edit` 
    
         let editingValue = this.state[editing]
         this.setState({...this.state,[editing]: !editingValue})
    }

    setIsPortfolioEditing = (index,e)=>{

        console.log('SET IS EDITING')
        console.log(e)
        console.log(index)
        this.setState({...this.state,isPortfolioEditing: index})

    }

    setIsLanguageEditing = (index,e)=>{

        console.log('SET IS EDITING')
        console.log(e)
        console.log(index)
        this.setState({...this.state,isLanguageEditing: index})

    }


    setIsSkillEditing = (index,e)=>{

        console.log('SET IS EDITING')
        console.log(e)
        console.log(index)
        this.setState({...this.state,isSkillEditing: index})

    }



    isPortfolioAdd = (isFirstAdd=false,e=null)=>{

        if(isFirstAdd) return this.setState({...this.state,isPortfolioAdding: !this.state.isPortfolioAdding,isPortfolioFirstAdd: true})
        this.setState({...this.state,isPortfolioAdding: !this.state.isPortfolioAdding,isPortfolioFirstAdd: false})
    }

    isLanguageAdd = (isFirstAdd=false,e=null)=>{

        
        if(isFirstAdd){
            this.setState({...this.state,isLanguageAdding: !this.state.isLanguageAdding,isLanguageFirstAdd: true})
        }else{
            this.setState({...this.state,isLanguageAdding: !this.state.isLanguageAdding,isLanguageFirstAdd:false})
        }
    }

    isSkillAdd = (isFirstAdd=false,e=null)=>{

       if(isFirstAdd) return this.setState({...this.state,isSkillAdding: !this.state.isSkillAdding,isSkillFirstAdd:true})
       this.setState({...this.state,isSkillAdding: !this.state.isSkillAdding,isSkillFirstAdd:false})
    }

    updateWindowDimensions() {

        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
      
     }
 
 


    
     static contextType = ThemeContext
  
     render(){
 
         const {theme, themeName} = this.context 
         const boxesStyling = { backgroundColor: themeName == 'dark' 
         ? theme.colors.complimentary['accent-3'] 
         :  theme.colors.complimentary.base }
         const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
         const textColorStyles = {color:  theme.colors.complimentary.foreground}
         const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
 


       
       const {state,props,allowEdit} = this 
       const {isPortfolioAdding,isLanguageAdding,isSkillAdding,width,
              isSkillEditing,isPortfolioEditing,isLanguageEditing,isLanguageFirstAdd,isSkillFirstAdd,isPortfolioFirstAdd} = state 
       const {actions,userProfile} =  props
       const {profile} = userProfile
       const {portfolio=[],language=[],skill=[]} = profile

        return(

           
    
            <div className="resume__curated" >

                 
                 <h2 className="resume__curated--title">
                     <span className="resume__curated--title-circle"></span>
                     <span className="resume__curated--title-text" style={textColorStylesAccent}>More Information About Me</span>
                 </h2>

                 <div className="resume__curated-rect" style={boxesStyling}>

                 <CuLanguages width={width} language={language} actions={actions} userProfile={userProfile} isEditing={isLanguageEditing} isLangAdd={isLanguageAdding} isAdding={isLanguageAdding} isLanguageAdding={this.isLanguageAdd.bind(this)} setIsEditing={this.setIsLanguageEditing.bind(this)} isFirstAdd={isLanguageFirstAdd} allowEdit={this.allowEdit.bind(this)} />

                     {/* <section className="resume__curated--card">

                     {!isEdit 
                        ?   <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={allowEdit.bind(this)}>
                            <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                            </small>
                        : null 
                        }
                        <div className="resume__curated--card-pointer"></div>

                        <div className="resume__curated--card-pointee">

                            <h5 className="resume__curated--card-pointee-period">Languages</h5>
                            <p className="resume__curated--card-pointee-legend">
                                <span>Language</span>
                                <span>Speak</span>
                                <span>Write</span>
                                <span>Read</span>
                                <span>Actions</span>
                                
                            </p>
                            <ul className="resume__curated--card-pointee-list">

                                
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>English</span>
                                    <span>Excellently</span>
                                    <span>Excellently</span>
                                    <span>Excellently</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Tshwane</span>
                                    <span>Good</span>
                                    <span>Fairly</span>
                                    <span>Fairly</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                </li>
                            </ul>

                        </div>

                     </section> */}

                     <section className="resume__curated--card resume__curated--card-2">
                    
                     <CuSkills width={width} skill={skill} actions={actions} userProfile={userProfile} isEditing={isSkillEditing} isSkillAdd={isSkillAdding} isAdding={isSkillAdding} isSkillAdding={this.isSkillAdd.bind(this)} setIsEditing={this.setIsSkillEditing.bind(this)} isFirstAdd={isSkillFirstAdd} allowEdit={this.allowEdit.bind(this)} />

                     {/* {!isEdit 
                        ?   <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={allowEdit.bind(this)}>
                            <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                            </small>
                        : null 
                        }
                     <div className="resume__curated--card-pointer"></div>

                    <div className="resume__curated--card-pointee">

                        <h5 className="resume__curated--card-pointee-period">Skills</h5>
                        <p className="resume__curated--card-pointee-legend">
                                <span>Skill</span>
                                <span>Score</span>
                                <span>Years</span>
                                <span>Actions</span>
                               
                            </p>

                        <ul className="resume__curated--card-pointee-list">

                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Mysql</span>
                                    <span>80%</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>PHP</span>
                                    <span>50%</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Javascript</span>
                                    <span>95%</span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>


                            </ul>

                    </div> */}

                    


                     </section>

                     <Cuport width={width} portfolio={portfolio} actions={actions} userProfile={userProfile} isEditing={isPortfolioEditing} isPortAdd={isPortfolioAdding} isAdding={isPortfolioAdding} isPortfolioAdding={this.isPortfolioAdd.bind(this)} setIsEditing={this.setIsPortfolioEditing.bind(this)} isFirstAdd={isPortfolioFirstAdd} allowEdit={this.allowEdit.bind(this)} />
                     {/* {isPortfolioAdding ? <Cuport isAdding={true} /> : null} */}

                     {/* <section className="resume__curated--card">

                     {!isEdit 
                        ?   <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={allowEdit.bind(this)}>
                            <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                           
                            </small>
                        : null 
                        }
                     <div className="resume__curated--card-pointer"></div>

                     <div className="resume__curated--card-pointee">

                        <h5 className="resume__curated--card-pointee-period">Portfolio</h5>
                        <p className="resume__curated--card-pointee-legend">
                                <span>Name</span>
                                <span>Description</span>
                                <span>Link</span>
                                <span>Actions</span>
                            
                            </p>

                        <ul className="resume__curated--card-pointee-list">

                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Indym</span>
                                    <span>An independent Modeling agency</span>
                                    <span><a href="https://indym.herokuapp.com" target="_blank">View</a></span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>PHP</span>
                                    <span>An independent Modeling agency</span>
                                    <span><a href="https://indym.herokuapp.com">View</a></span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>
                                <li className="resume__curated--card-pointee-list-i">
                                    <span>Javascript</span>
                                    <span>An independent Modeling agency</span>
                                    <span><a href="https://indym.herokuapp.com">View</a></span>
                                    <span>
                                        <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                            
                                        <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                    </span>
                                    
                                </li>


                            </ul>

                        </div>
                     </section> */}

                     
                    
                    
                    
                    
                   

                </div>

               
                



            </div>

          
        )
    }


}


export default Curated


