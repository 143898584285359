
import React, {Component} from 'react' 
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import qs from 'query-string'
import { toast } from 'react-toastify';
//import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import * as footerActions from '../Footer/actions'
import Tagline from './tagline/tagline' 
import Hero from './hero/hero' 
// import Postdate from './hero/reasons'




class footer extends Component{


    constructor(){

        super()
    }

    

    componentDidUpdate(){
        
        // console.log()
        // const {isActionSuccessful,launcher,isFetcing,actions} = this.props 
        // const  {removeNotification} = actions 
        // console.log('THE LAUNCHER INFOUPDATE::')
        // console.log(launcher)

        // if(isActionSuccessful && launcher === 'infoUpdate') {
        //     this.notify({message:'Profile info successfully updated',type: 'success',className: 'notify-success'}) 
        //     removeNotification()
        // }

        const {footer} = this.props
        const {isActionSuccessful,message} = footer
        // const {removeNotification,removeMessage} = actions 

        if(isActionSuccessful) {
            this.notify({message:'Job alert successfully created, confirm the email we\'ve sent to you to finish.',type: 'success',className: 'notify-success'}) 
            // removeNotification() 
            
          
           
        }else if(message.trim()){

            this.notify({message: message,type: 'error',className: 'notify-error'}) 
            //removeMessage() 
            
        }


       
        
    }

    notify = (config) => {

            
        console.log('THE TOAST CONFIG')
        console.log(config)
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

    }




    render(){

        const {props} = this 
        const {actions,footer} = props 
       


        return(

            <article className="footer">
                  
                {/* <Tagline /> */}
                <Hero footer={footer} actions={actions} /> 
               
    
            </article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        footer: {
            ...state.footer
        },
        router: {...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...footerActions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(footer)