
export const OPERATIONAL_ERROR_404 = 'OPERATIONAL_ERROR_404'
export const OPERATIONAL_ERROR_500 = 'OPERATIONAL_ERROR_500'
export const OPERATIONAL_ERROR_503 = 'OPERATIONAL_ERROR_503'
export const OPERATIONAL_ERROR_400 = 'OPERATIONAL_ERROR_400' 
export const OPERATIONAL_ERROR_401 = 'OPERATIONAL_ERROR_401' 
export const OPERATIONAL_ERROR_403 = 'OPERATIONAL_ERROR_403' 
export const OPERATIONAL_ERROR_408 = 'OPERATIONAL_ERROR_408'
export const NONE_OPERATIONAL_ERROR = 'NONE_OPERATIONAL_ERROR'  








