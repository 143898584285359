import React from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Manage from '@material-ui/icons/Edit';


const styles = {
  root: {
  
    fontSize: 20,
    
  },
  iconhover:{
      '&:hover':{

          content: ""
      }
  }
};



class Button extends React.Component{

  constructor(props){
    super();

  }

 


  render(){

    const {classes} = this.props 
    const {openPopup} = this.props
    return(

      <button  onClick={openPopup} className="dashboard__candalertlist__card__extra-manage-actions-icon">
        <Icon><Manage className={classes.root} /></Icon>
      </button>

      
      
    )
  }
}

export default withStyles(styles)(Button)