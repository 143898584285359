
import React from 'react';
import {BrowserRouter,Link} from 'react-router-dom';
import { useAppThemeContext } from '../../../context';


const Brand = ()=>{


	const {theme, themeName} = useAppThemeContext()

    const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
    let logo =  themeName === "dark" ? '/img/jo_white.png' : '/img/jobbrilogo.png'
		return(


			<div className="app__brand" >

                <Link to='/' className="app__brand--name">

                    <span className="fg-dark d-inline-block ">

                    <img src={logo} alt="jobbri logo" width="90" />
                    </span>
                    {/* <span className="fg-dk-green mg-left-fd-bt d-inline-block font-fd-tn">
                        o
                    </span> */}



                </Link>

                    
			</div>
		)


	
	

}




export default Brand;





