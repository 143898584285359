let urlProtocol = process.env.REACT_APP_SERVER_URL_PROTOCOL ? process.env.REACT_APP_SERVER_URL_PROTOCOL : "https"
let apiProtocol = process.env.REACT_APP_SERVER_API_PROTOCOL ? process.env.REACT_APP_SERVER_API_PROTOCOL : "https"
let appEnviroment = process.env.REACT_APP_ENVIROMENT ? process.env.REACT_APP_ENVIROMENT : "production"
export const server = {
    
    url : urlProtocol + '://' + process.env.REACT_APP_SERVER_URL_URI,
    api: apiProtocol + '://' + process.env.REACT_APP_SERVER_API_URI,
    
}

export const auth ={
    token: process.env.REACT_APP_AUTH_TOKEN
}

export const google = {
    gaAnalyticsTCODE : process.env.REACT_APP_GA_ID,
    gaDebug: appEnviroment.toLowerCase() === "development" ? true : false
}

