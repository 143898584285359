

import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import Modal from 'react-modal'; 
import Form from './form'
 
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '60%',
    padding               : 0,
    border                : 'none',
    background            : 'none',
    zIndex                : 1000,
    borderRadius          : 0
  }
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement')
 

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)'

class AlertModal extends Component{


  constructor(props){
    super(props)
    this.state ={
      modalIsOpen: false
    }
  }
  // const [modalIsOpen,setIsOpen] = React.useState(false);
  openModal(){
    this.setState({...this.state,modalIsOpen: true})
  }
 
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
 
  closeModal(){
    // console.log('THE VALUE OF THIS')
    // console.log(this)
    // console.log(this.setState)
    this.setState({...this.state,modalIsOpen: false})
  }
 
    render(){

    
    const {state,props,closeModal,openModal,afterOpenModal} = this 
    const {modalIsOpen} = state 
    const {auth,userProfile,actions,componentProps,width} = props 
    const {viewPage,styles={color: 'inherit'}} = componentProps
    const {profile} = userProfile 
    const {alertsCount} = profile 
    const modCont = width < 800 
    
    ? {
      paddingTop: `3rem`,
      paddingLeft: '5%',
      paddingBottom: '2rem',
    } : {}

    if(width > 900 && width <= 1200) customStyles.content.width = '80%'
    if(width <= 900) customStyles.content.width = '90%'

    const modFoot =  width <= 800 && alertsCount === 3 ? {marginTop: '-5rem'} : null
    
  
    // const auth = {}
    // const actions ={}
    // const disabledInput = alertSubscriberID <= 0 ? false : true 
     const disabledInput = true 
    // console.log('The disabledInput') 
    // console.log(disabledInput)
    
    return (


      <div style={{display: 'inline-block'}}>
          
          <button style={styles}  className={props.class} onClick={openModal.bind(this)}>

            {/* <span className="job__jobs--lt-alert-i">
                <Icon>
                    <Notifications />
                </Icon>
            </span>

            <span className="job__jobs--lt-alert-text"  >
              Email me jobs like this                     
            </span> */}

            {props.children}


          </button>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal.bind(this)}
          style={customStyles}
          contentLabel="Example Modal"
        >

        

            <section className="alert__modal">

              <article className="alert__modalcontainer">

                  <div className="alert__modalbody">
                  
                  { alertsCount < 3
                        ? null
                        : <h2 style={{marginBottom: '5rem',fontWeight: 'normal'}}>
                          <span style={{display: 'block'}}>You have reached your maximum allowed job alerts. </span>
                          <small style={{display: 'inline-block',color: 'grey',width: '80%',marginTop: '1rem'}}>Jobbri allows a maximum of three different job alerts per account, to add another one, delete one or more of your set job alerts.</small>
                        </h2>
                   
                    }
                    <section style={alertsCount < 3 ? {marginBottom: 0} : {marginBottom: '5rem'}}>
                      { alertsCount < 3 
                      ? <Form auth={auth} disabledInput={disabledInput} userProfile={userProfile} closeModal={closeModal.bind(this)} actions={actions} />
                      : <button className="alert__modalfootr--cancel" onClick={()=>viewPage('/alerts')}>Let Me Manage My Alerts</button>
                      }
                    </section>

                  </div>
                  
              </article>

              <div className="alert__modalfootr bx-raised-1" style={modFoot}>

                <section className="alert__modalfootr--container" style={modCont}>

                 {alertsCount < 3
                  ?<div className="alert__modalfootr--policy">
                    <p>By creating a job alert, you agree to Jobbri’s terms of use and privacy policy.</p>
                  </div>
                  : null
                  }
                  <button className="alert__modalfootr--cancel" onClick={closeModal.bind(this)}>Cancel</button>

                </section>

                
                

              </div>
            </section>

        
 
         
          
        </Modal>
      </div>
    )
  
  }
}
 
export default AlertModal