
import * as types from './types'
import * as props from './props'




export default (state = props, action)=>{

    switch (action.type) {


      

      
        
        
        case types.SET_CURRENT_SENDER:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                currentSender: action.payload
                
            } 
                
        case types.UNSET_CURRENT_SENDER:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                currentSender: '',
                message: ''
                
            } 
            
        
        case types.UNSET_NOTIFICATION_DISPLAY:

            return  {

                ...state,
                isFetching: false,
                isProgressBar: false,
                isActionSuccessful: action.payload
                
            } 
        
    
        
    
    
        default:
        return state
    }


}