
export default {

    box1: {

        container: {className:"careers__traits-offset-1"},
        title:{

             
            content: {

                number: {
                    text: "1.",
                    style: {className:""}
                },
                text: {
                    text: 'Smile',
                    style: {className:""}
                }
            }
             
         },
         body:{
             
            content: {

                number: {
                    text: "Most definitely, yes, our pictures are completely free to use for any purpose All you have to do to use kokapix images is simply just hit the Download button, and you are good to go",
                    style: {className:""}
                },
                text: {
                    text: 'Most definitely, yes, our pictures are completely free to use for any purpose All you have to do to use kokapix images is simply just hit the Download button, and you are good to go',
                    style: {className:""}
                }
            }

         }



    },
    box2: {

        container: {className:"careers__traits-offset-2"},
        title:{

             
            content: {

                number: {
                    text: "2.",
                    style: {className:""}
                },
                text: {
                    text: 'Passion',
                    style: {className:""}
                }
            }
             
         },
         body:{
             
            content: {

                number: {
                    text: "Most definitely, yes, our pictures are completely free to use for any purpose All you have to do to use kokapix images is simply just hit the Download button, and you are good to go",
                    style: {className:""}
                },
                text: {
                    text: 'Most definitely, yes, our pictures are completely free to use for any purpose All you have to do to use kokapix images is simply just hit the Download button, and you are good to go',
                    style: {className:""}
                }
            }

         }



    },
    box3: {

        container: {className:"careers__traits-offset-3"},
        title:{

             
            content: {

                number: {
                    text: "3.",
                    style: {className:""}
                },
                text: {
                    text: 'Creativity',
                    style: {className:""}
                }
            }
             
         },
         body:{
             
            content: {

                number: {
                    text: "Most definitely, yes, our pictures are completely free to use for any purpose All you have to do to use kokapix images is simply just hit the Download button, and you are good to go",
                    style: {className:""}
                },
                text: {
                    text: 'Most definitely, yes, our pictures are completely free to use for any purpose All you have to do to use kokapix images is simply just hit the Download button, and you are good to go',
                    style: {className:""}
                }
            }

         }



    },
    box4: {

        container: {className:"careers__traits-offset-4"},
        title:{

             
            content: {

                number: {
                    text: "4.",
                    style: {className:""}
                },
                text: {
                    text: 'Innovative',
                    style: {className:""}
                }
            }
             
         },
         body:{
             
            content: {

                number: {
                    text: "Most definitely, yes, our pictures are completely free to use for any purpose All you have to do to use kokapix images is simply just hit the Download button, and you are good to go",
                    style: {className:""}
                },
                text: {
                    text: 'Most definitely, yes, our pictures are completely free to use for any purpose All you have to do to use kokapix images is simply just hit the Download button, and you are good to go',
                    style: {className:""}
                }
            }

         }



    }
}