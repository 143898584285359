import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import FolderIcon from '@material-ui/icons/Folder';
import { toast } from 'react-toastify';


const styles = {
    root: {
     
     
      fontSize: 20,
      color: 'seagreen'
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };


 class DragAndDrop extends Component{


   handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
  };
   handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
  };
   handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  };
   handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()

    // let file = e..dataTransfer.files[0] 

    if(this.maxSelectFile(e) && this.checkMimeType(e) && this.checkFileSize(e)){ 
      // if return true allow to setState
        this.props.updateUploadState(e.dataTransfer.files[0])
        //e.dataTransfer.clearData()
      }


    // console.log('A FILE HAS BEEN DROPPED')
    // console.log(e)
    
  };


  maxSelectFile=(event)=>{

    let files = event.dataTransfer.files // create file object
        if (files.length > 1) { 
           const msg = 'Only 1 file can be uploaded at a time'
           event.dataTransfer.value = null // discard selected file 
           this.notify({message: msg,type: 'error',className: 'notify-error'}) 
           console.log(msg)
          return false;
 
      }
    return true;
 
}

checkMimeType=(event)=>{
    //getting file object
    let files = event.dataTransfer.files 
    //define message container
    let err = ''
    // list allow mime type
   const types = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']

   console.log('CHECK MIME TYPE FILE')
   console.log(files)
    // loop access array
    for(var x = 0; x < files.length; x++) {
     // compare file type find doesn't matach
         if (types.every(type => files[x].type !== type)) {
         // create error message and assign to container   
         err += files[x].type+' is not a supported format\n';
       }
     };
  
   if (err !== '') { // if message not same old that mean has error 
        event.dataTransfer.value = null // discard selected file
        this.notify({message: err,type: 'error',className: 'notify-error'}) 
        console.log(err)
         return false; 
    }
   return true;
  
}

checkFileSize=(event)=>{

    let files = event.dataTransfer.files
    let size = 2048000
    let err = ""; 
    for(var x = 0; x<files.length; x++) {

       console.log('THE FILE SIZE')
       console.log(files[x].size)
        if (files[x].size > size) {
        
        err += `${files[x].type} ${files[x].size} ${size} is too large, please pick a smaller file\n`;
    }
 };
 if (err !== '') {
    event.dataTransfer.value = null 
    this.notify({message: err,type: 'error',className: 'notify-error'}) 

    console.log(err)
    return false
}

return true;

}

notify = (config) => {

   
const {message} = config || ''
const type = config.type || 'info'
toast[type](message,
    {position: toast.POSITION.BOTTOM_LEFT,...config});

}


  render(){

    return (
        <div className={'drag-drop-zone'}
        onDrop={e => this.handleDrop(e)}
        onDragOver={e => this.handleDragOver(e)}
        onDragEnter={e => this.handleDragEnter(e)}
        onDragLeave={e => this.handleDragLeave(e)}
        >
            <span>
                <Icon>
                <FolderIcon style={styles.root} />
                </Icon>
            </span>
            <h2>Drag and drop your cv here</h2>
            <h3 className="upload-cv__folder-alt">Or</h3>
            {this.props.children}
        </div>
    )
  }
}



export default withStyles(styles)(DragAndDrop)