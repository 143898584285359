
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';



class Passwordform extends  Component{


    constructor(props){

        super(props)

        
        this.state = {
            form : {
                    
                    
                password:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                            isRequiredFormat:{
                                minLetters: 8,
                                maxLetters: 15
                                //specialCharacters: true
                            },
                            isPlaceholder: true
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "password",
                        type: 'password',
                        placeholder: 'Ntsako Surprise',
                        disabled: false
                        
                    }
                    
                },
               
                confirm:{
                    
                    control: 'input',
                    value:'',
                    validation: {
                        isRequiredFormat:{
                            isRequired: true,
                        
                            
                            
                        },
                        isPlaceholder: true
                
                    },
                    isValid: false,
                    isTouched: false,
                    validationMessage: '',
                    validationStyles: {
                        position: 'right'
                    },
                    config: {
                        
                        name: "confirm",
                        type: 'password',
                        placeholder: '1220551df',
                        disabled: false
                        
                    }
                    
                },
                

              
            
            },
            
        };
    }

    
    updator = (upControl)=>{
        
            console.log('THE UPDATE CONTROL')
            console.log(upControl)
            let newForm ={...this.state.form} 
            let updateControl = {...newForm[upControl.id]}
            updateControl.value = upControl.e.target.value 

        
            
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1]

                    if(!updateControl.isValid){

                        for(let k in newForm){ 


                            if(newForm[k].config.disabled === false && k.trim() !== upControl.id.trim()){
                                newForm[k].config.disabled = true
                            }
                        }
                    }else{

                        for(let k in newForm){ 

                            if(newForm[k].config.disabled === true){
                                newForm[k].config.disabled = false
                            }
                        }

                    }
                    
                }

            }
        
            updateControl.touched = upControl.blur
            newForm[upControl.id] = updateControl
            
            this.setState({
                form: newForm
                
            })


    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
    
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|,.<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) >= 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as username`]
            if(isRequiredFormat.minLetters && (value.length < isRequiredFormat.minLetters) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(isRequiredFormat.minPieces && (pieces.length < isRequiredFormat.minPieces) ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(isRequiredFormat.maxPieces && (pieces.length > isRequiredFormat.maxPieces) ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`] 
            if(isRequiredFormat.isNumber && isNaN(value) ) return [false,`${validateControl.config.name} should be a number with no spaces`] 
            if(isRequiredFormat.isLen && (value.length !== isRequiredFormat.isLen) ) return [false,`${validateControl.config.name} should be a total of ${isRequiredFormat.isLen} numbers`] 
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} Should be a valid email`]
        
                
        
        }
        
        return validData
    
    }
   

    submitForm = (e)=>{
                
        e.preventDefault() 

        
        
        const {actions,email} = this.props
        const {sendUserPassword} = actions 
        let submittData = {} 
        let formIsValid = true 
        let {form} = this.state 
        let {notify} = this
        
        
        
        for(let k in form){
            
            console.log('THE SUBMIT CONTROL')
            console.log(form[k])
            if(form[k].validation && form[k].validation.isRequiredFormat){

                console.log('THE CONTROL REQUIRES VALIDATION')

                if(form[k].isValid === false){

                    console.log('CONTROL:',k, ' is not valid')
                    formIsValid = false 
                    
                    console.log(notify)
                    notify({message:'Filled form data must be valid',type: 'warning',className: 'notify-warn'})
                    break


                }else{
 
                   

                    submittData[k] = form[k].value
                   
                
                }

            }else{

                if(form[k].value.trim() !== ''){

                    submittData[k] = form[k].value

                }
               

            }
          
        
        } 

        if(!formIsValid) return


    
        console.log(submittData)
        submittData.email = email
        sendUserPassword(submittData)  
        
        
        

    }

    notify = (config) => {

        
            console.log('THE TOAST CONFIG')
            console.log(config)
            const {message} = config || ''
            const type = config.type || 'info'
            toast[type](message,
                {position: toast.POSITION.BOTTOM_LEFT,...config});
        
    }
        
   
    
   

    render(){

        const {props} = this 
        const {resetPassword} = props 
        const {isFetching} = resetPassword

        return(

                    <section className="resetpassword__launchcta">

                        <form className="form resetpassword__launchcta--cont">
                            
                            <div className="resetpassword__launchcta--control bx-raised">

                                

                                <label className="resetpassword__launchcta-label">New Password</label>
                                <FormControl 
                            
                                    styles={{child:'resetpassword__launchcta-input',error: ''}}
                                    id = 'password'
                                    controlData={this.state.form.password}
                                    change={(control)=>this.updator(control)}
                                  
                                    
                                
                                />


                            </div> 

        

                            <div className="resetpassword__launchcta--control bx-raised">

                                

                                <label className="resetpassword__launchcta-label">Confirm Password</label>
                                <FormControl 
                            
                                    styles={{child:'resetpassword__launchcta-input',error: ''}}
                                    id = 'confirm'
                                    controlData={this.state.form.confirm}
                                    change={(control)=>this.updator(control)}
                                    
                                
                                />


                            </div> 

                            <div>

                             {

                                isFetching === true
                                ? 
                                    <section className="huloader huloader-co">
                                        <div className="huloader__loading huloader__loading-co">
                                            <div></div>
                                            <div></div>
                                        </div>  
                                    </section>

                                :
                                <button className="resetpassword__launchcta-submit" onClick={this.submitForm.bind(this)}>
                                    <span className="resetpassword__launchcta-submit-rect"></span>
                                    <span className="resetpassword__launchcta-submit-rect-2">
                                        <small>Update new password</small></span>
                                </button>
                                }
                            </div>

                            {/* <div className="service__modal--form-control">

                                <input type="submit" value="COUNT ME IN" onClick={(e)=>this.submitForm(e)} className="resetpassword__launchcta-submit" />
                                
                            </div>  */}

                            



                        
                            
                        </form>

                       
                        <div>

                            {
                                
                                // isProgressBar === true && isFetching === true
                                //     ?  <ProgressBarLinear />
                                //     : null
                            }
        
                        
                        </div>

                    </section>

                    
               

             
             
           
        )
    }




}

export default Passwordform