import React from 'react';
import Form from './form'



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class JobTitle extends React.Component{

  constructor(props){
    super();
    
  }


  updateOptions(i,e){

        console.log(e)
        e.preventDefault() 
        return

        const {options} = this.state  
        const {props} = this 
        const {handleChange} = props 

        let updateOpts = [...options]
        updateOpts.forEach((opts)=> opts.isSelected === true ? opts.isSelected = false : '')
        let newOptionValue = { value: options[i].value, isSelected: !options[i].isSelected}
        updateOpts[i] = newOptionValue 
        
        this.setState({...this.state,options: [...updateOpts]},()=>{

            handleChange({target: {name: e.name, value: e.value}})
        })
  }



//   submitCapturedData(e){

//     e.preventDefault() 

//     const {state,props} = this 
//     const {options} = state 
//     const {nextStep} = props 
//     let isSelected = false 
//     let theSelected = null

//     for(let i=0; i <= options.length; i++){

//         if(options[i].isSelected){

//             isSelected = true 
//             theSelected = options[i].value

//         }
//     }

//     if(!isSelected) return Notifica() 

//     nextStep({contractType: theSelected})

//     // console.log('THE CAPTURED DATA')
//     // console.log(e) 









//   }



  render(){

    const {props} = this 
    const {nextStep,prevStep,handleChange,values} = props
   
   
    return(


      <div className='questionnaire__i'>
        
        <h3  className="questionnaire__question">What job are you looking for?</h3> 

        <section className="questionnaire__options">

         <Form handleChange={handleChange} values = {values} />
        </section>

        <button className="questionnaire__next" onClick={prevStep}>Prev</button>
        <button className="questionnaire__next" onClick={nextStep}>Let's go</button>
       
      </div>


    );
  }
}

export default JobTitle