import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import { ThemeContext } from '../../../../context';


const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class TouchInfo extends Component{
    
    constructor(props){

        super(props)

    }


    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        
       
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        

        return(

           <section className="contact__touch">

                      <h2 className="contact__touch--header-title">
                          
                          <span className="contact__touch--header-title-text" style={textColorStyles}>Stay in touch</span>
                         
                          <span className="contact__touch--header-title-bar-1"></span>
                          <span className="contact__touch--header-title-bar-2"></span>
                      </h2>

                    <div className="bx-raised-1 contact__touch--content">

                        <section className="contact__touch--content-1">
                            <h2>Get in Touch</h2>
                            <div>

                              <span>support@jobbri.co.za</span>
                              <span>sales@jobbri.co.za</span>

                            </div>
                           
                        </section>

                        <section className="contact__touch--content-2">
                            <h2>Visit us</h2>
                            <div>

                              <span>32/1621 Rovers</span>
                              <span>Street Soshanguve </span>
                              <span>Block xx</span>

                            </div>
                           
                        </section>

                        <strong className="clearfix" />
                    </div>

                    <div className="contact__touch--circle"></div>


               
             
           </section>
        )
    }

}


export default withStyles(styles)(TouchInfo)