
import React, {Component} from 'react' 
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as authActions from '../Auth/actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import qs from 'query-string'
import Tagline from './tagline/tagline' 
import Hero from './hero/hero' 
import Postdate from './hero/reasons'




class Unsubscribe extends Component{


    constructor(props){

        super()
    }

    componentDidMount(){

        const {props} = this 
        const {actions,router} = props 
        const {push} = actions
        const {search} = router 

     

        let params =  qs.parse(search) 

        console.log('THE USER PARAMS')
        console.log(params)
        console.log(search)

        if(!(params.email && params.name)){

            push('/404')
           
        }


    }




    render(){

        const {props} = this 
        const {actions,router,unsubscribe} = props 
        const {search} = router 

        let params =  qs.parse(search) 
        const {email='',name=''} = params

        return(

            <article className="unsubscribe">

                <Helmet>
                    <title>Jobbri - Unsubscribe</title>
                </Helmet>
                  
                <Tagline name={name} />
                <Hero email={email} name={name} unsubscribe={unsubscribe} actions={actions} /> 
               
    
            </article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        unsubscribe: {
            ...state.unsubscribe
        },
        router: {...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...authActions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(Unsubscribe)