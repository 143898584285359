
import React, {Component} from 'react'
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import { toast } from 'react-toastify';
// import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import * as blogrActions from '../Blogr/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'

import BlogHeader from './components/blogheader/blogheader'
import PrimaryPosts from './components/primaryposts/primaryposts'
import SecondaryPosts from './components/secondaryposts/secondaryposts' 
import ErrorHandler from '../ClientErrorHandler/component'





class BlogHome extends Component{


    constructor(props){

        super()
        this.state = {

           
            width: 0,
            height: 0,
            
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


       
  

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        const {props} = this 
        const {blogr,actions} = props 
        const {fetchBlogPosts} = actions
        const {posts} = blogr 
        if(posts.length === 0) fetchBlogPosts()
        

       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
        const {props} = this 
        const {blogr,actions} = props 
        const {message=''}  = blogr 
        const {removeMessage} = actions
    

        if(message.trim()) removeMessage()
      
     }

     
    //  componentDidUpdate(){


    //     const {props} = this 
    //     const {blogr,actions} = props 
    //     const {message=''}  = blogr 
    //     const {removeMessage} = actions
    

    //     if(message.trim()) removeMessage()
       
        


     
    // }


    // componentDidMount(){

    //     const {props} = this 
    //     const {blogr,actions} = props 
    //     const {fetchBlogPosts} = actions
    //     const {posts} = blogr 
        
    //  if(posts.length === 0) fetchBlogPosts()

     
    // }

    componentDidUpdate(){

        

        const {props} = this 
        const {blogr,actions} = props 
        const {removeMessage,removeNotification} = actions
        const {message='',isActionSuccessful} = blogr 

        if(message.trim()) { 

            this.notify({message: message,type: 'error',className: 'notify-error'});
            removeMessage() 
        }

        if(isActionSuccessful) removeNotification()
       

        
        

    }


    openBlogPage(path,e){

        e.preventDefault()

        const {props} = this 
        const {actions} = props 
        const {topic,url} = path
        const {push} = actions
        return push(`${url}${topic}`)
        // if(!this.findPostLocally(slug)) return fetchBlogPosts(slug)
    
        
    }

    retryOnError = ()=>{

        const {props} = this 
        const {actions} = props 
        const {fetchBlogPosts} = actions
        fetchBlogPosts()


    }

    getErrorMeta = (color='warn')=>{

        let message,header,headerText  = ''
        
        if(color === 'warn'){

            message = 'We have unfortunately encountered an issue getting blog posts' 
            header = 'OH SNAP!'
            headerText = 'Blog posts could not load!'
           
        }else{

            message= 'We have unfortunately encountered an error processing a request'
            header = 'OOPS!'
            headerText = 'Something went wrong!'


        }
       
       

        return {

            header:header,
            headerText:headerText,
            errorText:message,
            buttonText: 'Please Try Again',
            actionColor: color,
            postErrorAction: this.retryOnError
        } 
    }


    notify = (config) => {

        
       
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

       
        
    }



    render(){

        const {props,state} = this 
        const {width} = state
        const {blogr} = props 
        const {isFetching=false,message='',posts} = blogr 
       

        if(isFetching === true) return  <article className="blog" style={{height: '100vh',width: '70%',paddingLeft: '17%',position: 'relative'}}>
            <Helmet>
                <title>Jobbri - Blog | Home</title>
            </Helmet>
        <ProgressBarCircular style={{width: '100%'}} /></article>;
         if(message.trim()) return <ErrorHandler errorMeta={this.getErrorMeta('error')}  /> 
         if(posts.length === 0) return <ErrorHandler errorMeta={this.getErrorMeta('warn')}  /> 

        return(

            <article className="blog">
                <Helmet>
                    <title>Jobbri - Blog | Home</title>
                </Helmet>
               <BlogHeader />
               <PrimaryPosts blogr={blogr} width={width} />
               <SecondaryPosts blogr={blogr} openBlogPage={this.openBlogPage.bind(this)} />
               <strong className="clearfix" />
               
            </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        
        blogr:{ ...state.blogr}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...navigatorActions,...blogrActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(BlogHome)