import { collectAnswers } from "./answers"
import { loadUserFailureRegistration } from "./actions"

export const transportData = (self,transporter)=>{

    console.log('transportData Loading;;;',transporter)
    switch(transporter){
        case 'registrationTransporter' : return registrationTransporter(self)
        case 'cvTransporter' : return cvTransporter(self)
        case 'updatesTransporter' : return updatesTransporter(self)
        case 'supportTransporter' : return supportTransporter(self)
        default : return false
    }

}


const registrationTransporter = (dataObject)=>{

    console.log('registrationTrans')

    const {props} = dataObject
    const {actions} = props
    // const {answers} = humanoid
    // const {register} = answers
    const {registerUser} = actions 
    const fetchedAnswers = collectAnswers(dataObject,'registerUser')
    console.log('fetchedAnswers',fetchedAnswers)
    setTimeout(()=>{
        loadUserFailureRegistration(dataObject,'local')

    },120000)
    

    //if(!fetchedAnswers)
    // registerUser(fetchedAnswers)

}

const cvTransporter = (dataObject)=>{

}

const updatesTransporter = (dataObject)=>{

}

const supportTransporter = (dataObject)=>{

}

const getDataObjectSlice = ()=>{
    const {props} = self
    const {actions} = props
    const {setProcessor,setHumanoidActionType} = actions
}