import { yellow } from '@material-ui/core/colors'
import { SettingsOutlined } from '@material-ui/icons'
import React, { Component } from 'react' 
import { ThemeContext } from '../../../context'







class ActionConfirm extends Component{
    
    constructor(){

        super()
        this.state ={
            showActionMessage: false,
            option: ''
        }

    

        
    }
    // componentDidMount(){

    //     const {props} = this 
    //     const {setTitle,confirmType} = props
        
    //     confirmType === 'delete' ?  setTitle('Confirm delete') : setTitle('Choose replace option')
    // }

   

    // componentWillUnmount(){

    //     const {actions,userProfile} = this.props
    //     const {removeMessage} = actions 
    //     const {message=''} = userProfile 

    //     if(message){

           
    //         removeMessage()
    //     }
    // }

    showUpload = (option)=>{

        const {props} = this 
        const {openUpload} = props

        this.setState({...this.state,showActionMessage: true,option: option})
        setTimeout(()=>{

            console.log('THE TIMEOUT IS RUNNING')
            console.log(openUpload)
            openUpload(option)

        },500)

       
    }

    deleteThingy = ()=>{

        const {props} = this 
        // const {deleteCV} = props

        // this.setState({...this.state,showActionMessage: true,option: option})
        // setTimeout(()=>{
        //     deleteCV()

        // },500)

       
    }

    
    componentWillUnmount(){
        //this.setState({...this.state,showActionMessage: false,option: ''})
    }

   
    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


     const {props,state} = this 
     const {userProfile} = props 
     const {message=''} = userProfile
    
     const {option,showActionMessage} = state
     let checkStyles = {borderRadius: '5px',marginRight: '5px',textAlign: 'center',display: 'inline-block',border: 'solid maroon 1px',height: '15px',width: '15px'}
     let confirmTextStyles = {display: 'inline-block'}
     let repBg = option === 'only' ? {backgroundColor: '#eed2d2'} : {backgroundColor: 'white'}
    //  let allBg = option === 'all' ? {backgroundColor: '#eed2d2'} : {backgroundColor: 'white'}
     let listStyles = {padding: '5px',width: '300px',marginBottom: '10px',cursor: 'pointer'}
     let iconStyles = {color: 'seagreen',position:'relative',bottom:'2px',display: 'inline-block'}
    //  let textStyles = {marginBottom: 0}
     let deleteErrorStyles = {color: 'white',display: 'inline-block',backgroundColor: 'seagreen',padding: '7px',cursor: 'pointer',textAlign: 'center',fontSize: '13px',borderRadius: '2px'}
     //#eed2d2

       
       

        return(

           
    
           
                 
                <ul className="upload-cv-success-list">

              
                        
                        <li className="upload-cv-success-list-i bx-raised-1" style={{...listStyles,...repBg}} onClick={()=>{this.deleteThingy()}}>

                                 <p style={{marginTop: '5px',marginBottom: '30px'}}>You are about to delete your set Job alert, are you sure you want to continue?</p>
                                <span style={checkStyles} aria-hidden="true">
                                     <i className="fa fa-lx fa-check" style={iconStyles}></i> 
                                 
                                </span>
                         
                            <span style={confirmTextStyles}  >Yes, delete this job alert</span>
                            
                        </li>
                    
                   
                    {message 
                    
                    ?  <span style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                        <small style={{display: 'block',color: 'red',marginBottom: '15px',fontSize: '14px'}}>Alert has failed to delete</small>
                        <span style={deleteErrorStyles} onClick={()=>{this.deleteThingy()}}>Let me try again</span>
                     </span> 
                    
                    :showActionMessage 
                        ?  <span style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                        
                            The system is deleting your job alert... 
                            </span> 
                        : null}
                        
                </ul>
               




          

          
        )
    }


}


export default ActionConfirm


