import * as types from './types'
import { CALL_API } from '../../middlwares/api'



export const fetchUserProfile = (data)=> {

  console.log('FETCHUSERPROFILESETTINGS')
  console.log(data)

  return {

      [CALL_API]: {
        endpoint: `/profile`,
        request: 'URL',
        method: 'POST',
        payload: {action: 'getProfile',payload: {...data}},
        authencitcated: false,
        types: [types.FETCH_USER_PROFILE_REQUEST, types.FETCH_USER_PROFILE_SUCCESS, types.FETCH_USER_PROFILE_FAILURE]
      }
    }

}


export const sendUserAvatarUpdate = (data,launcher='')=> {

  return {

      [CALL_API]: {
        endpoint: `/profile`,
        request: 'URL',
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        payload: data,
        authencitcated: false,
        isProgress: true,
        launcher: launcher,
        types: [types.SEND_USER_AVATAR_UPDATE_REQUEST, types.SEND_USER_AVATAR_UPDATE_SUCCESS, types.SEND_USER_AVATAR_UPDATE_FAILURE]
      }
    }

}

  
  export const sendUserProfileUpdate = (data,id,launcher)=> {

    return {

        [CALL_API]: {
          endpoint: `/profile`,
          request: 'URL',
          method: 'POST',
          payload: {action: 'updateUser',payload: {update: {...data},ID:id}},
          authencitcated: false,
          isProgress: true,
          launcher: launcher || '',
          types: [types.SEND_USER_PROFILE_UPDATE_REQUEST, types.SEND_USER_PROFILE_UPDATE_SUCCESS, types.SEND_USER_PROFILE_UPDATE_FAILURE]
        }
      }

  } 


  export const sendUserAlertUpdateData = (search)=> {

    return {

        [CALL_API]: {
          endpoint: `/inalerts`,
          request: 'URL',
          method: 'POST',
          payload: {action:'updateAlert',payload:{ID: search.ID,id: search.id,update: search.update}},
          authencitcated: false,
          isProgress: true,
          types: [types.SEND_USER_ALERT_UPDATE_REQUEST, types.SEND_USER_ALERT_UPDATE_SUCCESS, types.SEND_USER_ALERT_UPDATE_FAILURE]
        
      }

  }
}


export const deleteUserAlert = (search)=> {

  return {

      [CALL_API]: {
        endpoint: `/inalerts`,
        request: 'URL',
        method: 'POST',
        payload: {action:'deleteAlerts',payload:{ID: search.ID,id: search.id}},
        authencitcated: false,
        isProgress: true,
        types: [types.DELETE_USER_ALERT_REQUEST, types.DELETE_USER_ALERT_SUCCESS, types.DELETE_USER_ALERT_FAILURE]
      
    }

}
}

export const setAlertUpdateData = (update)=> {

  return {

        type: types.SET_ALERT_UPDATE_DATA,
        payload: update

      
    }

}



// RESUME ACTIONS 




export const fetchResume = (id)=> {

  return {

      [CALL_API]: {
        endpoint: `/resume`,
        request: 'URL',
        method: 'POST',
        payload: {action: 'getResume',payload: {ID:id,resume: true}},
        authencitcated: false,
        isProgress: true,
        launcher:  '',
        types: [types.FETCH_RESUME_REQUEST, types.FETCH_RESUME_SUCCESS, types.FETCH_RESUME_FAILURE]
      }
    }

}


export const addResumeItem = (data,id,launcher)=> {

    return {

        [CALL_API]: {
          endpoint: `/resume`,
          request: 'URL',
          method: 'POST',
          payload: {action: 'saveResume',payload: {resume: {...data},ID:id}},
          authencitcated: false,
          isProgress: true,
          launcher: launcher || '',
          types: [types.ADD_RESUME_ITEM_REQUEST, types.ADD_RESUME_ITEM_SUCCESS, types.ADD_RESUME_ITEM_FAILURE]
        }
      }

}


export const updateResumeItem = (data,id,launcher='')=> {

    return {

        [CALL_API]: {
          endpoint: `/resume`,
          request: 'URL',
          method: 'POST',
          payload: {action: 'updateResume',payload: {resume: {...data},ID:id}},
          authencitcated: false,
          isProgress: true,
          launcher: launcher || '',
          types: [types.UPDATE_RESUME_ITEM_REQUEST, types.UPDATE_RESUME_ITEM_SUCCESS, types.UPDATE_RESUME_ITEM_FAILURE]
        }

      }
      

}


export const deleteResumeItem = (data,id,launcher='')=> {

    return {

      [CALL_API]: {
        endpoint: `/resume`,
        request: 'URL',
        method: 'POST',
        payload: {action: 'deleteResume',payload: {resume: {...data},ID:id}},
        authencitcated: false,
        isProgress: true,
        launcher: launcher || '',
        types: [types.DELETE_RESUME_ITEM_REQUEST, types.DELETE_RESUME_ITEM_SUCCESS, types.DELETE_RESUME_ITEM_FAILURE]
      }
    
      }

}


export const deleteResume = (data,id,task,launcher)=> {

    return {

        [CALL_API]: {
          endpoint: `/resume`,
          request: 'URL',
          method: 'POST',
          payload: {action: task,payload: {update: {...data},ID:id}},
          authencitcated: false,
          isProgress: true,
          launcher: launcher || '',
          types: [types.DELETE_RESUME_REQUEST, types.DELETE_RESUME_SUCCESS, types.DELETE_RESUME_FAILURE]
        }
  
      }

}

export const deleteUploadResume = (data,id,launcher='')=> {

    return {

        [CALL_API]: {
          endpoint: `/resume`,
          request: 'URL',
          method: 'POST',
          payload: {action: 'deleteUploadResume',payload: {...data,ID:id}},
          authencitcated: false,
          // isProgress: true,
          launcher: launcher || '',
          types: [types.DELETE_UPLOAD_RESUME_REQUEST, types.DELETE_UPLOAD_RESUME_SUCCESS, types.DELETE_UPLOAD_RESUME_FAILURE]
        }
  
      }

}

export const saveUploadResume = (data,launcher='')=> {

  return {

    [CALL_API]: {
      endpoint: `/resume`,
      request: 'URL',
      method: 'POST',
      headers: {'Content-Type': 'multipart/form-data'},
      payload: data,
      authencitcated: false,
      isProgress: true,
      launcher: launcher || '',
      types: [types.SAVE_UPLOAD_RESUME_REQUEST, types.SAVE_UPLOAD_RESUME_SUCCESS, types.SAVE_UPLOAD_RESUME_FAILURE]
    }
  }
}


  export const replaceResume = (data,launcher='')=> {

    return {

        [CALL_API]: {
          endpoint: `/resume`,
          request: 'URL',
          method: 'POST',
          headers: {'Content-Type': 'multipart/form-data'},
          payload: data,
          authencitcated: false,
          isProgress: true,
          launcher: launcher || '',
          types: [types.REPLACE_RESUME_REQUEST, types.REPLACE_RESUME_SUCCESS, types.REPLACE_RESUME_FAILURE]
        }
  
      }

  }
  // export const sendUserAvatarUpdate = (data,launcher='')=> {

  //   return {
  
  //       [CALL_API]: {
  //         endpoint: `/profile`,
  //         request: 'URL',
  //         method: 'POST',
  //         headers: {'Content-Type': 'multipart/form-data'},
  //         payload: data,
  //         authencitcated: false,
  //         isProgress: true,
  //         launcher: launcher,
  //         types: [types.SEND_RESUME_AVATAR_UPDATE_REQUEST, types.SEND_RESUME_AVATAR_UPDATE_SUCCESS, types.SEND_RESUME_AVATAR_UPDATE_FAILURE]
  //       }
  //     }
  
  // }

  export const downloadUploadResume = (data,launcher='')=> {

    return {
  
      [CALL_API]: {
        endpoint: `/resume`,
        request: 'URL',
        method: 'POST',
        payload: {action: 'downloadResume',payload: {...data}},
        authencitcated: false,
        isProgress: true,
        launcher: launcher || '',
        isBlob: true,
        types: [types.DOWNLOAD_UPLOAD_RESUME_REQUEST, types.DOWNLOAD_UPLOAD_RESUME_SUCCESS, types.DOWNLOAD_UPLOAD_RESUME_FAILURE]
      }
    
      }
  
  }


  export const downloadShareResume = (data,launcher='',postLaunch=null)=> {

  return {

    [CALL_API]: {
      endpoint: `/resume`,
      request: 'URL',
      method: 'POST',
      payload: {action: 'getGeneratedResume',payload: {resume: {...data}}},
      authencitcated: false,
      isProgress: true,
      launcher: launcher,
      postLaunch: postLaunch,
      isBlob: true,
      types: [types.DOWNLOAD_SHARE_RESUME_REQUEST, types.DOWNLOAD_SHARE_RESUME_SUCCESS, types.DOWNLOAD_SHARE_RESUME_FAILURE]
    }
  
    }

}




export const subscribeAndAddUserAlert = (save={})=> {

  return {

      [CALL_API]: {
        endpoint: `/inalerts`,
        request: 'URL',
        method: 'POST',
        payload: {action:'saveSubscriberAlert',payload:{...save}},
        authencitcated: false,
        types: [types.SUBSCRIBE_AND_ADD_USER_ALERT_REQUEST, types.SUBSCRIBE_AND_ADD_USER_ALERT_SUCCESS, types.SUBSCRIBE_AND_ADD_USER_ALERT_FAILURE]
      }
    }

}

export const saveJoUserJobAlert = (save={})=> {

  return {

      [CALL_API]: {
        endpoint: `/inalerts`,
        request: 'URL',
        method: 'POST',
        payload: {action:'saveAlerts',payload:{...save}},
        authencitcated: false,
        types: [types.SAVE_JO_USER_JOB_ALERT_REQUEST, types.SAVE_JO_USER_JOB_ALERT_SUCCESS, types.SAVE_JO_USER_JOB_ALERT_FAILURE]
      }
    }

}


export const deleteSavedJob = (search)=> {

  return {

      [CALL_API]: {
        endpoint: `/bookmark`,
        request: 'URL',
        method: 'POST',
        payload: {action:'deleteBookmark',payload:search},
        authencitcated: false,
        types: [types.DELETE_SAVED_JOB_REQUEST, types.DELETE_SAVED_JOB_SUCCESS, types.DELETE_SAVED_JOB_FAILURE]
      }
    }

}



export const deleteUserSearchHistory = (search)=>{

  return {

    [CALL_API]: {
      endpoint: `/history`,
      request: 'URL',
      method: 'POST',
      payload: {action:'deleteHistory',payload:search},
      authencitcated: false,
      types: [types.DELETE_USER_SEARCH_HISTORY_REQUEST, types.DELETE_USER_SEARCH_HISTORY_SUCCESS, types.DELETE_USER_SEARCH_HISTORY_FAILURE]
    }
  }


}




export const sendUserJobBookmark = (data,id)=> {

  return {

    [CALL_API]: {
      endpoint: `/bookmark`,
      request: 'URL',
      method: 'POST',
      payload: {action:'bookmarkItem',payload:{bookmark: {...data},ID:id}},
      authencitcated: false,
      types: [types.SEND_USER_JOB_BOOKMARK_REQUEST, types.SEND_USER_JOB_BOOKMARK_SUCCESS, types.SEND_USER_JOB_BOOKMARK_FAILURE]
    }
  }

}



export const sendUserSearchHistory = (data,id)=>{

  return {

    [CALL_API]: {
      endpoint: `/history`,
      request: 'URL',
      method: 'POST',
      payload: {action:'saveHistory',payload:{history: {...data},ID:id}},
      authencitcated: false,
      types: [types.SEND_USER_SEARCH_HISTORY_REQUEST, types.SEND_USER_SEARCH_HISTORY_SUCCESS, types.SEND_USER_SEARCH_HISTORY_FAILURE]
    }
  }


}



export const sendUserAlertSubscriber = (data,launcher='footer')=> {

  return {

      [CALL_API]: {
        endpoint: `/subscriber`,
        method: 'POST',
        request: 'URL',
        payload: {action: 'saveSubscriber',payload: {subscriber: {...data}}},
        authencitcated: false,
        launcher: launcher,
        types: [types.SEND_USER_ALERT_SUBSCRIPTION_REQUEST, types.SEND_USER_ALERT_SUBSCRIPTION_SUCCESS, types.SEND_USER_ALERT_SUBSCRIPTION_FAILURE]
      }
    }

}



  


export const removeNotification = ()=>{

    return {
  
      type: types.UNSET_NOTIFICATION_DISPLAY,
      payload: false
    }
  }

  export const removeMessage = ()=>{

    return {
  
      type: types.UNSET_MESSAGE_NOTIFICATION_DISPLAY,
      payload: false
    }
  }
  


  export const receiveUserProfile = (user)=> {

    return {
      type: types.RECEIVE_USER_PROFILE,
      isFetching: false,
      isAuth: true,
      user: {...user}
    }

  }

  export const unsetIsProfile = ()=>{

    return {
  
      type: types.UNSET_IS_PROFILE,
      payload: false
    }
  }

  export const unsetUserProfile = ()=>{

    return {
  
      type: types.UNSET_USER_PROFILE
      
    }
  }
  
  
