
import * as types from './types'
import * as props from './props'



export default (state = props, action)=>{

    switch (action.type) {

        case types.SEND_USER_PASSWORD_REQUEST:

            return  {

                ...state,
                isActionSuccessful: false,
                message: '',
                isFetching: true
                
            }

        case types.SEND_USER_PASSWORD_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true
              
               
                
            }

        case types.SEND_USER_PASSWORD_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
                message: action.error
                
            }


        case types.UNSET_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                isActionSuccessful: false,
              
            
            }
        case types.UNSET_MESSAGE_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                message: ''
               
            }

      

      
        default:
        return state
    }


}