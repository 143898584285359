import React from 'react';
import { ThemeContext } from '../../../../context';
  
  class Card extends React.Component {


    constructor(props){
      super()
    }

    static contextType = ThemeContext
    
  render(){

      const {theme, themeName} = this.context 
      const boxesStyling = { backgroundColor: themeName == 'dark' 
      ? theme.colors.complimentary['accent-3'] 
      :  theme.colors.complimentary.base }
      const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
      const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
      const textColorStyles = {color:  theme.colors.complimentary.foreground}
      const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
      const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}

      const {props} = this 
      const {text} = props 
      // let style = { 
      //   backgroundImage: 'url(' + image + ')',
      //  };
      return (
        <article className="job__reccomend-card" style={boxesStyling} >
        
          <div className="jobb__reccomend-card-body">
          
            
            <h2 style={textColorStyles}>{text}</h2>
            {this.props.children}
            
           
        
        </div>
        
        </article>
      )
    }
  }
  
  export default Card
