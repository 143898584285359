
import React,{Component} from 'react'
import {BrowserRouter as Router, Link} from 'react-router-dom'

class Tagline extends Component{
    
    
    render(){

        return(

           

                
           
                      
                    <section className="forgotpassword__tagline">

                        <span className="forgotpassword__tagline-text">
                            <small>Forgotten</small>
                            <small>password</small>
                        </span>

                    </section>
                   
     
               
                

           
        )
    }

}


export default Tagline