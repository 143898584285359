
import React, {Component} from 'react' 
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'
import Verify from './verify'
import ResendFailed from './resendfail/resendfailed'

 



class CandidateVerify extends Component{


    constructor(props){

        super()
        
    }

    componentDidMount(){

        // const {listing} = this.props
        // const {search} = listing
        // console.log('THE SEARCH INFORMATION FROM THE REDUX STATE')
        // console.log(search)
        const {props} = this 
        const {auth,actions,userProfile} = props 
        const {push} = actions 
        const {appAuth} = auth 
        const {isAuth} = appAuth 
        const {profile} = userProfile
        // const {isPending} = profile 
        const {personal} = profile 
        const {is_pending} = personal 
        
        //if(!isAuth) return push('/access') 
        if(is_pending === 0) return push('/dashboard')
        //this.props.actions.fetchDashStats(id)
       
        
    }

    
    render(){

         const {actions,userProfile,candidateVerify} = this.props
         const {resentFailed} = candidateVerify
          if(resentFailed === true) return  <article className="dashboard__candidatedash">
               <Helmet>
                    <title>Jobbri - Verify</title>
                </Helmet>
              <ResendFailed userProfile={userProfile} actions={actions} candidateVerify={candidateVerify} />
              </article>;


        // console.log('THE JOBS PROP')
        // console.log(listing)

        return(

            <article className="dashboard__candidatedash">
                 <Helmet>
                    <title>Jobbri - Verify</title>
                </Helmet>
                
                <div>

                     <Verify  userProfile={userProfile} actions={actions} candidateVerify={candidateVerify} />

                   </div>
               
             </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{

        candidateVerify:{...state.candidateVerify},
        userProfile: {...state.userProfile},
        auth: {...state.auth}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(CandidateVerify)


