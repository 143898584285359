
import React, {Component} from 'react' 
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
//import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import * as userProfileActions from '../UserProfile/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'
import SavedJobs from './components/dashboard/savedjobs'
import jobs from './jobs'
import { toast } from 'react-toastify';





class CandSavedJobs extends Component{


    constructor(props){

        super(props)
    }

    
    componentDidMount(){

        const {props} = this 
        const {userProfile,actions} = props 
        const {push} = actions
        const {profile} = userProfile 
        const {personal} = profile 
        const {id,is_pending} = personal 
        
        if(is_pending === 1) return push('/account-verification')
        //actions.fetchSavedJobsList({ID:id})
        
    }






    
    componentDidUpdate(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message='',isAlertDelete=false} = userProfile 
        const {removeNotification,removeMessage} = actions

      
        if(isActionSuccessful) {

            this.notify({message:'Saved job successfully deleted',type: 'success',className: 'notify-success'}) 
            removeNotification() 
            
        }else if(message.trim()){
            this.notify({message:message,type: 'error',className: 'notify-error'}) 
            removeMessage() 
            
        }
     
        
    }


    notify = (config) => {

       
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});
    }


    
    componentWillUnmount(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message=''} = userProfile 
        const {removeNotification,removeMessage} = actions


        if(isActionSuccessful) {
            removeNotification() 
            
        }
        
        if(message.trim()){
            removeMessage() 
            
        }

     
        
    }
   

   

    render(){

        // const {candSavedJobs,userProfile,actions} = this.props
        const {userProfile,actions} = this.props
        const {push} = actions 
        const {profile} = userProfile 
        const {savedJobs=[]} = profile
       
        // const {savedJobsList,isFetching} = candSavedJobs 
        // if(isFetching) return  <article className="dashboard__savedjobs">
        //     <Helmet>
        //             <title>Jobbri - Saved Jobs</title>
        //         </Helmet><ProgressBarCircular /></article>;
        

        return(

            <article className="dashboard__savedjobs">
                 <Helmet>
                    <title>Jobbri - Saved Jobs</title>
                </Helmet>
                  
                  <div>

                     <SavedJobs savedJobs={savedJobs} userProfile={userProfile} actions={actions} push={push} />

                  </div>
                  
                 
            </article>
        )
    }




}


const mapStateToProps = (state)=>{

    const {userProfile} = state

    return{
        
        userProfile
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...userProfileActions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(CandSavedJobs)

