import React from 'react';




function Tools(props){

  

    return(

         <span style={props?.styles.textColorStyles}>My Job Tools</span>
    )
}

export default Tools