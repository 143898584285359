
import * as types from './types'
import * as props from './props'




export default (state = props, action)=>{

    switch (action.type) {

        case types.SAVE_CHAT_MESSAGE:

            return  {

                ...state,
                messages: [...state.messages,...action.payload.message],
                // questions: [...action.payload.questions],
                // isNotReady: action.payload.isNotReady,
                // isFetching: false,
                
            }
        case types.SET_NEXT_QUESTIONR:

            return  {

                ...state,
                currentQuestionr: action.payload.questionrID,
                
                
            }

        case types.SET_CURRENT_QUESTIONS:

            return  {

                ...state,
                currentQuestions: action.payload.currentQuestions,
                
                
            }

        case types.SET_PROCESSOR:

            return  {

                ...state,
                isProcessing: action.payload.isProcessing,
                isProcessingText:  action.payload.isProcessingText
                
                
            }

        case types.SET_CURRENT_PROFILE_FEATURE:

            return  {

                ...state,
                currentProfileFeature: action.payload.feature,
               
                
            }

        case types.SET_CURRENT_PROFILE_FEATURE_RECORD:

            return  {

                ...state,
                currentProfileFeatureRecord: action.payload.record,
                
                
            } 
        case types.SET_FILLER_ITEM:

            return  {

                ...state,
                fillers: {...state.fillers,[action.payload.key]: action.payload.value}
                
                
            } 

        case types.SET_UPDATE_TYPE:

            return  {

                ...state,
                updateType:  action.payload.updateType
                
                
            } 
        case types.SET_HUMANOID_ACTION_TYPE:

            return  {

                ...state,
                humanoidActionType:  action.payload.humanoidActionType
                
                
            } 

        case types.UPDATE_PROFILE_ITEMS:

            return  {

                ...state,
                questionas: {...state.questionas,profileitems: {...action.payload.profileitems}},
                
                
            }

        case types.SET_HUMANOID_ANSWER:

            return  {

                ...state,
                answers: {...state.answers,...action.payload.answers}
                
                
            }
        case types.UNSET_HUMANOID_ANSWER:

            return  {

                ...state,
                answers: {}
                
                
            }
        case types.SET_PROFILE_DYNAMIC_QUESTION:

            return {

                ...state,
                questionas: {...state.questionas,...action.payload.questionas}
            }

        case types.CLOSE_HUMANOID_CHAT:

            return  {

                ...state,
                answers: {},
                currentQuestions: [],
                currentQuestionr:'',
                currentQuestion:null,
                currentQuestionIndex: 0,
                isReturnUser: false

                
                
            }
    


        case types.SET_ACTIVE_ACTIVITY:

            return  {

                ...state,
                activeActivityHolder: {...state.activeActivityHolder,...action.payload}
                
                
            }


    

        case types.SAVE_CHAT_TEXT:

            return  {

                ...state,
                messages: [...state.messages,...action.payload.message],
                currentQuestion: action.payload.currentQuestion,
                currentQuestionIndex: action.payload.currentQuestionIndex,
                isFetching: false,
                
            }

        case types.SAVE_INITIAL_CHAT_MESSAGE:

            return  {

                ...state,
                messages: [...state.messages,...action.payload.message],
                currentStep: action.payload.currentStep,
                currentStepCQ: action.payload.currentStepCQ,
                isPausedMessagesSet: action.payload.isPausedMessagesSet,
                isNotReadyMessagesSet: action.payload.isNotReadyMessagesSet

                
            }

        case types.SAVE_CHAT_MESSAGE_WITH:

            return  {

                ...state,
                messages: [...state.messages,...action.payload.message],
                questions: [...action.payload.questions] ,
                answers: {...state.answers,...action.payload.answers},
                currentStep: action.payload.currentStep,
                currentStepCQ: action.payload.currentStepCQ,
                stepID: action.payload.stepID,
                isNotReady: false
                
            }

        case types.SAVE_HOST_MESSAGE:

            return  {

                ...state,
                messages: [...state.messages,...action.payload.message],
                host: {...action.payload.host} ,
                hostCurrentState: action.payload.hostCurrentState,
                hostCurrentStateSID: action.payload.hostCurrentStateCQ,
                hostCurrentStateCQ: action.payload.hostCurrentStateCQ
                
                
            }

        case types.SAVE_WITH_NO_MESSAGE:

            return  {

                ...state,
                questions: [...action.payload.questions] ,
                answers: {...state.answers,...action.payload.answers},
                currentStep: action.payload.currentStep,
                currentStepCQ: action.payload.currentStepCQ,
                stepID: action.payload.stepID,
                isNotReady: false
                
            }

        case types.SET_IS_COMPLETE:

            return  {

                ...state,
                isSetUpComplete: true,
                isNotReady: false,
                isClosed: false,
                isPaused: false
                
                
            }

        case types.CHANGE_ACTIVE_STATUS:

            return  {

                ...state,
                isActive: action.payload,
               
                
                
            }

        case types.SET_IS_RETURN_USER:

            return  {

                ...state,
                isReturnUser: action.payload,
                
                
                
            }
    

        case types.SET_DATA_CAPTURE_COMPLETE:

            return  {

                ...state,
                isDataCaptureComplete: true,
                isNotReady: false,
                isClosed: false,
                isPaused: false
                
                
            }
        case types.CLEAR_CHAT_MESSAGES:

            return  {

                ...state,
                messages: []
               
                
            }
        
        case types.SEND_USER_BUILT_RESUME_REQUEST:

            console.log('THE candidateDashboard REDUCER')
            console.log(state)
            return  {

                ...state,
                isFetching: true,
                isProcessed: false,
                applicantStats: {...state.applicantStats,alerts: action.response[0].alertsCount,saved: action.response[1].savedCount,searched: action.response[2].historyCount},
                
                
                
            }

        case types.SEND_USER_BUILT_RESUME_SUCCESS:

            console.log('THE candidateDashboard REDUCER')
            console.log(state)
            return  {

                ...state,
                isFetching: false,
                isProcessed: true,
                applicantStats: {...state.applicantStats,alerts: action.response[0].alertsCount,saved: action.response[1].savedCount,searched: action.response[2].historyCount},
              
               
                
            }

        case types.SEND_USER_BUILT_RESUME_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            }


        case types.SEND_USER_BUILT_RESUME_FACET_REQUEST:

           
            return  {

                ...state,
                isFetching: true,
                applicantStats: {...state.applicantStats,alerts: action.response[0].alertsCount,saved: action.response[1].savedCount,searched: action.response[2].historyCount},
                
                
                
            }

        case types.SEND_USER_BUILT_RESUME_FACET_SUCCESS:

          
            return  {

                ...state,
                isFetching: false,
                //isProcessed: true,
                applicantStats: {...state.applicantStats,alerts: action.response[0].alertsCount,saved: action.response[1].savedCount,searched: action.response[2].historyCount},
                
                
                
            }

        case types.SEND_USER_BUILT_RESUME_FACET_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            }

        case types.SAVE_DO_AGAIN_DATA:

            return  {

                ...state,
                isFetching: false,
                doAgainData: action.payload
                
            }

        case types.SET_UNRESOLVED_CLEARANCE:

            return  {
    
                    ...state,
                    isClearingUnresolved: action.payload.isClearingUnresolved,
                    isClearingUnresolvedText:  action.payload.isClearingUnresolvedText
                    
                    
            }
        case types.CLEAR_UNRESOLVED:

            return  {
    
                    ...state,
                    answers: {},
                    currentQuestions: [],
                    currentQuestionr:'',
                    currentQuestion:null,
                    currentQuestionIndex: 0,
                    doAgainData: null
    
                    
                    
                }
        
    
    
    
        default:
        return state
    }


}