
import app from './app'
import init from './init'
import { startGoogleServices } from './utilities/googleGa'

export default function(){

     // console.log('THE APP IS BOOTSTRAPPING')
     startGoogleServices()
     init(app)

}



