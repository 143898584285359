
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import * as chatHeaderActions from '../ChatHeader/actions'
import * as userProfileActions from '../UserProfile/actions'
import * as authActions from '../Auth/actions'


import ProgressBarCircular from '../ProgressBarCircular/component'
import About from './components/about'
import Alert from './alert'
import Experience from './components/experience'
import Portfolio from './components/portfolio'
import Education from './components/education' 
import Curated from './components/curated'
import ChatHeader from '../ChatHeader/component'
// import {closeModal} from './alert'


 



class Humanoid extends Component{


    constructor(props){

        super()
        this.state ={

            update: false,
            width:0,
            height: 0
        }
      
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }


    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
        setProcessor(false,'') 
      
     }
    // componentDidMount(){

    //     console.log('The component did mount and now we have props')
        

    //     // const {listing} = this.props
    //     // const {search} = listing
    //     // console.log('THE SEARCH INFORMATION FROM THE REDUX STATE')
    //     // console.log(search)
    //     const {props} = this 
    //     const {userProfile,actions} = props 
    //     const {profile} = userProfile 
    //     const {push} = actions
    //     const {id,isPending} = profile 
    //     if(isPending === 1) return push('/account-verification')
    //     this.props.actions.fetchDashStats(id)
       
        
    // }

    
    render(){

         const {humanoid,actions,chatHeader,userProfile,auth} = this.props 
         const {width} = this.state

        //  if(isFetching === true) return  <article className="dashboard__candsettings"><ProgressBarCircular /></article>;


        // console.log('THE JOBS PROP')
        // console.log(listing)

        return(

            <article className="humanoid">
                
                <Alert width={width}>
                    <ChatHeader />
                    <About humanoid={humanoid} chatHeader={chatHeader} userProfile={userProfile} actions={actions} auth={auth} />
                    
                </Alert>
                {/* <Experience /> */}
                {/* <Portfolio /> */}
                {/* <Education />
                <Curated /> */}
               
               
             </article>
        )
    }




}

const mapStateToProps = (state)=>{

    const {humanoid,userProfile,chatHeader,auth} = state 

    return{
        humanoid,
        userProfile,
        chatHeader,
        auth
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions,...chatHeaderActions,...userProfileActions,...authActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(Humanoid)


