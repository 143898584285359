
import React, {Component} from 'react' 
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import qs from 'query-string'
import { toast } from 'react-toastify';
import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions'
import Tagline from './tagline/tagline' 
import Hero from './hero/hero' 
// import Postdate from './hero/reasons'




class Resetpassword extends Component{


    constructor(props){

        super(props)
    }

    componentDidMount(){

        const {props} = this 
        const {actions,router} = props 
        const {push} = actions
        const {search} = router 

     

        let params =  qs.parse(search) 

        console.log('THE USER PARAMS')
        console.log(params)
        console.log(search)
        if(!params.email){

            push('/forgot-password')
          

        }


    }

    componentDidUpdate(){
        
        

        const {actions,resetPassword} = this.props
        const {isActionSuccessful,message} = resetPassword
        const {removeNotification,removeMessage} = actions 

        if(isActionSuccessful) {

            this.notify({message:'Password reset successfully completed.',type: 'success',className: 'notify-success'}) 
            removeNotification() 
            
          
           
        }else if(message.trim()){

            this.notify({message: message,type: 'error',className: 'notify-error'}) 
            removeMessage() 
            
        }


       
        
    }

    
    componentWillUnmount(){
        
        

        const {actions,resetPassword} = this.props
        const {isActionSuccessful,message,text}= resetPassword
        const {removeNotification,removeMessage} = actions 

        if(isActionSuccessful) removeNotification()
        if(message.trim()) removeMessage()


       
        
    }

    notify = (config) => {

            
        console.log('THE TOAST CONFIG')
        console.log(config)
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

    }




    render(){

        const {props} = this 
        const {actions,router,resetPassword} = props 
        const {search} = router 

        let params =  qs.parse(search) 
        const {email=''} = params


        return(

            <article className="resetpassword">
                <Helmet>
                    <title>Jobbri - Reset Password</title>
                </Helmet>
                  
                {/* <Tagline /> */}
                <Hero resetPassword={resetPassword} actions={actions} email={email} /> 
               
    
            </article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        resetPassword: {
            ...state.resetPassword
        },
        router: {...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(Resetpassword)