import React,{useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import Upload from '../upload'
// import Register from '../register' 
// import Success from '../sucess'
import DeleteSuccess from './deletesuccess' 
// import Confirm from '../successconfirm' 
// import DownloadMulti from '../downloadmulti'




export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  // const [openUpload,setOpenUpload] = React.useState({open: false,type: ''})
  const [title,setTitle] = React.useState('')

  
  const {text='',userProfile={},actions={},componentProps={},buttonProps={}} = props 
  const {compClass='none-class'} = componentProps
  const {isActionSuccessfull=false} = userProfile
  const ContextComponent = props.popUp

  const CustomButton = props.button ? props.button : null


  // const {isActionSuccessful=false,isInitSuccess=false,message='',isFetching=false,isResumeDeleted=false,isUploadResumeReplaced,
  //   isResumeReplaced=false,isUploadResume=false} = userProfile
  //   const {removeNotification,removeMessage} = actions
    // const {removeNotification} = actions

  // const actionTitle = isResumeDeleted ? 'Uploaded cv deleted' : isAuth ? isActionSuccessful ? 'Upload Success' : 'Upload CV' : 'Login or Register'

  const handleClickOpen = () => {
    // setOpenUpload({open: false, type: ''})
    setOpen(true);
  };

  // const handleOpenUpload = (replace='') => {

  //   if(isActionSuccessful && isUploadResume) removeNotification()
  //   setOpenUpload({open: true,type: replace});

  // };

  const handleSetTitle = (title) => {

    // if(replace) return setOpenUpload(true);
    // console.log('THE SET TITLE')
    // console.log(title)
    setTitle(title);
  };


  // useEffect(() => {
    
  //   if(isActionSuccessful) removeNotification()
  // });


//  const deleteCV = ()=>{


//     const {actions,userProfile} = props
//     const {deleteUploadResume} = actions 
//     const {profile} = userProfile
//     const {personal} = profile 
//     const {resume_url,id} = personal 
//     let parts = resume_url.indexOf('//') >= 0 ? resume_url.split('//').join('\\') : resume_url
//     const send = {fileName: parts}
//     deleteUploadResume(send,id)

// }

  const handleClose = () => {

    
    // if(isActionSuccessful && isUploadResume) removeNotification() 
    // if(message) removeMessage() 
    
    
    // setOpenUpload({open: false, type: ''});
    setOpen(false);
  };


 

  return (


    <section className={compClass}>

      {
      
      props.button
      ? buttonProps ? <CustomButton {...buttonProps} openPopup={handleClickOpen} /> : <CustomButton openPopup={handleClickOpen} /> 
      : props.children 
        ? props.children
        : <Button  style={props.styles ? props.styles : {border: "none",color: "black",fontSize: 1.1+"rem",display: 'inline-block'}} onClick={handleClickOpen}>
            {
              props.text 
                ? props.text 
                : props.children 
                  ? props.children
                  : 'Open dialog'
              }
          </Button>
      }

    {/* {
      
      props.children 
      ? props.children
      : <Button  style={{border: "none",color: "black",fontSize: 1.1+"rem"}} onClick={handleClickOpen}>
          {props.text ? props.text : 'Open dialog'}
        </Button>
      } */}


     
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>

          <ContextComponent userProfile={userProfile} actions={actions} {...componentProps} closePopup={handleClose} /> 
                   
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{color: 'seagreen'}}>
            Close
          </Button>
          
        </DialogActions>
      </Dialog>
    </section>
  );
}
