
import React,{Component} from 'react'
import {BrowserRouter as Router, Link} from 'react-router-dom' 


class Google extends Component{


 
    
    
    render(){

        return(

           

             
           
                 

                       
                        
                            <img className="access__header--social-img" src="/img/social/googlePlus.png" />
                        
                            
                       
           

                   
       
               
                

           
        )
    }

}


export default Google