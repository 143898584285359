
import * as types from './types'
import { CALL_API } from '../../middlwares/api'




  export const sendUserPassword = (data)=> {

    return {

        [CALL_API]: {
          endpoint: `/register`,
          method: 'POST',
          request: 'URL',
          payload: {action: 'resetPassword',payload: {reset: {...data}}},
          authencitcated: false,
          types: [types.SEND_USER_PASSWORD_REQUEST, types.SEND_USER_PASSWORD_SUCCESS, types.SEND_USER_PASSWORD_FAILURE]
        }
      }

  }


  export const removeNotification = ()=>{

    return {
  
      type: types.UNSET_NOTIFICATION_DISPLAY,
      payload: false
    }
  }

  export const removeMessage = ()=>{

    return {
  
      type: types.UNSET_MESSAGE_NOTIFICATION_DISPLAY,
      payload: false
    }
  }
  



