
import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import { posts } from '../../../Blogr/props';
import { ThemeContext } from '../../../context';




class SecondaryPosts extends Component{
    
    
    constructor(props) {
        super(props);
        this.state = {
            months: [
                'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
            ]
        }
      
    }

   
    

getPreviewText = (text)=>{

      
    let prevText = text.substr(text.indexOf('>')+1,text.indexOf("</p>"))
    console.log('THE PREVTEXT')
    console.log(prevText)
    console.log(text.indexOf('>'))
    console.log(text.indexOf('</p>'))
    console.log(prevText.length)
    // prevText = prevText.length > 240 ? prevText.substr(0, prevText.length - (prevText.length - 252)) : prevText
 

    return `${prevText.substr(0,80)}...`

}

getDate = (date)=>{

    const {state} = this 
    const {months} = state
    let setDate = new Date(date) 
    let getDate = setDate.getDate() 
    let formAtedDate = getDate < 10 ? `0${getDate}` : getDate

    return `${months[setDate.getMonth()]} ${formAtedDate}, ${setDate.getFullYear()}`

}

getCompanionPosts = (bPost)=>{

    const {post,i:p, openBlogPage, styles={}} = bPost
    // posts.array.forEach(element => {
        
    // });
    let companionElement = null
   

        
        const {slug,is_primary=0,title,body,created_at,image,author,post_topic_name} = post 
        let link = p === 1 ? "link d-block" : "link d-block blog__primary--post-offset"

        if(p > 2){

            companionElement = ( <div className="blog__secondary--post">
                            
                                    <secton className="blog__secondary--post-figda">

                                    
                                        <Link to={`/blog/post/${slug}`} className="link d-block">

                                            <figure className="blog__secondary--post-fig">
                                                <img src={image} className="blog__secondary--post-pik" />
                                            </figure>
                                            <small className="blog__secondary--post-date">{this.getDate(created_at)}</small>

                                        </Link>

                                        

                                    </secton>

                                    <section className="blog__secondary--post-conti">

                                        <Link to={`/blog/post/${slug}`} className="link d-block">

                                            <h2 className="blog__secondary--post-title" style={styles?.textColorStyles}>{title}</h2>
                                            <p className="blog__secondary--post-content" style={styles?.textColorStylesAccent}>
                                            {this.getPreviewText(body)}
                                            </p>
                                        </Link>
                                        <button className="blog__secondary--post-tag" onClick={(e)=>openBlogPage({topic:post_topic_name,url: '/blog/topic/'},e)}>#{post_topic_name}</button>
                                    </section>
                                    <strong className="clearfix" />
                                    

                                </div>
                            )
    

        }

    
    return companionElement



}

vibrate = (mss)=>{

    window.navigator.vibrate(500)
}


      
    
static contextType = ThemeContext
    
render(){

    const {theme, themeName} = this.context 
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
    const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}

        const {props} = this 
        const {blogr,openBlogPage} = props 
        const {posts} = blogr

        return(

           

                
                <div  className="blog__secondary">

                   <section className="blog__secondary--posts">

                       {posts.map((p,i)=>{

                           return this.getCompanionPosts({post:p,i,openBlogPage,styles:{textColorStyles, textColorStylesAccent}})
                       })}

                        {/* <div className="blog__secondary--post">
                            
                            <secton className="blog__secondary--post-figda">

                               
                                <Link to='/' className="link d-block">

                                    <figure className="blog__secondary--post-fig">
                                        <img src="/img/users/250_3.jpg" className="blog__secondary--post-pik" />
                                    </figure>
                                    <small className="blog__secondary--post-date">Feb 02, 2020</small>

                                </Link>

                                

                            </secton>

                            <section className="blog__secondary--post-conti">

                                <Link to='/' className="link d-block">

                                    <h2 className="blog__secondary--post-title">Jobbri posting done</h2>
                                    <p className="blog__secondary--post-content">
                                        psum dolor sit amet, consetetur sadipscing elitr, 
                                        sed diam 
                                    </p>
                                </Link>
                                <button className="blog__secondary--post-tag">#update</button>
                            </section>
                            <strong className="clearfix" />
                            

                        </div>

                        <div className="blog__secondary--post">
                            
                            <secton className="blog__secondary--post-figda">

                               
                                <Link to='/' className="link d-block">

                                    <figure className="blog__secondary--post-fig">
                                        <img src="/img/users/250_6.jpg" className="blog__secondary--post-pik" />
                                    </figure>
                                    <small className="blog__secondary--post-date">Feb 02, 2020</small>

                                </Link>

                                

                            </secton>

                            <section className="blog__secondary--post-conti">

                                <Link to='/' className="link d-block">

                                    <h2 className="blog__secondary--post-title">Stay alert of desired jobs</h2>
                                    <p className="blog__secondary--post-content">
                                        psum dolor sit amet, consetetur sadipscing elitr, 
                                        sed diam 
                                    </p>
                                </Link>
                                <button className="blog__secondary--post-tag">#career</button>
                            </section>
                            <strong className="clearfix" />
                            

                        </div> */}

                       

                   </section> 

                   <section className="blog__secondary--views">

                   <Link to='/blog/archive' className="link d-block">

                            <button className="blog__secondary--views-v">View</button>
                            <button className="blog__secondary--views-a">Archives</button>
                    </Link>
                            

                   </section>
                </div>
               
                

           
        )
    }

}


export default SecondaryPosts