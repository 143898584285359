
import React, {Component} from 'react' 
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
// import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import * as authActions from '../Auth/actions'

import qs from 'query-string'
import Tagline from './tagline/tagline' 
import Hero from './hero/hero' 
import Verification from './verification/verification'
import Postdate from './hero/reasons'




class AccountVerify extends Component{


    constructor(props){

        super()
        this.state = {timer: null,skipUpdate: false}
    }

    componentDidMount(){

        const {props} = this 
        const {actions,router} = props 
        const {push,sendUserVerificationCreds} = actions
        const {search} = router 

     

        let params =  qs.parse(search) 

        console.log('THE USER PARAMS')
        console.log(params)
        console.log(search)

        if(params.code && params.id){

            let data = {hash:params.code,id: params.id}
            sendUserVerificationCreds(data)

        }else{

            push('/404')

        }


    }

    
    componentDidUpdate(){

        console.log('THE LOGIN COMPONENT WILL MOUNT AT SOME POINT')
        console.log('THE VALUE OF AUTH IN DID UPDATE') 
        const {props,state} = this 
        const {skipUpdate} = state
        const {auth,actions} = props 
        const {appAuth,isAccountVerified} = auth
        const {isAuth} = appAuth
        const {push} = actions
      
    
     

       // console.log(isAuth)

       
        


         if(isAccountVerified && isAuth && skipUpdate === false){

        
           let timer = setTimeout(()=>{

                push('/dashboard')

            },2000)

            this.setState(({...this.state,timer: timer,skipUpdate: true}))
           
          
         }
         
        

    }

    cancelLoginOnVerification = ()=>{


        const {state,props} = this 
        const {actions} = props 
        const {push} = actions
        const {timer} = state 

        if(timer) clearTimeout(timer)
        push('/')

    }

    retryLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 


        push('/access')
    }




    render(){

        const {props} = this 
        const {auth} = props 
        const {isAccountVerified,isFetching,verificationFailed} = auth 

        // let styles = {

        //     marginBottom: '30px',
        //     fontSize: '1.4rem',
        //     width: '50%',
        //     height: '300px',
        //     marginLeft: '25%',
        //     paddingTop: '150px',
        //     boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04)'
        

        // }

       
        if(verificationFailed === true) return <article className="verify verify--out">
             <Helmet>
                    <title>Jobbri - Account verify</title>
                
                </Helmet>
            <Verification retryLogin={this.retryLogin.bind(this)} /></article>


        return(

          
            <article className="verify verify--out">
                 <Helmet>
                    <title>Jobbri - Account verify</title>
                
                </Helmet>
                  
                {/* <Tagline /> */}

             

                 { isFetching || !isAccountVerified
                
                ? <section className="verify__verifying">

                    <div>
                        <div className="verify__loader "></div>
                        <h2 className="verify__loader__text">Verifying user account...</h2>
                    </div>
                </section>
                
                : <Hero cancelLoginOnVerification={this.cancelLoginOnVerification.bind(this)}/> } 

               
            </article>
        )
    }




}

const mapStateToProps = (state)=>{


    return {

        auth: {...state.auth},
        router: {...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...authActions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(AccountVerify)