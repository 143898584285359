import React from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'; 
import { toast } from 'react-toastify';




const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class ExpLevel extends React.Component{

    constructor(){
        super();
        this.state = {
    
            options: [
    
                {value: 'Graduate',name: 'graduate',isSelected: false},
                {value: 'Junior',name: 'junior',isSelected: false},
                {value: 'Intermediate',name: 'intermediate',isSelected: false},
                {value: 'Expert',name: 'expert',isSelected: false},
               
    
            ],
            isLocal:  true
        }
       
      }
    
    
      updateOptions(i,e){
    
            console.log(e)
            e.preventDefault() 
    
            const {options} = this.state  
            const {props} = this 
            const {handleChange} = props 
    
            let updateOpts = [...options]
            let shouldUpdateNew = true
            updateOpts.forEach((opts,ii)=> {
    
                if(opts.isSelected){
    
                    if(i === ii){
    
                        opts.isSelected = false 
                        shouldUpdateNew = false
    
                    }else{
    
                        opts.isSelected = false
    
                    }
                } 
                
            })
    
            if(shouldUpdateNew){
                let newOptionValue = { value: options[i].value,name: options[i].name, isSelected: !options[i].isSelected}
                updateOpts[i] = newOptionValue 
            }
    
            
            this.setState({...this.state,options: [...updateOpts]},()=>{
    
                if(!updateOpts[i].isSelected){
    
                    handleChange({target: {name: updateOpts[i].name, value: updateOpts[i].value}}, true)
                }else{
    
                    handleChange({target: {name: updateOpts[i].name, value: updateOpts[i].value}})
                }
               
            })
      }
    
    //   syncOptionsValues(){
    
    //      const {state,props} = this 
    //      const {values} = props 
    //      const {options} = state 
    
    //      console.log('SYNC VALUES') 
    //      console.log(options)
    //      console.log(values)
    
    //      for(let i=0; i < options.length; i++){
    
    //         console.log(options[i].name) 
    //         console.log(values[options[i].name])
    //         if(!values[options[i].name]){
    
    //             options[i].isSelected = true 
    //             this.setState({...state,options: [...options]})
    //             break;
    //         }
    //      }
    
    //   }
    
      componentDidMount(){
    
        const {state,props} = this 
        const {values} = props 
        const {options} = state 
    
        console.log('THE VALUES')
        console.log(values)
    
    
        for(let ti=0; ti < options.length; ti++){
    
            if(values[options[ti].name] && options[ti].isSelected === false){
    
                let updateOpts = [...options] 
                let newOptionValue = { value: options[ti].value,name: options[ti].name, isSelected: true}
                updateOpts[ti] = newOptionValue 
                 this.setState({...this.state,options: [...updateOpts]})
                break;
    
            }
    
         }
    
       
      }
    
      validator(){


        const {state,notify} = this 
        const {options} = state 
        let validationReturn = false 
    
        for(let ti=0; ti < options.length; ti++){
    
            console.log('TI')
            console.log(ti) 
    
            if(options[ti].isSelected === true){
    
                validationReturn = true 
                break;
    
            }
    
         }
    
         if(!validationReturn){
    
            notify({message:'Select atleast one option to continue',type: 'warning',className: 'notify-warn'})
         }
    
         return validationReturn
    
      }
    
      notify = (config) => {
    
            
        console.log('THE TOAST CONFIG')
        console.log(config)
    
        const type = config.type || 'info'
        const message = config.message
    
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});
    
        
        
      }
    
    
    
      renderOptions(values){
    
    
         const {options} = this.state 
    
    
        let opts = options.map((opt,i)=>{
    
             if(opt.isSelected){
    
                
                return (
                    <div className="questionnaire__option"  style={{cursor: 'pointer'}} onClick={this.updateOptions.bind(this,i)} key={i}>
    
                        <section className="questionnaire__box questionnaire__box-bg">
                            <span className="questionnaire__mark">
                                <small>
                                    <Icon><CheckIcon /></Icon>
                                </small>
                            </span>
                            <span className="questionnaire__text">{opt.value}</span>
                        </section>
                        <section className="questionnaire__overlay questionnaire__overlay-bg bx-raised-1"></section>
                        
                     
                    </div>
                )
             }else{
    
                return (
                    <div className="questionnaire__option" style={{cursor: 'pointer'}} onClick={this.updateOptions.bind(this,i)} key={i}>
    
                        <section className="questionnaire__box">
                            <span className="questionnaire__text">{opt.value}</span>
                        </section> 
                        <section className="questionnaire__overlay"></section>
    
                    </div>
                )
    
             }
    
         })
    
         return opts
    
}
    














  render(){


   const {props,validator} = this 
    const {nextStep,prevStep,handleChange,values} = props 
   
   
    return(


      <div className='questionnaire__i'>
        
        <h3  className="questionnaire__question">What experience level best describes you?</h3> 

        <section className="questionnaire__options">

          {this.renderOptions(values)}
        </section>

        <button className="questionnaire__next" onClick={prevStep}>Prev</button>
        <button className="questionnaire__next" onClick={nextStep.bind(this,validator.bind(this))}>Next</button>
       
      </div>


    );
  }
}

export default withStyles(styles)(ExpLevel)