
import * as types from './types'

export const setCurrentSender = (current)=>{

  return {

    type: types.SET_CURRENT_SENDER,
    payload: current
  }
}

export const unsetCurrentSender = (current)=>{

  return {

    type: types.UNSET_CURRENT_SENDER,
    payload: ''
  }
}


export const removeNotification = ()=>{

    return {
  
      type: types.UNSET_NOTIFICATION_DISPLAY,
      payload: false
    }
  }


  
