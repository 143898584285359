
import * as types from './types'
import * as props from './props'



export default (state = props, action)=>{

    switch (action.type) {

        case types.SEND_USER_VERIFICATION_LINK_REQUEST:

            return  {

                ...state,
                isFetching: true,
                isActionSuccessful: false,
                text: '',
                message: '',
                
                
            }

        case types.SEND_USER_VERIFICATION_LINK_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true,
                text: action.response.text || ''
              
               
                
            }

        case types.SEND_USER_VERIFICATION_LINK_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: false,
                text: '',
                message: action.error
                
            }

        case types.UNSET_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                isActionSuccessful: false,
              
            
            }
        case types.UNSET_MESSAGE_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                message: ''
               
            }

      
        default:
        return state
    }


}