
import React from 'react' 



function Design() {
    

    return(

           <section className="grid-col-7 pos-rel pd-bottom-fd-xxx-lg pd-top-fd-lg mg-top-fd-lg">

               
               <div className="pos-rel top-offset-vh-xx-sm  pd-left-fl-xx-sm ">
                    <span className="fg-primary bx-raised hr-size-fl-sm text-align-center font-wt-bolder cursor-pointer pd-fd-sm bg-light font-fd-xx-tn d-inline-block">Post a job</span>
               </div>
           </section>
    )

}


export default Design