import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import { ThemeContext } from '../../context'
import Bio from './bio'
import Contact from './contact'
import Share from './share'
import ShareButton from './sharebutton'







class About extends Component{
    
    constructor(){

        super()
        this.state ={

            bioEdit: false,
            contactEdit: false,
            avatarEdit: false,
            profession: null,
            identity: null,
            localImage: null
            
            

        }

        
    }

    allowEdit = (key,e)=>{

        e.preventDefault()

         let editing = `${key}Edit` 
    
         let editingValue = this.state[editing]
         this.setState({...this.state,[editing]: !editingValue})
    }

    updateCaptured = (img)=>{

        this.setState({...this.state,localImage: img})
    }

    
    updateIdentity = (value,validStatus)=>{

         this.setState({...this.state,identity: {...this.state.identity,[value.key]: {isValid: validStatus,value: value.text}}},()=>{

            console.log('THE UPDATED IDENTITY DATA')
            console.log(this.state)
         })
    }

    updateProfession = (value,validStatus)=>{

        console.log('updateproffesion does get a call up')
        console.log(value)
        console.log(validStatus)
        this.setState({...this.state,profession: {...this.state.profession,[value.key]: {isValid: validStatus,value: value.text}}},()=>{
            console.log('THE UPDATED PROFFESSION DATA')
            console.log(this.state)
        })
    }


    verifyItem = (items)=>{

        let validData = null
        let noneTouched = true
        let breakOuter = false 

        console.log('The items')
        console.log(items)

        for(let item in items){

            if(breakOuter) break 

            console.log('THE ITEM VALUE')
            console.log(item)
            if(items[item]){

                noneTouched = false
                for(let k in item){
            
          
                    if(item[k].isValid === false){
    
                        breakOuter = true
                        validData = null
                        break
    
    
                    }else{
     
                        !validData ? validData = {[k]: item[k].value} : validData[k] = item[k].value
    
                    }
    
                
        
            
                 } 

            }
           
        }
       

        return {
            noneTouched,
            validData
        }
    }

    verifyInputs = ()=>{

            const {state} = this
            const {identity,profession} = state 
            console.log('THE VALUE OF THE STATE')
            console.log(state)
            let verified = this.verifyItem({identity: identity,profession: profession})

            console.log('THE VERIEIED INPUTS')
            console.log(verified)
             return verified
            




       
    }


    download = ()=>{

        const {props} = this 
        const {actions,resume} = props 
        const {downloadResume} = actions  
        downloadResume(resume)
    }

    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary['accent-3']}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


      const {state,props} = this 
      const {bioEdit,contactEdit,avatarEdit,localImage} = state 
      const {personal={},actions,userProfile} = props 
    //   const {profile} = userProfile 
    //   const {profileUrl} = profile
      const {profile_url='',id,version} = personal
      const bio = personal.bio ? personal.bio : '' 
    //   const version =  profile.version || personal.version
      
     
       

        return(

           
    
            <div className="resume__about">

                 
                 <section className="resume__about--bio">

                     
                    
                    
                     <>
                     
                     <h2 className="resume__about--bio-title">
                        <span>

                          {
                              personal.first_name
                              ? personal.first_name 
                              : null
                          }
                            </span>
                        <span>
                        {
                              personal.last_name 
                              ? personal.last_name 
                              : null
                          }</span>
                        </h2>
                      <h3 className="resume__about--bio-sub" style={textColorStyles}>
                          {
                              personal.nick_name 
                              ? personal.nick_name 
                              : null
                          }
                          </h3>
                     </>
                    
                     
                     <Bio bio={bio} />

                        
                     <div className="resume__about--bio-text" style={boxesStyling}>

                        <h4 style={textColorStyles}>Connect</h4>
                        <Contact contact={personal}  />

                     </div>

                     
                     
                 </section>

                 <section className="resume__about--profile">

                     <div className="resume__about--profile-rect" style={boxesStyling}>

                     
                         <h2 style={textColorStyles}>
                            {
                              personal.profession
                              ? personal.profession
                              : null

                          }</h2> 

                     </div>
                     

                     <div className="resume__about--profile-rect-2" style={boxesStyling}>

                        

                        <span className="resume__about--profile-circle-lg"></span>
                        <span className="resume__about--profile-circle-sm"></span>
                        <span className="resume__about--profile-avatar">
                                <img src="/img/250_2.jpg" alt="user cv profile photo" />
                                {/* <img src={localImage || profile_url} alt="user cv profile photo" /> */}
                                
                        </span>

                         
                     </div>

                     <div>
                     <p className="resume__about--profile-text">
                        <button className="resume__about--profile-text-btn" style={boxesStyling}>HIRE ME</button>
                        <button className="resume__about--profile-text-btn" style={boxesStyling} onClick={(e)=> this.download()}>DOWNLOAD CV</button>
                     </p>

                     <p>
                   

                        
                           <Share actionButton={ShareButton} />
                            {/* <a href={`http://localhost:3001/cv?r=${personal.resume_url_hash}`} target="_blank" className="resume--action-btns " onClick={(e)=>this.submitForm(e)}>

                                        <span className="resume--action-btns-rect resume--action-btns-rect-2"></span>
                                        <span className=" resume--action-btns-rect resume--action-btns-rect-3"></span>
                                        <span className="resume--action-btns-rect resume--action-btns-rect-4">
                                            <small className="resume--action-btns-rect-4-icon">
                                            <i class="fa fa-eye" aria-hidden="true"></i>

                                            </small>
                                            <small>Preview CV</small>
                                        </span>
                            </a> */}
                     </p>
                     </div>
                     
                </section>

                <strong className="clearfix"></strong>



            </div>

          
        )
    }


}


export default About


