
import React,{Component} from 'react'
import {Link} from 'react-router-dom' 

import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import DateRangeIcon from '@material-ui/icons/DateRange';
import Dropdown from './Dropdown/dropdown'
import { ThemeContext } from '../../../context';




const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };

 const monthsOfTheYear = [
    'January','February','March','April','May','June','July','August','September','October','November','December'
]



class ArchiveFilters extends Component{
    
    
    constructor(props){


        super(props)

        this.state = {
          showDropDown: false,
          dropDownID: '',
          years: [],
          months: [],
          currentItem: '',
          setYear: 'Years',
          setMonth: 'Months',
          setYearValue: '',
          setMonthValue: ''
          
        }

      }

      componentDidMount(){


        this.setState({
          years:[...this.getYears()],
          months: [...this.getMonths()]
        })


      }

    
      getYears = ()=>{

        let initialYear = 2021
        const currentYear = 2029 
        const elaspsedSinceInitial = currentYear - initialYear 
        
        const years = [] 

        for(let i=0; i <= elaspsedSinceInitial; i++){

          years.push({

            id: i,
            value: i+1,
            title: initialYear++,
            selected: false
          })
        }

        return years


      }

      getMonths = ()=>{

        

        return monthsOfTheYear.map((m,i)=>{

            return {

              id: i,
              value: i,
              title: m,
              selected: false
            }
      

        })
      

         


      }


      toggleSelectedRecent(id, key){

        let temp = this.state[key]
        temp[id].selected = !temp[id].selected
        this.setState({
          [key]: temp
        })

      }

      toggleSelectedPages(id, key){

        let temp = this.state[key]
        temp[id].selected = !temp[id].selected
        this.setState({
          [key]: temp
        })

      }

      displayDropDown = (filter)=>{

        console.log('THE FILTER GETS A CLICK AND IT RUNS')
        console.log(filter)

        this.setState({...this.state,showDropDown:true,dropDownID: filter})
        // filter === 'price' ? this.priceFilter(filterType) : this.nameFilter(filterType)

    }

    hideDropDown = (e,i='')=>{

      console.log('THE VALUE I HIDEDROPDOWN')
      console.log(i)
      e.stopPropagation() 
      if(i){
      	  
      	  const {props,state} = this 
      	  const {actions} = props 
      	//   const {crudJobFilter,saveJobSearchParams} = actions 
      	  let id = state.dropDownID
          let opt = state[id] 
          let value = opt[i].value 
          let title = opt[i].title
          let setValue= ''
          let setItem = ''

          id === 'years' ? setValue= 'setYearValue' : setValue = 'setMonthValue'
          id === 'years' ? setItem= 'setYear' : setItem = 'setMonth'
          console.log('THE OPT VALUE')
          console.log(opt)
      	
      	   
      	   this.setState({...this.state,[setValue]: value,[setItem]:title ,showDropDown:false,dropDownID: ''},()=>{

              console.log('THE CURRENT STATE')
              console.log(this.state)
      	   	  this.filterByDate(id)
      	   })
      	  
      	 
      	  
      	
      }else{
      	
      	 this.setState({...this.state,showDropDown:false,dropDownID: ''})
      	
      }
      

    }



   filterByTag = (tag)=>{

    const {props} = this 
    const {actions} = props 
    const {fetchBlogArchive} = actions
    // const {archive} = blogr 
    
    if(tag.trim() === 'all') return fetchBlogArchive()
    fetchBlogArchive({blog: {topic: tag}})

   }

    filterByDate = (id)=>{

      const {props,state} = this 
      const {setYearValue,setMonthValue} = state
      const {fetchArchive} = props 
      
      if(id === 'years'){

        
        fetchArchive({archive: {value: setYearValue,search: 'date',dateType: 'YEAR'}})

      }else{

        fetchArchive({archive: {value: setMonthValue,search: 'date',dateType: 'MONTH'}})

      }
      


   }


   
      
    
   static contextType = ThemeContext
    
   render(){

       const {theme, themeName} = this.context 
       const boxesStyling = { backgroundColor: themeName == 'dark' 
       ? theme.colors.complimentary['accent-3'] 
       :  theme.colors.complimentary.base }
       const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
       const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
       const textColorStyles = {color:  theme.colors.complimentary.foreground}
       const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
       const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}

        
        const {displayDropDown,state,hideDropDown,props} = this 
        const {dropDownID,showDropDown,setYear,setMonth} = state 
        const currentList =  state[dropDownID]  
        

        console.log('THE CURRENT LIST')
        console.log(currentList)
        console.log(setYear)

        // const {search,auth,actions,isPosts,isScrolled,width,isBottom} = props

        return(

           

                
                <div  className="archive__filters">

                  
                        <section className="archive__filters--title">
                            Filters
                        </section>
                        <div className="archive__filters--collection">

                            
                          <button className="archive__filters--collection-all" onClick={()=>this.filterByTag('all')}>All</button>
                          <button className="archive__filters--collection-up" onClick={()=>this.filterByTag('update')}>Update</button>
                          <button className="archive__filters--collection-ca" onClick={()=>this.filterByTag('career')}>Career</button>
                            

                        </div>

                        <section className="archive__filters--date">

                            {/* <button className="archive__filters--date-btn" style={{padding: '20px'}} onClick={displayDropDown.bind(this,'recent')}>Years</button> */}
                            {/* {
                                showDropDown && dropDownID === 'recent' ? <Dropdown list={currentList} hideDropDown={hideDropDown.bind(this)} /> : null

                            }
                            <button className="archive__filters--date-btn" style={{padding: '20px'}}  onClick={displayDropDown.bind(this,'pages')}>Months</button> 
                            {
                                    showDropDown && dropDownID === 'pages' ? <Dropdown list={currentList} hideDropDown={hideDropDown.bind(this)} /> : null

                            } */}
                            <section className="archive__filters--date-i" style={specialStyling} onClick={displayDropDown.bind(this,'years')}>
                           
                                    <p className="hearx__products--filter-i-dropdown-i" style={textColorStyles} >{setYear}</p>
                                    {
                                showDropDown && dropDownID === 'years' ? <Dropdown list={currentList} hideDropDown={hideDropDown.bind(this)} /> : null

                            }
                                    
                                   
                            </section>

                                
                                    <section className="archive__filters--date-i" style={boxesStyling} onClick={displayDropDown.bind(this,'months')}>
                                    <p className="hearx__products--filter-i-dropdown-i" style={textColorStyles} >{setMonth}</p>
                                    {
                                    showDropDown && dropDownID === 'months' ? <Dropdown list={currentList} hideDropDown={hideDropDown.bind(this)} /> : null

                            }
                                   
                        </section>
                            {/* <div className="archive__filters--date-wrap">
                                <span className="archive__filters--date-icon">
                                    <Icon>
                                        <DateRangeIcon />
                                    </Icon>
                                </span>
                                <span className="archive__filters--date-text">Any date</span>
                            </div> */}
                        </section>

                        

                           
                 
                       

                  

                  
                </div>
               
                

           
        )
    }

}


export default withStyles(styles)(ArchiveFilters)