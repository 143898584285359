
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
// import Brand from './brand/brand'
// import Primary from './primary/primary'

import * as navigatorActions from '../ReduxFirstNavigator/actions'
import * as authActions from '../Auth/actions'
// import * as logoutActions from '../Logout/actions'
import { withRouter } from 'react-router-dom';

import Men from './components/Men/men'
import Brand from './components/Brand/brand'
import Primary from './components/Primary/primary' 
import ProgressBarCircular from '../ProgressBarCircular/component'
import { ThemeContext } from '../context'




class ChatHeader extends Component{


    constructor(props){

        super()
        // this.state = {

           
        //     width: 0,
        //     height: 0,
            
        // }
        // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
   static contextType = ThemeContext

       
  

    // updateWindowDimensions() {
    //     this.setState({ width: window.innerWidth, height: window.innerHeight });
    //   }

    // componentDidMount(){

    //     this.updateWindowDimensions();
    //     window.addEventListener('resize', this.updateWindowDimensions);
       
    // }

    // componentWillUnmount(){

    //     window.removeEventListener('resize', this.updateWindowDimensions);
      
    //  }


    // logoutUser(e){

    //     e.preventDefault()
    //     this.props.actions.logoutUser()

    // }



    render(){

        const {theme, themeName} = this.context
        const humanoidOpenStyle = {
          borderRadius: '5px', 
          backgroundColor: themeName === 'dark' 
          ? theme.colors.complimentary['accent-4']
          : theme.colors.complimentary.base
         }
        const textColorStyle = {color:theme.colors.complimentary.foreground }
        

        // const {actions} = this.props
        // const {userProfile} = this.props.state
        // const {width} = this.state
        // const {profile,isFetching,isProgressBar} = userProfile 

         const {props} = this
        const {chatHeader} = props 
        const {isTyping} = chatHeader
       

        return(

           

                
                

         

              <div className="header__chat">
                 
                 <section className="header__chat--avatar">
                   <figure className="header__chat--avatar-fig">
                       <img src="/img/jobbrilogoalt.png" width="60" />
                   </figure>
                   <div className="header__chat--avatar-state">
                       <span className="header__chat--avatar-state-name" style={textColorStyle}>Jobbri Search</span>
                        <span className="header__chat--avatar-state-status">
                            {
                             isTyping ? '...is typing' : 'Online'
                        }</span>
                   </div>
                 </section>
                 <section className="header__chat--name" style={textColorStyle}>
                   Jobbri
                 </section>

                 {/* <section className="header__chat-user">
                    <Primary actions={actions} profile={profile} width={width} />
                   
                 </section>
                 <strong className="clearfix"></strong> */}

              </div>
            
            
            
          
            
            
           
           
       


        )
    }




}

const mapStateToProps = (state)=>{

    const {userProfile,chatHeader} = state

    return{
       userProfile,chatHeader
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...authActions,...navigatorActions},dispatch)
    }
}

export default withRouter(connect(mapStateToProps,mapDispachToProps)(ChatHeader))