
import React from 'react';
import {BrowserRouter,Link} from 'react-router-dom'; 
import { useAppThemeContext } from '../../../context';



const Brand = (props)=>{
      
    const {theme, themeName} = useAppThemeContext()

    const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        // let appBrandCss =  props.path === '/jobs' && (props.width <= 1200 || props.isScrolled === true) ?  'app__brand app__brand--sticky' : 'app__brand'
        // let logo =   props.path === '/jobs' &&  (props.width <= 1200 || props.isScrolled === true) ? '/img/jobbrilogoalt.png' :  '/img/jobbrilogo.png'
        // let logoSize =  props.path === '/jobs' && (props.width <= 1200 || props.isScrolled === true)  ? 60 : 90

        let appBrandCss =  props.path === '/jobs' ?  'app__brand app__brand--sticky' : 'app__brand'
        let logo =   props.path === '/jobs' ? '/img/jobbrilogoalt.png' : themeName === "dark" ? '/img/jo_white.png' : '/img/jobbrilogo.png'
        let logoSize =  props.path === '/jobs'  ? 60 : 90
        
	

		return(


			<div className={appBrandCss} >

                <Link to='/' className="app__brand--name">

                <span className="fg-dark d-inline-block ">

                   <img src={logo} alt="jobbri logo" width={logoSize} />
                </span>
                {/* <span className="fg-dk-green mg-left-fd-bt d-inline-block font-fd-tn">
                    o
                </span> */}



                </Link>

                
			</div>

		)


	
	

}




export default Brand;





