import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import * as central from './store/store'
// import {ConnectedRouter} from 'connected-react-router'
import {Router,Route} from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './notify.css'
import './react-custom-scrollbar.css'
import {AppThemeContext} from './components/context/index'
import { ThemeProvider } from "styled-components";
import GlobalStyle from './global'



  // Call it once in your app. At the root of your app is the best place
toast.configure()


export default (App)=>{


     ReactDOM.render(
            <Provider store={central.store}>
                
                <PersistGate loading={null} persistor={central.persistor} >
                  <AppThemeContext>
                  
                    <Router history={central.history}>
                      
                      <Route component={App} />
                  
                    </Router>
                   
                  </AppThemeContext>
                  

                

                </PersistGate>
                    
                
            </Provider>, 
            document.getElementById('root')
        );

}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
