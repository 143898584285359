
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../Navigator/actions'
import * as authActions from '../Auth/actions'
import { withRouter } from 'react-router-dom';



import Tools from './components/tools/tools'
import SettingOffset from './components/settingoffset/settingoffset'
import Navigation from './components/navigation/navigation'
import { ThemeContext } from '../context'






class CandidateBar extends Component{


    constructor(props){

        super()
        this.state = {

           
            width: 0,
            height: 0,
            
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


       
  

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
      
     }

    logoutUser(){

        this.props.actions.logoutUser()
    }


    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        

        const {state} = this 
        const {width} = state

        return(

                 
                    width > 900 
                    ? <div className="dashboard__sidebar" style={boxesStyling} >

                    

                        <div>
                            
                            <section className="dashboard__sidebar--tools" style={boxesStyling}>
                            <Tools styles={{textColorStyles}} />
                            </section>

                            <section className="dashboard__sidebar--nav" >

                                <Navigation actions={this.props.actions}  />
                            </section>

                            <section className="dashboard__sidebar--settingoffset">
                                <SettingOffset logoutUser={this.logoutUser.bind(this)} />
                            </section>
                            
                            

                        </div>
                        
                

                    </div>

                :null
            
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        state:{
            ...state.router
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions,...authActions},dispatch)
    }
}

export default withRouter(connect(mapStateToProps,mapDispachToProps)(CandidateBar))