

import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import Modal from 'react-modal'; 
import { ThemeContext } from '../context';
 
const customStyles = {
  content : {
    top                   : 'auto',
    left                  : 'auto',
    right                 : '3%',
    bottom                : '50px',
    // marginRight           : '-50%',
    // transform             : 'translate(-0, -40%)',
    width                 : '600px',
    maxWidth              : '600px',
    padding               : 0,
    border                : 'none',
    background            : 'none',
  
    borderRadius          : '15px'
  }
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement')
 

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)'

class AlertModal extends Component{


  constructor(props){
    super(props)
    this.state ={
      modalIsOpen: false,
      showHumanoid: false
    }
  }
  static contextType = ThemeContext
  // const [modalIsOpen,setIsOpen] = React.useState(false);
  openModal(){
    this.setState({...this.state,modalIsOpen: true})
  }
 
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
 
  closeModal(){
    // console.log('THE VALUE OF THIS')
    // console.log(this)
    // console.log(this.setState)
    this.setState({...this.state,modalIsOpen: false})
  }

  componentDidMount(){

    setTimeout(()=>{

       this.setState({...this.state,showHumanoid: true})
    },10000)
  }
 
    render(){

    
    const {state,props,closeModal,openModal,afterOpenModal} = this 
    const {modalIsOpen,showHumanoid} = state 
    const {width} = props 
    // console.log('THE WIDTH IN ALERTS')
    // console.log(width)

    const mobileStyles = width > 900 ?  {width: `600px`} : width  > 540 && width <= 900 ?  {width: `400px`}  : width > 360 && width <= 540 ? {width: `360px`} : {width: `310px`}
    const allStyles = {content:{...customStyles.content,...mobileStyles}}
    // console.log(allStyles)

    // const {searchy} = search 
    const {theme, themeName} = this.context
    const humanoidOpenStyle = {
      borderRadius: '5px', 
      backgroundColor: themeName === 'dark' 
      ? theme.colors.complimentary['accent-4']
      : theme.colors.complimentary.base
     }
    const modalBackStyle = {backgroundColor:theme.colors.complimentary.base }
   

    return (
      <div>
          {!modalIsOpen && showHumanoid
            ?<div className="humanoid__open" onClick={openModal.bind(this)} style={humanoidOpenStyle}>

            

              <section className="humanoid__open-humanoid">

                <img src="/img/humanoid.jpg" className="humanoid__open-humanoid-grafik"  />
                <span className="humanoid__open-humanoid-text">Hi</span>
                              
              </section>


            </div>
            : null
          }

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal.bind(this)}
          style={allStyles}
          contentLabel="Example Modal"
        >

        

            <section className="alert__modal">

              <article className="alert__modalcontainer" style={modalBackStyle}>

                  <div className="alert__modalbody">
                  
                      {props.children} 

                  </div>
                  
              </article>

             
            </section>

        
 
         
          
        </Modal>
      </div>
    )
  
  }
}
 
export default AlertModal