
// import SubContent from './subcontent'


// {
//     content: "",
//     title: ""
// }


export default [

    {
      
      title: 'General',
      faqs: [

        {
            content: "To get started on our platform, you can start by signing up for an account to access all of the features that we have made for you. Alternatively you can start by going to our jobs page to start searching for new job opportunities.",
            title: 'How do I get started on Jobbri?',
            //isDangerously: true
          
        },
        {
            content: "Finding work on Jobbri is a simple process of: Going to jobs page, search for your desired job, and click apply or apply on website button to apply for your chosen job. Please note: because we partly source and aggregate our listings from third parties, applying for a job may redirect you to that third party's website.",
            title: 'How is it like to find work on Jobbri?',
        
        },
        {content: "Convenience matters to our users, and Jobbri has spent enough time on necessary resources to ensure convenience for its users. With our users in mind, the convenience of using Jobbri is a product of greater user experience which we will constantly work hard to improve everyday.",
         title: "How convenient is using Jobbri?"
        },
        {content: "You can apply for by clicking on an apply or apply on website button to apply for your chosen job. Please note: because we partly source and aggregate our listings from third parties, applying for a job may redirect you to that third party's website. ",
         title:"How do I apply for jobs on Jobbri?"},

         {content: "There's a couple of ways you can contact Jobbri. You can use our support email support@jobbri.co.za or head over to our contact page to complete a form and send it to us.",
         title:"How do I contact Jobbri?"},
         {content: "A Jobbri humanoid is a simple robot that has been implemented to help you update your profile quick, simple,  and timely. The Humanoid uses a text chat to help you set up and edit your profile easily. You can leave a chat and return to it later.",
         title:"What is a Jobbri Humanoid?"},
         {content: "You can find all sort of jobs on Jobbri  from Permanent, part-time, Freelance, etc. ",
         title:"What type of jobs are posted on Jobbri?"},


         {content: "Jobbri aggregates millions of jobs from partner sites across the web and list them on one place to simplify your job hunting tasks.",
         title:"Where do jobs from Jobbri come from?"},
         {content: "You can apply for by clicking on an apply or apply on website button to apply for your chosen job. Please note: because we partly source and aggregate our listings from third parties, applying for a job may redirect you to that third party's website. ",
         title:"Where do I get assistance for Jobs I have applied through Jobbri?"},
         {content: `You are always welcomed and encouraged to report any issue you encounter while using Jobbri. To report bugs, errors, or any other issue, you can use our support email at support@jobbri.co.za, or social media channels that include Facebook, Twitter, and Instagram. 
         `,
         title:"How can I report bugs or errors that I encounter on Jobbri?"},
         {content: "User feedback is especially important to us, to reach out to us about your experience, please use our support email at support@jobbri.co.za, or social media channels that are made up of Facebook, twitter, and Instagram.",
         title:"Where can I give my feedback about my experience using Jobbri?"},
        
        

      ]
    
    },
    {
        
        title: 'Account',
        faqs: [
  
          {
              content: `You can sign-up for a Jobbri account by using Jobbri Humanoid or going to our sign-up page and clicking on the "Signup" button, fill in your email & password on the resulting page, and finally clicking "Sign up" button on the form to finish the process.`,
              title: 'How do I signup for Jobbri account?',
              isDangerously: true
            
          },
          {
              content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
              title: 'How do I verify my account?',
              isDangerously: true
              
          },
          {
            content: "If your verification email has expired or is invalid for some reason, login to your account to resend the activation email. ",
            title: 'My activation email is invalid, what do I do?',
            isDangerously: true
          
        },
        {
            content: `To reset your password, please go to the settings page of your account profile and click "reset" my password button. Alternatively, if you are not logged in or have forgotten your password, go to the sign-up page, press Signin button to open the Signon screen. On the Signin screen, press "forgot password" button and follow the prompts on the resulting page.`,
            title: 'How do I reset my password',
            isDangerously: true
          
        },

        {
            content: `To update your profile, login to your account and go to settings to start updating your profile, alternatively you can got to "My CV" page to update your profile. `,
            title: 'How do I update my profile?',
            isDangerously: true
          
        },

        {
            content: `To delete or deactivate your account, go to settings and press Deactivate Account.  `,
            title: 'How do I delete my account?',
            isDangerously: true
          
        },

        {
            content: `Your deleted account will stay inactive for a year within our platform, after which it will be permanently deleted. `,
            title: 'What happens to my deleted account?',
            isDangerously: true
          
        },

        {
            content: "Jobbri use your information to match your profile to employers. We also use your information to help us improve our services to you. ",
            title: 'How does Jobbri use my information?',
            isDangerously: true
          
        },
        {
            content: `Jobbri limits your activities on the platform when you have signed up, but have not yet confirmed or verify your account through an email sent to you. To have full activity access, verify your account. If the verification has failed or is invalid, you should resend the activation.`,
            title: `I signedup, but my account activities are limited?`,
            isDangerously: true
        },
       
  
        ]
      
    },
    {
        
        title: 'Job Alerts',
        faqs: [
  
          {
              content: "Staying updated about currently available jobs will increase your chances of finding your dream job. To stay updated about new jobs, subscribe to Jobbri job alerts.",
              title: 'How can I staty updated about the latest jobs?',
              isDangerously: true
            
          },
          {
              content: "If you have a Jobbri account, you are allowed a maximum of 4 unique job alerts, otherwise you are only allowed one unique job alert.",
              title: 'How many job alerts are allowed on Jobbri?',
              isDangerously: true
              
          },
          {
            content: "Job alerts are notifications that are sent to you on a regular basis for anything that you have subscribed to receive information about.",
            title: 'What are job alerts?',
            isDangerously: true
            
        },
        {
            content: "Job alerts work by sending you notifications whenever jobs you have subscribed to become available. ",
            title: 'How do job alerts work?',
            isDangerously: true
            
        },
        {
            content: `You can subscribe to receive notifications for the latest jobs by going to the footer of pages on our platform or you can click the "Email me jobs" button on Jobbri's jobs page.`,
            title: 'How do I subscribe to Jobbri job alerts?',
            isDangerously: true
            
        },
        {
            content: "You can unsubscribe from Jobbri job alerts by clicking on the unsubscribe link on the bottom of every  alerts email sent to you ",
            title: 'How do I unsubscribe from Jobbri job alerts?',
            isDangerously: true
            
        },
        {
            content: "Jobbri only send you job alerts when you have subscribed and verified your subscription through the email sent to you. If you are not receiving job alerts, check to verify if you have confirmed your subscription.",
            title: `I have subscribed, but I'm not receiving alerts?`,
            isDangerously: true
            
        },
        // {
        //     content: "Jobbri only send you job alerts when you have subscribed and verified your subscription through the email sent to you. If you are not receiving job alerts, check to verify if you have confirmed your subscription.",
        //     title: `I have subscribed, but I'm not receiving alerts?`,
        //     isDangerously: true
            
        // }
  
        ]
      
    },
    {
        
        title: 'Managing Jobs',
        faqs: [
  
          {
              content: "To save jobs you would like to apply to later, just press the red heart button on the job you like. Jobs you favourite will expire as soon as the job is filled and no longer available.",
              title: 'How do I save my favorite Jobs?',
              isDangerously: true
            
          },
          {
              content: "Yes, Jobbri has an option that allows you to share your saved jobs with others via email.",
              title: 'Can I share my saved jobs?',
              isDangerously: true
              
          },
          {
            content: "Saving jobs is a great way to archive jobs that you wish to apply to, but do not have time to do it at a given point in time. One of the benefits of saving jobs is the ability to share them with your friends and family to show them what you desire career-wise to make them help you further. It's always good to show interest if you need help.",
            title: 'What are the benefits of saving jobs?',
            isDangerously: true
            
        },
        {
            content: "A search history is a list of all the searches that you have performed on  Jobbri over time. Search history facilitates your job searching task and allow you to save time later when you have to search for the same kind of job. ",
            title: 'What is a search history?',
            isDangerously: true
            
        },
        {
            content: "Your search history can be found on your Search history page located within your Dashboard page.",
            title: 'Where do I find my search history?',
            isDangerously: true
            
        }
        
  
        ]
      
    },
    {
        
        title: 'Resume/CV',
        faqs: [
  
          {
              content: "You have two options to create your cv on Jobbri. The first option is using Jobbri Humanoid, the Humanoid interacts with you through a chat where you are asked questions pertaining to you, and all you have to do is respond to them. The second option uses the traditional method where you have to go to your profile and fill forms to update information about you, and essentially creating your cv in the process. ",
              title: 'How do I create a CV on Jobbri?',
              isDangerously: true
            
          },
          {
              content: `
              <p>Creating your cv via Jobbri opens a lot of possibilities for you. 
              With your cv created on Jobbri, you can apply for jobs easily and employers will have a quicker access to your cv 
              that is presented through a good and consistent design that employers have grown used to.</p>
              <p>Not only will you have a quicker access to your cv, but you can also share with others without a need to send an actual copy of your cv every time.</p>`,
              title: `What's the benefit of creating a CV on jobbri?`,
              isDangerously: true
              
          },
          {
              content: `Yes, your Jobbri account gives you access to everything and every possible action you may wish to take. `,
              title: 'Can I edit my cv on Jobbri?',
              isDangerously: true,
          },
          {
            content: `You can access your Jobbri cv through your Jobbri account. To get to your profile page, follow the My Profile link on your dashboard. `,
            title: 'How do I access my Jobbri CV?',
            isDangerously: true,
        },
        {
            content: `Yes, your Jobbri cv is always downloadable.  `,
            title: 'Can I download my Jobbri CV?',
            isDangerously: true,
        },
        {
            content: `Yes, your Jobbri CV is always shareable as you wish. `,
            title: 'Is my cv shareable?',
            isDangerously: true,
        },
        {
            content: `Yes, Jobbri always allows you the possibility to upload your cv, just press Upload cv to proceed.  `,
            title: 'Can I upload my own cv?',
            isDangerously: true,
        },
        {
            content: `Other than changing the original file name of your cv, Jobbri does not change your uploaded cv.`,
            title: 'Does Jobbri modify my uploaded CV?',
            isDangerously: true,
        },
        
  
        ]
      
    },

    // {
    //     content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    //     title: 'How does Jobbri works?',
      
    // },
    // {
    //     content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    //     title: 'How does Jobbri works?',
      
    // },

    // {
    //     content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    //     title: 'How does Jobbri works?',
      
    // },
    // {
    // content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    // title: 'How does Jobbri works?',
    
    // },
    // {
    // content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    // title: 'How does Jobbri works?',
    
    // },
    // {
    // content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    // title: 'How does Jobbri works?',
    
    // },
    // {
    // content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    // title: 'How does Jobbri works?',
    
    // },
    // {
    // content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    // title: 'How does Jobbri works?',
    
    // },
    
   
]