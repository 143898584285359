import { yellow } from '@material-ui/core/colors'
import React, { Component } from 'react' 
import { removeNotifications } from 'reapop'
import { isActionSuccessful } from '../UserResume/props'






class DeleteSuccess extends Component{
    
    constructor(){

        super()

        
    }

    openLogin = ()=>{

        const {props} = this 
        const {actions} = props 
        const {push} = actions 
        push('/access')
    }

    componentDidMount(){

        const {props} = this 
        const {setTitle} = props 
        setTitle('Delete success ')

    }

   

    
    render(){


       
       const {props} = this 
       const {openUpload} = props

        return(

           
    
            <div className="upload-cv-delete">

                 <h2 className="upload-cv-delete-title">
                 <span className="upload-cv-delete-text">
                    You have successfuly deleted your cv.
                 </span> 
                
                </h2>
                <p className="upload-cv-delete-message">To improve your chances of lending your dream job, employers may require your CV. You can upload your CV on Jobbri anytime.</p>
                <button className="upload-cv-delete-submit" onClick={(e)=>openUpload()}>
                    <span className="upload-cv-delete-submit-rect"></span>
                    <span className="upload-cv-delete-submit-rect-2">
                        <small>Upload New Cv</small></span>
                </button>




            </div>

          
        )
    }


}


export default DeleteSuccess


