import React, { Component } from 'react'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import FolderIcon from '@material-ui/icons/Folder';
import { toast } from 'react-toastify';
import DragDrop from './dragdrop'
import { ThemeContext } from '../context';




const styles = {
    root: {
     
     
      fontSize: 20,
      color: 'seagreen'
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Upload extends Component {

    constructor(props) {

      super()
      this.state ={
        selectedFile: null,
        selectedFileUrl: null
       }

    }
    static contextType = ThemeContext
    onChangeHandler= event =>{

        var files = event.target.files

        // console.log('THE UPLOADED FILES ONCHANGE HANDLER')
        // console.log(files) 

        if(this.maxSelectFile(event) && this.checkMimeType(event) && this.checkFileSize(event)){ 
        // if return true allow to setState
           this.setState({
                selectedFile: files[0],
                selectedFileUrl: URL.createObjectURL(files[0])
            })
        }

    }

    onClickHandler = (e) => {

        e.preventDefault()
        if(!this.state.selectedFile) return this.notify({message: 'No valid file has been selcted for submission',type: 'error',className: 'notify-error'})
        const data = new FormData() 

        // console.log('THE FORM DATA')
        // console.log(data)
        // console.log(this.state.selectedFile[0])
        // for(var x = 0; x < this.state.selectedFile.length; x++){

        //     data.append('file', this.state.selectedFile[x])
        // }

       
        const {actions,userProfile,openUpload={}} = this.props
        const {saveUploadResume,replaceResume} = actions 
        const {profile} = userProfile
        const {personal} = profile 
        const {id} = personal
        const {resume_url,is_upload_resume} = personal 
        const wipe = !openUpload.type.trim() ? null : openUpload.type === 'all' ? this.getResumeWipe() : null

        if(wipe){


            resume_url.indexOf('//') >= 0 ? data.append('oldFile',resume_url.split('//').join('\\')) :  data.append('oldFile',resume_url)
            data.append('is_upload_resume',is_upload_resume)


            data.append('resume',JSON.stringify(wipe))
            data.append('file', this.state.selectedFile)
            data.append('action','replaceResume')
            data.append('ID',id)
            replaceResume(data)
        
        }else{

            if(is_upload_resume === 1 && resume_url){

                resume_url.indexOf('//') >= 0 ? data.append('oldFile',resume_url.split('//').join('\\')) :  data.append('oldFile',resume_url)
                data.append('is_upload_resume',is_upload_resume)
            }
        
      
            // console.log(this.props)
            // console.log(data.append)
            // console.log(data.append('test','THEFORMDATATEST'))
            data.append('file', this.state.selectedFile)
            data.append('action','saveUploadResume')
            data.append('ID',id)
            saveUploadResume(data)
        
        }


        

    }

    maxSelectFile=(event)=>{

        let files = event.target.files // create file object
            if (files.length > 1) { 
               const msg = 'Only 1 file can be uploaded at a time'
               event.target.value = null // discard selected file 
               this.notify({message: msg,type: 'error',className: 'notify-error'}) 
               console.log(msg)
              return false;
     
          }
        return true;
     
    }

    checkMimeType=(event)=>{
        //getting file object
        let files = event.target.files 
        //define message container
        let err = ''
        // list allow mime type
        const types = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']
        // loop access array
        for(var x = 0; x<files.length; x++) {
         // compare file type find doesn't matach
             if (types.every(type => files[x].type !== type)) {
             // create error message and assign to container   
             err += files[x].type+' is not a supported format\n';
           }
         };
      
       if (err !== '') { // if message not same old that mean has error 
            event.target.value = null // discard selected file
            this.notify({message: err,type: 'error',className: 'notify-error'}) 
            console.log(err)
             return false; 
        }
       return true;
      
    }

    checkFileSize=(event)=>{

        let files = event.target.files
        let size = 2048000
        let err = ""; 
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
            
            err += `${files[x].type} ${files[x].size} ${size} is too large, please pick a smaller file\n`;
        }
     };
     if (err !== '') {
        event.target.value = null 
        this.notify({message: err,type: 'error',className: 'notify-error'}) 

        console.log(err)
        return false
   }
   
   return true;
   
   }

   notify = (config) => {

       
    const {message} = config || ''
    const type = config.type || 'info'
    toast[type](message,
        {position: toast.POSITION.BOTTOM_LEFT,...config});

    }

    updateUploadState = (updateFile)=>{

      
               this.setState({
                    selectedFile: updateFile,
                    selectedFileUrl: URL.createObjectURL(updateFile)
                })
            
    

    }

    getResumeWipe = ()=>{

        return {

            skill: { shouldEmpty: true },
            language: { shouldEmpty: true },
            experience: { shouldEmpty: true },
            education: { shouldEmpty: true },
            portfolio: { shouldEmpty: true }
        }
            


    //     let resu = {}
    //     Object.entries(resume).forEach((k,i)=>{

    //         if(k[0].trim() !== 'personal' || k[0].trim() !== 'qualification') resu[k[0]] = {shouldEmpty: true}

    //     })

    //    console.log('THE BUILT UP DELETE RESUME')
    //    console.log(resu)

    //    return resu
    }

    componentDidMount(){

        const {props} = this 
        const {setTitle} = props 
        setTitle('Upload your cv ')

    }

    

  
    render() {

        const {state,props} = this 
        const {selectedFile} = state 
        const {userProfile} = props 
        const {isFetching=false,message='',isInitFetching=false,isInitMessage} = userProfile 
        let deleteErrorStyles = {color: 'white',display: 'inline-block',backgroundColor: 'seagreen',padding: '7px',cursor: 'pointer',textAlign: 'center',fontSize: '13px',borderRadius: '2px'}
        const theme = this.context.theme

        return (
            

                <div className="upload-cv">

                    <p className="upload-cv__formats">

                        <i className="fa fa-lg fa-file-pdf-o"></i>
                        <i className="fa fa-lg fa-file-word-o" aria-hidden="true"></i>
                        <i className="fa fa-lg fa-file-text" aria-hidden="true"></i>


                    </p>
                    <section className="upload-cv__folder">

                        <DragDrop updateUploadState={this.updateUploadState.bind(this)} >

                            <form>

                                    
                                    
                            
                            <label htmlFor="cv-upload" style={{display: 'block'}} type="file">

                                <span type="button" className="upload-cv__folder-browse" >Browse</span> 
                                <input style={{display: 'none'}}  id="cv-upload" type="file" name="file" onChange={this.onChangeHandler}/>
                            </label>
                           

                            </form>

                            
                        </DragDrop>

                        {
                            isFetching && isInitFetching === false
                            ? <section className="upload-cv__files">   
                                <span className="upload-cv__files-name">File is uploading...</span>
                              </section>
                            :message && isInitMessage === false
                                ? <section className="upload-cv__files" style={{display: 'inline-block',fontSize: '15px',marginTop: '5px',color: 'seagreen'}}>
                                        <small style={{display: 'block',color: 'red',marginBottom: '10px',marginTop: '15px',fontSize: '14px'}}>File has failed to upload</small>
                                        <span style={deleteErrorStyles} onClick={this.onClickHandler.bind(this)}>Let me try again</span>
                                  </section> 
                                :selectedFile 
                                    ? <section className="upload-cv__files">
                                            <span className="upload-cv__files-icon"> <i style={{fontSize: 'red'}} className="fa fa-lg fa-file-pdf-o"></i></span>
                                            <span className="upload-cv__files-name">{selectedFile.name}</span>
                                    </section>
                                    :  null 
                                
                        }

                        

                       

                    </section>
                    
                    {

                    isFetching && isInitFetching === false
                        ? <div className="upload-cv__submit">
                            <section className="huloader huloader-co">
                            <div className="huloader__loading huloader__loading-co">
                                <div></div>
                                <div></div>
                            </div>  
                           </section></div>
                        :<section className="upload-cv__submit">
                            <button className="upload-cv__submit-submit" onClick={this.onClickHandler.bind(this)} >
                                <span className="upload-cv__submit-submit-rect"></span>
                                <span className="upload-cv__submit-submit-rect-2">
                                    <small style={{color: theme.colors.complimentary.foreground}}>Upload Cv</small></span>
                            </button>
                         </section>
                    }

                   
                </div>
                
               
            
          )
    }
    

}

export default withStyles(styles)(Upload)