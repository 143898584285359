
import React, {Component} from 'react' 
import {BrowserRouter as Router,Link} from 'react-router-dom'
import { toast } from 'react-toastify';
import { ThemeContext } from '../../../context';


// import Jobs from './jobs/jobs'
// import Brand from './brand/brand'
// import Primary from './primary/primary'
// import Secondary from './secondary/secondary'



class Dashboard extends  Component{


    constructor(props){

        super(props)
    }

    viewPage(location){

        const {push} = this.props 

        push(location)

    }


    
   

    
    componentDidUpdate(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message='',isAlertDelete=false} = userProfile 
        const {removeNotification,removeMessage} = actions

      
        if(isActionSuccessful) {

            this.notify({message:'Job alert action successfully completed',type: 'success',className: 'notify-success'}) 
            removeNotification() 
            
        }
     
        
    }


    notify = (config) => {

       
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});
    }
   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        

        const {props,viewPage} = this
        const {stats,alertSubscriberID,userProfile,actions,auth} = props
        const {alertsCount,savedCount,searchCount} = stats 
        const CandJobAlerts = this.props.candJobAlerts 

        console.log('DASHBOARD AUTH')
        console.log(Dashboard)

        return(


               <div>

               
                    <section className="dashboard__candidatedash--i">

                        <div className="dashboard__candidatedash--i-box bx-raised-1 " style={boxesStyling}>

                            
                                <span className="dashboard__candidatedash--i-box-title">Alerts</span>
                                <span className="dashboard__candidatedash--i-box-count" style={textColorStyles}>{alertsCount}</span>
                                <p className="dashboard__candidatedash--i-box-actions">
                                    <button className="dashboard__candidatedash--i-box-actions-b1" onClick={(e)=>this.viewPage('/alerts')}>
                                        View alerts
                                    </button>
                                    <CandJobAlerts class="dashboard__candidatedash--i-box-actions-b2" componentProps={{viewPage:viewPage.bind(this)}} alertsCount={alertsCount} userProfile={userProfile} auth={auth} actions={actions} alertSubscriberID={alertSubscriberID}>
                                        
                                        <span className="dashboard__candidatedash--i-box-actions-b2-add">+</span><span style={textColorStyles}>add alerts</span>
                                       
                                    </CandJobAlerts>
                                   
                                </p>
                                
                        </div>


                    </section>


                    <section className="dashboard__candidatedash--i">

                        <div className="dashboard__candidatedash--i-box bx-raised-1 " style={boxesStyling} >

                            
                                <span className="dashboard__candidatedash--i-box-title">Saved jobs</span>
                                <span className="dashboard__candidatedash--i-box-count" style={textColorStyles}>{savedCount}</span>
                                <p className="dashboard__candidatedash--i-box-actions">
                                    <button className="dashboard__candidatedash--i-box-actions-b1" onClick={(e)=>this.viewPage('/saved')}>
                                        View saved
                                    </button>
                                    
                                </p>
                                
                        </div>


                    </section>


                    <section className="dashboard__candidatedash--i">

                        <div className="dashboard__candidatedash--i-box bx-raised-1 " style={boxesStyling}>

                            
                                <span className="dashboard__candidatedash--i-box-title">Search history</span>
                                <span className="dashboard__candidatedash--i-box-count" style={textColorStyles}>{searchCount}</span>
                                <p className="dashboard__candidatedash--i-box-actions">
                                    <button className="dashboard__candidatedash--i-box-actions-b1" onClick={(e)=>this.viewPage('/searched')}>
                                        View history
                                    </button>
                                    
                                </p>
                                
                        </div>


                    </section>

                    <strong className="clearfix" />

                </div>

             
             
           
        )
    }




}

export default Dashboard