
import React,{Component} from 'react'
import { ThemeContext } from '../../context'
import PasswordForm from '../passwordform/passwordform'
// import Reasons from './reasons'


class Hero extends Component{
    
    constructor(props){
        super()
    }



    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}




        const {props} = this 
        const {actions,forgotPassword} = props 

        return(

            <div className="forgotpassword__hero">

               <section className="forgotpassword__parallelogram bx-raised" style={boxesStyling}>

                  <div className="forgotpassword__parallelogram--skew">
                   <div className="forgotpassword__parallelogram--h">
                        <h2 style={textColorStyles}>Forgot Password</h2>
                        <small style={{...textColorStylesAccent,fontSize: "15px"}}>Get a password reset link to reset your password</small>
                        <span></span>
                    </div> 


                   <PasswordForm actions={actions} forgotPassword={forgotPassword} />
                   

                    

                    </div>


               </section>

               <section className="forgotpassword__parallelogram-2 bx-raised"></section>
               <section className="forgotpassword__parallelogram-3 bx-raised"></section>
            </div>
        )
    }

}


export default Hero