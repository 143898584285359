
import * as types from './types'
import * as props from './props'



export default (state = props, action)=>{

    switch (action.type) {

        case types.RESEND_ACCOUNT_VERIFICATION_REQUEST:

            return  {

                ...state,
                resentFailed: false,
                isFetching: true
                
            }

        case types.RESEND_ACCOUNT_VERIFICATION_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                resentFailed: false,
                isResent: true
              
               
                
            }

        case types.RESEND_ACCOUNT_VERIFICATION_FAILURE:

            return  {

                ...state,
                isFetching: false,
                isResent: false,
                resentFailed: true,
                message: action.error
                
            }

      
        default:
        return state
    }


}