
import * as types from './types'
import { CALL_API } from '../../middlwares/api'




  export const sendAlertSubscriber = (data,launcher='footer')=> {

    return {

        [CALL_API]: {
          endpoint: `/subscriber`,
          method: 'POST',
          request: 'URL',
          payload: {action: 'saveSubscriber',payload: {subscriber: {...data}}},
          authencitcated: false,
          launcher: launcher,
          types: [types.SEND_ALERT_SUBSCRIBER_REQUEST, types.SEND_ALERT_SUBSCRIBER_SUCCESS, types.SEND_ALERT_SUBSCRIBER_FAILURE]
        }
      }

  }

  export const unsetNotificationDisplay = ()=> {

    return {

        type: types.UNSET_NOTIFICATION_DISPLAY,
        payload: false
        
      }

  }

