
import React, {Component} from 'react'
import {Helmet} from 'react-helmet' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import ProgressBarCircular from '../ProgressBarCircular/component'
import Categories from './components/categories/categories'
import Empty from './components/categories/empty'

 



class CandJobAlertsCat extends Component{


    constructor(props){

        super(props)
        this.state ={update: false}
    }

    componentDidMount(){

        console.log('The component did mount and now we have props')
        

        // const {listing} = this.props
        // const {search} = listing
        // console.log('THE SEARCH INFORMATION FROM THE REDUX STATE')
        // console.log(search)
        const {props} = this 
        const {userProfile,actions} = props 
        const {push} = actions
        const {profile} = userProfile 
        const {personal} = profile 
        const {id,is_pending} = personal 

        if(is_pending === 1) return push('/account-verification')
        this.props.actions.fetchAlertCats(id)
       
        
    }

    
    render(){

         const {candJobAlertsCat,actions} = this.props
         const {alertCategories=[],isFetching=false} = candJobAlertsCat 

         console.log('THE ALERTCATEGORY')
         console.log(alertCategories)
         
         if(isFetching) return  <article className="dashboard__candsettings">
              <Helmet>
                    <title>Jobbri - Job Alerts</title>
                </Helmet>
             <ProgressBarCircular /></article>;


        // console.log('THE JOBS PROP')
        // console.log(listing)

        return(

            <article className="dashboard__candalertscat">
                 <Helmet>
                    <title>Jobbri - Job Alerts</title>
                </Helmet>
                
                <div>

                     {
                     alertCategories.length > 0
                       ?<Categories alertCats={alertCategories} push={actions.push} setAlertsCategory={actions.setAlertsCategory} />
                       : <Empty />
                     }

                </div>
               
             </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        candJobAlertsCat:{
            ...state.candJobAlertsCat
        },
        userProfile: {...state.userProfile}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...navigatorActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(CandJobAlertsCat)


