import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MailIcon from '@material-ui/icons/Mail';








import LeftFilter from './leftfilter/leftfilter'
import RightFilter from './rightfilter/rightfilter'
import JobList from './joblist/joblist'


const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Jobs extends Component{
    
    constructor(props){

        super()
        this.state ={
            scrolled: false,
            isBottom: false,
            width: 0,
            options: {
                root: null,
                rootMargin: '0px',
                threshold: 0.2
            },
            observer: null
        }
        this.debounce = this.debounce 
        // this.IOCallback = this.IOCallback.bind(this)

    }


    handleScroll = () => {

       
        const width = window.innerWidth 
        const {state} = this 
        const {isBottom} = state

        console.log('THE SCROLL IS FIRING')


        // if(width <= 1200){

        //     this.setState({...this.state,width: width},()=>{

        //         window.addEventListener('scroll',this.handleScroll)

        //     })
           
        // }else{


            const offset= window.scrollY; 
            const wrappedElement = document.getElementById('job-recommend');

            if (wrappedElement && this.isBottom(wrappedElement)) {
            
                this.setState({...this.state,scrolled: false,width: width,isBottom: true});
            }else{

                this.setState({...this.state,scrolled: false,width: width,isBottom: false});
            }
                
            //   document.removeEventListener('scroll', this.trackScrolling);
            //}else{

                // if(offset > 200 ){

                //     this.setState({...this.state,scrolled: true,width: width});
                // }
                    // else{
            
                    //     this.setState({...this.state,scrolled: false});
            
                    // }
            //}

        //}

        

        

       
    }

    // trackScrolling = () => {
    //     const wrappedElement = document.getElementById('header');
    //     if (this.isBottom(wrappedElement)) {
    //       console.log('header bottom reached');
    //       document.removeEventListener('scroll', this.trackScrolling);
    //     }
    //   };

      isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }

    componentDidMount(){

        this.handleScroll()
        window.addEventListener('scroll',()=>{

            this.debounce(this.handleScroll,300)
        })
    }
    // componentDidMount(){

    //     // this.handleScroll()
    //     // window.addEventListener('scroll',()=>{

    //     //     this.debounce(this.handleScroll,1000)
    //     // })

    //    const {state} = this 
    //    const {options} = state
    //     const observer = new IntersectionObserver(this.IOCallback,options) 
    //     const wrap = document.getElementById('job-recommend');

    //     this.setState({...this.state,observer: observer,wrappedElement: wrap},()=>{

    //         if (this.state.wrappedElement) {

    //             observer.observe(this.state.wrappedElement)
    //         }
    //     })
       

    // }


    // componentDidUpdate(){

    //     // this.handleScroll()
    //     // window.addEventListener('scroll',()=>{

    //     //     this.debounce(this.handleScroll,1000)
    //     // })

    //     const {state} = this 
    //     const {wrappedElement,observer} = state

    //     if (wrappedElement) {

    //         observer.observe(wrappedElement)
    //     }

    // }
    IOCallback = (entries)=>{

        const [entry] = entries 
        const {state} = this 
        const {wrappedElement} = state 

        if(wrappedElement){

            if(this.isBottom(wrappedElement)){
                this.setState({...this.state,isBottom: entry.isIntersecting})
            }

        }
        this.setState({...this.state,isBottom: entry.isIntersecting})

    }


    debounce (method,delay){

        console.log('the debounce runs')

        clearTimeout(method._tID)

        method._tID = setTimeout(function(){
            method()
        },delay)

    }

    componentWillUnmount(){
        window.removeEventListener('scroll',this.handleScroll) 

    }


    // componentWillUnmount(){
        

        
    //     const {state} = this 
    //     const {wrappedElement,observer} = state
    
    //     if (wrappedElement) {

    //         observer.unobserve(wrappedElement)
    //     }
    // }


    render(){

        const {listing,jobs,search,auth,updateSearch,push,fetchJobs,actions,isFetching,userProfile,upload,modal} = this.props 
        const {scrolled,width,isBottom} = this.state
        const {posts = [],categories=[],locations=[]} = jobs
        let filtersData = {categories: categories,locations: locations} 
        let isPosts = posts && posts.length >= 1 ? true : false
        console.log('JOBS IN JOBS COMPONENT')
        console.log(posts)

        return(

           <section className="job__jobs">

               <LeftFilter listing={listing} modal={modal}  actions={actions} search={search} auth={auth} isPosts={isPosts} userProfile={userProfile} isScrolled={scrolled} isBottom={isBottom} width={width} upload={upload} />
               <JobList posts={posts} search={search} isFetching={isFetching} actions={actions} userProfile={userProfile} auth={auth} updateSearch={updateSearch} push={push} fetchJobs={fetchJobs} isScrolled={scrolled} isBottom={isBottom} width={width} />
               <RightFilter filtersData={filtersData} search={search} actions={actions} isPosts={isPosts} isFetching={isFetching} isScrolled={scrolled} width={width} isBottom={isBottom} />
               
               <strong className="clearfix" />
             
           </section>
        )
    }

}


export default withStyles(styles)(Jobs)