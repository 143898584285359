
import React, {Component} from 'react' 
import { toast } from 'react-toastify';
import { ThemeContext } from '../../context';
import CuratedLanguageInfo from './culanguageinfo'

// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getPortfolio = (e,i)=>{


       

        return <CuratedLanguageInfo language={e} key={i} index={i} />
    
    }


 

               

   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        
         const {props} = this 
        //  const {bioText} = state
         const { language} = props 
        
        

        return(

                    

                        
                       
                        // <>{ language.map((e,i)=> this.getlanguages(e,i))}</>

                        <section className="resume__curated--card">


                        <div className="resume__curated--card-pointer"></div>
   
                        <div className="resume__curated--card-pointee">
   
                           <h5 className="resume__curated--card-pointee-period">Languages</h5>
                           <p className="resume__curated--card-pointee-legend">

                                <span style={textColorStyles}>Language</span>
                                <span  style={textColorStyles}>Speak</span>
                                <span  style={textColorStyles}>Write</span>
                                <span  style={textColorStyles}>Read</span>
                                <span  style={textColorStyles}>Actions</span>
                               
                            </p>
                            <ul className="resume__curated--card-pointee-list">

                            {language.map((p,i)=> this.getPortfolio(p,i))} 
                         

                            </ul>
                           
                    
   
                           </div>
                        </section>

           
             
             
           
        )
    }




}

export default Bio