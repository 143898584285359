
import React, {Component} from 'react' 
import { ThemeContext } from '../../context'





class ShareButton extends  Component{


    constructor(props){

        super()

      
    }

    
    
    
   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        

        
         const {props,state} = this 
        //  const {bioText} = state
        //  const {actions,isEdit,allowEdit} = props 
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        //  let contact = true
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    
                <span className="resume--action-btns resume--action-btns-offset ">

                        <span className="resume--action-btns-rect resume--action-btns-rect-2"></span>
                        <span className=" resume--action-btns-rect resume--action-btns-rect-3"></span>
                        <span className="resume--action-btns-rect resume--action-btns-rect-4" style={boxesStyling}>
                            <small className="resume--action-btns-rect-4-icon">
                            <i class="fa fa-share-alt" aria-hidden="true"></i>
                            </small>
                            <small style={textColorStylesAccent}>Share My CV</small>
                        </span>
                </span> 
             
           
        )
    }




}

export default ShareButton