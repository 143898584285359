
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import CuratedPortfolioInfo from './cuportinfo'
import CuratedPortfolioForm from './cuportform'
import { ThemeContext } from '../../context';
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()

    }




    getPortfolio = (e,i,isEditing,setIsEditing,allowEdit,isPortAdd)=>{

        const {props,removeItem} = this 
        const {actions,userProfile,width} = props 

        if(i+1 === isEditing){

            return <CuratedPortfolioForm width={width} actions={actions} userProfile={userProfile} portfolio={e} key={i} isEditing={isEditing} allowEdit={allowEdit} setIsEditing={setIsEditing} />
        }else{

            return <CuratedPortfolioInfo width={width} actions={actions} userProfile={userProfile} portfolio={e} key={i} isPortAdd={isPortAdd} removeItem={removeItem} setIsEditing={setIsEditing} isEditing={isEditing} allowEdit={allowEdit} index={i} />
        }
        
        

            
        

    
    }

    removeItem = (e,itemId)=>{
        
        e.preventDefault() 
        
       
    
        console.log('THE ID OF THE DELETING ITEM')
        console.log(itemId)
        
        const {props} = this
        const {actions,userProfile} = props 
        const {profile} = userProfile
        const {id} = profile
        const {deleteResumeItem} = actions
      
     
         deleteResumeItem({portfolio: {id:itemId}},id)
        
        // if(!formIsValid) return
        // if(!isCaptchaVerified) return  notify({message:'Recaptcha must be selected before signin',type: 'warning',className: 'notify-warn'})
        
        
        
    
    }
 

               

   

    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        
         const {props,state} = this 
        //  const {bioText} = state
         const {width, isPortAdd = false,portfolio,isEditing=0,allowEdit,setIsEditing,isPortfolioAdding,actions,userProfile} = props 
        
        //  const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')

        //if(isPortfolioAdd) return <CuratedPortfolioForm isAdding={true} /> 

        if(!isPortAdd && portfolio.length <= 0) return <>
            <section className="resume__curated--card" style={{marginBottom: `100px`}} id={'portfolio'}>

                <div className="resume--add-info">

                <div class="resume--add-info-rect resume--add-info-rect-1 bx-raised">
                </div>
                <div class="resume--add-info-rect resume--add-info-rect-2 bx-raised">
                </div>
                <div class="resume--add-info-rect resume--add-info-rect-3 bx-raised"></div>
                <div class="resume--add-info-rect resume--add-info-rect-4 bx-raised" onClick={isPortfolioAdding}>

                        <p>
                            
                            <small style={{fontSize: '20px',display: 'block',textAlign: 'center'}}>
                               <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                               </i>
                              
                            </small>
                            <small style={{fontSize: '15px',display: 'block',textAlign: 'center'}}>Add projects you have done in the past</small>
                        </p>
                </div>

                </div>


            </section>
            
        </>

        return(

                    

                        
                       
                        // <>{ portfolio.map((e,i)=> this.getPortfolios(e,i))}</>

                        <section className="resume__curated--card">

                        {!isPortAdd && portfolio.length < 5 && isEditing === 0 
                           ?   <small className="resume--edit-pen resume__about--bio-edit-pencil" onClick={isPortfolioAdding}>
                               <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                               </i>
                              
                               </small>
                           : null 
                           }
                        <div className="resume__curated--card-pointer"></div>
   
                        <div className="resume__curated--card-pointee">
   
                           <h5 className="resume__curated--card-pointee-period">Portfolio</h5>

                           {width > 1200 
                            ?<p className="resume__curated--card-pointee-legend">

                                   <span style={textColorStyles}>Name</span>
                                   <span style={textColorStyles}>Description</span>
                                   <span style={textColorStyles}>Role</span>
                                   <span style={textColorStyles}>Link</span>
                                   <span style={textColorStyles}>Actions</span>
                                
                                </p>
                                : null
                            }
                           
                            <ul className="resume__curated--card-pointee-list">

                            {portfolio.map((p,i)=> this.getPortfolio(p,i,isEditing,setIsEditing,allowEdit,isPortAdd))} 
                            {isPortAdd ? <CuratedPortfolioForm width={width} actions={actions} userProfile={userProfile} isPortAdd={isPortAdd} isAdding={isPortAdd} isEditing={isEditing} setIsEditing={setIsEditing} isPortfolioAdding={isPortfolioAdding} allowEdit={allowEdit} /> : null}

                            </ul>
                           
                           {/* <p className="resume__curated--card-pointee-legend">
                                   <span>Name</span>
                                   <span>Description</span>
                                   <span>Link</span>
                                   <span>Actions</span>
                               
                               </p>
   
                           <ul className="resume__curated--card-pointee-list">
   
                                   <li className="resume__curated--card-pointee-list-i">
                                       <span>Indym</span>
                                       <span>An independent Modeling agency</span>
                                       <span><a href="https://indym.herokuapp.com" target="_blank">View</a></span>
                                       <span>
                                           <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                       
                                   </li>
                                   <li className="resume__curated--card-pointee-list-i">
                                       <span>PHP</span>
                                       <span>An independent Modeling agency</span>
                                       <span><a href="https://indym.herokuapp.com">View</a></span>
                                       <span>
                                           <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                       
                                   </li>
                                   <li className="resume__curated--card-pointee-list-i">
                                       <span>Javascript</span>
                                       <span>An independent Modeling agency</span>
                                       <span><a href="https://indym.herokuapp.com">View</a></span>
                                       <span>
                                           <small><i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                               
                                           <small><i className="fa fa-lx fa-trash-o" style={{color: 'seagreen'}} aria-hidden="true"></i></small>
                                       </span>
                                       
                                   </li>
   
   
                               </ul> */}
   
                           </div>
                        </section>

           
             
             
           
        )
    }




}

export default Bio