import { askQuestion } from "./questionr"




// export const start = (self = null)=>{


//     const {props} = self 
//     const {humanoid,actions} = props 
//     const {currentQuestionr='start',questionas} = humanoid 
//     const {setCurrentQuestions} = actions
//     const questionr = questionas[currentQuestionr] 
//     const {questions} = questionr 
//     setCurrentQuestions(questions)
//     askQuestion(self,{multiple:true, question: questions})

// }

export const incomplete = (self = null)=>{


  const {props} = self 
  const {humanoid,actions} = props 
  const {questionas} = humanoid 
  const {setCurrentQuestions,setNextQuestionr} = actions
  const questionr = questionas['incomplete'] 
  const {questions} = questionr 
  setNextQuestionr('incomplete')
  setCurrentQuestions(questions)
  askQuestion(self,{multiple:true, question: questions})

}



// export const register = (self = null,ID)=>{


//   const {props} = self 
//   const {humanoid,actions} = props 
//   const {questionas} = humanoid 
//   const questionr = questionas[ID] 
//   const {setCurrentQuestions} = actions
//   const {questions} = questionr 
//   console.log('THE REGISTER INITIATOR') 
//   console.log(JSON.stringify(ID))
//   setCurrentQuestions(questions)
//   askQuestion(self,{question: questions[0]},0)

// }


export const initiate = (self = null,ID)=>{


  console.log('THE INITIATE QUESTIONR ID')
  console.log(ID)
  const {props} = self 
  const {humanoid,actions} = props 
  const {questionas} = humanoid 
  const questionr = questionas[ID] 
  const {multipleQuestionsAsk=false} = questionr
  console.log('THE QUESTIONR')
  console.log(JSON.stringify(questionr))
  const {setCurrentQuestions} = actions 
  
  const {questions} = questionr 
  const exclude = ['complete','incomplete','closeWithFailure','postActivity','closeWithFailureConfirm','erroredLocal','incompleteWithError','personalask',
  'profileask','editrecordcomplete','addrecordcomplete','deleterecordcomplete','erroredLocal','erroredRemote'] 
  console.log('INITIATE QUESTIONS')
  console.log(JSON.stringify(questions))
  setCurrentQuestions(questions) 
  if(!multipleQuestionsAsk){

    console.log('THE FIRST QUESTONS')
    console.log(questions[0])
    askQuestion(self,{question: filterInitiQuestionType(setCurrentQuestions,questions[0])},0)

  }else{

    askQuestion(self,{multiple:true, question: filterMultipleQuestions(questions)})

  }
  

}


const initQuestions = function(setCurrentQuestions,questionrr){


  const nestedQuestions = questionrr.questions 
  // const nextIdex = index + 1
  // const prevIdex = index - 1
  // if(prevIdex >= 0) unsetLaskAsk(currentQuestions[prevIdex])
  questionrr['isLevelLastAsk'] = true 
  questionrr['lastAskIndex'] = 0

  setCurrentQuestions(nestedQuestions)
  return nestedQuestions[0]

  // const nestedQuestions = currentQuestions[currentQuestion].questions 
  // currentQuestions[currentQuestionIndex]['isLevelLastAsk'] = true 
  // currentQuestions[currentQuestionIndex]['lastAskIndex'] = currentQuestionIndex + 1

  // setCurrentQuestions(nestedQuestions)
  // askQuestion(self,{question: nestedQuestions[0]},0)


}

const filterInitiQuestionType = function(setCurrentQuestions,question){

  if(question.questions){
    return initQuestions(setCurrentQuestions,question)
  }else{
    return question
  }

}


const filterMultipleQuestions = (questions)=>{

  console.log('MULTIPLE QUESTIONS FILTER;;;',JSON.stringify(questions))

  let questionsInContext = [...questions]
  let shouldSkipInitiateQIndex = -1
  // let questionToRender = []
  
  for(let question=0; question < questionsInContext.length; question++){
    if(questionsInContext[question].skipOnInitial) {

      shouldSkipInitiateQIndex = question
      break;
    }
  }


  if(shouldSkipInitiateQIndex){
    console.log('QUESTION IN CONTEXT;;;',questionsInContext)
    console.log(JSON.stringify(questionsInContext.slice(0,shouldSkipInitiateQIndex)))
    return questionsInContext.slice(0,shouldSkipInitiateQIndex)
  }
 
  return questions
  

}


    
 






