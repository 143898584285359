
import React, {Component} from 'react'
import {Helmet} from 'react-helmet'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as navigatorActions from '../ReduxFirstNavigator/actions' 
import * as blogrActions from '../Blogr/actions'


// import { fetchBlogPosts } from '../Blogr/actions'
import ProgressBarCircular from '../ProgressBarCircular/component'

import ArchiveFilters from './components/archivefilters/archivefilters'
import ArchivePosts from './components/archiveposts/archiveposts'
import ErrorHandler from '../ClientErrorHandler/component' 
import { colors } from '@material-ui/core'



class BlogPost extends Component{


    constructor(props){

        super(props)
       
        
    }

    componentDidMount(){

        const {props} = this 
        const {blogr,actions} = props 
        const {fetchBlogArchive} = actions
        const {archive} = blogr 
        
        if(archive.length === 0) fetchBlogArchive()

    }

    componentDidUpdate(){

        

        const {props} = this 
        const {blogr,actions} = props 
        const {removeMessage,removeNotification} = actions
        const {message='',isActionSuccessful=false} = blogr 

        // if(message.trim()) { 

        //     //this.notify({message: message,type: 'error',className: 'notify-error'});
        //     removeMessage() 
        // }

        if(isActionSuccessful) removeNotification()
       

        
        

    }

    componentWillUnmount(){

       
        const {props} = this 
        const {blogr,actions} = props 
        const {message=''}  = blogr 
        const {removeMessage} = actions
    

        if(message.trim()) removeMessage()
      
     }


    

    // notify = (config) => {

        
       
    //     const {message} = config || ''
    //     const type = config.type || 'info'
    //     toast[type](message,
    //         {position: toast.POSITION.BOTTOM_LEFT,...config});

       
        
    // }


    openBlogPage(path,e){

        e.preventDefault()

        const {props} = this 
        const {actions} = props 
        const {topic,url} = path
        const {push} = actions
        return push(`${url}${topic}`)
        // if(!this.findPostLocally(slug)) return fetchBlogPosts(slug)
    
        
    }

    fetchArchive(filter=null,e=null){

        if(e) e.preventDefault()

        const {props} = this 
        const {actions} = props 
        // const {slug,url} = path
        const {fetchBlogArchive,push} = actions
        // if(!slug.trim()) return push('/404')
        if(!filter) return fetchBlogArchive()
        fetchBlogArchive(filter)
        // return push(url)
        // if(!this.findPostLocally(slug)) return fetchBlogPosts(slug)
        

        

    }

    retryOnError = ()=>{

        const {props} = this 
        const {blogr,actions} = props 
        const {fetchBlogArchive} = actions
        const {archive} = blogr 
        fetchBlogArchive()


    }

    getErrorMeta = (color='warn')=>{

        let message,header,headerText  = ''
        
        if(color === 'warn'){

            message = 'We have unfortunately encountered an issue getting blog posts' 
            header = 'OH SNAP!'
            headerText = 'Archived posts could not load!'
           
        }else{

            message= 'We have unfortunately encountered an error processing a request'
            header = 'OOPS!'
            headerText = 'Something went wrong!'


        }
       
       

        return {

            header:header,
            headerText:headerText,
            errorText:message,
            buttonText: 'Please Try Again',
            actionColor: color,
            postErrorAction: this.retryOnError
        } 
    }





    // renderForm (form,e){

    //     e.preventDefault()
    //     this.setState({
    //         currentForm: form
    //     })
    // }

    // fetchArchive = (arciveFilter)=>{

       
    // }

    // loginUser = (user)=>{

    //     console.log('THIS  LOGIN COMPONENT')
    //     console.log(this)
    //     this.props.actions.loginUser(user)
    // }


    render(){


        const {props} = this 
        const {blogr} = props 
        const {isFetching=false,message='',archive} = blogr 
        
        
       

       
        if(isFetching === true) return  <article className="archive" style={{height: '100vh',width: '70%',paddingLeft: '17%',position: 'relative'}}>
            <Helmet>
                <title>Jobbri - Blog | Archive</title>
            </Helmet>   
        <ProgressBarCircular style={{width: '100%'}} /></article>
         if(message.trim()) return <ErrorHandler errorMeta={this.getErrorMeta('error')}  /> 
         if(archive.length === 0) return <ErrorHandler errorMeta={this.getErrorMeta('warn')}  /> 

        return(

            <article className="archive">
               
               <Helmet>
                    <title>Jobbri - Blog | Archive</title>
                </Helmet>
            
               <ArchiveFilters fetchArchive={this.fetchArchive.bind(this)} />
               <ArchivePosts blogr={blogr} fetchArchive={this.fetchArchive.bind(this)} openBlogPage={this.openBlogPage.bind(this)} />
               <strong className="clearfix" />
               
            </article>
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        
        blogr:{ ...state.blogr},
        router:{...state.router}
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...navigatorActions,...blogrActions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(BlogPost)