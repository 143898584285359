
import React, {Component} from 'react'
import Alerts from './alert'

 

class CandJobAlerts extends Component{


    constructor(props){

        super(props)
        this.state = {
            width: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
       
    }
    

    componentWillUnmount(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message=''} = userProfile 
        const {removeNotification,removeMessage} = actions


        if(isActionSuccessful) {
            removeNotification() 
            
        }
        
        if(message.trim()){
            removeMessage() 
            
        }

        window.removeEventListener('resize', this.updateWindowDimensions);

     
        
    }


    updateWindowDimensions() {
        this.setState({ width: window.innerWidth})
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

 

    
    render(){


        const {props,state} = this 
        const {userProfile,actions,auth,componentProps} = props 
        const {width} = state
      

        

        return(

           
            <Alerts class={this.props.class} width={width} componentProps={componentProps} userProfile={userProfile} actions={actions} auth={auth}> {this.props.children} </Alerts>
               
           
        )
    }




}



export default CandJobAlerts


