
import React, { Component } from 'react' 
import FormControl from './formcontrol.js'
import { toast } from 'react-toastify';

 class Form extends Component{
    
    
    constructor(props){
    	
    	 super() 


        const {userProfile,auth} = props
        const {appAuth={}} = auth
        const {isAuth=false} = appAuth
        const {profile={}} = userProfile
        const {personal={}} = profile 
        const {email=''} = personal
    	 
    	  this.state = {
    	  	
    	  	  form : {
                    
                    
                    alertMail:{
                        
                        control: 'input',
                        value: isAuth ? email : '',
                        validation: {
                            isRequiredFormat:{
                              
                                specialCharacters: false,
                                isEmail: true,
                                isRequired: true,
                                
                            }
                        
                             
                        },
                        isValid: isAuth ? true : false,
                        isTouched: false,
                        isSuggest: false,
                        validationMessage: '',
                        validationStyles: {
                            position: 'bottom'
                        },
                        config: {
                            
                            name: "alertMail",
                            type: 'email',
                            placeholder: 'Enter your email',
                            disabled: true
                            
                        }
                       
                        
                    },
                    
                
            },
            
        
    	  	
    	  }
    	
    } 
    
    
    
    updator = (upControl)=>{
        
        console.log('THE UPDATE CONTROL')
        console.log(upControl) 
        const {state} = this
        let newForm ={...state.form} 
        let updateControl = {...newForm[upControl.id]}
        updateControl.value = upControl.e.target.value 
       


        // if(updateControl.isActive === false) updateControl.isActive = true

    
       
            if(!(updateControl.validation.isPlaceholder && updateControl.value.trim() === '')){

            
                if(upControl.blur){
                
                    let validData = this.controlValidate(updateControl) 
                    
                    updateControl.isValid = validData[0] 
                    updateControl.validationMessage = validData[1] 
              
                    

                }

            }
        
    
    
        updateControl.touched = upControl.blur
        newForm[upControl.id] = updateControl
        
        this.setState({
            form: newForm
        
        })

    
        	
                
        
        


    } 

    controlValidate = (validateControl)=>{
        
        let validData = [true,''] 
        
        if(validateControl.validation.isRequired){ 
        
            let valid = validateControl.value.trim() !== ''
            let message = ''
            if(!valid){
                message = `${validateControl.config.name} is a required field `
            }
    
            validData = !valid ? [valid,message] : validData 
            return validData
            
        }
        if(validateControl.validation.isRequiredFormat){
            
            let value =  validateControl.value.trim() 
            let specialCharactersRegex = '[`!@#$%^&*()_+\-=\[\]]{};:"\\|<>\/?~]'
            let charsArray = Array.from(specialCharactersRegex)
                charsArray.push("'")
            let containsSpecialCars = charsArray.some((v)=>value.indexOf(v) > 0)
            let pieces = value.split(/\W+/) 
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            console.log('SPECIAL CHARACTER TEST')
            console.log(charsArray)
            console.log(containsSpecialCars)
            console.log(value)
            console.log(specialCharactersRegex)
            let isRequiredFormat =  validateControl.validation.isRequiredFormat 
            if(isRequiredFormat.specialCharacters && containsSpecialCars === true ) return [false,`special characters are not allowed as ${validateControl.config.name}`]
            if(value.length < isRequiredFormat.minLetters ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minLetters} letters long`]
            if(pieces.length < isRequiredFormat.minPieces ) return [false,`${validateControl.config.name} should be atleast ${isRequiredFormat.minPieces} words`] 
            if(pieces.length > isRequiredFormat.maxPieces ) return [false,`${validateControl.config.name} should be atmost ${isRequiredFormat.maxPieces} words`]
            if(isRequiredFormat.isEmail && emailRegex.test(value) !== true) return [false,`${validateControl.config.name} should be a valid email`]
        
                
        
        }

        
        return validData
        
    }



    submitForm = (e)=>{
                
        e.preventDefault() 

        
        
        const {actions,closeModal,search,userProfile,auth} = this.props
        const {sendUserAlertSubscriber,saveJoUserJobAlert} = actions 
        const {jq} = search 
        const {appAuth={}} = auth
        const {isAuth=false} = appAuth
        const {profile={}} = userProfile
        const {personal={}} = profile 
        const {id=0} = personal
        let submittData = {} 
        let formIsValid = true 
        let {form} = this.state 
        let {notify} = this
        
        
        
        for(let k in form){
            
            console.log('THE SUBMIT CONTROL')
            console.log(form[k])
            if(form[k].validation && form[k].validation.isRequiredFormat){

                console.log('THE CONTROL REQUIRES VALIDATION')

                if(form[k].isValid === false){

                    console.log('CONTROL:',k, ' is not valid')
                    formIsValid = false 
                    
                    
                    return notify({message:`${k} must provided before you can continue.`,type: 'warning',className: 'notify-warn'})
                    


                }else{
 
                    
                        submittData[k] = form[k].value

                   
                
                }

            }
        
        } 

        if(!formIsValid) return notify({message:'Filled form data is invalid',type: 'warn',className: 'notify-warn'})

        // {"ID":52,"save":{"email":"mashu@testmail.com","jobKeyword": "Web Designer"}}
        //const {alertMailID,jobKeyword,frequency='weekly'} = save
        if(isAuth) return saveJoUserJobAlert({ID:id, save: {job_keyword: jq}})
   
        submittData.name = this.extractNameFromEmail(submittData.alertMail)
        submittData.keyword = jq
        submittData.email = submittData.alertMail
        sendUserAlertSubscriber(submittData) 

    
        
        
        

    }

    notify = (config) => {

        
            console.log('THE TOAST CONFIG')
            console.log(config)
            const {message} = config || ''
            const type = config.type || 'info'
            toast[type](message,
                {position: toast.POSITION.BOTTOM_LEFT,...config});
        
    }


    extractNameFromEmail(email){

        return  email.split("@")[0]
    }

    componentDidUpdate(){

      
         const {props,notify} = this
         const {userProfile,actions,closeModal} = props
         const {isActionSuccessful=false,message='' } = userProfile 
         const {removeNotification,remvoeMessage} = actions

         if(isActionSuccessful){
            notify({message:'Job alert successfully saved',type: 'warn',className: 'notify-success'})
            closeModal()
            removeNotification()

         }else if(message.trim()){

            notify({message:'There was an error saving job alert',type: 'warn',className: 'notify-error'})
            remvoeMessage()
            closeModal()
         }
        
    }


   

        
   

   
   
   
  
    render(){ 
    
    //   const {props,state,hideSuggestScreen,showSuggestScreen,searchLocations } = this 
    //   const {suggestions,locations} = props 
    //   const {showSuggest,showSuggestId,searchedLocations} = state 
    //   let  newLocations = [...locations] 
    //   let refinedLocations = searchedLocations.length >= 1 ? searchedLocations : newLocations.splice(0,4)

    const {props}= this
    const {userProfile,alertsCount=0} = props 
    const {isFetching=false} = userProfile
      
    
    return(

      
            <section className="alert__modalbody--form">

                <form className="form" autocomplete="off">

                <p className="alert__modalbody--form-i">

                       <FormControl 

                                    styles={{child:'bx-raised alert__modalbody--form-text',error: ''}}
                                    id = 'alertMail'
                                    controlData={this.state.form.alertMail}
                                    change={(control)=>this.updator(control)} 
                                   
                                   
                          />
                        
                    
            

                    
                </p>

              
                <strong className="clearfix" />
               


                <div className="alert__modalbody--form-submit">


                    {
                    !isFetching
                        ?    alertsCount < 3 
                                ? <input onClick={(e)=>this.submitForm(e)} type="submit" className="cursor-pointer" value="Email me jobs"/>
                                : null 
                            
                    : <section className="huloader huloader-co">
                            <div className="huloader__loading huloader__loading-co">
                                <div></div>
                                <div></div>
                            </div>  
                        </section>
                    }


                        {/* <input onClick={(e)=>this.submitForm(e)} type="submit" className="cursor-pointer" value="Email me jobs"/> */}

                    </div>


            </form>
        
    </section>
        
    )
    
    }

}


export default Form