
import React, {Component} from 'react'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { toast } from 'react-toastify';
// import ProgressBarLinear from './progressbarlinear'
import { LocalBar } from '@material-ui/icons';
import { ThemeContext } from '../context';



const styles = {
    root: {
    
      fontSize: 30,
      cursor:"pointer"
      
    },
    dropDownList: {

        width: 500
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };






class ManageAlert extends  Component{


    constructor(props){

        super(props)
        // this.getInitialState = this.getInitialState
        // const that = this
        //manageAlert: that.getInitialState()
        // const {getInitialState} = this
        this.state = {frequency:'',anchorEl: null,isSnackBar: false,snackType: '',manageAlert: {},
        snackMessage:'',snackCloseState: false,test: false,localState: false,showProgress: false
        }
    }


    componentDidMount(){


        const {props}= this 
        const {userProfile,componentProps} = props 
        const {profile=null} = userProfile
        const {alerts} = profile
        const {id} = componentProps 
        const manageAl = alerts.find((al,i)=>al.id === id)
        this.setState({...this.state,manageAlert: manageAl})
    }
    

    componentDidUpdate(){

        const {props}= this 
        const {userProfile,actions} = props 
        const {isActionSuccessful=false,message=''} = userProfile 
        const {removeNotification,removeMessage} = actions

        console.log('THE USER PROFILE MESSAGE')
        console.log(message)

        if(isActionSuccessful) {

            this.notify({message:'Job alert frequency successfully updated',type: 'success',className: 'notify-success'}) 
            removeNotification() 
            
        }else if(message.trim()){

            this.notify({message: 'Job alert frequency could not be updated',type: 'error',className: 'notify-error'}) 
            removeMessage() 
            
        }

     
        
    }


   
    updateUserAlert(e){

        e.preventDefault()

        const {props,state}= this 
        const {userProfile,actions} = props 
        const {profile} = userProfile
        // const {id} = componentProps 

        const {sendUserAlertUpdateData} = actions
        const {frequency,manageAlert} = state 
        // console.log(manageAlert.frequency)
        // console.log(frequency)
        if(!frequency.trim()) return this.notify({message:'You must first choose an update option to update',type: 'warn',className: 'notify-warn'}) 
        if(manageAlert.frequency.toLowerCase() === frequency.toLowerCase()){
           
            console.log('CURRENT FREQUENCY SIMILAR TO SET')
           return this.notify({message:'Note: You should set a different option to update',type: 'warn',className: 'notify-warn'}) 
        }
        // const {manageAlert} = this.state
        const alertID = manageAlert.id
        const {personal} = profile
        const {id} = personal
        
        sendUserAlertUpdateData({ID:id,id:alertID,update: {frequency: frequency}})

    }

    setAlertUpdate(e){

        e.preventDefault()

        this.setState({...this.state,frequency:e.target.value},()=>{

            console.log('THE CURRENT STATE')
            console.log(this.state)
        })

        // const {setAlertUpdateData} = this.props
        // let update ={[key]:e.value}
        // setAlertUpdateData(update)


        
    }


    handleClick = e => {

        
        this.setState({...this.state,anchorEl:e.currentTarget})
      }
    
    handleClose = (v,e) => {

    // console.log('HANDLE CLICK,EITHER THROUGH MENU OR ITS CHILDREN:close')
    // console.log(v)
    // console.log(v)
    let frequency = this.state.frequency
    // v ? frequency = v : frequency = frequency 
    frequency =  typeof v === 'string' ? v : frequency
    this.setState({...this.state,anchorEl:null,frequency: frequency},()=>{
        // console.log('THE STATE.FREQUENCY')
        // console.log(this.state)
    })
    }
 
    notify = (config) => {

       
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});
    }

    
    
   
    closeEditor(){

        const {push} = this.props

        push('/alerts/list')


    }

   
    // static getDerivedStateFromProps(props,state){

    //     console.log('THE PROPS IN GETDERIVED METHOD')
    //     console.log(props)
    //     console.log(state)

    //     if(props.isProgressBar === true && state.showProgress === true){
    //         return {showNotification: false}
    //     }else if(props.isProgressBar === true && state.showProgress === false){
             
    //         return {showProgress: props.isProgressBar}
            
    //     }

        
    // }
   
    

    testProps(e){
        e.preventDefault()
        this.setState({...this.state,test: true})
    }

     
    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const btnBackground = { backgroundColor: theme.colors.complimentary['accent-3']}
       
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}


        const {props,state,updateUserAlert,closeEditor,handleClick,handleClose,testProps}= this 
        const {userProfile,classes} = props 
        const {isFetching=false,isActionSuccessful=false} = userProfile
        const {anchorEl,manageAlert} = state
        const frequency = state.frequency.trim() ? state.frequency : manageAlert.frequency ? manageAlert.frequency : ''
        const {job_keyword} = manageAlert
        

        console.log('MANAGE ALERT IN MANAGE ALERT')
        console.log(manageAlert)
        console.log('THE MANAGEALERT:RENDER')
        console.log(isActionSuccessful)
        console.log(frequency)

        

        return(


               <div>

                
                    <section className="dashboard__candalertmanage--i" style={{width: '100%'}}>

                        <div className="dashboard__candalertmanage--i-box bx-raised-1 ">

                                <span className="dashboard__candalertmanage--i-box-title" style={textColorStyles}>Manage Job Alert</span>
                                <small className="dashboard__candalertmanage--i-box-label" style={textColorStylesAccent}>{job_keyword}</small>
                     
             
                        </div>


                    </section>

                    
                   <section>

                    <h4 className="dashboard__candalertmanage__title" style={textColorStyles}>Frequency</h4>
                       <div className="dashboard__candalertmanage__dropdown"  >

                            <span className="dashboard__candalertmanage__dropdown-text" style={textColorStylesAccent}>{frequency}</span>
                            <span className="dashboard__candalertmanage__dropdown-icon" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                <Icon>
                                    <ArrowDropDownIcon className={classes.root} />
                                </Icon>
                            </span>
                        
                       </div>
                       
                        <div>
                        
                            {anchorEl && 

                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                className={classes.dropDownList}
                                PaperProps={{style:{...boxesStyling}}}

                            >
                                <MenuItem value="daily" onClick={handleClose.bind(this,'Daily')} style={{...textColorStyles}}>Daily</MenuItem>
                                <MenuItem value="weekly" onClick={handleClose.bind(this,'Weekly')} style={{...textColorStyles}}>Weekly</MenuItem>
                                <MenuItem value="bi-Monthly" onClick={handleClose.bind(this,'Bi-Monthly')} style={{...textColorStyles}}>Bi-monthly</MenuItem>
                                <MenuItem value="monthly" onClick={handleClose.bind(this,'Monthly')} style={{...textColorStyles}}>Monthly</MenuItem>
                            </Menu>}
                           
                        </div>

            
                    
                            
                        {

                        isFetching
                            ? <div className="upload-cv__submit">
                                <section className="huloader huloader-co">
                                <div className="huloader__loading huloader__loading-co">
                                    <div></div>
                                    <div></div>
                                </div>  
                            </section></div>
                            : <button className="dashboard__candalertmanage__actions-save" style={themeName == 'dark' ? {...btnBackground,...textColorStyles} :{}} onClick={updateUserAlert.bind(this)}>Save</button>
                        }

                     
                           
                           



                       {/* <div>
                           {
                              
                                isProgressBar === true && isFetching === true
                                    ?  <ProgressBarLinear />
                                    : null
                           }

                          
                       </div> */}
                       
                       {/* <p>
                           <button onClick={(e)=>this.notify({message:'Frequency has successully been updated',type: 'success',className: 'notify-success'})}>
                               DISPLAY SUCCESS
                            </button>
                            <button onClick={(e)=>this.notify({message:'Frequency has successully been updated',type: 'warn',className: 'notify-warn'})}>
                               DISPLAY WARN
                            </button>
                            <button onClick={(e)=>this.notify({message:'Frequency has successully been updated',type: 'error',className: 'notify-error'})}>
                               DISPLAY ERROR
                            </button>
                           {showNotification && <ToastNotify config={toasNotifcation} /> }
                       </p> */}
                   </section>




           
                </div>

             
             
           
        )
    }




}

export default withStyles(styles)(ManageAlert)