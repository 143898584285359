import React, { Component } from 'react' 
import Icon from '@material-ui/core/Icon';
import Search from '@material-ui/icons/Search';
import Trash from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles'




const styles = {
    root: {
     
        color:"red",
        opacity: .5,
        fontSize: 20,
        '&:hover':{
            color: "red",
            opacity: 1
        }
        
      },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Empty extends Component{

     
    
    constructor(props){

        super(props)

    }


    
    viewPage(location){

        const {push} = this.props 

        push(location)

    }


 
    // openLinkPage(url,e){

    //     // e.preveventDefault()

    //     console.log('THE URL VALUE')
    //     console.log(url)
    //     console.log(e) 
    //     if(!url) return 

    //     const {props} = this 

    //     //window.open(url)
        

    // }
   


    render(){

        // const {classes,job,viewPage} = this.props 
        // const {bookMarkJob,openLinkPage,props} = this
        // const {job,classes} =  props 
        // const {url} = job 

        const {props,viewPage} = this 
        const {userProfile,actions} = props
        const CandJobAlerts = props.candJobAlerts

        return(

                <section className="dashboard__savedjobs--empty bx-raised">

                    <p className="dashboard__savedjobs--empty-text">You currently have no saved job alerts</p>

                    <CandJobAlerts userProfile={userProfile} actions={actions}
                     componentProps={{
                         
                        viewPage:viewPage.bind(this),
                        styles:{backgroundColor:'transparent',border: 'none'}
                    }} 
                    >
                   
                            <p className="dashboard__action-btns">

                                <span className="dashboard__action-btns-rect dashboard__action-btns-rect-2"></span>
                                <span className=" dashboard__action-btns-rect dashboard__action-btns-rect-3"></span>
                                <span className="dashboard__action-btns-rect dashboard__action-btns-rect-4">
                                    <small className="dashboard__action-btns-rect-4-icon">
                                    <i class="fa fa-plus" aria-hidden="true"></i>

                                    </small>
                                    <small>Add Job Alerts</small>
                                </span>
                            </p>

                    </CandJobAlerts>
                    
                </section>
                
                
       


      
        )
    }

}


export default withStyles(styles)(Empty)