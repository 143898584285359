
import * as types from './types'
import * as props from './props'

export default (state = props, action)=>{

    switch (action.type) {


        case types.FETCH_BLOG_POSTS_REQUEST:

            return  {

                ...state,
                isFetching: true,
              
                
            }

        case types.FETCH_BLOG_POSTS_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                isActionSuccessful: true,
                posts: [...action.response.blog],
                totalPosts: action.response.totalPosts,
                message: ''
               
              
               
                
            } 

           
        case types.FETCH_BLOG_POSTS_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            }


        case types.FETCH_BLOG_POST_REQUEST:

            return  {

                ...state,
                isFetching: true,
               
                
            }

        case types.FETCH_BLOG_POST_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                post: {...action.response.post},
                related: [...action.response.related],
                message: '',
                
                
                
                
            }

        case types.FETCH_BLOG_POST_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            }


        case types.FETCH_BLOG_ARCHIVE_REQUEST:

            return  {

                ...state,
                isFetching: true,
                
                
            }

        case types.FETCH_BLOG_ARCHIVE_SUCCESS:

            
            return  {

                ...state,
                isFetching: false,
                archive: [...action.response.blog],
                totalPosts: action.response.totalPosts,
                message: ''
                
                
                
                
            }

        case types.FETCH_BLOG_ARCHIVE_FAILURE:

            return  {

                ...state,
                isFetching: false,
                message: action.error
                
            }
    
    
        
        case types.UNSET_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                isActionSuccessful: false,
                isFetching: false,
                
            
            }
        case types.UNSET_MESSAGE_NOTIFICATION_DISPLAY: 

            return {

                ...state,
                message: '',
                text: ''
            
            }

      
    
    
        default:
        return state
    }


}



