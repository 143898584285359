import React from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import FavoriteIcon from '@material-ui/icons/Favorite'; 
import CheckIcon from '@material-ui/icons/Check';
import NotificationSystem from 'reapop'; 
import SelectDropdown from '../select'



const styles = {
    root: {
     
      color:"#999",
      fontSize: 30,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };



class Location extends React.Component{

    constructor(props) {
        super()
    
        const {values} = props
        let initValue = values.location   
        
        
        this.state = {
          selectedOption: initValue
        }
      }
    
      setOption = (selectedOption) => {
        
        const {props} = this 
        const {handleChange} = props 

        this.setState({ selectedOption },()=>{
            handleChange({target: {name: 'location', value: selectedOption }})
        })
      }
    
    render() {

        const name = 'select'
        const {props} = this 
        const {nextStep,prevStep,handleChange,values,width} = props
        const selectedOption = this.state.selectedOption
        const options = {
         
            limpopo: 'Limpopo',
            gauteng: 'Gauteng',
            mpumalanga: 'Mpumalanga',
            kzn: 'KZN',
            wcape: 'Western cape',
            ecape: 'Eastern Cape',
            fstate: 'Free State'

          
        }
    
        return (
           

            <div className='questionnaire__i'>
        
                <h3  className="questionnaire__question">What's your most prefered place of work?</h3> 

                <section className="questionnaire__options">

                <SelectDropdown
                    name={name}
                    onChange={this.setOption}
                    options={options}
                    selectedOption={selectedOption}
                    width={width}
                />
                </section>

                <button className="questionnaire__next" onClick={prevStep}>Prev</button>
                <button className="questionnaire__next" onClick={nextStep}>Next</button>
            
            </div>
        )
    }

}


export default Location


  

//   submitCapturedData(e){

//     e.preventDefault() 

//     const {state,props} = this 
//     const {options} = state 
//     const {nextStep} = props 
//     let isSelected = false 
//     let theSelected = null

//     for(let i=0; i <= options.length; i++){

//         if(options[i].isSelected){

//             isSelected = true 
//             theSelected = options[i].value

//         }
//     }

//     if(!isSelected) return Notifica() 

//     nextStep({contractType: theSelected})

//     // console.log('THE CAPTURED DATA')
//     // console.log(e) 









//   }



//   render(){

//     const {props} = this 
//     const {nextStep,prevStep,handleChange,values} = props
   
   
//     return(


    //   <div className='questionnaire__i'>
        
    //     <h3  className="questionnaire__question">What's your most prefered place of work?</h3> 

    //     <section className="questionnaire__options">

    //       {this.renderOptions(values)}
    //     </section>

    //     <button className="questionnaire__next" onClick={prevStep}>Prev</button>
    //     <button className="questionnaire__next" onClick={nextStep}>Next</button>
       
    //   </div>


//     );
//   }
// }

