
import React, {Component} from 'react' 
import FormControl from '../formcontrol'
// import ProgressBarLinear from '../progressbarlinear'
import { toast } from 'react-toastify';
import ContactForm from './contactform'
import ContactInfo from './contactinfo'
// import ReCAPTCHA from "react-google-recaptcha"


class Bio extends  Component{


    constructor(props){

        super()
      
    }

    
    
    
   

    render(){

        
         const {props} = this 
         const {isEdit,allowEdit,contact = {},userProfile,actions} = props 
         const editContStyles = isEdit ? {boxShadow: '-15px 10px 25px 5px rgba(0, 0, 0, 0.04);'} : {boxShadow: 'none'}
         //let contact = true
        // const {push} = actions
        // const {message = ''} = auth 
        // console.log('tHE MESSAGE')
        // console.log(message)
        // console.log(message.trim() !== '')


        return(

                    <section className="resume__about--bio-edit" style={editContStyles}>

                        {!isEdit && contact
                        ?   <small className="resume--edit-pen resume__about--bio-contact-pencil " onClick={allowEdit}>
                            <i className="fa fa-lx fa-pencil-square-o" style={{color: 'seagreen'}} aria-hidden="true">
                            </i>
                            </small>
                        : null 
                        }


                        
                       {isEdit 
                       ? <ContactForm contact={contact} actions={actions} userProfile={userProfile} allowEdit={allowEdit} /> 
                       : contact 
                            ? <ContactInfo  allowEdit={allowEdit} contact={contact} actions={actions} userProfile={userProfile} /> 
                            : <p className="resume__about--bio-text" style={{cursor: 'pointer'}} onClick={allowEdit}>
                                <small style={{fontSize: '20px',display: 'block',textAlign: 'center'}}>
                                <i className="fa fa-lx fa-plus" style={{color: 'seagreen'}} aria-hidden="true">
                                </i>
                                
                                </small>
                                <small style={{fontSize: '15px',display: 'block',textAlign: 'center'}}>Add contact information for employers to reach you</small>
                            </p>
                        }
                        
             
                        
{/* 
                        { isEdit 
                            ?<div className="resume__about--bio-edit-sub-btn">

                                <button className="resume--edit-btn " onClick={(e)=>this.submitForm(e)}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Save</small></span>
                                </button>
                                <button className="resume--edit-btn " onClick={allowEdit}>
                                        <span className="resume--edit-btn-rect"></span>
                                        <span className="resume--edit-btn-rect-2">
                                            <small>Cancel</small></span>
                                </button>
                            </div>
                        : null
                        } */}
                        
                        
                            

                    </section>

                    
               

             
             
           
        )
    }




}

export default Bio