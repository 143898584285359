
import React,{Component} from 'react'
import Benefits from './benefits/benefits' 
import { ThemeContext } from '../../context'


class Employer extends Component{
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const textColorStyles = {color: theme.colors.complimentary.foreground}


        return(

            <div className="grid-row">

                
                <div  className="home__jobseeker--container">
                    <h2 className="home__jobseeker--title" style={textColorStyles}>Jobseeker benefits</h2>
                     <Benefits styles={boxesStyling} theme={theme} textStyles={textColorStyles} />
                  
                 
                </div>
               
                

            </div>
        )
    }

}


export default Employer