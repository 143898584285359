
import * as types from './types'
import { CALL_API } from '../../middlwares/api'




  // export const fetchDashStats = (search)=> {

  //   return {

  //       [CALL_API]: {
  //         endpoint: `/adash`,
  //         method: 'POST',
  //         request: 'URL',
  //         payload: {ID: search},
  //         authencitcated: false,
  //         types: [types.FETCH_DASH_STATS_REQUEST, types.FETCH_DASH_STATS_SUCCESS, types.FETCH_DASH_STATS_FAILURE]
  //       }
  //     }

  // }

  export const sendUserBuiltResume = (search)=> {

    return {

        [CALL_API]: {
          endpoint: `/resume`,
          method: 'POST',
          request: 'URL',
          payload: {ID: search},
          authencitcated: false,
          types: [types.SEND_USER_BUILT_RESUME_REQUEST, types.SEND_USER_BUILT_RESUME_SUCCESS, types.SEND_USER_BUILT_RESUME_FAILURE]
        }
      }

  }

  export const sendUserBuiltResumeFacet = (search)=> {

    return {

        [CALL_API]: {
          endpoint: `/resume`,
          method: 'POST',
          request: 'URL',
          payload: {ID: search},
          authencitcated: false,
          types: [types.SEND_USER_BUILT_RESUME_FACET_REQUEST, types.SEND_USER_BUILT_RESUME_FACET_SUCCESS, types.SEND_USER_BUILT_RESUME_FACET_FAILURE]
        }
      }

  }




  export const saveMessage = (message)=>{

    return{

       type: types.SAVE_CHAT_MESSAGE,
       payload: {message: [...message]}

    }
  }

  export const setNextQuestionr = (id)=>{

    return{

       type: types.SET_NEXT_QUESTIONR,
       payload: {questionrID: id}

    }
  }

  export const setCurrentQuestions = (questions)=>{

    return{

       type: types.SET_CURRENT_QUESTIONS,
       payload: {currentQuestions: questions}

    }
  }


  export const setAnswer = (answers)=>{

    return{

       type: types.SET_HUMANOID_ANSWER,
       payload: {answers}

    }
  }
  export const unsetAnswer = ()=>{

    return{

       type: types.UNSET_HUMANOID_ANSWER
      //  payload: {answers}

    }
  }


  export const setActiveActivity = (activity,index,activityContextQuestions,question)=>{


    return{

       type: types.SET_ACTIVE_ACTIVITY,
       payload: {activity,index,activityContextQuestions,question}

    }
  }


  export const saveChatText = (message,currentQuestion=null,currentQuestionIndex)=>{

    return{

       type: types.SAVE_CHAT_TEXT,
       payload: {message: [...message],currentQuestion,currentQuestionIndex}

    }
  }


  export const saveHostMessage = (message,host,hostCurrentState,hostCurrentStateSID,hostCurrentStateCQ)=>{

    console.log('THE CURRENT AND MESSAGE')

    return{

       type: types.SAVE_HOST_MESSAGE,
       payload: {message: [...message],host,hostCurrentState,hostCurrentStateSID,hostCurrentStateCQ}

    }
  }

  
  export const saveInitialMessage = (message,current,currentStepCQ,isNotReadyMessagesSet,isPausedMessagesSet)=>{

    console.log('THE CURRENT AND MESSAGE')
    console.log(message)
    console.log(current)

    return{

       type: types.SAVE_INITIAL_CHAT_MESSAGE,
       payload: {message: [...message],currentStep: current,
        currentStepCQ: currentStepCQ,isNotReadyMessagesSet,isPausedMessagesSet}

    }
  }

  export const setProcessor = (isProcessing,isProcessingText)=>{


    return{

       type: types.SET_PROCESSOR,
       payload: {isProcessing,isProcessingText}

    }
  }

  export const setCurrentProfileFeature = (feature='')=>{


    return{

       type: types.SET_CURRENT_PROFILE_FEATURE,
       payload: {feature}

    }
  }

  export const setCurrentProfileFeatureRecord = (record=null)=>{


    return{

       type: types.SET_CURRENT_PROFILE_FEATURE_RECORD,
       payload: {record}

    }
  }

  export const setFillerItem = (key='',value='')=>{


    return{

       type: types.SET_FILLER_ITEM,
       payload: {key,value}

    }
  }


  export const setUpdateType = (updateType='')=>{


    return{

       type: types.SET_UPDATE_TYPE,
       payload: {updateType}

    }
  }

  export const setHumanoidActionType = (humanoidActionType='')=>{


    return{

       type: types.SET_HUMANOID_ACTION_TYPE,
       payload: {humanoidActionType}

    }
  }




  export const updateProfileItems = (profileitems=null)=>{


    return{

       type: types.UPDATE_PROFILE_ITEMS,
       payload: {profileitems}

    }
  }




  export const closeHumanoidchat = ()=>{

    
    return{

       type: types.CLOSE_HUMANOID_CHAT,

    }
  }


  export const saveMessageWith = (message,currentStep,currentStepCQ,stepID,answers,questions)=>{


    return{

       type: types.SAVE_CHAT_MESSAGE_WITH,
       payload: {message: [...message],currentStep,currentStepCQ,stepID,answers,questions}

    }
  }

  export const saveWithNoMessage = (currentStep,currentStepCQ,stepID,answers,questions,canSend)=>{


    return{

       type: types.SAVE_WITH_NO_MESSAGE,
       payload: {currentStep,currentStepCQ,stepID,answers,questions,canSend}

    }
  }

  export const clearMessages = ()=>{

    return{

       type: types.CLEAR_CHAT_MESSAGES

    }
  }

  export const updateActiveStatus = (status = null)=>{


    return {
        type: types.CHANGE_ACTIVE_STATUS,
        payload: status
    }

}
export const setIsReturnUser = (status)=>{

  return{

     type: types.SET_IS_RETURN_USER,
     payload: status

  }
}

  export const setIsSetupComplete = ()=>{

    return{

       type: types.SET_IS_COMPLETE

    }
  }

  export const setCompleteDataCapture = ()=>{

    return{

       type: types.SET_DATA_CAPTURE_COMPLETE,
      //  payload: complete.isDataCaptureComplete

    }
  }

  export const setProfileDynamicQuestion = (questionas)=>{

    return{

       type: types.SET_PROFILE_DYNAMIC_QUESTION,
      payload: {questionas}

    }
  }

  export const saveDoAgainData = (doAgainData)=>{

    return{

       type: types.SAVE_DO_AGAIN_DATA,
      payload: doAgainData

    } 
  }

  export const setUnresolvedClearance = (isClearingUnresolved,isClearingUnresolvedText)=>{


    return{

       type: types.SET_UNRESOLVED_CLEARANCE,
       payload: {isClearingUnresolved,isClearingUnresolvedText}

    }
  }

  export const clearUnresolved = ()=>{


    return{

       type: types.CLEAR_UNRESOLVED,
      
    }
  }



  




  