
import React,{Component} from 'react'
import {Link} from 'react-router-dom'




class BlogRelated extends Component{
    
    
    constructor(props) {
        super(props);
        this.state = {
            months: [
                'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'
            ]
        }
      
    }

    getDate = (date)=>{

        const {state} = this 
        const {months} = state
        let setDate = new Date(date) 
        let getDate = setDate.getDate() 
        let formAtedDate = getDate < 10 ? `0${getDate}` : getDate

        return `${months[setDate.getMonth()]} ${formAtedDate}, ${setDate.getFullYear()}`

    }


    getRelated = (relatedPost,i,openPage)=>{

        const {slug,image,title,created_at,author} = relatedPost
        let url = `/blog/post/${slug}`


        return (

            <div className="post__related--post" onClick={(e)=>openPage({slug:slug,url:url},e)} style={{cursor: 'pointer'}} key={i}>

            <section className="post__related--post-card-rect">
                <span className="post__related--post-card-rect-info">{this.getDate(created_at)}</span>
            </section>
            <section className="post__related--post-card-rect post__related--post-card-rect-2" >

                <img src={image} className="post__related--post-pik" />
                

            </section>

            <section className="post__related--post-container">

                <h2 className="post__related--post-title">{title}</h2>
                <span className="post__related--post-author">By {author} Mashele</span>

            </section>
            
                                
            

        </div>

        )

    }

      
    
    render(){


        const {props} = this 
        const {posts,openPage} = props 
        const filteredPosts = posts.length > 3 ? posts.slice(0,3) : posts
       

        return(

           

                
                <div  className="post__related">


                        <section className="post__related--post-wrap">

                            <h2 className="post__related--title">Related posts</h2>

                            {filteredPosts.map((post,i)=>{

                                return this.getRelated(post,i,openPage)
                            })}

                            <strong className="clearfix" />

                           

                            {/* <div className="post__related--post">
                                
                                <Link to='/' className="link d-block">

                                    <img src="/img/users/250_2.jpg" className="post__related--post-pik" />
                                    <h2 className="post__related--post-title">Kick-start your career...</h2>
                                
                                </Link>


                            </div> */}


                        </section>
                        
                 

                </div>
               
                

           
        )
    }

}


export default BlogRelated