
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
// import Brand from './brand/brand'
// import Primary from './primary/primary'

import * as navigatorActions from '../ReduxFirstNavigator/actions'
import * as authActions from '../Auth/actions'
// import * as logoutActions from '../Logout/actions'
import { withRouter } from 'react-router-dom';

import Men from './components/Men/men'
import Brand from './components/Brand/brand'
import Primary from './components/Primary/primary' 
import ProgressBarCircular from '../ProgressBarCircular/component'
import { ThemeContext } from '../context'




class LoggedHeader extends Component{


    constructor(props){

        super()
        this.state = {

           
            width: 0,
            height: 0,
            
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


       
  

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
       
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
      
     }


    logoutUser(e){

        e.preventDefault()
        this.props.actions.logoutUser()

    }



    static contextType = ThemeContext
  
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}

        console.log('I AM THE LOGGEDINHEADER COMPONENT AND I RERENDER')
    

        const {actions,userProfile} = this.props
        // const {userProfile} = this.props.state
        const {width} = this.state
        const {profile,isFetching,isProgressBar} = userProfile 
        if(Object.keys(profile).length === 0) return  <article className="dashboard__candsettings"><ProgressBarCircular /></article>;

        return(

            <header className={`${this.props.location.pathname === '/' ? 'pd-bottom-fd-xxx-sm': 'header__offset'} header--fxd-logged`} style={boxesStylingA} >

                
                

            <section>
              <div>
                 
                 <section className="hr-size-fl-xx-sm float-left bg-black">
                   <Men path = {this.props.location.pathname} actions={actions} logoutUser={this.logoutUser} width={width} />
                 </section>
                 <section className="hr-size-fl-xx-tn float-left">
                    <Brand />
                 </section>

                 <section className="hr-size-fl-md float-left">
                    <Primary actions={actions} userProfile={userProfile} width={width} />
                   
                 </section>
                 <strong className="clearfix"></strong>

              </div>
            
            
            </section>
          
            
            
           
           
       

    </header>
        )
    }




}

const mapStateToProps = (state)=>{

    const {userProfile,router} = state

    return{
     
            router,
            userProfile

        
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions,...authActions,...navigatorActions},dispatch)
    }
}

export default withRouter(connect(mapStateToProps,mapDispachToProps)(LoggedHeader))