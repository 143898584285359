
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
// import * as errorActions from '../ErrorHandler/actions'
import Routes from '../Routes/component' 
import AppErrorBoundary from '../AppErrorBoundary/component'
import { GlobalStyle } from '../../global'
import { ThemeContext } from '../context/index'




class Root extends Component{


    constructor(props){

        super(props)
    }
    static contextType = ThemeContext



    render(){
        const theme = this.context.theme
        return(

            
            // <AppErrorBoundary> 
                  <>
                   <GlobalStyle theme={{background: theme.colors.complimentary.base, foreground: theme.colors.complimentary.foreground}} />
                    <Routes/>
                 </>
              
            // </AppErrorBoundary>

           
        )
    }




}

const mapStateToProps = (state)=>{

    return{
        state:{
            ...state.history
        }
    }
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(Root)