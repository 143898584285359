
import React,{Component} from 'react'
import {BrowserRouter as Router, Link} from 'react-router-dom'
import { ThemeContext } from '../../../context'

class blogHeader extends Component{
    
    
    static contextType = ThemeContext
    
    render(){

        const {theme, themeName} = this.context 
        const boxesStyling = { backgroundColor: themeName == 'dark' 
        ? theme.colors.complimentary['accent-3'] 
        :  theme.colors.complimentary.base }
        const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
        const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
        const textColorStyles = {color:  theme.colors.complimentary.foreground}
        const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        const specialStyling =  themeName === "dark" ? {backgroundColor: theme.colors.complimentary['accent-3']} : {}


        return(

           

                
                <div  className="blog__header--container">
                      
                    <section className="blog__header bx-shadow-dark" style={boxesStyling}>

                        <span className="blog__header--signup" style={textColorStyles}>Career guidance</span>
                        <span className="blog__header--vbar"></span>
                        <span className="blog__header--signin" style={textColorStyles}>Product update</span>

                    </section>

                  

                   
                </div>
               
                

           
        )
    }

}


export default blogHeader