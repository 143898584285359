// import {
//   BorderRadiusSizes,
//   BorderSizes,
//   BorderWidth,
//   ButtonSizes,
//   DeviceSize,
//   FontSizes,
//   FontStyle,
//   FontWeight,
//   IsIOSDevice,
//   OSPlatform,
//   Space,
// } from '../tokens';
const colors = {
  primary: {
    base: '#009999',
    'accent-1': '#ccffff',
    'accent-2': '#66ffff',
    'accent-3': '#00ffff',
    'accent-4': '#009999',
    'accent-5': '#003333',
    foreground: '#FFFFFF',
    inactive: {
      background: '#fbd0e2',
      foreground: '#9C9292',
    },
  },
  secondary: {
    base: '#cc3333',
    'accent-1': '#f5d6d6',
    'accent-2': '#e08585',
    'accent-3': '#cc3333',
    'accent-4': '#7a1f1f',
    'accent-5': '#290a0a',
    foreground: '#FFFFFF',
    inactive: {
      background: '#eae6e1',
      foreground: '#9C9696',
    },
  },
  tertiary: {
    base: '#ffcc66',
    'accent-1': '#ffeecc',
    'accent-2': '#ffcc66',
    'accent-3': '#ffaa00',
    'accent-4': '#996600',
    'accent-5': '#332200',
    foreground: '#000000',
    inactive: {
      background: '#023126',
      foreground: '#A89F9F',
    },
  },
  complimentary: {
    base: '#FFFFFF',
    'accent-1': '#e6e6e6',
    'accent-2': '#b3b3b3',
    'accent-3': '#b3b3b3',
    'accent-4': '#4d4d4d',
    'accent-5': '#1a1a1a',
    foreground: '#1a1a1a',
    inactive: {
      background: '#f6dad5',
      foreground: '#9F9797',
    },
  },
  status: {
    success: '#73C322',
    error: '#D4111B',
    warning: '#FEAB1B',
  },
  white: '#FFFFFF',
  black: '#333333',
  grey: '#D9DDDC',
};
const statusColors = {
  primary: {
    success: '#73C322',
    error: '#D4111B',
    warning: '#FEAB1B',
  },
};
const active = colors.primary;
const lightTheme = {
  name: 'light',
  active,
  background: active.base,
  colors,
  statusColors,
  //space: Space,
  unit: 'px',
  // fontSize: FontSizes,
  // radius: BorderRadiusSizes,
  // borderWidth: BorderWidth,
  // borderSize: BorderSizes,
  // fontStyle: FontStyle,
  // fontWeight: FontWeight,
  // deviceSize: DeviceSize,
  // isIOSDevice: IsIOSDevice,
  // platform: OSPlatform,
  // text: {
  //   color: {
  //     primary: active.foreground,
  //     secondary: active.foreground,
  //     tertiary: active.foreground,
  //     qtary: active.foreground,
  //   },
  //   size: FontSizes.small,
  // },
  // button: ButtonSizes,
};

export default lightTheme;
