
import React,{Component} from 'react'
import Reasons from './reasons'
import { toast } from 'react-toastify';


class Hero extends Component{
    

    constructor(){

        super()
        this.state ={
            reason: '',
            skipMessage: false
        }
    }

    componentDidUpdate(){


        const {props,state} = this
        const {unsubscribe,actions} = props 
        const {isActionSuccessful,message='',text=''} = unsubscribe
        const {skipMessage} = state
        
        const {push,unsetNotificationDisplay} = actions
        

        if(isActionSuccessful){


            console.log('THE ACTION IS SUCCESSFULL')
            console.log(isActionSuccessful)
            let redirect = text.trim() !== '' ? false : true
            let msg = text || `You have successfully unsubscribed from Jobbri job alerts, thank you.`
            

            this.notify({
                message: msg,
                type: 'success',
                className: 'notify-success'
            })
            unsetNotificationDisplay()
            if(redirect) return push(`/jobs?jq=all`)
    
                
                
    
                //push(`/jobs?jq=${keyword}`)
               
              
        }else if(message.trim() && !skipMessage){

            this.notify({
                message: `There was an error unsubscribing, please try again.`,
                type: 'warn',
                className: 'notify-warn'
            })
            unsetNotificationDisplay()
        }
             
    }
    
    saveReason = (reason)=>{

        console.log('THE SET REASON')
        console.log(reason)
        this.setState({...this.state,reason: reason,skipMessage: true})

    }

    send = (e)=>{

        e.preventDefault()

        console.log('THE SEND GOT A CALL')

        const {props,state} = this 
        const {actions,email} = props 
        const {reason} = state
        const {sendUserUnsubscription} = actions 

        this.setState({...this.state,skipMessage: false},()=>{

            sendUserUnsubscription({email,reason})
        })

        


    }

    redirect = (e)=>{

        e.preventDefault()

        const {props,state} = this 
        const {actions,name} = props 
        const {push} = actions 
        this.notify({
            message: `Thanks for reconsidering us ${name}, we appreciate the love.`,
            type: 'success',
            className: 'notify-success'
        })

        setTimeout(()=>{
               
            return push(`/jobs?jq=all`)

        },1000)

     


        

        


    }

    notify = (config) => {

            
        console.log('THE TOAST CONFIG')
        console.log(config)
        const {message} = config || ''
        const type = config.type || 'info'
        toast[type](message,
            {position: toast.POSITION.BOTTOM_LEFT,...config});

    }




    render(){

        const {props} = this
        const {unsubscribe} = props 
        const {isFetching} = unsubscribe

        return(

            <div className="unsubscribe__hero">

               <section className="unsubscribe__parallelogram bx-raised">

                  <div className="unsubscribe__parallelogram--skew">
                   {/* <div className="unsubscribe__parallelogram--h">
                        <h2>Jobbri is a job search engine launching soon.</h2>
                        <span></span>
                    </div>  */}

                    <figure className="unsubscribe__parallelogram--fig">
                        <img src="/img/sad.png" />
                    </figure>

                    <div className="unsubscribe__parallelogram--q">
                        <h2>Why are you leaving us?</h2>

                    </div>
                    <div className="unsubscribe__parallelogram--txt">
                        {/* <p>Reach out to Jobbri, a start-up job search engine whose goal is to help you find desired jobs or talent easily.</p>
                        <p>We are a local listing job search engine, and one of
                            our goals is to collect all the jobs and bring them to you in one place.
                        </p> */}
                        <Reasons saveReason={this.saveReason.bind(this)} />
                    </div>

                    <div className="unsubscribe__launchcta-btn-cont" >

                        {isFetching 
                            ? null
                            :<button className="unsubscribe__launchcta-submit unsubscribe__launchcta-submit-1" onClick={this.redirect.bind(this)}>
                                <span className="unsubscribe__launchcta-submit-rect"></span>
                                <span className="unsubscribe__launchcta-submit-rect-2">
                                    <small>Never mind, I'll just stay</small></span>
                            </button>}

                        {isFetching 
                            ?  <section className="huloader huloader-co">
                                    <div className="huloader__loading huloader__loading-co">
                                        <div></div>
                                        <div></div>
                                    </div>  
                            </section>
                            :<button className="unsubscribe__launchcta-submit" onClick={this.send.bind(this)}>
                                <span className="unsubscribe__launchcta-submit-rect"></span>
                                <span className="unsubscribe__launchcta-submit-rect-2">
                                    <small>Sorry, I'm leaving</small>
                                </span>
                            </button>}

                        
                    </div>

                    </div>


               </section>

               <section className="unsubscribe__parallelogram-2 bx-raised"></section>
               <section className="unsubscribe__parallelogram-3 bx-raised"></section>
            </div>
        )
    }

}


export default Hero