

 

export const activityProps = {



    start:{

        questions:[

          {
              text:[
                  `Hi there! a warm welcome to Jobbri, I hope you are doing well`,
                  `Hi there! Welcome to Jobbri, we are happy to have you here!`,
                  `Hi there! welcome to Jobbri, we are happy you have chosen us.`,
                  `Hi there! Welcome to Jobbri, ,We are excited to have you here`
              ],
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
              isHigherOrder: true
             
           
              
             
          },
        //   {
        //       text:'What would you like to do?',
        //       isTemplate: true,
        //       template_name: 'start',
        //       acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //       prefferedAnswers: ['Yes','Yes, I do'],
        //       wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
        //       isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
        //       isInCorrectOnce: false,
        //       isSet: false,
        //       isHostStateInitMessage:true,
        //       isNoAccept: true,
             
              
              
             
        //   },

         
          // {
          //     question: `What would you like to do?`,
          //     template: true,
          //     template_name: 'guest',
          //     acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
          //     prefferedAnswers: ['Yes','Yes, I do'],
          //     wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
          //     isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
          //     afterUnreadyResponse: "I can see that you have reconsidered chating further, please respond with 'Initiate'",
          //     afterUnreadyResponseOnce: "You still have not responded with the expected response, please respond with 'Initiate",
          //     endChatResponse: [
          //         "I understand it may be inconvenient for you to chat now, you can initiate a chat anytime you are ready by reloading this page",
          //         `I'm sorry to learn that you are not ready, you can initiate a chat anytime by reloading the page, or sending a text "Initiate"`,
          //         `Thanks for considering creating a cv with our platform, please comeback a initiate a chat when you are ready`
          //        ],
          //     isEndChatAlternate: `Alternatively, you can just "Initiate" now.`,
          //     isInCorrectOnce: false,
          //     isUnreadyResponseOnce: false,
          //     isHostStateLastQuestion: true,
          //     isSet: false,
             
          //     isHostStateLastQuestion: true,
          //     isChatEnd: true
          //     //id: 'skill',
          //     //isExpected: true

             
          // }
        ],
        skipOnReturner: true,
        //endAction: 'loadNext'

          
    },

    userAction:{

        questions:[

        
          {
              text:'What would you like to do?',
              isTemplate: true,
              template_name: 'start',
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
             
              
              
             
          },
        ],
        skipOnReturner: true,
        endAction: 'loadNext'

          
    },
    incomplete:{

        questions:[

          {
              text:[
                  `I have detected that you have an incomplete work from our last chat.`,
                 
              ],
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
             
           
              
             
          },
          {
            // text:[`Yes, I do`,`yeah, of course`],
            isTemplate: true,
            template_name: 'incompleteUserConfirmation',
            //skipOnInitial: true
           
           
            
            
          
          },
          {
              text:'...Would you like to continue from where we last left off?',
              isTemplate: true,
              template_name: 'incomplete',
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
              skipOnInitial: true
             
              
              
             
          },

         
        ],
        multipleQuestionsAsk: true,
        skipOnReturner: true,
        endAction: 'loadWithIncomplete'

          
    },
    incompleteWithError:{

        questions:[

          {
              text:[
                  `From our last chat, we had an issue sending your information`,
                 
              ],
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
             
           
              
             
          },
          {
            // text:[`Yes, I do`,`yeah, of course`],
            isTemplate: true,
            template_name: 'incompletePreviousConfirmation',
            //skipOnInitial: true
           
           
            
            
          
        },
          {
              text:'...Would you like to continue from where we last left off?',
              isTemplate: true,
              template_name: 'incompleteWithError',
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
              skipOnInitial: true
             
              
              
             
          },

         
        ],
        multipleQuestionsAsk: true,
        skipOnReturner: true,
        endAction: 'loadWithIncompleteError'

          
    },
    complete:{

        questions:[

          {
              text:[
                  `This chat has successfully been completed`,
                 
              ],
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
              isHigherOrder: true
             
           
              
             
          },
          {
              text:'...would you like to do anything else?',
              isTemplate: true,
              template_name: 'complete',
             
             
              
              
             
          },
         
        ],
        multipleQuestionsAsk: true,
        skipOnReturner: true,
        endAction: 'loadComplete'

          
    },

    erroredLocal:{

        questions:[

          {
              text:[
                  `We are having an issue processing your information, I think this is on us.`,
                 
              ],
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
              isHigherOrder: true
             
           
              
             
          },
          {
            // text:'...would you like us to try this again for you?',
            isTemplate: true,
            template_name: 'erroredNext',
           
           
            
            
          
          },
          {
              text:'We can try again...would you like us to try this again for you?',
              isTemplate: true,
              template_name: 'errored',
              skipOnInitial: true
             
             
              
              
            
          },
         
        ],
        multipleQuestionsAsk: true,
        endAction: 'closeWithError'

          
    },

    erroredRemote:{

        questions:[

          {
              text:[
                  `There was an error sending your information`,
                 
              ],
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
              isHigherOrder: true
             
           
              
             
          },
          {
              text:'...would you like to try again?',
              isTemplate: true,
              template_name: 'errored',
             
             
              
              
             
          },
         
        ],
        multipleQuestionsAsk: true,
        endAction: 'closeWithError'

          
    },


    returner:{

        questions:[

          {
              text:[
                  `Hi there! welcome back, I hope you are doing well`,
                  `Hi there! It's nice to have you back`,
                  `Welcome back!, Jobbri is happy to have you`,
                  `Hi, I hope you had fun while away, we are happy to have you back`
              ],
              acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
              prefferedAnswers: ['Yes','Yes, I do'],
              wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
              isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
              isInCorrectOnce: false,
              isSet: false,
              isHostStateInitMessage:true,
              isNoAccept: true,
              isHigherOrder: true
             
           
              
             
          }
         
        ],
        skipOnReturner: true,
      

          
    },

    

    register: {

        endAction: 'registerUser',
        questions: [

            // {
            //     text:[
            //         `Hi there! I'm about to register you on Jobbri`,
                    
            //     ],
            //     acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //     prefferedAnswers: ['Yes','Yes, I do'],
            //     wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
            //     isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
            //     isInCorrectOnce: false,
            //     isSet: false,
            //     isHostStateInitMessage:true,
            //     isNoAccept: true,
               
             
                
               
            // },
            // {
            //     text:[
            //         `Hi there! I'm THE SECOND QUESTION FROM THIS ACTIVITY`,
                    
            //     ],
            //     acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //     prefferedAnswers: ['Yes','Yes, I do'],
            //     wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
            //     isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
            //     isInCorrectOnce: false,
            //     isSet: false,
            //     isHostStateInitMessage:true,
            //     isNoAccept: true,
               
             
                
               
            // },
            {
                // text:[
                //     `Hi there! I'm THE QUESTION FROM THIS THING`,
                    
                // ],
                key: 'personal',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
                questions: [

                    {
                        chain: 'register.personal',
                        key: 'name',
                        text:[
                            `What's your name?`,
                            
                        ],
                    },
                    {
                        chain: 'register.personal',
                        key: 'traits',
                        questions:[
                            {
                                chain: 'register.personal.traits',
                                key: 'color',
                                text: [`What's your favorite color?`]
                            },
                            {
                                chain: 'register.personal.traits',
                                key: 'subjects',
                                text: [`Do you have any subjects?`],
                                expected: ['yes','no'],
                                expectedFormat: {isString: true},
                                prefferedAnswer: 'yes',
                                questions: [
                                    {
                                        chain: 'register.personal.traits.subjects',
                                        key: 'major',
                                        text: [`What's your major?`]
                                    },
                                    {
                                        chain: 'register.personal.traits.subjects',
                                        key: 'module',
                                        text: [`What's your favorite module?`]
                                    }
                                   
                                   
                                ]
                            }
                           
                            
                        ],
                    },
                    {
                        chain: 'register.personal',
                        key: 'surname',
                        text:[
                            `What's your surname?`,
                            
                        ],
                    },
                ]
               
             
                
               
            },
            // {
            //     text:[
            //         `I'm the next question after a chaining question.`,
                    
            //     ],
            //     acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //     prefferedAnswers: ['Yes','Yes, I do'],
            //     wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
            //     isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
            //     isInCorrectOnce: false,
            //     isSet: false,
            //     isHostStateInitMessage:true,
            //     isNoAccept: true,
               
             
                
               
            // },
            // {
            //     text:[
            //         `I'm the last, last question`,
                    
            //     ],
            //     acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //     prefferedAnswers: ['Yes','Yes, I do'],
            //     wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
            //     isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
            //     isInCorrectOnce: false,
            //     isSet: false,
            //     isHostStateInitMessage:true,
            //     isNoAccept: true,
               
             
                
               
            // },
            
            // {
            //     key: 'email',
            //     isComplete: false,
            //     queries: [
        
            //         {
            //             question: `What's your highest academic achievement?`,
            //             acceptedAnswers: ['Matric','Diploma','Degree','Certificate','Grade','Associate','Bachelor','Masters','Doctoral'],
            //             wrongAnswerResponse: 'Your response should be precise, and be either "Matric","Degree","Diploma","Certificate", or your highest passed grade',
            //             correctAnswerResponse: 'So far so good.',
            //             isInCorrectOnceResponse: 'Lol! Your option is still not correct, please provide your highest academic performance, such as "Grade 10" if that\'s your highest.',
            //             isInCorrectOnce: false,
            //             isSet: false,
            //             isExpected: true,
            //             id: 'email'
            //         }
        
        
                    
            //     ],
                
            // },
            
            // {
            //     key: 'skill',
            //     isComplete: false,
            //     queries: [
        
            //         {
            //             question: `Do you possess any skills?`,
            //             acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             prefferedAnswers: ['Yes','Yes, I do'],
            //             wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
            //             isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
            //             isInCorrectOnce: false,
            //             isSet: false,
                        
                        
                       
            //         },
            //         {
            //             // question: `Please  list your skills`,
            //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
            //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
            //             // isInCorrectOnce: false,
            //             isSet: false,
            //             isDictionary: true,
            //             isEndDictionary: false,
            //             isEndDictionaryQuestion: 'Would you like to add another skill?',
            //             isEndDictionaryQuestionAccepted: ['Yes','no'],
            //             isEndDictionaryQuestionPreffered: ['yes'],
            //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
            //             dictionaryQuestions: {
        
            //                 maxRotations: 3,
            //                 currentRotation: 0,
            //                 rotationAnswers: [],
            //                 answers: {},
            //                 currentQuestionIndex: 0,
            //                 questions:[
            //                 {
            //                     q:'Please type your skill name',
            //                     key: 'name',
            //                     rules: {isString: true, isMaxLen: 25},
            //                     isInCorrectResponseString: 'Your skill name should be a number',
            //                     isInCorrectResponseMax: 'Your skill name should be at most 25 characters'
            //                 },
            //                 {
            //                     q:'On a score of 1 to 100, with 100 being highly skilled for the provided skill, please type your scoree',
            //                     key: 'score',
            //                     rules: {isNumber:true},
            //                     isInCorrectResponseNum: 'Your skill score should be a number',
            //                     isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
            //                     isEndQ: true
            //                 }
            //                 ],
                            
                            
            //             },
            //             id: 'skills',
            //             isExpected: true
        
                       
            //         }
        
        
        
                    
            //     ]
            // },
            
        
        
        ]
    },
    profile: {


        questions:[

        
            {
                text:'Which part of your profile would you like to update?',
                isTemplate: true,
                template_name: 'profile',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
                
                
               
            },
        ],
        endAction: 'loadNext'
  

        // questions: [

           
        //     // {
        //     //     key: 'institute',
        //     //     isComplete: false,
        //     //     queries: [
        
        //     //         // {
        //     //         //     question: `Would you like to ?`,
        //     //         //     acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //         //     prefferedAnswers: ['Yes','Yes, I do'],
        //     //         //     wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
        //     //         //     isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
        //     //         //     isInCorrectOnce: false,
        //     //         //     isSet: false,
                        
                        
                       
        //     //         // },
        //     //         {
        //     //             // question: `Please  list your skills`,
        //     //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
        //     //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
        //     //             // isInCorrectOnce: false,
        //     //             isSet: false,
        //     //             isDictionary: true,
        //     //             isEndDictionary: false,
        //     //             isEndDictionaryQuestion: 'Would you like to add another institution?',
        //     //             isEndDictionaryQuestionAccepted: ['Yes','no'],
        //     //             isEndDictionaryQuestionPreffered: ['yes'],
        //     //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
        //     //             dictionaryQuestions: {
        
        //     //                 maxRotations: 2,
        //     //                 currentRotation: 0,
        //     //                 rotationAnswers: [],
        //     //                 answers: {},
        //     //                 currentQuestionIndex: 0,
        //     //                 questions:[
        //     //                 {
        //     //                     q:'Please type the institution where you have studied',
        //     //                     key: 'name',
        //     //                     rules: {isString: true, isMaxLen: 50},
        //     //                     isInCorrectResponseString: 'Your institution name should be a number',
        //     //                     isInCorrectResponseMax: 'Your institution name should be at most 50 characters'
        //     //                 },
        //     //                 {
        //     //                     q:'Which year have you begun your studies at the institution you have provided?',
        //     //                     key: 'from',
        //     //                     rules: {isNumber:true},
        //     //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
        
        //     //                 {
        //     //                     q:'Have you completed your studies at the Institution?',
        //     //                     key: 'status',
        //     //                     rules: {isRange:true},
        //     //                     range: ['yes','no'],
        //     //                     preffered: ['yes'],
        //     //                     isDependable: true,
        //     //                     dependableID: 'status',
        //     //                     shouldDependent: false,
        //     //                     isInCorrectResponseRange: `I'm expecting a Yes or No answer for the question about completion of your studies at the provided school.`,
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
                            
        //     //                 {
        //     //                     q:'Which year have you finished your studies at the institution you have provided?',
        //     //                     key: 'to',
        //     //                     rules: {isNumber:true},
        //     //                     isDependent: true,
        //     //                     dependentID: 'status',
        //     //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
        //     //                 {
        //     //                     q:'Please type the subject/modules that you majored in at the specified institution',
        //     //                     key: 'decription',
        //     //                     rules: {isString:true,isMaxLen: 100},
        //     //                     isInCorrectResponseString: 'Your majors should be of type text',
        //     //                     isInCorrectResponseMax: 'Your majors should be at most 50 characters long',
        //     //                     isEndQ: true
                                
        //     //                 },
        //     //                 ],
                            
                            
        //     //             },
        //     //             id: 'institutions',
        //     //             isExpected: true
        
                       
        //     //         },
        //     //         {
        //     //             // question: `Please  list your skills`,
        //     //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
        //     //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
        //     //             // isInCorrectOnce: false,
        //     //             isSet: false,
        //     //             isDictionary: true,
        //     //             isEndDictionary: false,
        //     //             isEndDictionaryQuestion: 'Would you like to add another experience?',
        //     //             isEndDictionaryQuestionAccepted: ['Yes','no'],
        //     //             isEndDictionaryQuestionPreffered: ['yes'],
        //     //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
        //     //             dictionaryQuestions: {
        
        //     //                 maxRotations: 2,
        //     //                 currentRotation: 0,
        //     //                 rotationAnswers: [],
        //     //                 answers: {},
        //     //                 currentQuestionIndex: 0,
        //     //                 questions:[
        //     //                 {
        //     //                     q:'Please type the company where you have worked',
        //     //                     key: 'name',
        //     //                     rules: {isString: true, isMaxLen: 50},
        //     //                     isInCorrectResponseString: 'The company name you have provided should not be a number',
        //     //                     isInCorrectResponseMax: 'The company name you have provided  should be at most 50 characters'
        //     //                 },
        //     //                 {
        //     //                     q:'What position did you hold at the company?',
        //     //                     key: 'position',
        //     //                     rules: {isString:true},
        //     //                     isInCorrectResponseString: 'Please note that the position name should not be a number',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
        //     //                 {
        //     //                     q:'Which year have you started working at the company you have provided?',
        //     //                     key: 'from',
        //     //                     rules: {isNumber:true},
        //     //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
                            
        //     //                 {
        //     //                     q:'Which year have you stopped working at the company you have provided?',
        //     //                     key: 'to',
        //     //                     rules: {isNumber:true},
        //     //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
        //     //                 {
        //     //                     q:'Please type a small description of what you used to do at the company',
        //     //                     key: 'decription',
        //     //                     rules: {isString:true,isMaxLen: 100},
        //     //                     isInCorrectResponseString: 'Your majors should be of type text',
        //     //                     isInCorrectResponseMax: 'Your majors should be at most 50 characters long',
        //     //                     isEndQ: true
                                
        //     //                 },
        //     //                 ],
                            
                            
        //     //             },
        //     //             id: 'experiences',
        //     //             isExpected: true
        
                       
        //     //         }
        
        
        
                    
        //     //     ]
        //     // },
        //     // {
        //     //     key: 'institute',
        //     //     isComplete: false,
        //     //     queries: [
        
        //     //         {
        //     //             question: `What's the name of the institution where you obtained your qualification?`,
        //     //             // acceptedAnswers: ['Matric','Diploma','Degree','Certificate','Grade','Associate','Bachelor','Masters','Doctoral'],
        //     //             // wrongAnswerResponse: 'Your response should be precise, and be either "Matric","Degree","Diploma","Certificate", or your highest passed grade',
        //     //             // correctAnswerResponse: 'So far so good.',
        //     //             // isInCorrectOnceResponse: 'Lol! Your option is still not correct, please provide your highest academic performance, such as "Grade 10" if that\'s your highest.',
        //     //             // isInCorrectOnce: false,
        //     //             isSet: false,
        //     //             isExpected: true,
        //     //             id: 'name'
        //     //         }
        
        
                    
        //     //     ],
                
        //     // },
        //     {
        //         key: 'skill',
        //         isComplete: false,
        //         queries: [
        
        //             {
        //                 question: `Do you possess any skills?`,
        //                 acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //                 prefferedAnswers: ['Yes','Yes, I do'],
        //                 wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
        //                 isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
        //                 isInCorrectOnce: false,
        //                 isSet: false,
                        
                        
                       
        //             },
        //             {
        //                 // question: `Please  list your skills`,
        //                 // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //                 // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
        //                 // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
        //                 // isInCorrectOnce: false,
        //                 isSet: false,
        //                 isDictionary: true,
        //                 isEndDictionary: false,
        //                 isEndDictionaryQuestion: 'Would you like to add another skill?',
        //                 isEndDictionaryQuestionAccepted: ['Yes','no'],
        //                 isEndDictionaryQuestionPreffered: ['yes'],
        //                 isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
        //                 dictionaryQuestions: {
        
        //                     maxRotations: 3,
        //                     currentRotation: 0,
        //                     rotationAnswers: [],
        //                     answers: {},
        //                     currentQuestionIndex: 0,
        //                     questions:[
        //                     {
        //                         q:'Please type your skill name',
        //                         key: 'name',
        //                         rules: {isString: true, isMaxLen: 25},
        //                         isInCorrectResponseString: 'Your skill name should be a number',
        //                         isInCorrectResponseMax: 'Your skill name should be at most 25 characters'
        //                     },
        //                     {
        //                         q:'On a score of 1 to 100, with 100 being highly skilled for the provided skill, please type your scoree',
        //                         key: 'score',
        //                         rules: {isNumber:true},
        //                         isInCorrectResponseNum: 'Your skill score should be a number',
        //                         isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
        //                         isEndQ: true
        //                     }
        //                     ],
                            
                            
        //                 },
        //                 id: 'skills',
        //                 isExpected: true
        
                       
        //             }
        
        
        
                    
        //         ]
        //     },
        //     // {
        //     //     key: 'language',
        //     //     isComplete: false,
        //     //     queries: [
        
        //     //         {
        //     //             question: `Would you like to add some of the lanugaues you speak?`,
        //     //             acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //             prefferedAnswers: ['Yes','Yes, I do'],
        //     //             wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
        //     //             isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
        //     //             isInCorrectOnce: false,
        //     //             isSet: false,
                        
                        
                       
        //     //         },
        //     //         {
        //     //             // question: `Please  list your skills`,
        //     //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
        //     //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
        //     //             // isInCorrectOnce: false,
        //     //             isSet: false,
        //     //             isDictionary: true,
        //     //             isEndDictionary: false,
        //     //             isEndDictionaryQuestion: 'Would you like to add another langauge?',
        //     //             isEndDictionaryQuestionAccepted: ['Yes','no'],
        //     //             isEndDictionaryQuestionPreffered: ['yes'],
        //     //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
        //     //             dictionaryQuestions: {
        
        //     //                 maxRotations: 3,
        //     //                 currentRotation: 0,
        //     //                 rotationAnswers: [],
        //     //                 answers: {},
        //     //                 currentQuestionIndex: 0,
        //     //                 questions:[
        //     //                 {
        //     //                     q:'Please type your language',
        //     //                     key: 'language',
        //     //                     rules: {isString: true, isMaxLen: 25},
        //     //                     isInCorrectResponseString: 'Your language name should be a text',
        //     //                     isInCorrectResponseMax: 'Your skill name should be at most 25 characters'
        //     //                 },
        //     //                 {
        //     //                     q:`On a rating between "poorly","good","excellently", What's your level of speaking for this language?`,
        //     //                     key: 'speak',
        //     //                     rules: {isRange: true},
        //     //                     range: ['Poorly','Good','Excellently'],
        //     //                     isInCorrectResponseRange: 'Your chosen language speaking level should fall between "poorly","good","excellently',
                               
                               
        //     //                 },
        //     //                 {
        //     //                     q:`On a rating between "poorly","good","excellently", What's your level of writing for this language?`,
        //     //                     key: 'write',
        //     //                     rules: {isRange: true},
        //     //                     range: ['Poorly','Good','Excellently'],
        //     //                     isInCorrectResponseRange: 'Your chosen language writing level should fall between "poorly","good","excellently',
                               
                               
        //     //                 },
        //     //                 {
        //     //                     q:`On a rating between "poorly","good","excellently", What's your level of reading for this language?`,
        //     //                     key: 'read',
        //     //                     rules: {isRange: true},
        //     //                     range: ['Poorly','Good','Excellently'],
        //     //                     isInCorrectResponseRange: 'Your chosen language reading level should fall between "poorly","good","excellently',
        //     //                     isEndQ: true
                               
                               
        //     //                 }
        //     //                 ],
                            
                            
        //     //             },
        //     //             id: 'languages',
        //     //             isExpected: true,
        //     //             isChatLastStepQuestion: true
        
                       
        //     //         }
        
        
        
                    
        //     //     ]
        //     // },
            
        
        
        
        // ]
    },
    personal: {


        questions:[

        
            {
                text:[
                    `What's your name?`,
                    
                ],
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
             
                
               
            },
        ],
        endAction: 'updateUserProfileSegment'
  

        // questions: [

           
        //     // {
        //     //     key: 'institute',
        //     //     isComplete: false,
        //     //     queries: [
        
        //     //         // {
        //     //         //     question: `Would you like to ?`,
        //     //         //     acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //         //     prefferedAnswers: ['Yes','Yes, I do'],
        //     //         //     wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
        //     //         //     isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
        //     //         //     isInCorrectOnce: false,
        //     //         //     isSet: false,
                        
                        
                       
        //     //         // },
        //     //         {
        //     //             // question: `Please  list your skills`,
        //     //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
        //     //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
        //     //             // isInCorrectOnce: false,
        //     //             isSet: false,
        //     //             isDictionary: true,
        //     //             isEndDictionary: false,
        //     //             isEndDictionaryQuestion: 'Would you like to add another institution?',
        //     //             isEndDictionaryQuestionAccepted: ['Yes','no'],
        //     //             isEndDictionaryQuestionPreffered: ['yes'],
        //     //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
        //     //             dictionaryQuestions: {
        
        //     //                 maxRotations: 2,
        //     //                 currentRotation: 0,
        //     //                 rotationAnswers: [],
        //     //                 answers: {},
        //     //                 currentQuestionIndex: 0,
        //     //                 questions:[
        //     //                 {
        //     //                     q:'Please type the institution where you have studied',
        //     //                     key: 'name',
        //     //                     rules: {isString: true, isMaxLen: 50},
        //     //                     isInCorrectResponseString: 'Your institution name should be a number',
        //     //                     isInCorrectResponseMax: 'Your institution name should be at most 50 characters'
        //     //                 },
        //     //                 {
        //     //                     q:'Which year have you begun your studies at the institution you have provided?',
        //     //                     key: 'from',
        //     //                     rules: {isNumber:true},
        //     //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
        
        //     //                 {
        //     //                     q:'Have you completed your studies at the Institution?',
        //     //                     key: 'status',
        //     //                     rules: {isRange:true},
        //     //                     range: ['yes','no'],
        //     //                     preffered: ['yes'],
        //     //                     isDependable: true,
        //     //                     dependableID: 'status',
        //     //                     shouldDependent: false,
        //     //                     isInCorrectResponseRange: `I'm expecting a Yes or No answer for the question about completion of your studies at the provided school.`,
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
                            
        //     //                 {
        //     //                     q:'Which year have you finished your studies at the institution you have provided?',
        //     //                     key: 'to',
        //     //                     rules: {isNumber:true},
        //     //                     isDependent: true,
        //     //                     dependentID: 'status',
        //     //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
        //     //                 {
        //     //                     q:'Please type the subject/modules that you majored in at the specified institution',
        //     //                     key: 'decription',
        //     //                     rules: {isString:true,isMaxLen: 100},
        //     //                     isInCorrectResponseString: 'Your majors should be of type text',
        //     //                     isInCorrectResponseMax: 'Your majors should be at most 50 characters long',
        //     //                     isEndQ: true
                                
        //     //                 },
        //     //                 ],
                            
                            
        //     //             },
        //     //             id: 'institutions',
        //     //             isExpected: true
        
                       
        //     //         },
        //     //         {
        //     //             // question: `Please  list your skills`,
        //     //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
        //     //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
        //     //             // isInCorrectOnce: false,
        //     //             isSet: false,
        //     //             isDictionary: true,
        //     //             isEndDictionary: false,
        //     //             isEndDictionaryQuestion: 'Would you like to add another experience?',
        //     //             isEndDictionaryQuestionAccepted: ['Yes','no'],
        //     //             isEndDictionaryQuestionPreffered: ['yes'],
        //     //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
        //     //             dictionaryQuestions: {
        
        //     //                 maxRotations: 2,
        //     //                 currentRotation: 0,
        //     //                 rotationAnswers: [],
        //     //                 answers: {},
        //     //                 currentQuestionIndex: 0,
        //     //                 questions:[
        //     //                 {
        //     //                     q:'Please type the company where you have worked',
        //     //                     key: 'name',
        //     //                     rules: {isString: true, isMaxLen: 50},
        //     //                     isInCorrectResponseString: 'The company name you have provided should not be a number',
        //     //                     isInCorrectResponseMax: 'The company name you have provided  should be at most 50 characters'
        //     //                 },
        //     //                 {
        //     //                     q:'What position did you hold at the company?',
        //     //                     key: 'position',
        //     //                     rules: {isString:true},
        //     //                     isInCorrectResponseString: 'Please note that the position name should not be a number',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
        //     //                 {
        //     //                     q:'Which year have you started working at the company you have provided?',
        //     //                     key: 'from',
        //     //                     rules: {isNumber:true},
        //     //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
                            
        //     //                 {
        //     //                     q:'Which year have you stopped working at the company you have provided?',
        //     //                     key: 'to',
        //     //                     rules: {isNumber:true},
        //     //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
        //     //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
        //     //                 },
        //     //                 {
        //     //                     q:'Please type a small description of what you used to do at the company',
        //     //                     key: 'decription',
        //     //                     rules: {isString:true,isMaxLen: 100},
        //     //                     isInCorrectResponseString: 'Your majors should be of type text',
        //     //                     isInCorrectResponseMax: 'Your majors should be at most 50 characters long',
        //     //                     isEndQ: true
                                
        //     //                 },
        //     //                 ],
                            
                            
        //     //             },
        //     //             id: 'experiences',
        //     //             isExpected: true
        
                       
        //     //         }
        
        
        
                    
        //     //     ]
        //     // },
        //     // {
        //     //     key: 'institute',
        //     //     isComplete: false,
        //     //     queries: [
        
        //     //         {
        //     //             question: `What's the name of the institution where you obtained your qualification?`,
        //     //             // acceptedAnswers: ['Matric','Diploma','Degree','Certificate','Grade','Associate','Bachelor','Masters','Doctoral'],
        //     //             // wrongAnswerResponse: 'Your response should be precise, and be either "Matric","Degree","Diploma","Certificate", or your highest passed grade',
        //     //             // correctAnswerResponse: 'So far so good.',
        //     //             // isInCorrectOnceResponse: 'Lol! Your option is still not correct, please provide your highest academic performance, such as "Grade 10" if that\'s your highest.',
        //     //             // isInCorrectOnce: false,
        //     //             isSet: false,
        //     //             isExpected: true,
        //     //             id: 'name'
        //     //         }
        
        
                    
        //     //     ],
                
        //     // },
        //     {
        //         key: 'skill',
        //         isComplete: false,
        //         queries: [
        
        //             {
        //                 question: `Do you possess any skills?`,
        //                 acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //                 prefferedAnswers: ['Yes','Yes, I do'],
        //                 wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
        //                 isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
        //                 isInCorrectOnce: false,
        //                 isSet: false,
                        
                        
                       
        //             },
        //             {
        //                 // question: `Please  list your skills`,
        //                 // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //                 // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
        //                 // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
        //                 // isInCorrectOnce: false,
        //                 isSet: false,
        //                 isDictionary: true,
        //                 isEndDictionary: false,
        //                 isEndDictionaryQuestion: 'Would you like to add another skill?',
        //                 isEndDictionaryQuestionAccepted: ['Yes','no'],
        //                 isEndDictionaryQuestionPreffered: ['yes'],
        //                 isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
        //                 dictionaryQuestions: {
        
        //                     maxRotations: 3,
        //                     currentRotation: 0,
        //                     rotationAnswers: [],
        //                     answers: {},
        //                     currentQuestionIndex: 0,
        //                     questions:[
        //                     {
        //                         q:'Please type your skill name',
        //                         key: 'name',
        //                         rules: {isString: true, isMaxLen: 25},
        //                         isInCorrectResponseString: 'Your skill name should be a number',
        //                         isInCorrectResponseMax: 'Your skill name should be at most 25 characters'
        //                     },
        //                     {
        //                         q:'On a score of 1 to 100, with 100 being highly skilled for the provided skill, please type your scoree',
        //                         key: 'score',
        //                         rules: {isNumber:true},
        //                         isInCorrectResponseNum: 'Your skill score should be a number',
        //                         isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
        //                         isEndQ: true
        //                     }
        //                     ],
                            
                            
        //                 },
        //                 id: 'skills',
        //                 isExpected: true
        
                       
        //             }
        
        
        
                    
        //         ]
        //     },
        //     // {
        //     //     key: 'language',
        //     //     isComplete: false,
        //     //     queries: [
        
        //     //         {
        //     //             question: `Would you like to add some of the lanugaues you speak?`,
        //     //             acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //             prefferedAnswers: ['Yes','Yes, I do'],
        //     //             wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
        //     //             isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
        //     //             isInCorrectOnce: false,
        //     //             isSet: false,
                        
                        
                       
        //     //         },
        //     //         {
        //     //             // question: `Please  list your skills`,
        //     //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
        //     //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
        //     //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
        //     //             // isInCorrectOnce: false,
        //     //             isSet: false,
        //     //             isDictionary: true,
        //     //             isEndDictionary: false,
        //     //             isEndDictionaryQuestion: 'Would you like to add another langauge?',
        //     //             isEndDictionaryQuestionAccepted: ['Yes','no'],
        //     //             isEndDictionaryQuestionPreffered: ['yes'],
        //     //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
        //     //             dictionaryQuestions: {
        
        //     //                 maxRotations: 3,
        //     //                 currentRotation: 0,
        //     //                 rotationAnswers: [],
        //     //                 answers: {},
        //     //                 currentQuestionIndex: 0,
        //     //                 questions:[
        //     //                 {
        //     //                     q:'Please type your language',
        //     //                     key: 'language',
        //     //                     rules: {isString: true, isMaxLen: 25},
        //     //                     isInCorrectResponseString: 'Your language name should be a text',
        //     //                     isInCorrectResponseMax: 'Your skill name should be at most 25 characters'
        //     //                 },
        //     //                 {
        //     //                     q:`On a rating between "poorly","good","excellently", What's your level of speaking for this language?`,
        //     //                     key: 'speak',
        //     //                     rules: {isRange: true},
        //     //                     range: ['Poorly','Good','Excellently'],
        //     //                     isInCorrectResponseRange: 'Your chosen language speaking level should fall between "poorly","good","excellently',
                               
                               
        //     //                 },
        //     //                 {
        //     //                     q:`On a rating between "poorly","good","excellently", What's your level of writing for this language?`,
        //     //                     key: 'write',
        //     //                     rules: {isRange: true},
        //     //                     range: ['Poorly','Good','Excellently'],
        //     //                     isInCorrectResponseRange: 'Your chosen language writing level should fall between "poorly","good","excellently',
                               
                               
        //     //                 },
        //     //                 {
        //     //                     q:`On a rating between "poorly","good","excellently", What's your level of reading for this language?`,
        //     //                     key: 'read',
        //     //                     rules: {isRange: true},
        //     //                     range: ['Poorly','Good','Excellently'],
        //     //                     isInCorrectResponseRange: 'Your chosen language reading level should fall between "poorly","good","excellently',
        //     //                     isEndQ: true
                               
                               
        //     //                 }
        //     //                 ],
                            
                            
        //     //             },
        //     //             id: 'languages',
        //     //             isExpected: true,
        //     //             isChatLastStepQuestion: true
        
                       
        //     //         }
        
        
        
                    
        //     //     ]
        //     // },
            
        
        
        
        // ]
    },
    personalask: {


        questions:[

            {
                text:'Which part of your personal information would you like to update?',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
                
                
               
            },

        
            {
                text:'Please choose one or more of the options on the message I will send below',
                isTemplate: true,
                template_name: 'personalask',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
                
                
               
            },
        ],
        multipleQuestionsAsk: true,
        endAction: 'loadNext'
  

        
    },
    profileask: {


        questions:[

            {
                text:'What action would you like to take to update your academic information?',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
                
                
               
            },

        
            {
                text:'Please choose one or more of the options on the message I will send below',
                isTemplate: true,
                template_name: 'profileask',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
                
                
               
            },
        ],
        multipleQuestionsAsk: true,
        endAction: 'loadNext'
  

        
    },

    profileitems: {

        alias: '',
        questions:[

        ],
        endAction: 'updateUserProfileSegment'
  

        
    },
    
    askedit: {


        questions:[

            {
                text:'Which one of the records would you like to update?',
                isTemplate: true,
                template_name: 'editItems',
                
                
                
               
            }
        ],
        endAction: 'loadNext'
  

        
    },
    askeditkeys: {


        questions:[

            {
                text:'Which parts of the selected record would you like to update?',
                isTemplate: true,
                template_name: 'editItemKeys',
               
                
                
               
            },

        
            
        ],
        endAction: 'loadNext'
  

        
    },
    askadd: {


        questions:[

            {
                text:'Which of the record(s) would you like to update?',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
                
                
               
            },

        
            {
                text:'Please choose one or more of the options on the message I will send below',
                isTemplate: true,
                template_name: 'askedit',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
                
                
               
            },
        ],
        endAction: 'loadNext'
  

        
    },
    addlimitreached: {


        questions:[

            {
                text:'You have reached your maximum allowed record additions, please remove one or more to add another',
                isTemplate: true,
                template_name: 'limitreached',
                
                
               
            },

        
            
        ],
        endAction: 'loadNext'
  

        
    },
    askdelete: {


        questions:[

            {
                text:'Which of the records would you like to delete?',
                isTemplate: true,
                template_name: 'deleteItems',
               
            },

        
         
        ],
        endAction: 'updateUserProfileSegment'
  

        
    },
    askdeletekeys: {


        questions:[

            {
                text:'Which parts of the selected record would you like to update?',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
                
                
               
            },

        
            {
                text:'Please choose one or more of the options on the message I will send below',
                isTemplate: true,
                template_name: 'askeditkeys',
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
               
                
                
               
            },
        ],
        endAction: 'loadNext'
  

        
    },
    cv: {

        questions: [

            {
                key: 'welcome',
                isComplete: false,
                queries: [
        
                    {
                        question: `Hi there Surprise, I'm excited you want to create your cv with us, over the next few minutes, I'll be chating with you to help you create your cv with ease.`,
                        // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                        // prefferedAnswers: ['Yes','Yes, I do'],
                        // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                        // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                        // isInCorrectOnce: false,
                        // isSet: false,
                        isNoAccept: true
                        
                        
                       
                    },
                    {
                        question: `Are you ready to rumble?`,
                        acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                        prefferedAnswers: ['Yes','Yes, I do'],
                        wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
                        isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
                        afterUnreadyResponse: "I can see that you have reconsidered chating further, please respond with 'Initiate'",
                        afterUnreadyResponseOnce: "You still have not responded with the expected response, please respond with 'Initiate",
                        endChatResponse: [
                            "I understand it may be inconvenient for you to chat now, you can initiate a chat anytime you are ready by reloading this page",
                            `I'm sorry to learn that you are not ready, you can initiate a chat anytime by reloading the page, or sending a text "Initiate"`,
                            `Thanks for considering creating a cv with our platform, please comeback a initiate a chat when you are ready`
                           ],
                        isEndChatAlternate: `Alternatively, you can just "Initiate" now.`,
                        isInCorrectOnce: false,
                        isUnreadyResponseOnce: false,
                        isSet: false,
                        isChatEnd: true
                        //id: 'skill',
                        //isExpected: true
        
                       
                    }
        
        
        
                    
                ]
            },
            
            {
                key: 'qualification',
                isComplete: false,
                queries: [
        
                    {
                        question: `What's your highest academic achievement?`,
                        acceptedAnswers: ['Matric','Diploma','Degree','Certificate','Grade','Associate','Bachelor','Masters','Doctoral'],
                        wrongAnswerResponse: 'Your response should be precise, and be either "Matric","Degree","Diploma","Certificate", or your highest passed grade',
                        correctAnswerResponse: 'So far so good.',
                        isInCorrectOnceResponse: 'Lol! Your option is still not correct, please provide your highest academic performance, such as "Grade 10" if that\'s your highest.',
                        isInCorrectOnce: false,
                        isSet: false,
                        isExpected: true,
                        id: 'name'
                    }
        
        
                    
                ],
                
            },
            // {
            //     key: 'institute',
            //     isComplete: false,
            //     queries: [
        
            //         // {
            //         //     question: `Would you like to ?`,
            //         //     acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //         //     prefferedAnswers: ['Yes','Yes, I do'],
            //         //     wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
            //         //     isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
            //         //     isInCorrectOnce: false,
            //         //     isSet: false,
                        
                        
                       
            //         // },
            //         {
            //             // question: `Please  list your skills`,
            //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
            //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
            //             // isInCorrectOnce: false,
            //             isSet: false,
            //             isDictionary: true,
            //             isEndDictionary: false,
            //             isEndDictionaryQuestion: 'Would you like to add another institution?',
            //             isEndDictionaryQuestionAccepted: ['Yes','no'],
            //             isEndDictionaryQuestionPreffered: ['yes'],
            //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
            //             dictionaryQuestions: {
        
            //                 maxRotations: 2,
            //                 currentRotation: 0,
            //                 rotationAnswers: [],
            //                 answers: {},
            //                 currentQuestionIndex: 0,
            //                 questions:[
            //                 {
            //                     q:'Please type the institution where you have studied',
            //                     key: 'name',
            //                     rules: {isString: true, isMaxLen: 50},
            //                     isInCorrectResponseString: 'Your institution name should be a number',
            //                     isInCorrectResponseMax: 'Your institution name should be at most 50 characters'
            //                 },
            //                 {
            //                     q:'Which year have you begun your studies at the institution you have provided?',
            //                     key: 'from',
            //                     rules: {isNumber:true},
            //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
        
            //                 {
            //                     q:'Have you completed your studies at the Institution?',
            //                     key: 'status',
            //                     rules: {isRange:true},
            //                     range: ['yes','no'],
            //                     preffered: ['yes'],
            //                     isDependable: true,
            //                     dependableID: 'status',
            //                     shouldDependent: false,
            //                     isInCorrectResponseRange: `I'm expecting a Yes or No answer for the question about completion of your studies at the provided school.`,
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
                            
            //                 {
            //                     q:'Which year have you finished your studies at the institution you have provided?',
            //                     key: 'to',
            //                     rules: {isNumber:true},
            //                     isDependent: true,
            //                     dependentID: 'status',
            //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
            //                 {
            //                     q:'Please type the subject/modules that you majored in at the specified institution',
            //                     key: 'decription',
            //                     rules: {isString:true,isMaxLen: 100},
            //                     isInCorrectResponseString: 'Your majors should be of type text',
            //                     isInCorrectResponseMax: 'Your majors should be at most 50 characters long',
            //                     isEndQ: true
                                
            //                 },
            //                 ],
                            
                            
            //             },
            //             id: 'institutions',
            //             isExpected: true
        
                       
            //         },
            //         {
            //             // question: `Please  list your skills`,
            //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
            //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
            //             // isInCorrectOnce: false,
            //             isSet: false,
            //             isDictionary: true,
            //             isEndDictionary: false,
            //             isEndDictionaryQuestion: 'Would you like to add another experience?',
            //             isEndDictionaryQuestionAccepted: ['Yes','no'],
            //             isEndDictionaryQuestionPreffered: ['yes'],
            //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
            //             dictionaryQuestions: {
        
            //                 maxRotations: 2,
            //                 currentRotation: 0,
            //                 rotationAnswers: [],
            //                 answers: {},
            //                 currentQuestionIndex: 0,
            //                 questions:[
            //                 {
            //                     q:'Please type the company where you have worked',
            //                     key: 'name',
            //                     rules: {isString: true, isMaxLen: 50},
            //                     isInCorrectResponseString: 'The company name you have provided should not be a number',
            //                     isInCorrectResponseMax: 'The company name you have provided  should be at most 50 characters'
            //                 },
            //                 {
            //                     q:'What position did you hold at the company?',
            //                     key: 'position',
            //                     rules: {isString:true},
            //                     isInCorrectResponseString: 'Please note that the position name should not be a number',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
            //                 {
            //                     q:'Which year have you started working at the company you have provided?',
            //                     key: 'from',
            //                     rules: {isNumber:true},
            //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
                            
            //                 {
            //                     q:'Which year have you stopped working at the company you have provided?',
            //                     key: 'to',
            //                     rules: {isNumber:true},
            //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
            //                 {
            //                     q:'Please type a small description of what you used to do at the company',
            //                     key: 'decription',
            //                     rules: {isString:true,isMaxLen: 100},
            //                     isInCorrectResponseString: 'Your majors should be of type text',
            //                     isInCorrectResponseMax: 'Your majors should be at most 50 characters long',
            //                     isEndQ: true
                                
            //                 },
            //                 ],
                            
                            
            //             },
            //             id: 'experiences',
            //             isExpected: true
        
                       
            //         }
        
        
        
                    
            //     ]
            // },
            // {
            //     key: 'institute',
            //     isComplete: false,
            //     queries: [
        
            //         {
            //             question: `What's the name of the institution where you obtained your qualification?`,
            //             // acceptedAnswers: ['Matric','Diploma','Degree','Certificate','Grade','Associate','Bachelor','Masters','Doctoral'],
            //             // wrongAnswerResponse: 'Your response should be precise, and be either "Matric","Degree","Diploma","Certificate", or your highest passed grade',
            //             // correctAnswerResponse: 'So far so good.',
            //             // isInCorrectOnceResponse: 'Lol! Your option is still not correct, please provide your highest academic performance, such as "Grade 10" if that\'s your highest.',
            //             // isInCorrectOnce: false,
            //             isSet: false,
            //             isExpected: true,
            //             id: 'name'
            //         }
        
        
                    
            //     ],
                
            // },
            {
                key: 'skill',
                isComplete: false,
                queries: [
        
                    {
                        question: `Do you possess any skills?`,
                        acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                        prefferedAnswers: ['Yes','Yes, I do'],
                        wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                        isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                        isInCorrectOnce: false,
                        isSet: false,
                        
                        
                       
                    },
                    {
                        // question: `Please  list your skills`,
                        // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                        // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
                        // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
                        // isInCorrectOnce: false,
                        isSet: false,
                        isDictionary: true,
                        isEndDictionary: false,
                        isEndDictionaryQuestion: 'Would you like to add another skill?',
                        isEndDictionaryQuestionAccepted: ['Yes','no'],
                        isEndDictionaryQuestionPreffered: ['yes'],
                        isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
                        dictionaryQuestions: {
        
                            maxRotations: 3,
                            currentRotation: 0,
                            rotationAnswers: [],
                            answers: {},
                            currentQuestionIndex: 0,
                            questions:[
                            {
                                q:'Please type your skill name',
                                key: 'name',
                                rules: {isString: true, isMaxLen: 25},
                                isInCorrectResponseString: 'Your skill name should be a number',
                                isInCorrectResponseMax: 'Your skill name should be at most 25 characters'
                            },
                            {
                                q:'On a score of 1 to 100, with 100 being highly skilled for the provided skill, please type your scoree',
                                key: 'score',
                                rules: {isNumber:true},
                                isInCorrectResponseNum: 'Your skill score should be a number',
                                isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                isEndQ: true
                            }
                            ],
                            
                            
                        },
                        id: 'skills',
                        isExpected: true
        
                       
                    }
        
        
        
                    
                ]
            },
            // {
            //     key: 'language',
            //     isComplete: false,
            //     queries: [
        
            //         {
            //             question: `Would you like to add some of the lanugaues you speak?`,
            //             acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             prefferedAnswers: ['Yes','Yes, I do'],
            //             wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
            //             isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
            //             isInCorrectOnce: false,
            //             isSet: false,
                        
                        
                       
            //         },
            //         {
            //             // question: `Please  list your skills`,
            //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
            //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
            //             // isInCorrectOnce: false,
            //             isSet: false,
            //             isDictionary: true,
            //             isEndDictionary: false,
            //             isEndDictionaryQuestion: 'Would you like to add another langauge?',
            //             isEndDictionaryQuestionAccepted: ['Yes','no'],
            //             isEndDictionaryQuestionPreffered: ['yes'],
            //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
            //             dictionaryQuestions: {
        
            //                 maxRotations: 3,
            //                 currentRotation: 0,
            //                 rotationAnswers: [],
            //                 answers: {},
            //                 currentQuestionIndex: 0,
            //                 questions:[
            //                 {
            //                     q:'Please type your language',
            //                     key: 'language',
            //                     rules: {isString: true, isMaxLen: 25},
            //                     isInCorrectResponseString: 'Your language name should be a text',
            //                     isInCorrectResponseMax: 'Your skill name should be at most 25 characters'
            //                 },
            //                 {
            //                     q:`On a rating between "poorly","good","excellently", What's your level of speaking for this language?`,
            //                     key: 'speak',
            //                     rules: {isRange: true},
            //                     range: ['Poorly','Good','Excellently'],
            //                     isInCorrectResponseRange: 'Your chosen language speaking level should fall between "poorly","good","excellently',
                               
                               
            //                 },
            //                 {
            //                     q:`On a rating between "poorly","good","excellently", What's your level of writing for this language?`,
            //                     key: 'write',
            //                     rules: {isRange: true},
            //                     range: ['Poorly','Good','Excellently'],
            //                     isInCorrectResponseRange: 'Your chosen language writing level should fall between "poorly","good","excellently',
                               
                               
            //                 },
            //                 {
            //                     q:`On a rating between "poorly","good","excellently", What's your level of reading for this language?`,
            //                     key: 'read',
            //                     rules: {isRange: true},
            //                     range: ['Poorly','Good','Excellently'],
            //                     isInCorrectResponseRange: 'Your chosen language reading level should fall between "poorly","good","excellently',
            //                     isEndQ: true
                               
                               
            //                 }
            //                 ],
                            
                            
            //             },
            //             id: 'languages',
            //             isExpected: true,
            //             isChatLastStepQuestion: true
        
                       
            //         }
        
        
        
                    
            //     ]
            // },
            
        
        
        
        ]
    },
    support: {

        questions: [

            {
                key: 'welcome',
                isComplete: false,
                queries: [
        
                    {
                        question: `Hi there Surprise, I'm excited you want to create your cv with us, over the next few minutes, I'll be chating with you to help you create your cv with ease.`,
                        // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                        // prefferedAnswers: ['Yes','Yes, I do'],
                        // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                        // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                        // isInCorrectOnce: false,
                        // isSet: false,
                        isNoAccept: true
                        
                        
                       
                    },
                    {
                        question: `Are you ready to rumble?`,
                        acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                        prefferedAnswers: ['Yes','Yes, I do'],
                        wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
                        isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
                        afterUnreadyResponse: "I can see that you have reconsidered chating further, please respond with 'Initiate'",
                        afterUnreadyResponseOnce: "You still have not responded with the expected response, please respond with 'Initiate",
                        endChatResponse: [
                            "I understand it may be inconvenient for you to chat now, you can initiate a chat anytime you are ready by reloading this page",
                            `I'm sorry to learn that you are not ready, you can initiate a chat anytime by reloading the page, or sending a text "Initiate"`,
                            `Thanks for considering creating a cv with our platform, please comeback a initiate a chat when you are ready`
                           ],
                        isEndChatAlternate: `Alternatively, you can just "Initiate" now.`,
                        isInCorrectOnce: false,
                        isUnreadyResponseOnce: false,
                        isSet: false,
                        isChatEnd: true
                        //id: 'skill',
                        //isExpected: true
        
                       
                    }
        
        
        
                    
                ]
            },
            
            {
                key: 'qualification',
                isComplete: false,
                queries: [
        
                    {
                        question: `What's your highest academic achievement?`,
                        acceptedAnswers: ['Matric','Diploma','Degree','Certificate','Grade','Associate','Bachelor','Masters','Doctoral'],
                        wrongAnswerResponse: 'Your response should be precise, and be either "Matric","Degree","Diploma","Certificate", or your highest passed grade',
                        correctAnswerResponse: 'So far so good.',
                        isInCorrectOnceResponse: 'Lol! Your option is still not correct, please provide your highest academic performance, such as "Grade 10" if that\'s your highest.',
                        isInCorrectOnce: false,
                        isSet: false,
                        isExpected: true,
                        id: 'name'
                    }
        
        
                    
                ],
                
            },
            // {
            //     key: 'institute',
            //     isComplete: false,
            //     queries: [
        
            //         // {
            //         //     question: `Would you like to ?`,
            //         //     acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //         //     prefferedAnswers: ['Yes','Yes, I do'],
            //         //     wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
            //         //     isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
            //         //     isInCorrectOnce: false,
            //         //     isSet: false,
                        
                        
                       
            //         // },
            //         {
            //             // question: `Please  list your skills`,
            //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
            //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
            //             // isInCorrectOnce: false,
            //             isSet: false,
            //             isDictionary: true,
            //             isEndDictionary: false,
            //             isEndDictionaryQuestion: 'Would you like to add another institution?',
            //             isEndDictionaryQuestionAccepted: ['Yes','no'],
            //             isEndDictionaryQuestionPreffered: ['yes'],
            //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
            //             dictionaryQuestions: {
        
            //                 maxRotations: 2,
            //                 currentRotation: 0,
            //                 rotationAnswers: [],
            //                 answers: {},
            //                 currentQuestionIndex: 0,
            //                 questions:[
            //                 {
            //                     q:'Please type the institution where you have studied',
            //                     key: 'name',
            //                     rules: {isString: true, isMaxLen: 50},
            //                     isInCorrectResponseString: 'Your institution name should be a number',
            //                     isInCorrectResponseMax: 'Your institution name should be at most 50 characters'
            //                 },
            //                 {
            //                     q:'Which year have you begun your studies at the institution you have provided?',
            //                     key: 'from',
            //                     rules: {isNumber:true},
            //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
        
            //                 {
            //                     q:'Have you completed your studies at the Institution?',
            //                     key: 'status',
            //                     rules: {isRange:true},
            //                     range: ['yes','no'],
            //                     preffered: ['yes'],
            //                     isDependable: true,
            //                     dependableID: 'status',
            //                     shouldDependent: false,
            //                     isInCorrectResponseRange: `I'm expecting a Yes or No answer for the question about completion of your studies at the provided school.`,
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
                            
            //                 {
            //                     q:'Which year have you finished your studies at the institution you have provided?',
            //                     key: 'to',
            //                     rules: {isNumber:true},
            //                     isDependent: true,
            //                     dependentID: 'status',
            //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
            //                 {
            //                     q:'Please type the subject/modules that you majored in at the specified institution',
            //                     key: 'decription',
            //                     rules: {isString:true,isMaxLen: 100},
            //                     isInCorrectResponseString: 'Your majors should be of type text',
            //                     isInCorrectResponseMax: 'Your majors should be at most 50 characters long',
            //                     isEndQ: true
                                
            //                 },
            //                 ],
                            
                            
            //             },
            //             id: 'institutions',
            //             isExpected: true
        
                       
            //         },
            //         {
            //             // question: `Please  list your skills`,
            //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
            //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
            //             // isInCorrectOnce: false,
            //             isSet: false,
            //             isDictionary: true,
            //             isEndDictionary: false,
            //             isEndDictionaryQuestion: 'Would you like to add another experience?',
            //             isEndDictionaryQuestionAccepted: ['Yes','no'],
            //             isEndDictionaryQuestionPreffered: ['yes'],
            //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
            //             dictionaryQuestions: {
        
            //                 maxRotations: 2,
            //                 currentRotation: 0,
            //                 rotationAnswers: [],
            //                 answers: {},
            //                 currentQuestionIndex: 0,
            //                 questions:[
            //                 {
            //                     q:'Please type the company where you have worked',
            //                     key: 'name',
            //                     rules: {isString: true, isMaxLen: 50},
            //                     isInCorrectResponseString: 'The company name you have provided should not be a number',
            //                     isInCorrectResponseMax: 'The company name you have provided  should be at most 50 characters'
            //                 },
            //                 {
            //                     q:'What position did you hold at the company?',
            //                     key: 'position',
            //                     rules: {isString:true},
            //                     isInCorrectResponseString: 'Please note that the position name should not be a number',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
            //                 {
            //                     q:'Which year have you started working at the company you have provided?',
            //                     key: 'from',
            //                     rules: {isNumber:true},
            //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
                            
            //                 {
            //                     q:'Which year have you stopped working at the company you have provided?',
            //                     key: 'to',
            //                     rules: {isNumber:true},
            //                     isInCorrectResponseNum: 'The year that you have started should be a valid year',
            //                     // isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                
            //                 },
            //                 {
            //                     q:'Please type a small description of what you used to do at the company',
            //                     key: 'decription',
            //                     rules: {isString:true,isMaxLen: 100},
            //                     isInCorrectResponseString: 'Your majors should be of type text',
            //                     isInCorrectResponseMax: 'Your majors should be at most 50 characters long',
            //                     isEndQ: true
                                
            //                 },
            //                 ],
                            
                            
            //             },
            //             id: 'experiences',
            //             isExpected: true
        
                       
            //         }
        
        
        
                    
            //     ]
            // },
            // {
            //     key: 'institute',
            //     isComplete: false,
            //     queries: [
        
            //         {
            //             question: `What's the name of the institution where you obtained your qualification?`,
            //             // acceptedAnswers: ['Matric','Diploma','Degree','Certificate','Grade','Associate','Bachelor','Masters','Doctoral'],
            //             // wrongAnswerResponse: 'Your response should be precise, and be either "Matric","Degree","Diploma","Certificate", or your highest passed grade',
            //             // correctAnswerResponse: 'So far so good.',
            //             // isInCorrectOnceResponse: 'Lol! Your option is still not correct, please provide your highest academic performance, such as "Grade 10" if that\'s your highest.',
            //             // isInCorrectOnce: false,
            //             isSet: false,
            //             isExpected: true,
            //             id: 'name'
            //         }
        
        
                    
            //     ],
                
            // },
            {
                key: 'skill',
                isComplete: false,
                queries: [
        
                    {
                        question: `Do you possess any skills?`,
                        acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                        prefferedAnswers: ['Yes','Yes, I do'],
                        wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                        isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                        isInCorrectOnce: false,
                        isSet: false,
                        
                        
                       
                    },
                    {
                        // question: `Please  list your skills`,
                        // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                        // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
                        // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
                        // isInCorrectOnce: false,
                        isSet: false,
                        isDictionary: true,
                        isEndDictionary: false,
                        isEndDictionaryQuestion: 'Would you like to add another skill?',
                        isEndDictionaryQuestionAccepted: ['Yes','no'],
                        isEndDictionaryQuestionPreffered: ['yes'],
                        isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
                        dictionaryQuestions: {
        
                            maxRotations: 3,
                            currentRotation: 0,
                            rotationAnswers: [],
                            answers: {},
                            currentQuestionIndex: 0,
                            questions:[
                            {
                                q:'Please type your skill name',
                                key: 'name',
                                rules: {isString: true, isMaxLen: 25},
                                isInCorrectResponseString: 'Your skill name should be a number',
                                isInCorrectResponseMax: 'Your skill name should be at most 25 characters'
                            },
                            {
                                q:'On a score of 1 to 100, with 100 being highly skilled for the provided skill, please type your scoree',
                                key: 'score',
                                rules: {isNumber:true},
                                isInCorrectResponseNum: 'Your skill score should be a number',
                                isInCorrecResponseMax: 'Please note that your skill score should be up to 100',
                                isEndQ: true
                            }
                            ],
                            
                            
                        },
                        id: 'skills',
                        isExpected: true
        
                       
                    }
        
        
        
                    
                ]
            },
            // {
            //     key: 'language',
            //     isComplete: false,
            //     queries: [
        
            //         {
            //             question: `Would you like to add some of the lanugaues you speak?`,
            //             acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             prefferedAnswers: ['Yes','Yes, I do'],
            //             wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
            //             isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
            //             isInCorrectOnce: false,
            //             isSet: false,
                        
                        
                       
            //         },
            //         {
            //             // question: `Please  list your skills`,
            //             // acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
            //             // wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Yes, I do","No","no, I don`t", etc',
            //             // isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please list your skills in the format indicated in the last text as your answer.',
            //             // isInCorrectOnce: false,
            //             isSet: false,
            //             isDictionary: true,
            //             isEndDictionary: false,
            //             isEndDictionaryQuestion: 'Would you like to add another langauge?',
            //             isEndDictionaryQuestionAccepted: ['Yes','no'],
            //             isEndDictionaryQuestionPreffered: ['yes'],
            //             isEndDictionaryWrongAnswerResponse: 'Please reply yes or no so that I can send you my next question',
            //             dictionaryQuestions: {
        
            //                 maxRotations: 3,
            //                 currentRotation: 0,
            //                 rotationAnswers: [],
            //                 answers: {},
            //                 currentQuestionIndex: 0,
            //                 questions:[
            //                 {
            //                     q:'Please type your language',
            //                     key: 'language',
            //                     rules: {isString: true, isMaxLen: 25},
            //                     isInCorrectResponseString: 'Your language name should be a text',
            //                     isInCorrectResponseMax: 'Your skill name should be at most 25 characters'
            //                 },
            //                 {
            //                     q:`On a rating between "poorly","good","excellently", What's your level of speaking for this language?`,
            //                     key: 'speak',
            //                     rules: {isRange: true},
            //                     range: ['Poorly','Good','Excellently'],
            //                     isInCorrectResponseRange: 'Your chosen language speaking level should fall between "poorly","good","excellently',
                               
                               
            //                 },
            //                 {
            //                     q:`On a rating between "poorly","good","excellently", What's your level of writing for this language?`,
            //                     key: 'write',
            //                     rules: {isRange: true},
            //                     range: ['Poorly','Good','Excellently'],
            //                     isInCorrectResponseRange: 'Your chosen language writing level should fall between "poorly","good","excellently',
                               
                               
            //                 },
            //                 {
            //                     q:`On a rating between "poorly","good","excellently", What's your level of reading for this language?`,
            //                     key: 'read',
            //                     rules: {isRange: true},
            //                     range: ['Poorly','Good','Excellently'],
            //                     isInCorrectResponseRange: 'Your chosen language reading level should fall between "poorly","good","excellently',
            //                     isEndQ: true
                               
                               
            //                 }
            //                 ],
                            
                            
            //             },
            //             id: 'languages',
            //             isExpected: true,
            //             isChatLastStepQuestion: true
        
                       
            //         }
        
        
        
                    
            //     ]
            // },
            
        
        
        
        ]
    },
    close:{

        questions:[

            {
                text:[
                    `It was great chating with you, Goodbye`,
                    `It was great chatting with you, enjoy what remains of today`,
                    
                ],
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
                isHigherOrder: true
               
             
                
               
            },
        ],
        skipOnReturner: true,
         
  
            

    },
    
    closeWithFailure:{

        endAction:"closeWithFailurePostActivity",
        multipleQuestionsAsk: true,
        skipOnReturner: true,
        questions:[

            {
                text:[
                    `It was great chating with you, it's just unfortunate we couldn't finish our chat, I believe we'll do better next time, peace`,
                    `It was great chatting with you, allow us to rectify our mistakes next time`,
                    
                ],
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
                isHigherOrder: true
               
             
                
               
            },
            {
                // text:[`...for now, I'm wishing you a great day`,`Wishing you a great day`],
                isTemplate: true,
                template_name: 'closeWithFailureConfirm',
               
               
                
                
              
            },
            {
                text:[`...for now, I'm wishing you a great day`,`Wishing you a great day`],
                isTemplate: true,
                template_name: 'closeWithFailure',
                skipOnInitial: true
               
               
                
                
              
            },
        ]

         
  
            

    },
    postActivity:{

        endAction:"postActivity",
        multipleQuestionsAsk: true,
        questions:[

            {
                text:[
                    `bye, cheers!`,
                    `good bye`,
                    
                ],
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
                isHigherOrder: true
               
             
                
               
            },
            {
                // text:[`I see that you are still texting... could that imply that you still want to chat?`],
                isTemplate: true,
                template_name: 'postActivityClose',
              
               
            },
            {
                text:[
                    `cool`,
                    `cool beans`,
                    
                ],
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
                isHigherOrder: true,
                skipOnInitial: true
               
             
                
               
            },
            {
                text:[`I see that you are still texting... could that imply that you still want to chat?`],
                isTemplate: true,
                template_name: 'postActivity',
                // skipOnInitial: true
               
            },
        ]
         
  
            

    },
    continueToChats:{

        endAction:"continueToChats",
        questions:[

           
           
            {
                text:[`No worries, what would you like to do?`],
                isTemplate: true,
                template_name: 'continueToChats',
                
               
            },
        ]
         
  
            

    },
    confirmNewCleanChat:{

        endAction:"confirmNewCleanChat",
        questions:[

           
           
            {
                text:[`A new, fresh chat will clear any other session you've had before, are you sure you want to continue`],
                isTemplate: true,
                template_name: 'confirmNewCleanChat',
               
               
            },
        ]
         
  
            

    },
    thinkItThrough:{

        endAction:"thinkItThrough",
        questions:[

            {
                text:[
                    `No worries, just type anything once you have made your decision`,
                    
                    
                ],
                acceptedAnswers: ['Yes','No','Yes, I do','No, I dont','I do','I don`t','yea','yeah'],
                prefferedAnswers: ['Yes','Yes, I do'],
                wrongAnswerResponse: 'Your response should be precise, and be either "Yes","Ye, I do","No","no, I don`t", etc',
                isInCorrectOnceResponse: 'Lol! Your option is still incorrect, please choose either "Yes", "Ye","ye,I do","yes, I do", "No", or "No, I don`t" as your answer.',
                isInCorrectOnce: false,
                isSet: false,
                isHostStateInitMessage:true,
                isNoAccept: true,
                isHigherOrder: true
               
             
                
               
            },
           
           
            {
                text:[`seeing your text, I take it you have made up your mind`],
                isTemplate: true,
                template_name: 'thinkItThrough',
                skipOnInitial: true
               
               
            },
        ]
         
  
            

    },
    editrecordcomplete:{

        questions:[

          {
              text:[
                  `You have successfully updated your profile`,
                 
              ],
                
             
          },
          {
              text:'...would you like to update any other record?',
              isTemplate: true,
              template_name: 'editcomplete',
             
             
              
              
             
          },
         
        ],
        multipleQuestionsAsk: true,
        skipOnReturner: true,
        endAction: 'loadNext'

          
    },
    editfeaturecomplete:{

        questions:[

          {
              text:'...would you like to update any educational information?',
              isTemplate: true,
              template_name: 'editcomplete',
             
             
              
              
             
          },
         
        ],
        endAction: 'loadNext'

          
    },
    editprofilecomplete:{

        questions:[

          {
              text:'...would you like to update any other part of your profile?',
              isTemplate: true,
              template_name: 'editcomplete',
             
             
              
              
             
          },
         
        ],
        endAction: 'loadNext'

          
    },
    addrecordcomplete:{

        questions:[

          {
              text:[
                  `You have successfully updated your profile`,
                 
              ],
                
             
          },
          {
              text:'...would you like to add another record?',
              isTemplate: true,
              template_name: 'editcomplete',
             
             
              
              
             
          },
         
        ],
        multipleQuestionsAsk: true,
        endAction: 'loadNext'

          
    },
    deleterecordcomplete:{

        questions:[

          {
              text:[
                  `You have successfully updated your profile`,
                 
              ],
                
             
          },
          {
              text:'...would you like to delete another record?',
              isTemplate: true,
              template_name: 'editcomplete',
             
             
              
              
             
          },
         
        ],
        multipleQuestionsAsk: true,
        endAction: 'loadNext'

          
    },
    editprofilefailure:{

        questions:[

            {
                text:[
                    `The was an error sending update information`,
                   
                ],
            },

          {
              text:'...would you like the system to resend update?',
              isTemplate: true,
              template_name: 'editfailure',
             
             
              
              
             
          },
         
        ],
        endAction: 'loadNext'

          
    },
    addrecordfailure:{

        questions:[

          {
              text:[
                  `There was an error adding your record`,
                 
              ],
                
             
          },
          {
              text:'...would you like to try again?',
              isTemplate: true,
              template_name: 'editfailure',
             
             
              
              
             
          },
         
        ],
        endAction: 'loadNext'

          
    },
    deleterecordfailure:{

        questions:[

          {
              text:[
                  `The chosen record could not be deleted`,
                 
              ],
                
             
          },
          {
              text:'...would you like to try deleting it again?',
              isTemplate: true,
              template_name: 'editfailure',
             
             
              
              
             
          },
         
        ],
        endAction: 'loadNext'

          
    },

        
      
}
    
   
        
    
    


