import React, { Component } from 'react' 
import {Link} from 'react-router-dom'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import Notifications from '@material-ui/icons/Notifications';
import DescriptionIcon from '@material-ui/icons/Description';


const styles = {
    root: {
     
      color:"#ee4c7c",
      fontSize: 20,

      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    },
    white: {color:'white',fontSize: 20},
  };




class Join extends Component{
    
    constructor(props){

        super(props)

    }


    render(){

        const {props} = this 
        const {classes,width} = props 
        const UploadCV = props.upload 
        const styles = {

            fontSize: '1.1rem',
            paddingTop: '3px',
            fontWeight: 'normal',
            color: 'white',border: 'none'
        }
        // const containerMobileStyles = width <= 800 ? {display: 'inline-block',width: '90px',padding: 0,margin:0} : null
        const contClass = width <= 800 ? 'job__jobs--lt-join job__jobs--lt-mobile-containers' : 'job__jobs--lt-join'
        // const mobileStyles = width <= 800 ? {

        // display: 'inline-block',
        // width: 'auto',
        // backgroundColor: 'transparent',
        // padding: 0,
        // margin: 0,
        // } : null

        return(





           <section className={contClass}>


                
               

                    {/* <h4 className="job__jobs--lt-tags-title">
                        <span className="job__jobs--lt-tags-title-icon"><Icon><Notifications className={classes.root} /> </Icon></span>
                        <small className="job__jobs--lt-tags-title-text">Trending Tags</small>
                    </h4> */}

              {width > 800 
                    ?  <div class="job__jobs--lt-join-card">
                            <div class="job__jobs--lt-join-card-rect job__jobs--lt-join-card-rect-1 bx-raised">
                            </div>
                            <div class="job__jobs--lt-join-card-rect job__jobs--lt-join-card-rect-2 bx-raised">
                            </div>
                            <div class="job__jobs--lt-join-card-rect job__jobs--lt-join-card-rect-3 bx-raised">
                            </div>
                            <div class="job__jobs--lt-join-card-rect job__jobs--lt-join-card-rect-4 bx-raised">

                                <h4 className="job__jobs--lt-tags-title">
                                <span className="job__jobs--lt-tags-title-icon"><Icon><DescriptionIcon className={classes.root} /> </Icon></span>
                                <small className="job__jobs--lt-tags-title-text">ADD CV</small>
                            </h4>

                            <p className="job__jobs--lt-join-card-rect-4-text">Let employers find you quick and easier. Add your resume now!</p>

                            <button className="job__jobs--lt-join-card-rect-4-sub-btn">
                                <span className="job__jobs--lt-join-card-rect-4-sub-btn-rect"></span>
                                <span className="job__jobs--lt-join-card-rect-4-sub-btn-rect-2">
                                    <small><UploadCV text="Add Resume" styles={styles} /></small>
                                    </span> 

                            </button>
                            

                            </div>
                        </div>
                    : <UploadCV>
                    
                        <button className="job__jobs--lt-mobile-card-rect-4-sub-btn">
                            <span className="job__jobs--lt-mobile-card-rect-4-sub-btn-rect"></span>
                                <span className="job__jobs--lt-mobile-card-rect-4-sub-btn-rect-2">
                                    <small><Icon><DescriptionIcon className={classes.white} /></Icon></small>
                                    </span> 

                        </button>
                    </UploadCV>
                }
                    

                

              
                
             
           </section>
        )
    }

}


export default withStyles(styles)(Join)